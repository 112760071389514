angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/messages/messages.tpl.html',
    '<div ng-controller="MessagesCtrl as messages" class="map-panel-controller">\n' +
    '  <div id="map-panel-messages" class="map-panel map-primary-panel" ng-swipe-left="messages.closePanel()" ng-swipe-disable-mouse>\n' +
    '    <div class="row map-panel-heading">\n' +
    '      <div class="small-2 columns">\n' +
    '        <a class="arrow-back" ng-click="messages.closePanel()">\n' +
    '          <i class="fa fa-angle-double-left"></i>\n' +
    '        </a>\n' +
    '      </div>\n' +
    '      <div class="small-13 columns">\n' +
    '        <h3>Messages</h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="map-primary-panel-content">\n' +
    '      <div class="row cursor-pointer"\n' +
    '           ng-repeat="conversation in messages.conversationsLoader.records | activeRecord: \'map.messages.detail\' : \'id\' : \'contactProfile\'"\n' +
    '           ng-class="{\'is-active-record\': conversation.isActiveRecord}"\n' +
    '           ng-click="messages.openConversationByIndex($index)">\n' +
    '        <div class="small-5 columns map-panel-profile-photo">\n' +
    '          <img ng-src="{{conversation.contactProfile | profilePicture:45}}" class="circle45">\n' +
    '        </div>\n' +
    '        <div class="small-10 columns">\n' +
    '          <span class="panel-text-heading">\n' +
    '            <user-widget-name user="conversation.contactProfile" one-line="false"></user-widget-name>\n' +
    '            <favourite-star iduser="{{conversation.contactProfile.id}}"></favourite-star>\n' +
    '          </span>\n' +
    '          <br/>\n' +
    '          <span class="info-text">\n' +
    '            <i class="fa fa-clock-o"></i> {{conversation.lastUpdated}} ago\n' +
    '          </span><br/>\n' +
    '          <span ng-show="conversation.sentByMe">Me: </span>\n' +
    '          {{conversation.lastMessage}}\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div id="map-panel-conversation-messages" class="map-secondary-panel-container"></div>\n' +
    '</div>\n' +
    '');
}]);
