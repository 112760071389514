(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name panels.myWork.service:MyWorkPanelManager
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.MyWork')
    .service('MyWorkPanelManager', MyWorkPanelManager);

  function MyWorkPanelManager(PanelsFactory) {
    var
      self = this,
      targetContainerId = 'map-panel-work-actions',
      panels = {
        editWork: {
          id: 'map-panel-my-work-edit',
          templateUrl: 'map/panels/my-work/my-work-edit.tpl.html'
        },
        bidDetail: {
          id: 'map-panel-my-work-bid-detail',
          templateUrl: 'map/panels/my-work/my-work-bid-detail.tpl.html'
        }
      };

    self.panels = PanelsFactory.get(panels, targetContainerId, true, false, true);
  }

})();
