(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Cache.service:Cache
   *
   * @description
   *
   */
  angular
    .module('Cache')
    .service('Cache', Cache);

  function Cache(DSCacheFactory) {
    var self = this;

    self.create = function (name, options) {
      return new DSCacheFactory(name, angular.extend({
        deleteOnExpire: 'aggressive',
        storageMode: 'localStorage'
      }, options));
    };

    self.get = DSCacheFactory.get;
  }

})();
