angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/people/people-invite-to-join.tpl.html',
    '<div id="map-panel-people-invite-to-join-action" class="map-secondary-panel"\n' +
    '     ng-controller="PeopleInviteToJoinCtrl as peopleInviteToJoin" ng-swipe-left="peopleInviteToJoin.closePanel()" ng-swipe-disable-mouse>\n' +
    '  <div class="row map-panel-heading">\n' +
    '    <div class="small-2 columns">\n' +
    '      <a class="arrow-back" ng-click="peopleInviteToJoin.closePanel()">\n' +
    '        <i class="fa fa-angle-double-left"></i>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '    <div class="small-13 columns">\n' +
    '      <h3>Send Invitation</h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="invite-detail-container">\n' +
    '\n' +
    '    <div class="row invite-resend-form">\n' +
    '      <div class="small-15 columns">\n' +
    '        <form name="inviteToJoinForm" role="inviteToJoinForm">\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <input type="text" name="firstName" ng-model="peopleInviteToJoin.data.firstName" placeholder="First name" ng-required="true" />\n' +
    '              <small class="invite-resend-error" ng-show="inviteToJoinForm.firstName.$dirty && inviteToJoinForm.firstName.$error.required">\n' +
    '                Missing first name\n' +
    '              </small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <input type="text" name="lastName" ng-model="peopleInviteToJoin.data.lastName" placeholder="Last name" ng-required="true" />\n' +
    '              <small class="invite-resend-error" ng-show="inviteToJoinForm.lastName.$dirty && inviteToJoinForm.lastName.$error.required">\n' +
    '                Missing last name\n' +
    '              </small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <input name="email" type="email" ng-model="peopleInviteToJoin.data.email" placeholder="Email Address" ng-required="true" />\n' +
    '              <small class="invite-resend-error" ng-show="inviteToJoinForm.email.$dirty && inviteToJoinForm.email.$error.required">\n' +
    '                Missing email address\n' +
    '              </small>\n' +
    '              <small class="invite-resend-error" ng-show="inviteToJoinForm.email.$dirty && inviteToJoinForm.email.$error.email">\n' +
    '                This is not a valid email address\n' +
    '              </small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <textarea name="message" ng-required="true" ng-model="peopleInviteToJoin.data.message" placeholder="Message"\n' +
    '                        msd-elastic></textarea>\n' +
    '              <small class="invite-resend-error" ng-show="inviteToJoinForm.message.$dirty && inviteToJoinForm.message.$error.required">\n' +
    '                Missing request message\n' +
    '              </small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-10 columns">\n' +
    '              <small class="invite-resend-error" ng-show="peopleInviteToJoin.errorMessage">{{peopleInviteToJoin.errorMessage}}</small>\n' +
    '            </div>\n' +
    '            <div class="small-5 columns invites-buttons-container text-right">\n' +
    '              <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}"\n' +
    '              spinner-key="spinner-people-invite-to-join"></span>\n' +
    '              <button ng-hide="peopleInviteToJoin.isSending" class="button positive" ng-click="peopleInviteToJoin.send()"\n' +
    '                ng-disabled="inviteToJoinForm.$invalid">SEND</button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '        </form>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row">\n' +
    '      <div class="small-15 columns">\n' +
    '        <h3>Contacts Import</h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="row">\n' +
    '      <div class="small-15 columns">\n' +
    '        <small class="error" ng-show="peopleInviteToJoin.importErrorMessage">{{peopleInviteToJoin.importErrorMessage}}</small>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row">\n' +
    '      <div class="small-15 columns">\n' +
    '        <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}"\n' +
    '              spinner-key="spinner-import-contacts"></span>\n' +
    '        <ul ng-hide="peopleInviteToJoin.isImporting" class="small-block-grid-3 signin-social">\n' +
    '          <!--<li>-->\n' +
    '            <!--<a ng-click="peopleInviteToJoin.importFacebook()"><i class="fa fa-facebook"></i></a>-->\n' +
    '          <!--</li>-->\n' +
    '          <!--<li>-->\n' +
    '            <!--<a ng-click="peopleInviteToJoin.importLinkedin()"><i class="fa fa-linkedin"></i></a>-->\n' +
    '          <!--</li>-->\n' +
    '          <li>\n' +
    '            <a ng-click="peopleInviteToJoin.importGoogleplus()" title="Google" alt="Google"><i class="fa fa-google-plus"></i></a>\n' +
    '          </li>\n' +
    '          <li>\n' +
    '            <span class="cursor-pointer" ng-file-select="peopleInviteToJoin.importVCard($files)" ng-multiple="false"\n' +
    '               ng-accept="\'text/vcard,text/x-vcard,application/vcard\'" title="Import from file (vCard)" alt="Upload a file (vCard)"><i class="fa fa-upload"></i></span>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
