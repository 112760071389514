(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Panels.Profile.controller:PrivacySettingsCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Profile')
    .controller('PrivacySettingsCtrl', PrivacySettingsCtrl);

  function PrivacySettingsCtrl($scope, PanelsProfileManager, usSpinnerService, $analytics) {
    $scope.isSaving = false;
    $scope.options = {
      public: 'Everyone',
      network: 'My network',
      private: 'Only me'
    };
    $scope.editProfileErrorMessage = null;

    $scope.closeEditPanel = function () {
      PanelsProfileManager.panels.close('privacySettings');
    };

    $scope.save = function () {
      $scope.isSaving = true;
      usSpinnerService.spin('spinner-profile-edit');
      $scope.editProfileErrorMessage = null;
      $scope.privacy.save().then(function () {
        usSpinnerService.stop('spinner-profile-edit');
        $scope.isSaving = false;
        $scope.closeEditPanel();
        $analytics.eventTrack('Update Privacy Settings', {category: 'Profile'});
      }, function (result) {
        $scope.editProfileErrorMessage = result.data.errorMessage;
        usSpinnerService.stop('spinner-profile-edit');
        $scope.isSaving = false;
      });
    };
  }

})();
