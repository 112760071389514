(function () {
  'use strict';

  /* @ngdoc object
   * @name Panels.Updates
   * @requires $stateProvider
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Updates', [
      'xeditable'
    ]);

  angular
    .module('Map.Panels.Updates')
    .config(config);

  function config($stateProvider, Defaults) {
    $stateProvider
      .state('map.updates', angular.extend({}, Defaults.mapStateParams, {
        url: '/updates',
        onEnter: ['QueueManager', 'InitHelper', function (QueueManager, InitHelper) {
          QueueManager.add(InitHelper.getOpenPanelQueueItem('updates'));
        }]
      }))
      .state('map.updates.replies', angular.extend({}, Defaults.mapStateParams, {
        url: '/{id}/replies',
        onEnter: ['QueueManager', 'QueueItem', '$q', '$timeout', '$rootScope', '$stateParams', 'InitHelper',
          function (QueueManager, QueueItem, $q, $timeout, $rootScope, $stateParams, InitHelper) {
            QueueManager.add(new QueueItem(function (panelReady) {
              $q.when(panelReady).then(function () {
                $timeout(function () {
                  $rootScope.$broadcast('event:updates-open-reply', {
                    idUpdate: InitHelper.decryptId($stateParams.id)
                  });
                }, 100);
              });

              return $q.when(true);
            }));
          }]
      }));
  }

})();
