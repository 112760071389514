(function () {
  'use strict';

  /* @ngdoc object
   * @name Map.Panels.Profile
   * @requires $stateProvider
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Profile', [
      'ngImgCrop',
      'Components'
    ]);

  angular
    .module('Map.Panels.Profile')
    .config(config);

  function config($stateProvider, Defaults) {
    $stateProvider
      .state('map.profile', angular.extend({}, Defaults.mapStateParams, {
        url: '/profile',
        onEnter: ['$q', '$stateParams', 'QueueManager', 'QueueItem', 'PanelsManager',
          function ($q, $stateParams, QueueManager, QueueItem, PanelsManager) {
            QueueManager.add(new QueueItem(function () {
              PanelsManager.ready.then(function () {
                PanelsManager.panels.open('profile');
              });
              return $q.when(true);
            }));
          }]
      }))
      .state('map.profile.name', angular.extend({}, Defaults.mapStateParams, {
        url: '/name',
        onEnter: ['QueueManager', 'InitHelper', 'QueueItem', '$q', '$timeout', '$rootScope',
          function (QueueManager, InitHelper, QueueItem, $q, $timeout, $rootScope) {
            QueueManager.add(new QueueItem(function (panelReady) {
              $q.when(panelReady).then(function () {
                $timeout(function () {
                  $rootScope.$broadcast('event:profile-open-name');
                });
              });

              return $q.when(true);
            }));
          }]
      }))
      .state('map.profile.contact', angular.extend({}, Defaults.mapStateParams, {
        url: '/contact',
        onEnter: ['QueueManager', 'InitHelper', 'QueueItem', '$q', '$timeout', '$rootScope',
          function (QueueManager, InitHelper, QueueItem, $q, $timeout, $rootScope) {
            QueueManager.add(new QueueItem(function (panelReady) {
              $q.when(panelReady).then(function () {
                $timeout(function () {
                  $rootScope.$broadcast('event:profile-open-contact');
                });
              });

              return $q.when(true);
            }));
          }]
      }))
      .state('map.profile.about', angular.extend({}, Defaults.mapStateParams, {
        url: '/about',
        onEnter: ['QueueManager', 'InitHelper', 'QueueItem', '$q', '$timeout', '$rootScope',
          function (QueueManager, InitHelper, QueueItem, $q, $timeout, $rootScope) {
            QueueManager.add(new QueueItem(function (panelReady) {
              $q.when(panelReady).then(function () {
                $timeout(function () {
                  $rootScope.$broadcast('event:profile-open-about');
                });
              });

              return $q.when(true);
            }));
          }]
      }))
      .state('map.profile.privacy', angular.extend({}, Defaults.mapStateParams, {
        url: '/privacy',
        onEnter: ['QueueManager', 'InitHelper', 'QueueItem', '$q', '$timeout', '$rootScope',
          function (QueueManager, InitHelper, QueueItem, $q, $timeout, $rootScope) {
            QueueManager.add(new QueueItem(function (panelReady) {
              $q.when(panelReady).then(function () {
                $timeout(function () {
                  $rootScope.$broadcast('event:profile-open-privacy');
                });
              });

              return $q.when(true);
            }));
          }]
      }))
      .state('map.profile.password', angular.extend({}, Defaults.mapStateParams, {
        url: '/password',
        onEnter: ['QueueManager', 'InitHelper', 'QueueItem', '$q', '$timeout', '$rootScope',
          function (QueueManager, InitHelper, QueueItem, $q, $timeout, $rootScope) {
            QueueManager.add(new QueueItem(function (panelReady) {
              $q.when(panelReady).then(function () {
                $timeout(function () {
                  $rootScope.$broadcast('event:profile-open-password');
                });
              });

              return $q.when(true);
            }));
          }]
      }))
      .state('map.profile.picture', angular.extend({}, Defaults.mapStateParams, {
        url: '/picture',
        onEnter: ['QueueManager', 'InitHelper', 'QueueItem', '$q', '$timeout', '$rootScope',
          function (QueueManager, InitHelper, QueueItem, $q, $timeout, $rootScope) {
            QueueManager.add(new QueueItem(function (panelReady) {
              $q.when(panelReady).then(function () {
                $timeout(function () {
                  $rootScope.$broadcast('event:profile-open-picture');
                });
              });

              return $q.when(true);
            }));
          }]
      }))
      .state('map.profile.subscriptions', angular.extend({}, Defaults.mapStateParams, {
        url: '/subscriptions',
        onEnter: ['QueueManager', 'InitHelper', 'QueueItem', '$q', '$timeout', '$rootScope',
          function (QueueManager, InitHelper, QueueItem, $q, $timeout, $rootScope) {
            QueueManager.add(new QueueItem(function (panelReady) {
              $q.when(panelReady).then(function () {
                $timeout(function () {
                  $rootScope.$broadcast('event:profile-open-subscriptions');
                });
              });

              return $q.when(true);
            }));
          }]
      }));
  }

})();
