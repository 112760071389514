(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Panels.Experience.controller:ExperienceRequestValidationCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Experience')
    .controller('ExperienceRequestValidationCtrl', ExperienceRequestValidationCtrl);

  function ExperienceRequestValidationCtrl(Defaults, $scope, $filter, usSpinnerService, $state,
                                           ExperienceManager, ExperiencePanelManager, $analytics) {
    var vm = this;
    vm.detail = $scope.detail;
    vm.searchUrl = Defaults.baseUrl + '/Users/index?search[network]=network&search[query]=';
    vm.errorMessage = null;
    vm.isSending = false;
    vm.data = {
      idUser: null,
      firstName: '',
      lastName: '',
      email: '',
      message: 'Can you please validate my experience?'
    };

    vm.closePanel = function () {
      ExperiencePanelManager.panels.close('requestValidation', {}, angular.noop);
    };

    vm.onSearchResponse = function (response) {
      var records = [];
      angular.forEach(response.content.records, function (record) {
        record.profilePicture = $filter('profilePicture')(record);
        records.push(record);
      });

      return records;
    };

    vm.onSearchSelect = function (selected) {
      if (!angular.isDefined(selected)) {
        return;
      }

      vm.data.idUser = selected.originalObject.id;
    };

    vm.send = function () {
      vm.errorMessage = null;
      if (parseInt(vm.data.idUser, 10) > 0 ||
        (vm.data.firstName !== '' && vm.data.lastName !== '' && vm.data.email !== '')) {
        vm.isSending = true;
        usSpinnerService.spin('spinner-request-validation');
        ExperienceManager.requestValidation(vm.detail.id, vm.data, function () {
          vm.closePanel();
          $analytics.eventTrack('Request validation', {category: 'Experience'});
        }, function (reason) {
          usSpinnerService.stop('spinner-request-validation');
          vm.isSending = false;
          vm.errorMessage = reason;
        });
      } else {
        vm.errorMessage = 'Select a user from your network or provide first name, last name and email of new one.';
      }
    };
  }

})();
