(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Panels.People.service:PeopleManager
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.People')
    .service('PeopleManager', PeopleManager);

  function PeopleManager(PanelsFactory, $state) {
    var
      self = this,
      targetContainerId = 'map-panel-people-actions',
      panels = {
        peopleInvite: {
          id: 'map-panel-people-invite',
          templateUrl: 'map/panels/people/people-invite.tpl.html'
        },
        peopleDetail: {
          id: 'map-panel-people-detail',
          templateUrl: 'map/panels/people/people-detail.tpl.html',
          resolve: {
            userProfile: ['Auth', function (Auth) {
              return Auth.getSession();
            }]
          }
        },
        inviteToJoin: {
          id: 'map-panel-people-invite-to-join',
          templateUrl: 'map/panels/people/people-invite-to-join.tpl.html'
        }
      };

    self.panels = PanelsFactory.get(panels, targetContainerId, true, false, true, function () {
      $state.go('map.people');
    });
  }

})();
