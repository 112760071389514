(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name LostPassword.controller:LostPasswordResetCtrl
   *
   * @description
   *
   */
  angular
    .module('LostPassword')
    .controller('LostPasswordResetCtrl', LostPasswordResetCtrl);

  function LostPasswordResetCtrl($stateParams, Defaults, Restangular, usSpinnerService, $state, $analytics) {
    var vm = this;
    vm.data = {};
    vm.passwordPattern = new RegExp(Defaults.passwordPattern);
    vm.passwordMessage = Defaults.passwordMessage;
    vm.isResetting = false;
    vm.isInvalidToken = false;
    vm.isReset = false;

    if (!angular.isDefined($stateParams.token) ||
      !angular.isString($stateParams.token) ||
      $stateParams.token.length < 40) {
      vm.isInvalidToken = true;
    }

    vm.reset = function () {
      vm.errorMessage = null;
      vm.isResetting = true;
      usSpinnerService.spin('spinner-lost-password');
      var dataEncoded = $.param(vm.data);
      Restangular.one('Users').customPUT(dataEncoded, 'lostPassword', {
        token: $stateParams.token
      }, {
        'Content-Type': 'application/x-www-form-urlencoded'
      }).then(function () {
        vm.isReset = true;
        $analytics.eventTrack('Reset', {category: 'Lost Password'});
      }, function (result) {
        usSpinnerService.stop('spinner-lost-password');
        vm.errorMessage = result.data.errorMessage;
        vm.isResetting = false;
      });
    };

    vm.continue = function () {
      $state.go('signin');
    };
  }

})();
