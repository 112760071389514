angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('registration/account-verification.tpl.html',
    '<div id="email-verification" class="image-background-connection">\n' +
    '  <div class="row outside-container">\n' +
    '    <div class="small-15 medium-9 large-8 columns outside-form" ng-if="accountVerification.validToken">\n' +
    '      <form name="accountVerificationForm" role="accountVerificationForm">\n' +
    '        <div class="row">\n' +
    '          <div class="small-6 small-push-9 columns text-right">\n' +
    '            <img src="images/mineler.png" class="home-page-logo"/>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row">\n' +
    '          <div class="small-15 columns text-left">\n' +
    '            <h4><user-widget-name user="accountVerification.detail"></user-widget-name>,</h4>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row">\n' +
    '          <div class="small-15 columns text-left">\n' +
    '            You are one step away from being a verified Mineler member.<br/>\n' +
    '            Enter you new password to continue.\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row content">\n' +
    '          <div class="small-15 columns">\n' +
    '            <input name="password" type="password" ng-model="accountVerification.data.password"\n' +
    '                   placeholder="Password" ng-required="true" ng-pattern="accountVerification.passwordPattern" />\n' +
    '            <small class="error" ng-show="accountVerificationForm.password.$dirty && accountVerificationForm.password.$error.required">\n' +
    '              Missing new password\n' +
    '            </small>\n' +
    '            <small class="error" ng-show="accountVerificationForm.password.$dirty && accountVerificationForm.password.$error.pattern">\n' +
    '              {{accountVerification.passwordMessage}}\n' +
    '            </small>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="small-15 columns">\n' +
    '            <input type="password" name="verifyPassword" ng-model="accountVerification.verifyPassword"\n' +
    '                   placeholder="Verify Password" ng-required="true" match="accountVerification.data.password" />\n' +
    '            <small class="error" ng-show="accountVerificationForm.verifyPassword.$dirty && accountVerificationForm.verifyPassword.$error.match">\n' +
    '              Passwords do not match\n' +
    '            </small>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row content">\n' +
    '          <div class="small-10 columns">\n' +
    '            <small class="error" ng-show="accountVerification.errorMessage">{{accountVerification.errorMessage}}</small>\n' +
    '          </div>\n' +
    '          <div class="small-5 columns text-right email-verify-button">\n' +
    '            <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-email-verify"></span>\n' +
    '            <button ng-hide="accountVerification.isVerifying" class="button positive" ng-click="accountVerification.verify()"\n' +
    '                    ng-disabled=\'accountVerificationForm.$invalid\'>Continue</button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="small-15 medium-11 large-10 columns outside-message" ng-if="!accountVerification.validToken">\n' +
    '      <div class="row">\n' +
    '        <div class="small-6 small-push-9 columns text-right">\n' +
    '          <img src="images/mineler.png" class="home-page-logo"/>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row" ng-if="accountVerification.error102">\n' +
    '        <div class="small-6 medium-3 columns message-icon">\n' +
    '          <i class="fa fa-minus-circle"></i>\n' +
    '        </div>\n' +
    '        <div class="small-9 medium-12 columns message-text text-left">\n' +
    '          <h3>Oops,</h3>\n' +
    '          Some information is missing. Please try again.\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row" ng-if="accountVerification.error103">\n' +
    '        <div class="small-6 medium-3 columns message-icon">\n' +
    '          <i class="fa fa-exclamation-circle"></i>\n' +
    '        </div>\n' +
    '        <div class="small-9 medium-12 columns message-text text-left">\n' +
    '          <h3>We\'re sorry, your request cannot be located.</h3>\n' +
    '          Please help us improve your Mineler experience by contacting us.\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns text-right">\n' +
    '          <button class="button positive" ng-click="accountVerification.continue()">Continue</button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
