(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name Map.Panels.Messages.directive:messagesThread
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="panels.messages">
   <file name="index.html">
   <messages-thread></messages-thread>
   </file>
   </example>
   *
   */
  angular
    .module('Map.Panels.Messages')
    .directive('messagesThread', messagesThread);

  function messagesThread() {
    return {
      restrict: 'EA',
      scope: {
        myProfile: '=myProfile',
        contactProfile: '=contactProfile',
        autoLoader: '=autoLoader',
        onSendCallback: '=',
        onLoadCallback: '=',
        reversed: '=reversed',
        parameters: '='
      },
      templateUrl: 'map/panels/messages/messages-thread-directive.tpl.html',
      replace: false,
      controllerAs: 'messagesThread',
      controller: function ($scope, $timeout, usSpinnerService, $analytics, RecordsSet, $filter) {
        var
          vm = this,
          onSendCallback = $scope.onSendCallback || angular.noop,
          onLoadCallback = $scope.onLoadCallback || angular.noop,
          parameters = $scope.parameters || {};

        vm.isSending = false;
        vm.errorMessage = null;
        vm.myProfile = $scope.myProfile;
        vm.contactProfile = $scope.contactProfile;
        if (angular.isDefined($scope.autoLoader)) {
          vm.autoLoader = $scope.autoLoader;
        } else {
          vm.autoLoader = true;
        }

        if (angular.isDefined($scope.reversed)) {
          vm.reversed = $scope.reversed;
        } else {
          vm.reversed = true;
        }

        vm.messagesLoader = RecordsSet.getLoader('Messages', 'index', angular.extend({}, {
          idUser: $scope.contactProfile.idUser
        }, parameters));

        vm.getMessages = function () {
          if (vm.reversed) {
            return $filter('reverse')(vm.messagesLoader.records);
          }

          return vm.messagesLoader.records;
        };

        vm.getProfile = function (idUser) {
          if (idUser === vm.myProfile.id) {
            return vm.myProfile;
          }

          return vm.contactProfile;
        };

        vm.send = function () {
          vm.errorMessage = null;
          vm.isSending = true;
          usSpinnerService.spin('spinner-messages-new');

          vm.messagesLoader.post(angular.extend({}, {
            message: vm.newMessage
          }, parameters), {
            id: vm.contactProfile.idUser
          }, 'beginning')
            .then(function () {
              vm.newMessage = null;
              vm.isSending = false;
              usSpinnerService.stop('spinner-messages-new');
              onSendCallback();
              $analytics.eventTrack('Send', {category: 'Messages'});
            }, function () {
              vm.isSending = false;
              vm.errorMessage = 'An error occurred';
              usSpinnerService.stop('spinner-messages-new');
            });
        };

        vm.messagesLoader.load()
          .then(function () {
            onLoadCallback();
          });

        if (vm.autoLoader) {
          $timeout(function () {
            $('.message-thread-list').scroll(angular.bind(vm, function (event) {
              if (vm.messagesLoader.hasMore() && event.currentTarget.scrollTop < 50) {
                vm.messagesLoader.load();
              }
            }));
          }, 500);
        }
      }
    };
  }
}());
