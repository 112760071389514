angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/work/work-detail-updates.tpl.html',
    '<div class="work-detail-updates-container" ng-if="workDetail.updatesLoader.records.length > 0">\n' +
    '  <div class="row">\n' +
    '    <div class="small-15 columns">\n' +
    '      <span class="panel-text-heading">Updates</span>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row cursor-pointer update-row"\n' +
    '       ng-repeat="update in workDetail.updatesLoader.records | activeRecord: \'map.work.detail.updates\' : \'idUpdate\'"\n' +
    '       ng-class="{\'is-active-record\': update.isActiveRecord}"\n' +
    '       ng-click="workDetail.openUpdateByIndex($index)">\n' +
    '    <div class="small-3 columns map-panel-profile-photo">\n' +
    '      <img ng-src="{{update.userCreated | profilePicture:45}}" class="circle45">\n' +
    '    </div>\n' +
    '    <div class="small-12 columns">\n' +
    '      <user-widget-name user="update.userCreated"></user-widget-name>\n' +
    '      <br/>\n' +
    '          <span class="info-text">\n' +
    '            <i class="fa fa-clock-o"></i> {{update.modified}} ago\n' +
    '            <span ng-show="update.relatedUpdatesCount">\n' +
    '              <i class="fa fa-reply-all"></i>  {{update.relatedUpdatesCount}}\n' +
    '            </span>\n' +
    '            <span ng-show="update.mediaCountTotal">\n' +
    '              <i class="fa fa-image"></i> {{update.mediaCountTotal}}\n' +
    '            </span>\n' +
    '            <span ng-show="update.connectedRecords.location">\n' +
    '              <i class="fa fa-map-marker"></i>\n' +
    '            </span>\n' +
    '          </span><br/>\n' +
    '          <span class="update-text-content">\n' +
    '            {{update.textContent | truncate: 50: \'...\': true}}\n' +
    '          </span>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row">\n' +
    '    <div class="small-15 columns text-center">\n' +
    '      <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}"\n' +
    '            spinner-key="spinner-work-detail-updates"></span>\n' +
    '      <a ng-show="workDetail.updatesLoader.hasMore() && !workDetail.updatesLoader.isLoading"\n' +
    '         ng-click="workDetail.loadUpdates()">Load more ...</a>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
