(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Experience.service:ExperienceManager
   *
   * @description
   *
   */
  angular
    .module('Experience')
    .service('ExperienceManager', ExperienceManager);

  function ExperienceManager(Restangular, Auth, $rootScope, $filter) {
    var self = this;

    function processRequest(token, message, action, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;
      Restangular.one('ExperienceValidations').customPOST({
        message: message
      }, action, {
        token: token
      }).then(function (result) {
        successCallback(result);
      }, function (result) {
        var reason = result.data.errorMessage;
        errorCallback(reason);
      });
    }

    self.delete = function (id, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;
      Restangular.one('Experience').one('index').remove({id: id}).then(function (result) {
        successCallback(result);
      }, function (result) {
        errorCallback(result.data.errorMessage);
      });
    };

    self.validate = function (token, message, successCallback, errorCallback) {
      processRequest(token, message, 'validate', successCallback, errorCallback);
    };

    self.ignore = function (token, message, successCallback, errorCallback) {
      processRequest(token, message, 'ignore', successCallback, errorCallback);
    };

    self.resend = function (id, requestMessage, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;
      Restangular.one('ExperienceValidations').customPOST({requestMessage: requestMessage}, 'resend', {
        id: id
      }).then(function (result) {
        successCallback(result.plain());
      }, function (result) {
        var reason = result.data.errorMessage;
        errorCallback(reason);
      });
    };

    self.requestValidation = function (id, data, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;
      Restangular.one('ExperienceValidations').customPOST(data, 'index', {
        idExperience: id
      }).then(function (result) {
        successCallback(result);
      }, function (result) {
        var reason = result.data.errorMessage;
        errorCallback(reason);
      });
    };

    self.validationDetail = function (token) {
      return Restangular.one('Experience').one('detail').get({token: token});
    };

    self.loadById = function (id, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;

      return Restangular.one('Experience').one('index').get({id: id})
        .then(function (result) {
          successCallback(result);
        }, function (result) {
          errorCallback(result.data.errorMessage);
        });
    };

    self.loadValidationById = function (id, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;

      return Restangular.one('ExperienceValidations').one('index').get({id: id})
        .then(function (result) {
          successCallback(result.plain());
        }, function (result) {
          errorCallback(result.data.errorMessage);
        });
    };

    self.loadValidations = function (id) {
      if (isNaN(id) || parseInt(id, 10) < 1) {
        throw 'Invalid id of experience';
      }

      return Restangular.one('ExperienceValidations').getList('index', {
        idExperience: id,
        limit: -1
      });
    };

    self.addExperience = function (data, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;
      Restangular.one('Experience').customPOST(data, 'index', {})
        .then(function (result) {
          result = angular.extend(result, {
            latitude: data.latitude,
            longitude: data.longitude
          });
          $rootScope.$broadcast('event:experience-added', result);
          successCallback(result);
        }, function (result) {
          var reason = result.data.errorMessage;
          errorCallback(reason);
        });
    };

    self.editExperience = function (data, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;
      Restangular.one('Experience').customPUT(data, 'index', {id: data.id})
        .then(function (result) {
          $rootScope.$broadcast('event:experience-updated', result);
          successCallback(result);
        }, function (result) {
          var reason = result.data.errorMessage;
          errorCallback(reason);
        });
    };

    self.validateExperienceData = function (experience, hasDataChanged) {
      var
        dateFromNum,
        dateToNum,
        dateNowNum,
        startDateValid = true,
        endDateValid = true,
        errors = {
          isError: false
        };

      if (!angular.isDefined(hasDataChanged)) {
        hasDataChanged = false;
      }

      if (experience.projectName === '' && (isNaN(experience.idProject) || parseInt(experience.idProject, 10) <= 0)) {
        errors.projectError = 'Project has to be selected';
        errors.isError = true;
      }

      if (experience.employerName === '' && (isNaN(experience.idEmployer) ||
        parseInt(experience.idEmployer, 10) <= 0)) {
        errors.employerError = 'Company has to be selected';
        errors.isError = true;
      }

      if (experience.jobTitleName === '' && (isNaN(experience.idJobTitle) ||
        parseInt(experience.idJobTitle, 10) <= 0)) {
        errors.jobTitleError = 'Position has to be selected';
        errors.isError = true;
      }

      dateFromNum = parseInt(experience.dateFrom.replace(/-/g, ''), 10);
      dateToNum = parseInt(experience.dateTo.replace(/-/g, ''), 10);
      dateNowNum = parseInt($filter('date')(new Date(), 'yyyyMMdd'), 10);

      if (dateFromNum === 0) {
        errors.dateFromError = 'At least year has to be provided';
        errors.isError = true;
        startDateValid = false;
      } else {
        if (dateFromNum > dateNowNum) {
          errors.dateFromError = 'Time period cannot start in future';
          errors.isError = true;
          startDateValid = false;
        }
      }

      if (dateToNum === 0 && experience.isCurrentExperience !== true) {
        errors.dateToError = 'At least year has to be provided for non current experience';
        errors.isError = true;
        endDateValid = false;
      }

      if (startDateValid && endDateValid) {
        if (experience.isCurrentExperience !== true && dateFromNum > dateToNum) {
          errors.dateToError = 'Experience cannot end before start date';
          errors.isError = true;
        }
      }

      if (hasDataChanged === true && experience.validationsCounter > 0) {
        if (experience.validationsAction !== 'delete' &&
          experience.validationsAction !== 'invalidate' &&
          experience.validationsAction !== 'invalidate_resend'
        ) {
          errors.validationsAction = 'Select what would you like to do with existing validations';
          errors.isError = true;
        }
      }

      return errors;
    };

  }

})();
