(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Map.controller:ButtonsCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Toolbars')
    .controller('ButtonsCtrl', ButtonsCtrl);

  function ButtonsCtrl(MapComponent, $rootScope, HelpManager, $analytics) {
    var vm = this;
    vm.isSatelliteView = true;

    vm.setSatelliteView = function () {
      MapComponent.setSatelliteView();
      vm.isSatelliteView = true;
      $analytics.eventTrack('Change View', {category: 'Map', label: 'Satellite View'});
    };

    vm.setRoadView = function () {
      MapComponent.setRoadView();
      vm.isSatelliteView = false;
      $analytics.eventTrack('Change View', {category: 'Map', label: 'Road View'});
    };

    vm.resetMap = function () {
      MapComponent.resetBounds();
      $analytics.eventTrack('Center Map', {category: 'Map'});
    };

    vm.dropPin = function () {
      MapComponent.dropPin();
      $analytics.eventTrack('Drop a Pin', {category: 'Map'});
    };

    vm.isDroppedPin = MapComponent.getIsDroppedPin;

    vm.toggleMyVisibility = function () {
      MapComponent.toggleMyLocationsVisiblity();
      $analytics.eventTrack('Toggle My Pins', {category: 'Map'});
    };
    vm.myLocationsVisible = MapComponent.areMyLocationsVisible;

    vm.toggleFilter = function () {
      MapComponent.turnFilterOff();
      $analytics.eventTrack('Cancel Filters', {category: 'Map'});
    };
    vm.isFilter = MapComponent.isFilter;

    vm.toggleCluster = function () {
      MapComponent.toggleCluster();
      $analytics.eventTrack('Toggle Clusters', {category: 'Map'});
    };

    vm.isCluster = MapComponent.isCluster;

    vm.goHome = function () {
      MapComponent.turnFilterOff();
      $rootScope.$broadcast('event:map-reset-home');
      $analytics.eventTrack('Home', {category: 'Map'});
    };

    vm.openHelp = function () {
      HelpManager.open();
      $analytics.eventTrack('Help', {category: 'Map'});
    };
  }

})();
