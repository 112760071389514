(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Map.Toolbars.controller:FavouriteUsersCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Toolbars.FavouriteUsers')
    .controller('FavouriteUsersCtrl', FavouriteUsersCtrl);

  function FavouriteUsersCtrl(FavouriteUsers, $scope) {
    FavouriteUsers.loadFromCache();
    var vm = this;

    vm.list = FavouriteUsers.list;

    vm.scrollLeft = function () {
      $('#map-favourite-users-container').scrollLeft($('#map-favourite-users-container').scrollLeft() - 35);
    };

    vm.scrollRight = function () {
      $('#map-favourite-users-container').scrollLeft($('#map-favourite-users-container').scrollLeft() + 35);
    };

    vm.removeUser = function ($index) {
      FavouriteUsers.removeUser($index);
    };

    vm.isVisible = FavouriteUsers.isVisible;
    vm.toggleVisibility = function (idUser) {
      FavouriteUsers.toggleVisibility(idUser);
    };

    $scope.$on('event:favouriteUsers-loaded', angular.bind(vm, function () {
      vm.list = FavouriteUsers.list;
    }));
  }

})();
