(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name Panels.People.directive:userWidgetName
   * @restrict E
   * @element
   *
   */
  angular
    .module('Map.Panels.People')
    .directive('userWidgetName', userWidgetName);

  function userWidgetName($state, InitHelper) {
    return {
      restrict: 'E',
      template: '<span>' +
      '<a ng-if="userWidgetName.isLink" ng-click="userWidgetName.openProfile($event)" ' +
      '   ng-bind-html="userWidgetName.userName"></a>' +
      '<span ng-if="!userWidgetName.isLink" ng-bind-html="userWidgetName.userName"></span>' +
      '</span>',
      scope: {
        user: '=',
        isLink: '@',
        onlyFirstName: '@',
        oneLine: '@'
      },
      replace: true,
      controllerAs: 'userWidgetName',
      controller: ['$scope', function ($scope) {
        var
          vm = this,
          delimiter;
        if ($scope.isLink !== 'true') {
          vm.isLink = false;
        } else {
          vm.isLink = true;
        }
        if ($scope.onlyFirstName !== 'true') {
          $scope.onlyFirstName = false;
        } else {
          $scope.onlyFirstName = true;
        }
        if ($scope.oneLine !== 'false') {
          $scope.oneLine = true;
          delimiter = ' ';
        } else {
          $scope.oneLine = false;
          delimiter = '<br/>';
        }

        if ($scope.user.isCompany) {
          vm.userName = $scope.user.companyName;
        } else {
          vm.userName = ($scope.onlyFirstName ?
            $scope.user.firstName : $scope.user.firstName + delimiter + $scope.user.lastName);
        }

        vm.openProfile = function ($event) {
          $event.stopPropagation();
          $state.go('map.people.detail', {id: InitHelper.encryptId($scope.user.id)});
        };
      }]
    };
  }

})();
