(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Panels.Experience.controller:ExperienceEditCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Experience')
    .controller('ExperienceEditCtrl', ExperienceEditCtrl);

  function ExperienceEditCtrl($scope, ExperiencePanelManager, Defaults, $state,
                              ExperienceManager, usSpinnerService, Restangular, $analytics) {
    var vm = this;
    vm.isAdding = false;
    vm.errorMessage = null;
    vm.searchCompanyUrl = Defaults.baseUrl + '/Customers/index?search[query]=';
    vm.searchPositionUrl = Defaults.baseUrl + '/JobTitles/index?search[query]=';
    vm.data = $scope.experienceDetail;
    vm.data.dateFrom = vm.data.dateFrom.date;
    vm.data.dateTo = vm.data.dateTo.date;
    vm.data.validationsAction = 'invalidate_resend';
    vm.projects = $scope.projects;
    vm.hasDataChanged = false;

    vm.closePanel = function (experienceData) {
      var data = {};
      if (angular.isObject(experienceData)) {
        data = experienceData;
      }
      ExperiencePanelManager.panels.close('experienceEdit', data, function () {
        $state.go('map.experienceMy');
      });
    };

    vm.onFocusCompany = function () {
      if (vm.data.idEmployer !== 0) {
        vm.data.employerName = '';
        vm.data.idEmployer = 0;
        $scope.$broadcast('angucomplete-alt:clearInput', 'experience-company-input');
      }
    };

    vm.onFocusJobTitle = function () {
      if (vm.data.idJobTitle !== 0) {
        vm.data.jobTitleName = '';
        vm.data.idJobTitle = 0;
        $scope.$broadcast('angucomplete-alt:clearInput', 'experience-position-input');
      }
    };

    vm.onSearchResponse = function (response) {
      return response.content.records;
    };

    vm.onCompanySearchSelect = function (selected) {
      if (!angular.isDefined(selected)) {
        return;
      }
      if (angular.isObject(selected.originalObject)) {
        vm.data.idEmployer = selected.originalObject.id;
      }
    };

    vm.onCompanyChanged = function (query) {
      vm.data.employerName = query;
    };

    vm.onPositionSearchSelect = function (selected) {
      if (!angular.isDefined(selected)) {
        return;
      }
      if (angular.isObject(selected.originalObject)) {
        vm.data.idJobTitle = selected.originalObject.id;
      }
    };

    vm.onPositionChanged = function (query) {
      vm.data.jobTitleName = query;
    };

    vm.loadSkills = function ($query) {
      return Restangular.one('Tags').getList('index', {
        query: $query,
        limit: 15
      }).then(function (result) {
        return result.plain();
      });
    };

    vm.save = function () {
      var toSave = angular.copy(vm.data);
      vm.errorMessage = null;
      vm.errors = ExperienceManager.validateExperienceData(toSave, vm.hasDataChanged);

      if (vm.errors.isError === false) {
        vm.isAdding = true;
        toSave.dateFrom = {date: toSave.dateFrom};
        toSave.dateTo = {date: toSave.dateTo};
        usSpinnerService.spin('spinner-experience-add');
        ExperienceManager.editExperience(toSave, function (result) {
          vm.closePanel({
            action: 'update',
            data: result
          });
          $analytics.eventTrack('Edit', {category: 'Experience'});
        }, function (reason) {
          vm.errorMessage = reason;
          usSpinnerService.stop('spinner-experience-add');
          vm.isAdding = false;
        });
      }
    };

    $scope.$watch(function () {
      return vm.data;
    }, function (dataNew, dataOld) {
      if (dataNew.idProject !== dataOld.idProject) {
        vm.hasDataChanged = true;
      }

      if (dataNew.projectName !== dataOld.projectName) {
        vm.hasDataChanged = true;
      }

      if (dataNew.idJobTitle !== dataOld.idJobTitle) {
        vm.hasDataChanged = true;
      }

      if (dataNew.jobTitleName !== dataOld.jobTitleName) {
        vm.hasDataChanged = true;
      }

      if (dataNew.idEmployer !== dataOld.idEmployer) {
        vm.hasDataChanged = true;
      }

      if (dataNew.employerName !== dataOld.employerName) {
        vm.hasDataChanged = true;
      }

      if (dataNew.description !== dataOld.description) {
        vm.hasDataChanged = true;
      }

      if (dataNew.dateFrom !== dataOld.dateFrom) {
        vm.hasDataChanged = true;
      }

      if (!angular.equals(dataNew.skills, dataOld.skills)) {
        vm.hasDataChanged = true;
      }

    }, true);
  }

})();
