angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/dashboards/work-dialog.tpl.html',
    '<div class="row">\n' +
    '  <div class="small-15 medium-9 large-9 columns work-dialog-image-container">\n' +
    '    <img src="/images/work-popup.png" />\n' +
    '  </div>\n' +
    '  <div class="small-15 medium-6 large-6 columns">\n' +
    '    <div class="work-dialog-text">\n' +
    '      <h3>Welcome to Mineler’s Work Space!</h3>\n' +
    '      <span>Here you can find work opportunities and apply for advertised positions. We’re a bit different.</span>\n' +
    '      <br/><br/>\n' +
    '      <span>Your visual profile will help your future employers see all the great work you’ve done in the past. Your verified experience improves your chances of getting the work.</span>\n' +
    '      <br/><br/>\n' +
    '      <span>When applying you can attach your resume and all supporting documents and certifications. Mineler will store this for you if you apply next time as well.</span>\n' +
    '      <br/><br/>\n' +
    '      <span>Don’t wait any longer, click below to discover your new opportunities.</span>\n' +
    '    </div>\n' +
    '    <div id="work-dialog-job-apply-button" class="text-right">\n' +
    '      <button class="button positive" ng-click="workDialog.applyForJob()">Apply for work</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
