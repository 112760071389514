angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/work/work-detail-messages.tpl.html',
    '<div class="work-detail-messages-container" ng-hide="workDetail.isUserWorkOwner() && workDetail.conversationsLoader.records.length < 1">\n' +
    '  <div class="row">\n' +
    '    <div class="small-15 columns">\n' +
    '      <span class="panel-text-heading">Messages</span>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div ng-if="workDetail.isUserWorkOwner()">\n' +
    '    <div class="row cursor-pointer"\n' +
    '         ng-repeat="conversation in workDetail.conversationsLoader.records | activeRecord: \'map.work.detail.messages\' : \'idUser\' : \'contactProfile\'"\n' +
    '         ng-class="{\'is-active-record\': conversation.isActiveRecord}"\n' +
    '         ng-click="workDetail.openConversationByIndex($index)">\n' +
    '      <div class="small-3 columns map-panel-profile-photo">\n' +
    '        <img ng-src="{{conversation.contactProfile | profilePicture:45}}" class="circle45">\n' +
    '      </div>\n' +
    '      <div class="small-12 columns">\n' +
    '          <span class="panel-text-heading">\n' +
    '            <user-widget-name user="conversation.contactProfile" one-line="true"></user-widget-name>\n' +
    '          </span>\n' +
    '        <br/>\n' +
    '          <span class="info-text">\n' +
    '            <i class="fa fa-clock-o"></i> {{conversation.lastUpdated}} ago\n' +
    '          </span><br/>\n' +
    '        <span ng-show="conversation.sentByMe">Me: </span>\n' +
    '        {{conversation.lastMessage}}\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row">\n' +
    '      <div class="small-15 columns text-center">\n' +
    '      <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}"\n' +
    '            spinner-key="spinner-work-detail-conversations"></span>\n' +
    '        <a ng-show="workDetail.conversationsLoader.hasMore() && !workDetail.conversationsLoader.isLoading"\n' +
    '           ng-click="workDetail.loadConversations()">Load more ...</a>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div ng-if="!workDetail.isUserWorkOwner()" messages-thread contact-profile="workDetail.jobPosition.customer.user"\n' +
    '       my-profile="workDetail.myProfile.profile" auto-loader="false" reversed="false" parameters="workDetail.messagesParameters"></div>\n' +
    '</div>\n' +
    '');
}]);
