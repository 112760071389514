(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Map.Panels.service:PanelsManager
   *
   * @description
   *
   */
  angular
    .module('Map.Panels')
    .service('PanelsManager', PanelsManager);

  function PanelsManager(PanelsFactory, $q, $state) {
    var
      self = this,
      deferred = $q.defer(),
      targetContainerId = 'panels-container',
      panels = {
        profile: {
          id: 'map-panel-profile',
          templateUrl: 'map/panels/profile/profile.tpl.html',
          resolve: {
            userProfile: ['Auth', function (Auth) {
              return Auth.getSession();
            }]
          }
        },
        updates: {
          id: 'map-panel-updates',
          templateUrl: 'map/panels/updates/updates.tpl.html',
          resolve: {}
        },
        people: {
          id: 'map-panel-people',
          templateUrl: 'map/panels/people/people.tpl.html',
          resolve: {}
        },
        experience: {
          id: 'map-panel-experience',
          templateUrl: 'map/panels/experience/experience.tpl.html',
          resolve: {
            userProfile: ['Auth', function (Auth) {
              return Auth.getSession();
            }]
          }
        },
        work: {
          id: 'map-panel-work',
          templateUrl: 'map/panels/work/work.tpl.html',
          resolve: {}
        },
        myWork: {
          id: 'map-panel-my-work',
          templateUrl: 'map/panels/my-work/my-work.tpl.html',
          resolve: {}
        },
        messages: {
          id: 'map-panel-messages',
          templateUrl: 'map/panels/messages/messages.tpl.html',
          resolve: {
            userProfile: ['Auth', function (Auth) {
              return Auth.getSession();
            }]
          }
        },
        location: {
          id: 'map-panel-location',
          templateUrl: 'map/panels/location/location.tpl.html',
          resolve: {
            locationDetail: ['Restangular', '$stateParams', 'InitHelper',
              function (Restangular, $stateParams, InitHelper) {
              return Restangular.one('Locations').one('index').get({id: InitHelper.decryptId($stateParams.id)})
                .then(function (result) {
                  return result.plain();
                });
            }]
          }
        }
      };

    self.ready = deferred.promise;
    self.panels = PanelsFactory.get(panels, targetContainerId, true, true, false, function () {
      $state.go('map');
    });

    /**
     * Panels are ready
     */
    self.setReady = function () {
      deferred.resolve();
    };
  }

})();
