(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Map.Panels.MyWork.controller:MyWorkCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.MyWork')
    .controller('MyWorkCtrl', MyWorkCtrl);

  function MyWorkCtrl(PanelsManager, MyWorkPanelManager, $scope, $analytics, RecordsSet) {
    var
      vm = this,
      myBidsTabLoaded = false,
      placedWorkTabLoaded = false;

    vm.tab = '';
    vm.placedWorkLoader = RecordsSet.getLoader('Work', 'index', {posted: true});

    function openMyBidsTab() {
      if (myBidsTabLoaded === false) {
        myBidsTabLoaded = true;
        $analytics.eventTrack('My Bids Tab', {category: 'My Work'});
      }
    }

    function openPlacedWorkTab() {
      if (placedWorkTabLoaded === false) {
        placedWorkTabLoaded = true;
        $analytics.eventTrack('Placed Work Tab', {category: 'My Work'});
      }
    }

    vm.closePanel = function () {
      MyWorkPanelManager.panels.closeAll();
      PanelsManager.panels.close('myWork');
    };

    vm.openTab = function (tab) {
      MyWorkPanelManager.panels.closeAll();
      if (tab === vm.tab) {
        return;
      }
      vm.tab = tab;
      switch (tab) {
        case 'myBids':
          openMyBidsTab();
          break;
        case 'placedWork':
          openPlacedWorkTab();
          break;
      }
    };

    if (angular.isDefined($scope.tab) && $scope.tab !== '') {
      vm.openTab($scope.tab);
    } else {
      vm.openTab('myBids');
    }

    if (angular.isDefined($scope.onOpen) && angular.isFunction($scope.onOpen)) {
      $scope.onOpen();
    }
  }

})();
