angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('lost-password/lost-password-reset.tpl.html',
    '<div id="lost-password" class="image-background-connection">\n' +
    '\n' +
    '  <div class="row outside-container">\n' +
    '    <div class="small-15 medium-9 large-8 columns outside-form" ng-if="!lostPasswordReset.isReset && !lostPasswordReset.isInvalidToken">\n' +
    '      <form name="lostPasswordResetForm" role="lostPasswordResetForm">\n' +
    '        <div class="row">\n' +
    '          <div class="small-6 small-push-9 columns text-right">\n' +
    '            <img src="images/mineler.png" class="home-page-logo"/>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="small-15 columns text-left">\n' +
    '            <h4>RESET YOUR PASSWORD?</h4>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row">\n' +
    '          <div class="small-15 columns text-left">\n' +
    '            Please enter a new password to continue using Mineler.\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row content">\n' +
    '          <div class="small-15 columns text-left">\n' +
    '            <input name="password" type="password" ng-model="lostPasswordReset.data.password"\n' +
    '                   placeholder="New Password" ng-required="true" ng-pattern="lostPasswordReset.passwordPattern" />\n' +
    '            <small class="error" ng-show="lostPasswordResetForm.password.$dirty && lostPasswordResetForm.password.$error.required">\n' +
    '              Missing new password\n' +
    '            </small>\n' +
    '            <small class="error" ng-show="lostPasswordResetForm.password.$dirty && lostPasswordResetForm.password.$error.pattern">\n' +
    '              {{lostPasswordReset.passwordMessage}}\n' +
    '            </small>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row content">\n' +
    '          <div class="small-15 columns text-left">\n' +
    '            <input type="password" name="verifyPassword" ng-model="lostPasswordReset.verifyPassword"\n' +
    '                   placeholder="Verify New Password" ng-required="true" match="lostPasswordReset.data.password" />\n' +
    '            <small class="error" ng-show="lostPasswordResetForm.verifyPassword.$dirty && lostPasswordResetForm.verifyPassword.$error.match">\n' +
    '              Passwords do not match\n' +
    '            </small>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row content">\n' +
    '          <div class="small-10 columns text left">\n' +
    '            <small class="error" ng-show="lostPasswordReset.errorMessage">{{lostPasswordReset.errorMessage}}</small>\n' +
    '          </div>\n' +
    '          <div class="small-5 columns text-right">\n' +
    '            <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-lost-password"></span>\n' +
    '            <button ng-hide="lostPasswordReset.isResetting" class="button positive" ng-click="lostPasswordReset.reset()"\n' +
    '                    ng-disabled=\'lostPasswordResetForm.$invalid\'>Continue</button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '      </form>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="small-15 medium-11 large-10 columns outside-message" ng-if="lostPasswordReset.isReset || lostPasswordReset.isInvalidToken">\n' +
    '      <div class="row">\n' +
    '        <div class="small-6 small-push-9 columns text-right">\n' +
    '          <img src="images/mineler.png" class="home-page-logo"/>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row" ng-if="lostPasswordReset.isReset">\n' +
    '        <div class="small-6 medium-3 columns message-icon">\n' +
    '          <i class="fa fa-check-circle"></i>\n' +
    '        </div>\n' +
    '        <div class="small-9 medium-12 columns message-text text-left">\n' +
    '          <h3>Great Work!</h3>\n' +
    '          Your password has been successfully recovered.\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row" ng-if="lostPasswordReset.isInvalidToken">\n' +
    '        <div class="small-6 medium-3 columns message-icon">\n' +
    '          <i class="fa fa-minus-circle"></i>\n' +
    '        </div>\n' +
    '        <div class="small-9 medium-12 columns message-text text-left">\n' +
    '          <h3>Oops,</h3>\n' +
    '          Some information is missing. Please try again.\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns text-right">\n' +
    '          <button class="button positive" ng-click="lostPasswordReset.continue()">Continue</button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
