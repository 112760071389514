angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/experience/date-picker-directive.tpl.html',
    '<div class="small-4 columns">\n' +
    '  <input type="number" class="form-control" ng-model="val.year"\n' +
    '         placeholder="YEAR" min="1900" max="{{maxYear}}" step="1" value="{{maxYear}}"/>\n' +
    '</div>\n' +
    '<div class="small-5 columns">\n' +
    '  <select id="dateSelectMonth_{{token}}" data-placeholder="MONTH" class="form-control" ng-model="val.month"\n' +
    '          ng-show="showSelectMonth();" ng-options="m.value as m.name for m in months">\n' +
    '    <option></option>\n' +
    '  </select>\n' +
    '  <a ng-click="selectMonth();" ng-show="showSelectMonthLink();">SELECT MONTH</a>\n' +
    '</div>\n' +
    '<div class="small-4 columns">\n' +
    '  <select id="dateSelectDay_{{token}}" data-placeholder="DAY" class="form-control" ng-model="val.day"\n' +
    '          ng-show="showSelectDay();" ng-options="d.value as d.name for d in days">\n' +
    '    <option></option>\n' +
    '  </select>\n' +
    '  <a ng-click="selectDay();" ng-show="showSelectDayLink();">SELECT DAY</a>\n' +
    '</div>\n' +
    '');
}]);
