angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/work/work-tab-work.tpl.html',
    '<div class="row cursor-pointer"\n' +
    '     ng-repeat="jobPosition in work.workLoader.records | activeRecord: \'map.work.detail\' : \'id\'"\n' +
    '     ng-class="{\'is-active-record\': jobPosition.isActiveRecord}"\n' +
    '     ng-click="work.openDetailByIndex($index)">\n' +
    '\n' +
    '  <div class="small-5 columns map-panel-profile-photo">\n' +
    '    <img ng-src="{{jobPosition.customer.user | profilePicture:45}}" class="circle45">\n' +
    '  </div>\n' +
    '  <div class="small-10 columns">\n' +
    '    <span class="panel-text-heading">\n' +
    '       {{jobPosition.jobTitle.name}}, {{jobPosition.reference}}\n' +
    '    </span><br/>\n' +
    '    <span class="panel-text-heading">\n' +
    '      <span ng-if="jobPosition.project.name">{{jobPosition.project.name}}, </span>{{jobPosition.customer.companyName}}\n' +
    '    </span><br/>\n' +
    '    {{jobPosition.description | truncate: 50: \'...\': true}}\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row" ng-show="work.workLoader.hasMore() && !work.workLoader.isLoading">\n' +
    '  <div class="small-15 columns text-center">\n' +
    '    <a ng-click="work.loadMore()">Load more ...</a>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
