(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Map.service:MapComponent
   *
   * @description
   *
   */
  angular
    .module('Map')
    .service('MapComponent', MapComponent);

  function MapComponent(Cache, $rootScope, Restangular, $timeout, Auth, $compile,
                        PanelsManager, InitHelper, $q, $analytics, $state) {
    var
      self = this,
      publicPinColor = '#91bfdb',
      favPinColor = '#7fbf7b',
      overlayPinColor = '#fc8d59',
      defaultPinZIndex = 0,
      favPinZIndex = 1,
      overlayPinZIndex = 2,
      myPinZIndex = 3,
      droppedPinZIndex = 4,
      myCountryId = 0,
      cache = Cache.create('map'),
      markers = {},
      locations = {},
      cluster = null,
      mapBounds = null,
      mapApi = null,
      map = null,
      activeLocations = [],
      myLocations = [],
      myLocationsVisible = true,
      isFilterOn = false,
      usersVisibility = [],
      favouriteUsersLocationsIds = {},
      isClusterOn = true,
      myLocationsEtag = null,
      favouriteUsersLocationsEtag = null,
      currentMapEntity = null,
      isDroppedPin = false,
      droppedPin = null;

    /**
     * Generate marker icon based on supplied color
     * @param color
     * @returns google.maps.Symbol
     */
    function pinSymbol(color) {
      return {
        path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,' +
        '-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
        fillColor: color,
        fillOpacity: 1,
        strokeColor: '#6A5D25',
        strokeWeight: 1,
        scale: 1
      };
    }

    /**
     * Add new location
     *
     * @param id
     * @param latitude
     * @param longitude
     */
    function addLocation(id, latitude, longitude) {
      if (!angular.isDefined(locations[id])) {
        locations[id] = {
          latitude: latitude,
          longitude: longitude
        };
      }
    }

    /**
     * Get marker for location
     * @param id
     * @returns google.maps.Marker
     */
    function getMarker(id) {
      if (angular.isDefined(locations[id])) {
        if (!angular.isDefined(markers[id])) {
          markers[id] = new mapApi.Marker({
            map: null,
            position: new mapApi.LatLng(locations[id].latitude, locations[id].longitude),
            icon: pinSymbol(publicPinColor),
            optimized: false
          });

          mapApi.event.addListener(markers[id], 'click', function () {
            $state.go('map.location', {
              id: InitHelper.encryptId(id),
              zoomToLocation: false
            });
            $rootScope.$broadcast('event:map-location-clicked', {
              id: id
            });
            $analytics.eventTrack('Open Location', {category: 'Map', value: id});
          });
        }

        return markers[id];
      }

      return null;
    }

    /**
     * Activate map view
     * @param mapTypeId One of google.maps.MapTypeId constants
     */
    function setMapView(mapTypeId) {
      map.getGMap().setMapTypeId(mapTypeId);
    }

    /**
     * Deactivate all currently active locations
     */
    function deactivateLocations() {
      _.forEach(activeLocations, function (id) {
        if (angular.isDefined(locations[id])) {
          if (angular.isDefined(markers[id])) {
            markers[id].setMap(null);
          }
        }
      });
      activeLocations = [];
    }

    /**
     * Get IDs of locations to activate for default state
     * @returns []
     */
    function getLocationsToActivate() {
      var allLocations = [];
      if (myLocationsVisible) {
        allLocations.push(_.pluck(myLocations, 'id'));
      }

      _.forEach(favouriteUsersLocationsIds, function (location, id) {
        if (usersVisibility[id]) {
          allLocations.push(location);
        }
      });

      return _.union.apply(_, allLocations);
    }

    /**
     * Load locations for entity
     * @param endPoint  Endpoint in API
     * @param idList    List of IDs identifying entity
     * @param onLoadCallback  Callback
     * @returns {boolean}
     */
    function loadEntityLocations(endPoint, idList, onLoadCallback) {
      onLoadCallback = onLoadCallback || angular.noop;
      if (!angular.isArray(idList) && !angular.isObject(idList)) {
        throw 'Invalid parameter for locations';
      }
      if (idList.length === 0) {
        return false;
      }
      Restangular.one('Locations').customPOST(idList, endPoint).then(function (result) {
        onLoadCallback(result.records, result.recordsInfo, result.contentEtag);
      });
    }

    /**
     * Set filter state
     * @param boolean
     */
    function setFilter(value) {
      if (value === false) {
        currentMapEntity = null;
        cache.put('currentMapEntity', currentMapEntity);
      }
      isFilterOn = value;
      cache.put('isFilterOn', isFilterOn);
    }

    /**
     * Get icon for marker
     * @param id ID of location
     * @returns google.maps.Symbol
     */
    function getMarkerSetup(id) {
      var
        isMyPin = false,
        isFavPinCounter = 0,
        icon,
        zIndex;
      if (myLocationsVisible) {
        isMyPin = (_.findIndex(myLocations, {id: id}) !== -1);
      }

      _.forEach(favouriteUsersLocationsIds, function (locations, idFavUser) {
        if (usersVisibility[idFavUser]) {
          if (_.contains(locations, id)) {
            isFavPinCounter++;
            if (isFavPinCounter > 1) {
              return false;
            }
          }
        }
      });

      if ((isMyPin && isFavPinCounter === 1) || isFavPinCounter > 1) {
        icon = pinSymbol(overlayPinColor);
        zIndex = overlayPinZIndex;
      } else if (isMyPin && isFavPinCounter === 0) {
        icon = null;
        zIndex = myPinZIndex;
      } else if (!isMyPin && isFavPinCounter === 1) {
        icon = pinSymbol(favPinColor);
        zIndex = favPinZIndex;
      } else {
        icon = pinSymbol(publicPinColor);
        zIndex = defaultPinZIndex;
      }

      return {
        icon: icon,
        zIndex: zIndex
      };
    }

    /**
     * Activate filter flag
     */
    function turnFilterOn() {
      setFilter(true);
    }

    /**
     * Colourise active locations
     */
    function colouriseLocations() {
      var markerSetup;
      _.forEach(activeLocations, function (id) {
        markerSetup = getMarkerSetup(id);
        markers[id].setIcon(markerSetup.icon);
        markers[id].setZIndex(mapApi.Marker.MAX_ZINDEX + markerSetup.zIndex);
      });
      map.refresh();
    }

    /**
     * Activate locations by their ids
     * @param ids
     */
    function activateLocations(ids) {
      var markersToActivate = [];
      deactivateLocations();
      ids = _.uniq(ids);
      mapBounds = new mapApi.LatLngBounds();
      _.forEach(ids, function (id) {
        var marker = getMarker(id);
        marker.setMap(map.getGMap());
        mapBounds.extend(marker.getPosition());
        markersToActivate.push(marker);
      });
      activeLocations = ids;
      colouriseLocations();

      if (isClusterOn) {
        cluster.clearMarkers();
        cluster.addMarkers(markersToActivate);
        map.refresh();
      }
      cache.put('activeLocations', activeLocations);
    }

    function addActiveLocation(id) {
      var
        marker = getMarker(id),
        markerSetup = getMarkerSetup(id);
      if (angular.isDefined(marker)) {
        marker.setMap(map.getGMap());
        marker.setIcon(markerSetup.icon);
        marker.setZIndex(mapApi.Marker.MAX_ZINDEX + markerSetup.zIndex);
        cluster.addMarkers([marker]);
        activeLocations.push(id);
      }
    }

    /**
     * Activate locations for entity
     * @param toShow
     */
    function activateEntityLocations(toShow) {
      activateLocations(toShow);
      self.resetBounds();
      cache.put('locations', locations);
      turnFilterOn();
    }

    /**
     * Show locations for search filter
     * @param filters
     */
    function showSearchLocations(filters) {
      loadEntityLocations('search', filters, function (records) {
        var toShow = [];
        _.forEach(records, function (location) {
          addLocation(location.id, location.latitude, location.longitude);
          toShow.push(location.id);
        });
        activateEntityLocations(toShow);
      });
    }

    /**
     * Show locations for entity =
     * @param endPoint
     * @param idList
     */
    function showEntityLocations(endPoint, idList) {
      var
        toShow = [],
        deferred = $q.defer();
      loadEntityLocations(endPoint, {idList: idList}, function (records) {
        _.forEach(records, function (locations) {
          _.forEach(locations, function (location) {
            addLocation(location.id, location.latitude, location.longitude);
            toShow.push(location.id);
          });
        });
        activateEntityLocations(toShow);
        deferred.resolve();
      });

      return deferred.promise;
    }

    /**
     * Load data from cache
     */
    function loadFromCache() {
      var
        locationsToActivate = [],
        myLocationsVisibleCache,
        isFilterOnCache,
        isClusterOnCache;
      favouriteUsersLocationsIds = cache.get('favouriteUsersLocationsIds') || {};
      myLocations = cache.get('myLocations') || [];
      locations = cache.get('locations') || {};
      locationsToActivate = cache.get('activeLocations') || [];
      myLocationsVisibleCache = cache.get('myLocationsVisible');
      myLocationsVisible = (angular.isDefined(myLocationsVisibleCache) ? myLocationsVisibleCache : true);
      favouriteUsersLocationsEtag = cache.get('favouriteUsersLocationsEtag') || null;
      myLocationsEtag = cache.get('myLocationsEtag') || null;
      isFilterOnCache = cache.get('isFilterOn');
      isFilterOn = (angular.isDefined(isFilterOnCache) ? isFilterOnCache : false);
      isClusterOnCache = cache.get('isClusterOn');
      isClusterOn = (angular.isDefined(isClusterOnCache) ? isClusterOnCache : true);
      usersVisibility = cache.get('usersVisibility') || [];
      currentMapEntity = cache.get('currentMapEntity') || null;

      Auth.getSession().then(function (session) {
        if (!angular.isObject(session.profile)) {
          return;
        }

        if (angular.isDefined(session.profile.country) && angular.isDefined(session.profile.country.idCountry)) {
          myCountryId = session.profile.country.idCountry;
        }
        loadEntityLocations('indexByUsers', {idList: [session.idUser]}, function (records, recordsInfo, contentEtag) {
          if (contentEtag !== myLocationsEtag) {
            myLocations = records[session.idUser] || [];
            myLocationsEtag = contentEtag;
            _.forEach(myLocations, function (location) {
              addLocation(location.id, location.latitude, location.longitude);
            });
            cache.put('myLocations', myLocations);
            cache.put('myLocationsEtag', myLocationsEtag);
            cache.put('locations', locations);
          }

          if (isFilterOn === false) {
            if (myLocations.length === 0 && parseInt(myCountryId) > 0) {
              showSearchLocations({idCountry: [myCountryId]});
            } else {
              self.resetFilter(false);
            }
          }
        });
      });

      $timeout(function () {
        if (isFilterOn === false) {
          self.resetFilter(false);
        } else {
          activateLocations(locationsToActivate);
        }

        if (angular.isObject(currentMapEntity)) {
          $rootScope.$broadcast('event:map-entity-updated', {
            currentMapEntity: currentMapEntity
          });
        }
        self.resetBounds();
      }, 1000);
    }

    /**
     * Load favourite users locations
     * @param favUsersIds
     */
    function loadFavouriteUsers(favUsersIds) {
      var
        favUsersLocations = {};

      if (favUsersIds.length === 0) {
        favouriteUsersLocationsIds = {};
        favouriteUsersLocationsEtag = null;
        cache.put('favouriteUsersLocationsIds', favouriteUsersLocationsIds);
        cache.put('favouriteUsersLocationsEtag', favouriteUsersLocationsEtag);
        //colouriseLocations();

        if (isFilterOn === false) {
          activateLocations(getLocationsToActivate());
        } else {
          colouriseLocations();
        }

      } else {
        loadEntityLocations('indexByUsers', {idList: favUsersIds}, function (records, recordsInfo, contentEtag) {
          if (contentEtag !== favouriteUsersLocationsEtag) {
            _.forEach(favUsersIds, function (id) {
              favUsersLocations[id] = [];
              _.forEach(records[id], function (location) {
                favUsersLocations[id].push(location.id);
                addLocation(location.id, location.latitude, location.longitude);
              });
            });
            favouriteUsersLocationsIds = favUsersLocations;
            favouriteUsersLocationsEtag = contentEtag;
            cache.put('favouriteUsersLocationsIds', favouriteUsersLocationsIds);
            cache.put('favouriteUsersLocationsEtag', favouriteUsersLocationsEtag);
            cache.put('locations', locations);
            //colouriseLocations();

            if (isFilterOn === false) {
              activateLocations(getLocationsToActivate());
            } else {
              colouriseLocations();
            }

          }
        });
      }
    }

    /**
     * Set current entity which is used to filter the map
     * @param entityName
     * @param data
     */
    function setCurrentMapEntity(entityName, data, entityDetail) {
      if (angular.isUndefined(entityDetail)) {
        entityDetail = {};
      }

      currentMapEntity = {
        entity: entityName,
        data: data
      };
      cache.put('currentMapEntity', currentMapEntity);

      $rootScope.$broadcast('event:map-entity-updated', {
        currentMapEntity: currentMapEntity,
        entityDetail: entityDetail
      });

      return currentMapEntity;
    }

    function getYOffset() {
      var
        topBarHeight = $('#map-toolbars-container').innerHeight(),
        dashboardsHeight = $('#dashboards-container').innerHeight();

      return dashboardsHeight - topBarHeight;
    }

    /**
     * Activate satellite view
     */
    self.setSatelliteView = function () {
      setMapView(mapApi.MapTypeId.HYBRID);
    };

    /**
     * Activate road view
     */
    self.setRoadView = function () {
      setMapView(mapApi.MapTypeId.ROADMAP);
    };

    /**
     * Load locations associated with user
     * @param idUser
     */
    self.loadUserLocations = function (idUser) {
      var
        deferred = $q.defer(),
        currentMapEntity = setCurrentMapEntity('user', idUser);
      showEntityLocations('indexByUsers', idUser)
        .then(function () {
          deferred.resolve(currentMapEntity);
        });

      return deferred.promise;
    };

    /**
     * Load locations associated with company
     * @param idCompany
     */
    self.loadCompanyLocations = function (company) {
      setCurrentMapEntity('company', [company.id], company);
      showEntityLocations('indexByCompanies', [company.id]);
    };

    /**
     * Load locations associated with project
     * @param idProject
     */
    self.loadProjectLocations = function (idProject) {
      setCurrentMapEntity('project', idProject);
      showEntityLocations('indexByProjects', idProject);
    };

    /**
     * Load locations for selected filters
     * @param filters
     */
    self.loadFiltersLocations = function (filters) {
      setCurrentMapEntity('filter', filters);
      showSearchLocations(filters);
    };

    /**
     * Toggle visibility of my locations
     */
    self.toggleMyLocationsVisiblity = function () {
      myLocationsVisible = !myLocationsVisible;
      cache.put('myLocationsVisible', myLocationsVisible);
      if (!isFilterOn) {
        activateLocations(getLocationsToActivate());
      } else {
        colouriseLocations();
      }
    };

    /**
     * Turn filter off
     */
    self.turnFilterOff = function () {
      if (isFilterOn === true) {
        setFilter(false);
        self.resetFilter();
        $rootScope.$broadcast('event:map-reset-filter');
        PanelsManager.panels.closeAll();
      }
    };

    /**
     * Toggle clustering
     */
    self.toggleCluster = function () {
      var markers = [];
      _.forEach(activeLocations, function (id) {
        markers.push(getMarker(id));
      });
      if (isClusterOn === true) {
        cluster.removeMarkers(markers);
        _.forEach(markers, function (marker) {
          marker.setMap(map.getGMap());
        });
      } else {
        cluster.addMarkers(markers);
      }
      map.refresh();
      isClusterOn = !isClusterOn;
      cache.put('isClusterOn', isClusterOn);
    };

    /**
     * Get status of clustering
     * @returns {boolean}
     */
    self.isCluster = function () {
      return isClusterOn;
    };

    /**
     * Get visibility status of my locations
     * @returns {boolean}
     */
    self.areMyLocationsVisible = function () {
      return myLocationsVisible;
    };

    /**
     * Toggle visibility of individual users
     * @param idUser
     */
    self.toggleVisibility = function (idUser) {
      if (usersVisibility[idUser]) {
        usersVisibility[idUser] = false;
      } else {
        usersVisibility[idUser] = true;
      }
      cache.put('usersVisibility', usersVisibility);

      if (!isFilterOn) {
        activateLocations(getLocationsToActivate());
      } else {
        colouriseLocations();
      }

      $analytics.eventTrack('Toggle', {category: 'Favourite Users', label: 'Toggle visibility'});
    };

    /**
     * Get visibility status of individual users
     * @param idUser
     * @returns {*}
     */
    self.isUserVisible = function (idUser) {
      if (!angular.isDefined(usersVisibility[idUser])) {
        usersVisibility[idUser] = true;
      }
      return usersVisibility[idUser];
    };

    /**
     * Get status of filter
     * @returns {boolean}
     */
    self.isFilter = function () {
      return isFilterOn;
    };

    /**
     * Reset visible map boundaries
     */
    self.resetBounds = function () {
      if (mapBounds !== null && mapBounds.isEmpty() === false) {
        map.getGMap().fitBounds(mapBounds);
        if (map.getGMap().getZoom() > 10) {
          map.getGMap().setZoom(10);
        }
        if (isClusterOn) {
          cluster.repaint();
        }
        map.refresh();
      }
    };

    /**
     * Reset filter - active my locations
     */
    self.resetFilter = function (resetUsers) {
      if (resetUsers !== false) {
        usersVisibility = _.mapValues(usersVisibility, function () {
          return false;
        });
        myLocationsVisible = true;
      }

      activateLocations(getLocationsToActivate());
      self.resetBounds();
    };

    /**
     * Get current entity used to filter the map
     * @returns {*}
     */
    self.getCurrentMapEntity = function () {
      return currentMapEntity;
    };

    /**
     * Drop new pin
     */
    self.dropPin = function () {
      if (isDroppedPin) {
        droppedPin.setMap(null);
        droppedPin = null;
        isDroppedPin = false;
      } else {

        var markerOptions = {
            map: map.getGMap(),
            position: map.getGMap().getCenter(),
            icon: pinSymbol('#feb24c'),
            draggable: true,
            zIndex: mapApi.Marker.MAX_ZINDEX + droppedPinZIndex,
            optimized: false
          },
          infoWindow;

        droppedPin = new mapApi.Marker(markerOptions);
        infoWindow = new mapApi.InfoWindow();
        mapApi.event.addListener(droppedPin, 'click', (function (droppedPin, $scope) {
            return function () {
              function getCountry() {
                var
                  deferred = $q.defer(),
                  geocoder = new mapApi.Geocoder();

                geocoder.geocode({latLng: droppedPin.getPosition()}, function (result, status) {
                  if (status === mapApi.GeocoderStatus.OK) {
                    var index = _.findIndex(result, {types: ['country', 'political']});
                    if (index !== -1) {
                      deferred.resolve(result[index]['address_components'][0]['short_name']);
                    } else {
                      deferred.reject();
                    }

                  } else {
                    deferred.reject();
                  }
                });

                return deferred.promise;
              }

              var
                content = '<div class="dropped-map-marker-window">' +
                  '<button class="button" ng-click="cancelDroppedPin();">CANCEL</button>' +
                  '<button class="button positive" ng-click="addExperience();">ADD EXPERIENCE</button>' +
                  '</div>',
                compiled;
              $scope.cancelDroppedPin = function () {
                if (droppedPin) {
                  droppedPin.setMap(null);
                  droppedPin = null;
                }
                isDroppedPin = false;
              };

              $scope.addExperience = function () {
                $state.go('map.experienceMy.add', {
                  location: {
                    latitude: droppedPin.getPosition().lat(),
                    longitude: droppedPin.getPosition().lng(),
                    countryCode: getCountry()
                  }
                });
              };
              $scope.droppedPin = droppedPin;

              $scope.$on('event:experience-add-dropped-pin-cancelled', function () {
                $scope.cancelDroppedPin();
              });

              compiled = $compile(content)($scope);
              $scope.$apply();
              infoWindow.setContent(compiled[0]);
              infoWindow.open(map.getGMap(), droppedPin);

            };
          })(droppedPin, $rootScope.$new(true))
        );
        isDroppedPin = true;
      }
    };

    /**
     * Check whether pin has been dropped
     * @returns {boolean}
     */
    self.getIsDroppedPin = function () {
      return isDroppedPin;
    };

    /**
     * Add new my location
     * @param id
     * @param latitude
     * @param longitude
     */
    self.addMyLocation = function (id, latitude, longitude) {
      var $index = _.findIndex(myLocations, {id: id});
      if ($index === -1) {
        myLocations.push({id: id, latitude: latitude, longitude: longitude});
        addLocation(id, latitude, longitude);
        activeLocations.push(id);
        cache.put('myLocations', myLocations);
        cache.put('locations', locations);

        if (isFilterOn === false) {
          self.resetFilter();
        } else {
          activateLocations(activeLocations);
        }
      }
    };

    /**
     * Initialize map interface
     * @param google.maps.Map instance
     * @param google.maps
     */
    self.init = function (mapInstance, gMapApi) {
      map = mapInstance;
      mapApi = gMapApi;
      cluster = new MarkerClusterer(map.getGMap(), [], {
        imagePath: '/images/cluster/m',
        maxZoom: 14
      });
      loadFromCache();
    };

    /**
     * Reset the map after setting country of residence
     */
    $rootScope.$on('event:user-profileMissingDataSet', function ($event, userProfile) {
      if (myLocations.length === 0 && parseInt(userProfile.country.id) > 0) {
        showSearchLocations({idCountry: [userProfile.country.id]});
      }
    });

    /**
     * Load favourite users locations once the favourite users have been loaded
     */
    $rootScope.$on('event:favouriteUsers-loaded', function ($event, favUsersIds) {
      loadFavouriteUsers(favUsersIds);
    });

    /**
     * Load locations of new favourite user
     */
    $rootScope.$on('event:favouriteUsers-added', function ($event, user) {
      loadEntityLocations('indexByUsers', {idList: [user.id]}, function (records) {
        favouriteUsersLocationsIds[user.id] = [];
        usersVisibility[user.id] = false;
        _.forEach(records[user.id], function (location) {
          favouriteUsersLocationsIds[user.id].push(location.id);
          addLocation(location.id, location.latitude, location.longitude);
        });
        cache.put('favouriteUsersLocationsIds', favouriteUsersLocationsIds);
        cache.put('locations', locations);
        self.toggleVisibility(user.id);
      });
    });

    /**
     * Unset locations of removed favourite user
     */
    $rootScope.$on('event:favouriteUsers-removed', function ($event, removed) {
      delete favouriteUsersLocationsIds[removed.id];
      delete usersVisibility[removed.id];
      cache.put('favouriteUsersLocationsIds', favouriteUsersLocationsIds);
      cache.put('usersVisibility', usersVisibility);
      if (isFilterOn === false) {
        activateLocations(getLocationsToActivate());
      } else {
        colouriseLocations();
      }
    });

    /**
     * Add marker on new experience
     */
    $rootScope.$on('event:experience-added', function ($event, experience) {
      self.addMyLocation(experience.idLocation, experience.latitude, experience.longitude);
    });

    /**
     * Cleanup on sign out
     */
    $rootScope.$on('event:auth-logoutSuccess', function () {
      favouriteUsersLocationsIds = {};
      favouriteUsersLocationsEtag = null;
      myLocations = [];
      myLocationsEtag = null;
      locations = {};
      markers = {};
      activeLocations = [];
      mapBounds = null;
      myLocationsVisible = true;
      usersVisibility = [];
      isFilterOn = false;
      isClusterOn = true;
      cache.removeAll();
    });

    $rootScope.$on('event:center-map', function ($event, data) {
      var
        marker,
        x,
        activateLocationMarker = function (id) {
          if (activeLocations.indexOf(id) === -1) {
            addActiveLocation(id);
          }

          marker = getMarker(id);
          if (angular.isDefined(marker)) {
            x = -1 * Math.floor(data.offsetX / 2);
            map.getGMap().setZoom(15);
            map.getGMap().panTo(marker.getPosition());
            if ($(window).width() > 640) {
              map.getGMap().panBy(x, getYOffset() / 2);
            }
            map.refresh();
          }
        };

      if (angular.isNumber(data.idLocation)) {
        if (!angular.isDefined(locations[data.idLocation])) {
          Restangular.one('Locations').customGET('index', {id: data.idLocation})
            .then(function (result) {
              addLocation(result.id, result.latitude, result.longitude);
              activateLocationMarker(result.id);
            });
        } else {
          activateLocationMarker(data.idLocation);
        }
      }
    });
  }

})();
