(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Map.Toolbars.controller:MenuCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Toolbars')
    .controller('MenuCtrl', MenuCtrl);

  function MenuCtrl($scope, $state, Auth, PanelsManager, FeedbackManager, $timeout, $analytics, IntroManager) {
    var vm = this;
    vm.isOpen = false;
    Auth.getSession().then(function (session) {
      vm.profile = session.profile;
    });

    vm.toggle = function () {
      vm.isOpen = !vm.isOpen;

      if (vm.isOpen) {
        $analytics.eventTrack('Open', {category: 'Menu', label: 'Menu'});
        $timeout(function () {
          vm.isOpen = false;
        }, 7000);
      }
    };

    vm.signOut = function () {
      $analytics.eventTrack('SignOut', {category: 'User'});
      Auth.logout();
      $state.params = angular.extend($state.params, {logout: true});
      $state.go('signin');
    };

    vm.openProfile = function () {
      $analytics.eventTrack('Open', {category: 'Menu', label: 'Profile'});
      vm.toggle();
    };

    vm.openMyWork = function () {
      $analytics.eventTrack('Open', {category: 'Menu', label: 'Work'});
      PanelsManager.panels.open('myWork');
      vm.toggle();
    };

    vm.openFeedback = function () {
      $analytics.eventTrack('Open', {category: 'Menu', label: 'Contact Mineler'});
      FeedbackManager.init();
      vm.toggle();
    };

    vm.openMessages = function () {
      $analytics.eventTrack('Open', {category: 'Menu', label: 'Messages'});
      vm.toggle();
    };

    vm.openIntro = function () {
      $analytics.eventTrack('Open', {category: 'Menu', label: 'Intro'});
      IntroManager.openPopup();
    };

    $scope.$on('event:user-profileUpdated', angular.bind(vm, function (event, session) {
      vm.profile = session.profile;
    }));

  }

})();
