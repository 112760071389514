angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/profile/profile-edit-picture.tpl.html',
    '<div id="map-panel-profile-edit" class="map-secondary-panel"\n' +
    '     ng-controller="ProfileEditPictureCtrl" ng-swipe-left="closeEditPanel()" ng-swipe-disable-mouse>\n' +
    '  <div class="row map-panel-heading">\n' +
    '    <div class="small-2 columns">\n' +
    '      <a class="arrow-back" ng-click="closeEditPanel()">\n' +
    '        <i class="fa fa-angle-double-left"></i>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '    <div class="small-13 columns">\n' +
    '      <h3>Upload My Photo</h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row profile-edit profile-edit-picture">\n' +
    '    <div class="small-15 columns">\n' +
    '      <form name="editProfilePictureForm" role="editProfilePictureForm">\n' +
    '        <div class="row">\n' +
    '          <div class="small-15 columns">\n' +
    '            <div>Select an image file: <input type="file" id="fileInput" /></div>\n' +
    '            <div class="row">\n' +
    '              <div class="small-8 small-offset-2 columns">\n' +
    '                <small class="profile-edit-error" ng-show="editProfileErrorMessage">{{editProfileErrorMessage}}</small>\n' +
    '              </div>\n' +
    '              <div class="small-5 columns profile-edit-button-container">\n' +
    '                <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-profile-edit"></span>\n' +
    '                <button ng-hide="isSaving" class="right button positive" ng-click="savePicture()" ng-disabled=\'!profilePictureLoaded\'>Save</button>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="cropArea">\n' +
    '              <img-crop image="profilePicture" result-image="croppedProfilePicture" result-image-size="400" on-load-done="onLoadDone()"></img-crop>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
