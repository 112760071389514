(function () {
  'use strict';

  /* @ngdoc object
   * @name Panels.Experience
   * @requires $stateProvider
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Experience', [
      'angucomplete-alt',
      'ngTagsInput'
    ]);

  angular
    .module('Map.Panels.Experience')
    .config(config);

  function config($stateProvider, Defaults) {
    $stateProvider
      .state('map.experience', angular.extend({}, Defaults.mapStateParams, {
        url: '/experience',
        onEnter: ['QueueManager', 'InitHelper', function (QueueManager, InitHelper) {
          QueueManager.add(InitHelper.getOpenPanelQueueItem('experience', 'experience'));
        }]
      }))
      .state('map.experience.detail', angular.extend({}, Defaults.mapStateParams, {
        url: '/{id}/detail',
        onEnter: ['QueueManager', 'InitHelper', 'QueueItem', '$q', '$timeout', '$rootScope', '$stateParams',
          function (QueueManager, InitHelper, QueueItem, $q, $timeout, $rootScope, $stateParams) {
            QueueManager.add(new QueueItem(function (panelReady) {
              $q.when(panelReady).then(function () {
                $timeout(function () {
                  $rootScope.$broadcast('event:experience-open-detail', {
                    idExperience: InitHelper.decryptId($stateParams.id)
                  });
                }, 100);
              });

              return $q.when(true);
            }));
          }]
      }))
      .state('map.experienceValidations', angular.extend({}, Defaults.mapStateParams, {
        url: '/experience/validations',
        onEnter: ['QueueManager', 'InitHelper', function (QueueManager, InitHelper) {
          QueueManager.add(InitHelper.getOpenPanelQueueItem('experience', 'validations'));
        }]
      }))
      .state('map.experienceValidations.detail', angular.extend({}, Defaults.mapStateParams, {
        url: '/{id}/detail',
        onEnter: ['QueueManager', 'InitHelper', 'QueueItem', '$q', '$timeout', '$rootScope', '$stateParams',
          function (QueueManager, InitHelper, QueueItem, $q, $timeout, $rootScope, $stateParams) {
            QueueManager.add(new QueueItem(function (panelReady) {
              $q.when(panelReady).then(function () {
                $timeout(function () {
                  $rootScope.$broadcast('event:experience-open-detail-validation', {
                    idExperienceValidation: InitHelper.decryptId($stateParams.id)
                  });
                }, 100);
              });

              return $q.when(true);
            }));
          }]
      }))
      .state('map.experienceMy', angular.extend({}, Defaults.mapStateParams, {
        url: '/experience/my',
        onEnter: ['QueueManager', 'InitHelper', function (QueueManager, InitHelper) {
          QueueManager.add(InitHelper.getOpenPanelQueueItem('experience', 'myExperience'));
        }]
      }))
      .state('map.experienceMy.detail', angular.extend({}, Defaults.mapStateParams, {
        url: '/{id}/detail',
        onEnter: ['QueueManager', 'InitHelper', 'QueueItem', '$q', '$timeout', '$rootScope', '$stateParams',
          function (QueueManager, InitHelper, QueueItem, $q, $timeout, $rootScope, $stateParams) {
            QueueManager.add(new QueueItem(function (panelReady) {
              $q.when(panelReady).then(function () {
                $timeout(function () {
                  $rootScope.$broadcast('event:experience-open-detail-my', {
                    idExperience: InitHelper.decryptId($stateParams.id)
                  });
                }, 100);
              });

              return $q.when(true);
            }));
          }]
      }))
      .state('map.experienceMy.add', angular.extend({}, Defaults.mapStateParams, {
        url: '/add',
        params: {location: {}},
        onEnter: ['QueueManager', 'InitHelper', 'QueueItem', '$q', '$timeout', '$rootScope', '$stateParams', '$state',
          function (QueueManager, InitHelper, QueueItem, $q, $timeout, $rootScope, $stateParams, $state) {
            QueueManager.add(new QueueItem(function (panelReady) {
              $q.when(panelReady).then(function () {
                $timeout(function () {
                  if (angular.isObject($stateParams.location) && angular.isDefined($stateParams.location.latitude)) {
                    $rootScope.$broadcast('event:experience-open-add-dropped-pin', {
                      location: $stateParams.location
                    });
                  } else {
                    $state.go('map.experienceMy');
                  }
                });
              });

              return $q.when(true);
            }));
          }]
      }));
  }

})();
