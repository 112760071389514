(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Panels.Profile.controller:ChangePasswordCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Profile')
    .controller('ChangePasswordCtrl', ChangePasswordCtrl);

  function ChangePasswordCtrl(PanelsProfileManager, Defaults, Auth, usSpinnerService, $analytics) {
    var vm = this;
    vm.data = {};
    vm.editProfileErrorMessage = null;
    vm.passwordPattern = new RegExp(Defaults.passwordPattern);
    vm.passwordMessage = Defaults.passwordMessage;
    vm.isSaving = false;

    vm.closeEditPanel = function () {
      PanelsProfileManager.panels.close('changePassword');
    };

    vm.save = function () {
      vm.isSaving = true;
      usSpinnerService.spin('spinner-profile-edit');
      vm.editProfileErrorMessage = null;
      Auth.updatePassword(vm.data, function () {
        usSpinnerService.stop('spinner-profile-edit');
        vm.isSaving = false;
        vm.closeEditPanel();
        $analytics.eventTrack('Update Password', {category: 'Profile'});
      }, function (error) {
        vm.editProfileErrorMessage = error;
        usSpinnerService.stop('spinner-profile-edit');
        vm.isSaving = false;
      });
    };
  }

})();
