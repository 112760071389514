(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.factory:QueueManager
   *
   * @description Holds a set of steps (QueueItem instance) that need to be execute in particular order
   *
   */
  angular
    .module('Queue')
    .factory('QueueManager', QueueManager);

  function QueueManager($timeout, QueueItem) {
    var QueueManagerBase = {};
    /**
     * List of items that needs to be executed
     * @type {QueueItem[]|Array}
     */
    QueueManagerBase.queue = [];

    /**
     * Add new item to the list
     * @param {QueueItem} item
     */
    QueueManagerBase.add = function (item) {
      if (!(item instanceof QueueItem)) {
        throw 'Only instance of QueueItem is allowed';
      }

      this.queue.push(item);
    };

    /**
     * Resets queue
     */
    QueueManagerBase.reset = function () {
      this.queue = [];
    };

    /**
     * Start processing of items in queue
     */
    QueueManagerBase.start = function () {
      var that = this;

      if (this.queue.length === 0) {
        return;
      }

      (/**
        * Main queue loop
        * Always selects first item of the queue and executes it
        * If there is another item then function is called again with result of item as argument
        *
        * @param {misc} previousResult - Result of previously run operation
        * @inner
        */
        function queueLoop(previousResult) {
        if (that.queue.length === 0) {
          return;
        }

        /**
         * Item to process
         * @type {QueueItem}
         */
        var item = that.queue.shift();
        item.callback(previousResult).then(function (result) {
          var delay = item.options.delay || 0;
          /**
           * Process next item with delay, pass result from current item
           */
          $timeout(function () {
            queueLoop(result);
          }, delay);
        });

      })();
    };

    return QueueManagerBase;
  }

})();
