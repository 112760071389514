(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Security.constant:UserRoles
   *
   * @description
   *
   */
  angular
    .module('Security')
    .constant('UserRoles', {
      all: '*',
      user: 'user',
      guest: 'guest'
    });

})();
