(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name LostPassword.controller:LostPasswordCtrl
   *
   * @description
   *
   */
  angular
    .module('LostPassword')
    .controller('LostPasswordCtrl', LostPasswordCtrl);

  function LostPasswordCtrl(Restangular, usSpinnerService, $state, $analytics) {
    var vm = this;
    vm.email = null;
    vm.captcha = null;
    vm.isSending = false;
    vm.isSent = false;

    vm.send = function () {
      vm.isSending = true;
      usSpinnerService.spin('spinner-password-reset');
      Restangular.one('Users').one('lostPassword').get({
        email: vm.email,
        captchaResponse: vm.captcha.response,
        captchaChallenge: vm.captcha.challenge
      }).then(function () {
        vm.isSent = true;
        usSpinnerService.stop('spinner-password-reset');
        $analytics.eventTrack('Request', {category: 'Lost Password'});
      }, function (result) {
        vm.errorMessage = result.data.errorMessage;
        usSpinnerService.stop('spinner-password-reset');
        vm.isSending = false;
      });
    };

    vm.continue = function () {
      $state.go('signin');
    };
  }

})();
