(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name socialShare.directive:socialShare
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="socialShare">
       <file name="index.html">
        <social-share></social-share>
       </file>
     </example>
   *
   */
  angular
    .module('socialShare')
    .directive('socialShare', socialShare);

  function socialShare() {
    return {
      restrict: 'EA',
      scope: {
        url: '=',
        title: '=',
        name: '=',
        description: '=',
        source: '=',
        picture: '=',
        hashTags: '=',
        buttonId: '='
      },
      templateUrl: 'social-share/social-share-directive.tpl.html',
      replace: false,
      controllerAs: 'socialShare',
      controller: ['$scope', 'SocialShare', '$location', 'BitlyUrlShortener', 'Defaults',
          function ($scope, SocialShare, $location, BitlyUrlShortener, Defaults) {
        var vm = this;
        if ($scope.buttonId) {
          vm.id = $scope.buttonId;
        } else {
          vm.id = '';
        }
        vm.share = function (socialService) {
          var url = $scope.url || $location.absUrl();
          BitlyUrlShortener.shorten(url).then(function (shortUrl) {
            SocialShare.share(socialService, {
              url: shortUrl,
              name: $scope.name,
              title: $scope.title,
              description: $scope.description,
              source: $scope.source || 'MinelerApp',
              picture: $scope.picture || Defaults.appUrl + '/images/mineler-share-01.png',
              hashTags: $scope.hashTags
            });
          });
        };
      }]
    };
  }
}());
