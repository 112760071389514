(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Panels.Messages.service:MessagesManager
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Messages')
    .service('MessagesManager', MessagesManager);

  function MessagesManager(PanelsFactory, $state) {
    var
      self = this,
      targetContainerId = 'map-panel-conversation-messages',
      panels = {
        messagesThread: {
          id: 'map-panel-messages-thread',
          templateUrl: 'map/panels/messages/messages-thread.tpl.html',
          resolve: {
            userProfile: ['Auth', function (Auth) {
              return Auth.getSession();
            }]
          }
        }
      };

    self.panels = PanelsFactory.get(panels, targetContainerId, true, false, true, function () {
      $state.go('map.messages');
    });
  }

})();
