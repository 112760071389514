(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Panels.Profile.service:PanelsProfileManager
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Profile')
    .service('PanelsProfileManager', PanelsProfileManager);

  function PanelsProfileManager(PanelsFactory, $state) {
    var
      self = this,
      targetContainerId = 'map-panel-profile-edit',
      panels = {
        profileEditName: {
          id: 'map-panel-profile-edit-name',
          templateUrl: 'map/panels/profile/profile-edit-name.tpl.html',
          resolve: {
            userProfile: ['Auth', function (Auth) {
              return Auth.getSession();
            }]
          }
        },
        profileEditContact: {
          id: 'map-panel-profile-edit-contact',
          templateUrl: 'map/panels/profile/profile-edit-contact.tpl.html',
          resolve: {
            userProfile: ['Auth', function (Auth) {
              return Auth.getSession();
            }]
          }
        },
        profileEditAbout: {
          id: 'map-panel-profile-edit-about',
          templateUrl: 'map/panels/profile/profile-edit-about.tpl.html',
          resolve: {
            userProfile: ['Auth', function (Auth) {
              return Auth.getSession();
            }]
          }
        },
        profileEditPicture: {
          id: 'map-panel-profile-edit-picture',
          templateUrl: 'map/panels/profile/profile-edit-picture.tpl.html'
        },
        privacySettings: {
          id: 'map-panel-privacy-settings',
          templateUrl: 'map/panels/profile/privacy-settings.tpl.html',
          resolve: {
            privacy: ['Restangular', function (Restangular) {
              return Restangular.one('Privacy').one('index').get();
            }]
          }
        },
        changePassword: {
          id: 'map-panel-change-password',
          templateUrl: 'map/panels/profile/change-password.tpl.html'
        },
        addResume: {
          id: 'map-panel-work-resume-add',
          templateUrl: 'map/panels/profile/profile-add-resume.tpl.html'
        },
        editResume: {
          id: 'map-panel-work-resume-edit',
          templateUrl: 'map/panels/profile/profile-edit-resume.tpl.html'
        },
        subscriptionSettings: {
          id: 'map-panel-subscription-settings',
          templateUrl: 'map/panels/profile/subscription-settings.tpl.html',
          resolve: {
            subscriptions: ['Restangular', function (Restangular) {
              return Restangular.one('Subscriptions').one('index').get();
            }]
          }
        }
      };

    self.panels = PanelsFactory.get(panels, targetContainerId, true, false, true, function () {
      $state.go('map.profile');
    });
  }

})();
