angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/profile/change-password.tpl.html',
    '<div id="map-panel-profile-edit" class="map-secondary-panel"\n' +
    '     ng-controller="ChangePasswordCtrl as changePassword" ng-swipe-left="changePassword.closeEditPanel()" ng-swipe-disable-mouse>\n' +
    '  <div class="row map-panel-heading">\n' +
    '    <div class="small-2 columns">\n' +
    '      <a class="arrow-back" ng-click="changePassword.closeEditPanel()">\n' +
    '        <i class="fa fa-angle-double-left"></i>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '    <div class="small-13 columns">\n' +
    '      <h3>Change Password</h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row profile-edit profile-edit-about">\n' +
    '    <div class="small-15 columns">\n' +
    '      <form name="changePasswordForm" role="changePasswordForm">\n' +
    '        <div class="row">\n' +
    '          <div class="small-15 columns">\n' +
    '            <input type="password" ng-model="changePassword.data.oldPassword" name="oldPassword" ng-required="true" placeholder="Old Password" />\n' +
    '            <small class="profile-edit-error" ng-show="changePasswordForm.oldPassword.$dirty && changePasswordForm.oldPassword.$error.required">\n' +
    '              Missing old password\n' +
    '            </small>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="small-15 columns">\n' +
    '            <input type="password" ng-model="changePassword.data.newPassword" name="newPassword" ng-required="true" placeholder="New Password"\n' +
    '              ng-pattern="changePassword.passwordPattern"/>\n' +
    '            <small class="profile-edit-error" ng-show="changePasswordForm.newPassword.$dirty && changePasswordForm.newPassword.$error.required">\n' +
    '              Missing new password\n' +
    '            </small>\n' +
    '            <small class="profile-edit-error" ng-show="changePasswordForm.newPassword.$dirty && changePasswordForm.newPassword.$error.pattern">\n' +
    '              {{changePassword.passwordMessage}}\n' +
    '            </small>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="small-8 small-offset-2 columns">\n' +
    '            <small class="profile-edit-error" ng-show="changePassword.editProfileErrorMessage">{{changePassword.editProfileErrorMessage}}</small>\n' +
    '          </div>\n' +
    '          <div class="small-5 columns profile-edit-button-container">\n' +
    '            <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-profile-edit"></span>\n' +
    '            <button ng-hide="changePassword.isSaving" class="right button positive" ng-click="changePassword.save()" ng-disabled=\'changePasswordForm.$invalid\'>Save</button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
