(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name socialIntegration.service:SocialAccounts
   *
   * @description
   *
   */
  angular
    .module('socialIntegration')
    .service('SocialAccounts', SocialAccounts);

  function SocialAccounts(Auth, Defaults, $q, $state, $analytics, PromptDialog, AlertDialog, socialProviders) {
    var
      self = this,
      isSignupAsCompany;

    function socialSignIn(provider, options, authProvider) {
      var deferred = $q.defer();

      if (!angular.isDefined(authProvider)) {
        authProvider = provider;
      }

      if (!angular.isObject(options)) {
        options = {};
      }
      options.redirect_uri = $state.href($state.current, $state.params, {absolute: true});

      hello.login(provider, options, function () {
        var result = hello(provider).getAuthResponse();
        if (angular.isObject(result) && angular.isDefined(result.access_token)) {
          Auth.socialLogin(authProvider, result.access_token, isSignupAsCompany,
            null, function (authResult) {
            deferred.resolve(authResult);
          }, function (reason) {
            deferred.reject(reason);
          });
        } else {
          deferred.reject('An error occurred');
        }
      });

      return deferred.promise;
    }

    function googleSignIn() {
      return socialSignIn('google', {
        scope: Defaults.googlePlusScope
      }, 'googleplus');
    }

    function facebookSignIn() {
      return socialSignIn('facebook', {});
    }

    function linkedinSignIn() {
      return socialSignIn('linkedin', {
        scope: Defaults.linkedInScope
      });
    }

    self.signIn = function (provider, isSignUp, isCompany) {
      if (!angular.isDefined(isSignUp) || isSignUp !== true) {
        isSignUp = false;
      }

      isSignupAsCompany = isCompany;

      var eventCategory = (isSignUp ? 'Sign Up' : 'Sign In');

      switch (provider) {
        case 'google':
          $analytics.eventTrack('Google+', {category: eventCategory});
          return googleSignIn();
        case 'facebook':
          $analytics.eventTrack('Facebook', {category: eventCategory});
          return facebookSignIn();
        case 'linkedin':
          $analytics.eventTrack('LinkedIn', {category: eventCategory});
          return linkedinSignIn();
      }
    };

    function disconnectSocialService(provider) {
      var providerName = socialProviders[provider].name;
      PromptDialog.open('Are you sure you want to disconnect your Mineler account from ' +
                          providerName +
                          '? You will not be able to use ' + providerName + ' to sign in to Mineler',
                          'Your Mineler account will be disconnected from ' + providerName)
        .result.then(function () {
          Auth.disconnectSocialService(socialProviders[provider].backendProviderIdentifier);
        });
    }

    function connectSocialService(provider, options) {
      var deferred = $q.defer();

      if (!angular.isObject(options)) {
        options = {};
      }

      options.redirect_uri = Defaults.appUrl;

      hello.login(provider, options, function (result) {
        if (angular.isDefined(result.authResponse) && angular.isDefined(result.authResponse.access_token)) {
          Auth.connectSocialService(socialProviders[provider].backendProviderIdentifier,
            result.authResponse.access_token,
            function (authResult) {
              deferred.resolve(authResult);
              AlertDialog.open('Your Mineler account has been successfully connected with ' +
                socialProviders[provider].name);
            }, function (reason) {
              deferred.reject(reason);
            });
        } else {
          deferred.reject('An error occurred');
        }
      });

      return deferred.promise;
    }

    self.toggleConnection = function (provider) {
      Auth.getSession().then(function (session) {
        switch (provider) {
          case 'google':
            if (session.profile.isGoogleplus) {
              disconnectSocialService(provider);
            } else {
              connectSocialService(provider, {
                scope: Defaults.googlePlusScope
              });
            }
            break;
          case 'facebook':
            if (session.profile.isFacebook) {
              disconnectSocialService(provider);
            } else {
              connectSocialService(provider, {});
            }
            break;
          case 'linkedin':
            if (session.profile.isLinkedin) {
              disconnectSocialService(provider);
            } else {
              connectSocialService(provider, {
                scope: Defaults.linkedInScope
              });
            }
            break;
        }
      });
    };

    self.isConnected = function (provider) {
      return Auth.getSession().then(function (session) {
        switch (provider) {
          case 'google':
            return session.profile.isGoogleplus;
          case 'facebook':
            return session.profile.isLinkedin;
          case 'linkedin':
            return session.profile.isLinkedin;
        }

        throw 'Unknown provider';
      });
    };

    self.connect = function (provider, accessToken) {
      var
        deferred = $q.defer(),
        providerName = socialProviders[provider].name;

      PromptDialog.open('It seems your Mineler account is not yet connected with ' +
        providerName +
        '? Would you like to connect it now?',
        'Connecting your Mineler account with ' + providerName)
        .result.then(function () {
          return Auth.connectSocialService(socialProviders[provider].backendProviderIdentifier, accessToken,
            function () {
              deferred.resolve();
              AlertDialog.open('Your Mineler account has been successfully connected with ' +
              socialProviders[provider].name);
            }, function (reason) {
              deferred.reject(reason);
            });
        }, function () {
          deferred.resolve();
        });

      return deferred.promise;
    };
  }

})();
