angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/experience/experience.tpl.html',
    '<div ng-controller="ExperienceCtrl as experience" class="map-panel-controller">\n' +
    '  <div id="map-panel-experience-container" class="map-panel map-primary-panel" ng-swipe-left="experience.closePanel()"\n' +
    '       ng-swipe-disable-mouse>\n' +
    '    <div class="row map-panel-heading">\n' +
    '      <div class="small-2 columns">\n' +
    '        <a class="arrow-back" ng-click="experience.closePanel()">\n' +
    '          <i class="fa fa-angle-double-left"></i>\n' +
    '        </a>\n' +
    '      </div>\n' +
    '      <div class="small-13 columns">\n' +
    '        <ul>\n' +
    '          <li class="cursor-pointer" ng-class="{\'panel-heading-active\': experience.tab===\'experience\'}"\n' +
    '            ><a ui-sref="map.experience">Experience</a>\n' +
    '          </li>\n' +
    '          <li class="cursor-pointer" ng-class="{\'panel-heading-active\': experience.tab===\'myExperience\'}"\n' +
    '            ><a ui-sref="map.experienceMy">My Experience</a>\n' +
    '          </li>\n' +
    '          <li class="cursor-pointer" ng-class="{\'panel-heading-active\': experience.tab===\'validations\'}"\n' +
    '            ><a ui-sref="map.experienceValidations">Validations</a>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="map-primary-panel-content" ng-show="experience.tab === \'experience\'">\n' +
    '      <div class="row cursor-pointer"\n' +
    '           ng-repeat="ex in experience.experienceLoader.records | activeRecord: \'map.experience.detail\' : \'id\'"\n' +
    '           ng-class="{\'is-active-record\': ex.isActiveRecord}"\n' +
    '           ng-click="experience.openDetailByIndex($index)">\n' +
    '        <div class="small-5 columns map-panel-profile-photo">\n' +
    '          <img ng-src="{{ex.user | profilePicture:45}}" class="circle45">\n' +
    '        </div>\n' +
    '        <div class="small-10 columns">\n' +
    '          <span class="panel-text-heading">\n' +
    '            <user-widget-name user="ex.user"></user-widget-name>\n' +
    '          </span><br/>\n' +
    '          <span class="panel-text-heading">\n' +
    '            {{ex.jobTitleName}}\n' +
    '          </span><br/>\n' +
    '          <span class="panel-text-heading">\n' +
    '            {{ex.projectName}}, {{ex.employerName}}\n' +
    '          </span><br/>\n' +
    '          <span class="experience-date">\n' +
    '            {{ex.dateFrom | experienceDate}} - {{ex.dateTo | experienceDate}}\n' +
    '          </span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row" ng-if="experience.experienceLoader.hasMore() && !experience.experienceLoader.isLoading">\n' +
    '        <div class="small-15 columns text-center">\n' +
    '          <a ng-click="experience.loadMore()">Load more ...</a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="map-primary-panel-content" ng-show="experience.tab === \'myExperience\'">\n' +
    '      <div class="row cursor-pointer"\n' +
    '           ng-repeat="ex in experience.myExperienceLoader.records | activeRecord: \'map.experienceMy.detail\' : \'id\'"\n' +
    '           ng-class="{\'is-active-record\': ex.isActiveRecord}"\n' +
    '           ng-click="experience.openMyDetailByIndex($index)">\n' +
    '        <div class="small-5 columns map-panel-profile-photo">\n' +
    '          <img ng-src="{{ex.user | profilePicture:45}}" class="circle45">\n' +
    '        </div>\n' +
    '        <div class="small-10 columns">\n' +
    '          <span class="panel-text-heading">\n' +
    '            {{ex.jobTitleName}}\n' +
    '          </span><br/>\n' +
    '          <span class="panel-text-heading">\n' +
    '            {{ex.projectName}}, {{ex.employerName}}\n' +
    '          </span><br/>\n' +
    '          <span class="experience-date">\n' +
    '            {{ex.dateFrom | experienceDate}} - {{ex.dateTo | experienceDate}}\n' +
    '          </span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row" ng-if="experience.myExperienceLoader.hasMore() && !experience.myExperienceLoader.isLoading">\n' +
    '        <div class="small-15 columns text-center">\n' +
    '          <a ng-click="experience.loadMoreMy()">Load more ...</a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="map-primary-panel-content" ng-show="experience.tab === \'validations\'">\n' +
    '      <div\n' +
    '        ng-repeat="(isReceivedRequest, validations) in experience.validationsLoader.records | activeRecord: \'map.experienceValidations.detail\' : \'id\' | groupBy: \'isReceivedRequest\'">\n' +
    '        <div ng-if="isReceivedRequest == \'false\'" class="invites-group-heading">Sent Requests</div>\n' +
    '        <div ng-if="isReceivedRequest == \'true\'" class="invites-group-heading">Received Requests</div>\n' +
    '        <div class="row cursor-pointer" ng-repeat="validation in validations"\n' +
    '             ng-class="{\'is-active-record\': validation.isActiveRecord}"\n' +
    '             ng-click="experience.openValidationByData(validation)">\n' +
    '          <div class="small-5 columns map-panel-profile-photo">\n' +
    '            <img ng-if="isReceivedRequest == \'true\'" ng-src="{{validation.experience.user | profilePicture:45}}"\n' +
    '                 class="circle45">\n' +
    '\n' +
    '            <img ng-if="isReceivedRequest == \'false\'" ng-src="{{validation.userValidatedBy | profilePicture:45}}"\n' +
    '                 class="circle45">\n' +
    '\n' +
    '          </div>\n' +
    '          <div class="small-10 columns">\n' +
    '              <span class="panel-text-heading">\n' +
    '                {{validation.experience.jobTitleName}}\n' +
    '              </span><br/>\n' +
    '              <span class="panel-text-heading">\n' +
    '                {{validation.experience.projectName}}, {{validation.experience.employerName}}\n' +
    '              </span><br/>\n' +
    '              <span class="experience-date">\n' +
    '                {{validation.experience.dateFrom | experienceDate}} - {{validation.experience.dateTo | experienceDate}}\n' +
    '              </span><br/>\n' +
    '              <span class="info-text">\n' +
    '                <i class="fa fa-clock-o"></i> {{validation.lastSent}} ago\n' +
    '              </span>\n' +
    '          </div>\n' +
    '\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row" ng-if="experience.validationsLoader.hasMore() && !experience.validationsLoader.isLoading">\n' +
    '        <div class="small-15 columns text-center">\n' +
    '          <a ng-click="experience.loadMoreValidations()">Load more ...</a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div id="map-panel-experience-actions" class="map-secondary-panel-container"></div>\n' +
    '</div>\n' +
    '\n' +
    '');
}]);
