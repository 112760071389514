angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('components/alert-dialog.tpl.html',
    '<div class="prompt-dialog-content">\n' +
    '  <div class="prompt-message" ng-bind-html="alertDialog.content"></div>\n' +
    '\n' +
    '  <div class="prompt-buttons">\n' +
    '    <button class="button positive" ng-click="alertDialog.confirm()">OK</button>\n' +
    '  </div>\n' +
    '\n' +
    '</div>\n' +
    '');
}]);
