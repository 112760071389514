angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/profile/profile-edit-resume.tpl.html',
    '<div id="map-panel-profile-edit" class="map-secondary-panel"\n' +
    '     ng-controller="ProfileEditResumeCtrl as editResume" ng-swipe-left="editResume.closePanel()" ng-swipe-disable-mouse>\n' +
    '  <div class="row map-panel-heading">\n' +
    '    <div class="small-2 columns">\n' +
    '      <a class="arrow-back" ng-click="editResume.closePanel()">\n' +
    '        <i class="fa fa-angle-double-left"></i>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '    <div class="small-13 columns">\n' +
    '      <h3>Edit Resume</h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row profile-edit">\n' +
    '    <div class="small-15 columns">\n' +
    '      <form name="editResumeForm" role="editResumeForm">\n' +
    '        <div class="row">\n' +
    '          <div class="small-15 columns">\n' +
    '            <input type="text" name="name" ng-model="editResume.data.name" ng-required="true"\n' +
    '                   placeholder="My Resume"/>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="small-15 columns">\n' +
    '            <span ng-hide="editResume.selectedFile" class="custom-file-upload-button" ng-file-select="editResume.onFileSelect($files)" ng-required="true"><i class="fa fa-paperclip"></i> Select a file to upload</span>\n' +
    '            <span ng-show="editResume.selectedFile">{{editResume.selectedFile.name}}<a ng-click="editResume.removeFile()" ng-hide="editResume.isSaving"> <i class="fa fa-close"></i></a></span>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="small-15 columns">\n' +
    '            <small class="profile-edit-error" ng-show="editResume.errorMessage">{{editResume.errorMessage}}</small>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="small-15 columns profile-edit-button-container text-right">\n' +
    '            <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-profile-edit"></span>\n' +
    '            <button ng-hide="editResume.isSaving" class="button important" ng-click="editResume.delete()">Delete</button>\n' +
    '            <file-download ng-hide="editResume.isSaving" class="button positive" url="editResume.downloadUrl" label="\'\n' +
    '            Download\'"\n' +
    '              event-category="Resume" event-name="Download"></file-download>\n' +
    '            <button ng-hide="editResume.isSaving" class="button positive" ng-click="editResume.save()" ng-disabled=\'editResumeForm.$invalid\'>Save</button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
