(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Map.Panels.MyWorkWork.controller:BidMessagesCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.MyWork')
    .controller('BidMessagesCtrl', BidMessagesCtrl);

  function BidMessagesCtrl(MyWorkEditPanelManager, $scope) {
    var vm = this;
    vm.contactProfile = $scope.contactProfile;
    vm.myProfile = $scope.userProfile.profile;
    vm.messagesParameters = $scope.messagesParameters;

    vm.closePanel = function () {
      MyWorkEditPanelManager.panels.close('bidMessages');
    };
  }
}());
