(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Panels.Work.controller:EditResumeCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Profile')
    .controller('ProfileEditResumeCtrl', ProfileEditResumeCtrl);

  function ProfileEditResumeCtrl($scope, PanelsProfileManager, usSpinnerService, $upload,
                          Defaults, Restangular, PromptDialog, $analytics) {
    var vm = this;
    vm.data = $scope.resume;
    vm.errorMessage = null;
    vm.selectedFile = null;
    vm.isSaving = false;
    vm.downloadUrl = '/Resume/download?id=' + vm.data.id;

    vm.closePanel = function (data) {
      if (!angular.isDefined(data) || !angular.isObject(data)) {
        data = {};
      }

      PanelsProfileManager.panels.close('editResume', data);
    };

    vm.onFileSelect = function ($files) {
      vm.errorMessage = null;
      if (angular.isDefined($files[0])) {
        if ($files[0].size >= Defaults.maxFileUploadSize) {
          vm.errorMessage = Defaults.maxFileUploadMessage;
        } else {
          vm.selectedFile = $files[0];
        }
      }
    };

    vm.removeFile = function () {
      vm.selectedFile = null;
    };

    vm.save = function () {
      vm.isSaving = true;
      usSpinnerService.spin('spinner-profile-edit');
      vm.errorMessage = null;
      if (vm.selectedFile) {
        $upload.upload({
          url: Defaults.baseUrl + '/Resume/index?id=' + vm.data.id,
          data: {
            name: vm.data.name
          },
          file: vm.selectedFile,
          method: 'PUT',
          fileFormDataName: 'resume'
        })
          .success(function (data) {
            usSpinnerService.stop('spinner-profile-edit');
            vm.isSaving = false;
            vm.closePanel({
              status: 'updated',
              updatedResume: data.content
            });
            $analytics.eventTrack('Edit Resume', {category: 'Profile', label: 'Update name only'});
          })
          .error(function (result) {
            vm.errorMessage = result.errorMessage;
            usSpinnerService.stop('spinner-profile-edit');
            vm.isSaving = false;
          });
      } else {
        var dataEncoded = $.param(vm.data);
        Restangular.one('Resume').customPUT(dataEncoded, 'index', {id:vm.data.id}, {
          'Content-Type': 'application/x-www-form-urlencoded'
        })
          .then(function (result) {
            usSpinnerService.stop('spinner-profile-edit');
            vm.isSaving = false;
            PanelsProfileManager.panels.close('editResume', {
              status: 'updated',
              updatedResume: result
            });
            $analytics.eventTrack('Edit Resume', {category: 'Profile', label: 'Upload new file'});
          }, function (result) {
            vm.errorMessage = result.data.errorMessage;
            usSpinnerService.stop('spinner-profile-edit');
            vm.isSaving = false;
          });
      }

    };

    vm.delete = function () {
      PromptDialog.open('Are you sure?', 'Your resume will be deleted')
        .result.then(function () {
          vm.isSaving = true;
          usSpinnerService.spin('spinner-profile.edit');
          Restangular.one('Resume').one('index').remove({id: vm.data.id})
            .then(function () {
              vm.closePanel({status: 'deleted'});
              $analytics.eventTrack('Delete Resume', {category: 'Profile'});
            }, function (result) {
              vm.errorMessage = result.data.errorMessage;
              usSpinnerService.stop('spinner-profile.edit');
              vm.isSaving = false;
            });
        });
    };
  }

})();
