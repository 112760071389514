angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('network/quick-connect.tpl.html',
    '<div class="row">\n' +
    '  <div class="small-15 columns text-right">\n' +
    '    <h2>Contact Request</h2>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="row">\n' +
    '  <div class="small-3 columns">\n' +
    '    <img class="circle45 right" ng-src="{{networkQuickConnect.userProfile | profilePicture:45}}"/>\n' +
    '  </div>\n' +
    '  <div class="small-12 columns">\n' +
    '    <h3><user-widget-name user="networkQuickConnect.userProfile"></user-widget-name></h3>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="row" ng-show="networkQuickConnect.userProfile.personalMessage">\n' +
    '  <div class="small-15 columns quick-connect-personal-message">\n' +
    '    {{networkQuickConnect.userProfile.personalMessage | truncate: 200: \'...\': true}}\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row">\n' +
    '  <div class="small-15 columns">\n' +
    '    <div ng-if="networkQuickConnect.userProfile.phoneHome || networkQuickConnect.userProfile.phoneMobile || networkQuickConnect.userProfile.email">\n' +
    '      <h3>Contact</h3>\n' +
    '          <span ng-show="networkQuickConnect.userProfile.phoneHome"><i\n' +
    '            class="fa fa-phone-square"></i> {{networkQuickConnect.userProfile.phoneHome}}<br/></span>\n' +
    '          <span ng-show="networkQuickConnect.userProfile.phoneMobile"><i\n' +
    '            class="fa fa-mobile"></i> {{networkQuickConnect.userProfile.phoneMobile}}<br/></span>\n' +
    '          <span  ng-show="networkQuickConnect.userProfile.email">\n' +
    '            <i class="fa fa-envelope-square"></i> {{networkQuickConnect.userProfile.email}}</span>\n' +
    '      <br/><br/>\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-if="networkQuickConnect.addressNotEmpty()">\n' +
    '      <h3>Address</h3>\n' +
    '      <div class="map-panel-profile-address">\n' +
    '          <span>\n' +
    '          <i class="fa fa-home"></i>\n' +
    '            </span>\n' +
    '          <span>\n' +
    '            <span ng-show="networkQuickConnect.userProfile.streetAddress">{{networkQuickConnect.userProfile.streetAddress}}<br/></span>\n' +
    '            <span ng-show="networkQuickConnect.userProfile.suburb">{{networkQuickConnect.userProfile.suburb}}</span> <span\n' +
    '            ng-show="networkQuickConnect.userProfile.postCode">{{networkQuickConnect.userProfile.postCode}}<br/></span>\n' +
    '            <span ng-show="networkQuickConnect.userProfile.state.name">{{networkQuickConnect.userProfile.state.name}}<br/></span>\n' +
    '            {{networkQuickConnect.userProfile.country.name}}\n' +
    '          </span>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row network-form-container">\n' +
    '  <div class="small-15 columns">\n' +
    '    <form name="networkForm" role="networkForm" novalidate>\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <small class="error" ng-show="networkQuickConnect.errorMessage">{{networkQuickConnect.errorMessage}}</small>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <label>Request\n' +
    '            <textarea name="message" ng-required="true" ng-model="networkQuickConnect.message" ng-maxlength="255"\n' +
    '                      maxlength="255" msd-elastic>\n' +
    '            </textarea>\n' +
    '          </label>\n' +
    '          <small class="error" ng-show="networkForm.message.$dirty && networkForm.message.$error.required">Missing\n' +
    '            message\n' +
    '          </small>\n' +
    '          <small class="error" ng-show="networkForm.message.$dirty && networkForm.message.$error.maxlength">Max 255\n' +
    '            characters allowed\n' +
    '          </small>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns text-right network-buttons-container">\n' +
    '          <span us-spinner="{radius:5, width:5, length: 13, color: \'#ffffff\'}"\n' +
    '                spinner-key="spinner-network-send"></span>\n' +
    '          <button ng-hide="networkQuickConnect.isSending" class="button" ng-click="networkQuickConnect.close()"><i\n' +
    '            class="fa fa-fw fa-times-circle"></i> Cancel\n' +
    '          </button>\n' +
    '          <button ng-hide="networkQuickConnect.isSending" class="button positive" ng-click="networkQuickConnect.send()"\n' +
    '                  ng-disabled=\'networkForm.$invalid\'><i class="fa fa-sign-in"> </i> Send\n' +
    '          </button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
