(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Panels.People.controller:PeopleDetailCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.People')
    .controller('PeopleDetailCtrl', PeopleDetailCtrl);

  function PeopleDetailCtrl(PeopleManager, $scope, NetworkManager, usSpinnerService, InitHelper, PromptDialog,
                            $analytics, $state, MapComponent) {
    var vm = this;
    vm.user = $scope.user;
    vm.myProfile = $scope.userProfile;
    vm.isSending = false;
    vm.requestMessage = 'I would like to connect with you on Mineler.';
    vm.errorMessage = null;

    vm.closePanel = function (data) {
      if (!angular.isDefined(data) || !angular.isObject(data)) {
        data = {};
      }
      PeopleManager.panels.close('peopleDetail', data, function () {
        if (vm.user.profileRelation !== 'confirmed_contact' && vm.user.profileRelation !== 'owner') {
          $state.go('map.people.suggestions');
        } else {
          $state.go('map.people.network');
        }
      });
    };

    vm.disConnect = function () {
      PromptDialog.open('Are you sure?', vm.user.firstName + ' will be removed from your network')
        .result.then(function () {
          vm.isSending = true;
          usSpinnerService.spin('spinner-people-detail-action');
          NetworkManager.disconnect(vm.user.id, function () {
            vm.closePanel({removeContact: true});
            $analytics.eventTrack('Disconnect', {category: 'People'});
          }, function () {
            vm.closePanel();
          });
        });
    };

    vm.sendMessage = function () {
      $state.go('map.messages.detail', {id: InitHelper.encryptId(vm.user.id)});
      $analytics.eventTrack('Send Message', {category: 'People'});
    };

    vm.connectButtonLabel = (vm.user.isCompany ? 'FOLLOW' : 'CONNECT');
    vm.connect = function () {
      vm.errorMessage = null;
      vm.isSending = true;
      usSpinnerService.spin('spinner-people-detail-action');
      NetworkManager.connect(vm.user.id, null, null, null, vm.requestMessage, function (result) {
        vm.closePanel({
          inviteSent: true,
          invite: result.plain()
        });
        $analytics.eventTrack('Connect', {category: 'People'});
      }, function (reason, errorCode) {
        if (angular.isDefined(errorCode) && errorCode === 205) {
          vm.closePanel();
        } else {
          vm.isSending = false;
          usSpinnerService.stop('spinner-people-detail-action');
          vm.errorMessage = reason;
        }
      });
    };

    vm.showExperience = function () {
      $analytics.eventTrack('Show Experience', {category: 'People'});
      $('#map-search-input_value').val(vm.user.firstName + ' ' + vm.user.lastName);
      MapComponent.loadUserLocations([vm.user.id]);
    };
  }

})();
