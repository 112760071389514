(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.factory:QueueItem
   *
   * @description Creates an instance of QueueItem that can be added to the QueueManager to perform some operation
   *
   */
  angular
    .module('Queue')
    .factory('QueueItem', QueueItem);

  function QueueItem() {
    /**
     * Creates new instance of QueueItem
     * @param {function} callback
     * @param {Object} options
     * @constructor
     */
    var QueueItemBase = function (callback, options) {
      if (!angular.isFunction(callback)) {
        throw 'Callback has to be a function';
      }

      this.callback = callback;
      this.options = options || {};
    };

    return QueueItemBase;
  }

})();
