angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/messages/messages-thread-directive.tpl.html',
    '<div class="messages-widget">\n' +
    '  <div ng-if="messagesThread.reversed">\n' +
    '    <div ng-include="\'map/panels/messages/messages-thread-directive-list.tpl.html\'"></div>\n' +
    '    <div ng-include="\'map/panels/messages/messages-thread-directive-textarea.tpl.html\'"></div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div ng-if="!messagesThread.reversed">\n' +
    '    <div ng-include="\'map/panels/messages/messages-thread-directive-textarea.tpl.html\'"></div>\n' +
    '    <div ng-include="\'map/panels/messages/messages-thread-directive-list.tpl.html\'"></div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
