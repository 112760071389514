(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Panels.Experience.controller:ExperienceDetailCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Experience')
    .controller('ExperienceDetailCtrl', ExperienceDetailCtrl);

  function ExperienceDetailCtrl(ExperiencePanelManager, $scope, ExperienceManager,
                                PromptDialog, $analytics, $state) {
    var vm = this;
    vm.detail = $scope.detail;
    vm.isMyExperience = $scope.isMyExperience;

    vm.validations = ExperienceManager.loadValidations(vm.detail.id).$object;

    vm.closePanel = function (result) {
      ExperiencePanelManager.panels.close('experienceDetail', result, function () {
        if (!angular.isObject(result) || (result === 'edit' && result === 'validate')) {
          if (vm.isMyExperience) {
            $state.go('map.experienceMy');
          } else {
            $state.go('map.experience');
          }
        }
      });
    };

    vm.addExperience = function () {
      ExperiencePanelManager.panels.open('experienceAdd', {
        experience: vm.detail
      });
    };

    vm.delete = function () {
      PromptDialog.open('Are you sure?')
        .result.then(function () {
          ExperienceManager.delete(vm.detail.id, function () {
            vm.closePanel({
              action: 'remove'
            });
            $analytics.eventTrack('Delete', {category: 'Experience'});
          });
        });
    };

    vm.edit = function () {
      vm.closePanel({
        action: 'edit'
      });
    };

    vm.requestValidation = function () {
      vm.closePanel({
        action: 'validate'
      });
    };
  }

})();
