angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/experience/experience-detail.tpl.html',
    '<div id="map-panel-experience-detail-action" class="map-secondary-panel"\n' +
    '     ng-controller="ExperienceDetailCtrl as experienceDetail" ng-swipe-left="experienceDetail.closePanel()" ng-swipe-disable-mouse>\n' +
    '  <div class="row map-panel-heading">\n' +
    '    <div class="small-2 columns">\n' +
    '      <a class="arrow-back" ng-click="experienceDetail.closePanel()">\n' +
    '        <i class="fa fa-angle-double-left"></i>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '    <div class="small-6 columns map-panel-heading-experience-name">\n' +
    '      <h3>\n' +
    '        <user-widget-name user="experienceDetail.detail.user"></user-widget-name>\n' +
    '      </h3>\n' +
    '      <user-action-button user="experienceDetail.detail.user"></user-action-button>\n' +
    '    </div>\n' +
    '    <div class="small-7 text-right columns">\n' +
    '      <button class="button positive" ng-click="experienceDetail.addExperience()">\n' +
    '        I WORKED HERE\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="experience-detail-container">\n' +
    '    <div class="row">\n' +
    '      <div class="small-15 columns">\n' +
    '        <user-widget image-class="circle45" user="experienceDetail.detail.user" width="45" height="45"></user-widget>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="row">\n' +
    '      <div class="small-15 columns">\n' +
    '        <h3>{{experienceDetail.detail.jobTitleName}}</h3>\n' +
    '        <h3>\n' +
    '          <span project-widget experience="experienceDetail.detail"></span>, <span company-widget experience="experienceDetail.detail"></span>\n' +
    '        </h3>\n' +
    '        <h4>{{experienceDetail.detail.dateFrom | experienceDate}} - {{experienceDetail.detail.dateTo | experienceDate}}</h4>\n' +
    '        <div>\n' +
    '          {{experienceDetail.detail.description}}\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row experience-detail-skill" ng-show="experienceDetail.detail.skills.length">\n' +
    '      <div class="small-15 columns">\n' +
    '        <h3>Skills</h3>\n' +
    '        <div ng-repeat="skill in experienceDetail.detail.skills">{{skill.name}}</div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row experience-detail-validations" ng-repeat="validation in experienceDetail.validations">\n' +
    '      <div class="small-15 columns">\n' +
    '        <div class="row">\n' +
    '          <div class="small-3 columns">\n' +
    '            <user-widget image-class="circle30" user="validation.userValidatedBy" width="30" height="30"></user-widget>\n' +
    '          </div>\n' +
    '          <div class="small-10 columns">\n' +
    '            <user-widget-name user="validation.userValidatedBy" is-link="true"></user-widget-name><br/>\n' +
    '            {{validation.validationMessage}}\n' +
    '          </div>\n' +
    '          <div class="small-2 columns text-right">\n' +
    '            <user-action-button user="validation.userValidatedBy"></user-action-button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row experience-menu" ng-if="experienceDetail.isMyExperience">\n' +
    '      <div class="small-15 columns text-right">\n' +
    '        <button class="button" dropdown-toggle="#experience-menu">\n' +
    '          <i class="fa fa-angle-down"></i> Options\n' +
    '        </button>\n' +
    '        <button class="button positive" ng-click="experienceDetail.requestValidation()">REQUEST VALIDATION</button>\n' +
    '        <ul id="experience-menu" class="f-dropdown text-left">\n' +
    '          <li><a ng-click="experienceDetail.edit()">EDIT</a></li>\n' +
    '          <li><a ng-click="experienceDetail.delete()">DELETE</a></li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
