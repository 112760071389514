(function () {
  'use strict';

  /* @ngdoc object
   * @name Map.Dashboards
   *
   * @description
   *
   */
  angular
    .module('Map.Dashboards', [
      'Components'
    ]);

  angular
    .module('Map.Dashboards')
    .config(config);

  function config() {
  }

})();
