(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Map.Panels.Work.service:PanelsWorkManager
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Work')
    .service('WorkPanelManager', WorkPanelManager);

  function WorkPanelManager(PanelsFactory, $state) {
    var
      self = this,
      targetContainerId = 'map-panel-work-actions',
      panels = {
        detail: {
          id: 'map-panel-work-detail',
          templateUrl: 'map/panels/work/work-detail.tpl.html',
          resolve: {
            userProfile: ['Auth', function (Auth) {
              return Auth.getSession();
            }]
          }
        }
      };

    self.panels = PanelsFactory.get(panels, targetContainerId, true, false, true, function () {
      $state.go('map.work');
    });
  }

})();
