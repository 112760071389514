(function () {
  'use strict';

  /* @ngdoc object
   * @name Security.User
   * @requires $stateProvider
   *
   * @description
   *
   */
  angular
    .module('Security.User', [
    ]);

  angular
    .module('Security.User');

})();
