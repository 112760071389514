(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Components.service:PanelsFactory
   *
   * @description
   *
   */
  angular
    .module('Components')
    .service('PanelsFactory', PanelsFactory);

  /**
   * PanelsFactory service
   *
   * @param $injector
   * @param $compile
   * @param $q
   * @param $rootScope
   * @param $window
   * @param $timeout
   * @constructor
   */
  function PanelsFactory($injector, $compile, $q, $rootScope, $window, $timeout) {
    var self = this;

    /**
     * Creates a new instance of panels manager
     *
     * @param {object} panels Panels with configuration
     * @param {string} targetContainerId ID of container where the panels will be loaded
     * @param {boolean} removeFromDOM Flag indicating whether panel should be removed from DOM when closing it
     * @param {boolean} primaryPanel Indicates primary panel - first level
     * @param {function} onCloseCallback Callback executed when closing panel
     * @param $injector
     * @param $compile
     * @param $q
     * @param $rootScope
     * @constructor
     */
    function PanelsFactoryTemplate (panels, targetContainerId, removeFromDOM, primaryPanel, secondaryPanel,
                                    onCloseCallback, $injector, $compile, $q, $rootScope) {
      var
        /**
         * Class indicating active panel
         * @type {string}
         */
        activeClass = 'active',
        /**
         * List of deferred promises
         * @type {{}}
         */
        defers = {},
        /**
         * List of scopes
         * @type {{}}
         */
        scopes = {};

      primaryPanel = (primaryPanel === true ? true : false);
      secondaryPanel = (secondaryPanel === true ? true : false);
      onCloseCallback = onCloseCallback || angular.noop;

      /**
       * Resolve promises for panel
       * @param {object} resolves
       * @param {object} params
       * @returns {{}}
       */
      function getResolvePromises (resolves, params) {
        var promisesArr = {};
        if (!angular.isObject(params)) {
          params = {};
        }
        angular.forEach(resolves, function (value, key) {
          if (angular.isFunction(value) || angular.isArray(value)) {
            promisesArr[key] = $q.when($injector.invoke(value, {}, params));
          }
        });

        return promisesArr;
      }

      /**
       * Opens a panel
       * @param {string} name Panel to be opened
       * @param {object} params Parameters forwarded to newly opened panel
       * @returns {Document.promise|*|k.promise|{then, catch, finally}}
       */
      this.open = function (name, params) {
        this.closeAll(angular.noop);
        var
          id,
          resolve,
          content,
          panelScope,
          resolveIter = 1,
          compiled,
          newPanel = true,
          deferred = $q.defer();

        if (!angular.isDefined(panels[name])) {
          throw 'Unknown panel';
        }

        id = panels[name].id;

        if (removeFromDOM === true) {
          $('#' + targetContainerId + ' .map-panel-container:not("#' + id + '")').remove();
        } else {
          $('#' + targetContainerId + ' .map-panel-container:not("#' + id + '")').removeClass(activeClass);
        }

        if (!document.getElementById(id)) {
          resolve = panels[name].resolve || {};
          $q.all(getResolvePromises(resolve, params)).then(function (resolveVars) {
            content = '<div id="' + id + '" ' +
              'ng-include="\'' + panels[name].templateUrl + '\'" class="map-panel-container active"></div>';
            panelScope = $rootScope.$new();
            resolveIter = 1;

            angular.forEach(resolve, function (value, key) {
              panelScope[key] = resolveVars[key];
            });

            angular.forEach(params, function (value, key) {
              panelScope[key] = params[key];
            });

            scopes[name] = panelScope;
            compiled = $compile(content)(panelScope);

            $('#' + targetContainerId).append(compiled);
          });
          newPanel = false;
        }

        $('#' + id).addClass(activeClass);
        defers[name] = deferred;

        $rootScope.$broadcast('event:panel-activated');
        $rootScope.$broadcast('event:panel-activated:' + name, {
          newPanel: newPanel,
          params: params
        });

        $timeout(function () {
          if (primaryPanel !== true) {
            if (secondaryPanel !== true) {
              if ($window.innerWidth < ($('#' + targetContainerId).innerWidth() +
                $('#' + targetContainerId).prev('.map-secondary-panel').parents('.map-secondary-panel-container')
                  .prev('.map-primary-panel').innerWidth() +
                $('#' + targetContainerId).prev('.map-secondary-panel').innerWidth())) {
                $('#' + targetContainerId).prev('.map-secondary-panel').parents('.map-secondary-panel-container')
                  .prev('.map-primary-panel').addClass('primary-panel-hidden');
              }

              if ($window.innerWidth < ($('#' + targetContainerId).innerWidth() +
                $('#' + targetContainerId).prev('.map-secondary-panel').innerWidth())) {
                $('#' + targetContainerId).prev('.map-secondary-panel').addClass('secondary-panel-hidden');
              }
            } else {
              if ($window.innerWidth < ($('#' + targetContainerId).innerWidth() +
                $('#' + targetContainerId).prev('.map-primary-panel').innerWidth())) {
                $('#' + targetContainerId).prev('.map-primary-panel').addClass('primary-panel-hidden');
              }
            }
          }
        }, 50);

        return deferred.promise;
      };

      /**
       * Close one panel
       * @param {string} name Panel to be closed
       * @param {object} result Data available after closing the panel
       * @param {function} callback Callback executed when closing the panel, if not provided defautl callback will be executed
       */
      this.close = function (name, result, callback) {
        callback = callback || onCloseCallback;
        var id;
        if (!angular.isDefined(panels[name])) {
          throw 'Unknown panel';
        }
        id = panels[name].id;
        if (removeFromDOM === true) {
          $('#' + id).remove();
          if (angular.isDefined(scopes[name])) {
            scopes[name].$destroy();
          }
        } else {
          $('#' + id).removeClass(activeClass);
        }

        if (angular.isDefined(defers[name])) {
          defers[name].resolve(result);
          delete defers[name];
        }

        if (!primaryPanel && !secondaryPanel) {
          if ($('#' + targetContainerId).prev('.map-secondary-panel').is(':visible')) {
            $('#' + targetContainerId).prev('.map-secondary-panel').parents('.map-secondary-panel-container')
              .prev('.map-primary-panel').removeClass('primary-panel-hidden');
          } else {
            $('#' + targetContainerId).prev('.map-secondary-panel').removeClass('secondary-panel-hidden');
          }
        } else {
          $('#' + targetContainerId).prev('.map-primary-panel').removeClass('primary-panel-hidden');
        }

        callback();
      };

      /**
       * Closes all panels
       * @param {function} callback Callback executed when closing panels, if not provided default callback will be executed
       */
      this.closeAll = function (callback) {
        callback = callback || onCloseCallback;
        if (removeFromDOM === true) {
          $('#' + targetContainerId + ' .map-panel-container').remove();
          angular.forEach(scopes, function (scope) {
            scope.$destroy();
          });
        } else {
          $('#' + targetContainerId + ' .map-panel-container').removeClass(activeClass);
        }
        callback();
      };
    }

    /**
     * Creates a new instance of panels manager
     *
     * @param {object} panels Panels with configuration
     * @param {string} targetContainerId ID of container where the panels will be loaded
     * @param {boolean} removeFromDOM Flag indicating whether panel should be removed from DOM when closing it
     * @param {boolena} primaryPanel Indicates primary panel - first level
     * @param {function} onCloseCallback Callback executed when closing panel
     * @returns {PanelsFactoryTemplate}
     */
    self.get = function (panels, targetContainerId, removeFromDOM, primaryPanel, secondaryPanel, onCloseCallback) {
      return new PanelsFactoryTemplate(panels, targetContainerId, removeFromDOM, primaryPanel, secondaryPanel,
        onCloseCallback, $injector, $compile, $q, $rootScope);
    };
  }

})();
