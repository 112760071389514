angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/dashboards/people.tpl.html',
    '<div class="row dashboard-container" ng-controller="PeopleDashboardCtrl as peopleDashboard" ng-click="peopleDashboard.openPanel()">\n' +
    '  <div class="small-15">\n' +
    '    <div class="row">\n' +
    '      <div class="small-15 small-centered medium-2 medium-uncentered large-2 large-uncentered columns icon-container text-center"><i\n' +
    '        class="fa fa-group"></i></div>\n' +
    '      <div class="medium-13 large-13 hide-for-small-only columns text-right">\n' +
    '        <h4 class="dashboard-header text-white">People\n' +
    '          <span\n' +
    '          us-spinner="{lines: 17, length: 0, width: 3,radius: 7, corners: 1, trail: 100, hwaccel: true, color: \'#ffffff\', left: \'-20px\'}"\n' +
    '          spinner-key="spinner-dashboard-people"></span>\n' +
    '        </h4>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row hide-for-small-only">\n' +
    '      <div class="small-15 columns dashboard-content-container">\n' +
    '        <div class="row" ng-show="peopleDashboard.isEmpty">\n' +
    '          <div class="small-5 columns text-left">\n' +
    '            <img src="/images/dashboards/people.png" />\n' +
    '          </div>\n' +
    '          <div class="small-10 columns dashboard-message text-right">\n' +
    '            {{peopleDashboard.message}}\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row" ng-show="!peopleDashboard.isEmpty">\n' +
    '          <div class="small-15 columns">\n' +
    '            <div class="row dashboard-content-row" ng-repeat="suggestion in peopleDashboard.suggestions">\n' +
    '              <div class="small-5 columns">\n' +
    '                <img ng-src="{{suggestion | profilePicture:45}}" class="circle45">\n' +
    '              </div>\n' +
    '              <div class="small-10 columns">\n' +
    '                <user-widget-name user="suggestion"></user-widget-name>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
