(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name socialIntegration.constant:socialProviders
   *
   * @description
   *
   */
  angular
    .module('socialIntegration')
    .constant('socialProviders', {
      google: {
        name: 'Google Plus',
        backendProviderIdentifier: 'googleplus'
      },
      facebook: {
        name: 'Facebook',
        backendProviderIdentifier: 'facebook'
      },
      linkedin: {
        name: 'Linkedin',
        backendProviderIdentifier: 'linkedin'
      }
    });
}());
