(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Panels.Experience.service:ExperiencePanelManager
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Experience')
    .service('ExperiencePanelManager', ExperiencePanelManager);

  function ExperiencePanelManager(PanelsFactory, $state) {
    var
      self = this,
      targetContainerId = 'map-panel-experience-actions',
      panels = {
        experienceDetail: {
          id: 'map-panel-experience-detail',
          templateUrl: 'map/panels/experience/experience-detail.tpl.html'
        },
        requestValidation: {
          id: 'map-panel-experience-request-validation',
          templateUrl: 'map/panels/experience/experience-request-validation.tpl.html'
        },
        experienceAdd: {
          id: 'map-panel-experience-add',
          templateUrl: 'map/panels/experience/experience-add.tpl.html'
        },
        experienceEdit: {
          id: 'map-panel-experience-edit',
          templateUrl: 'map/panels/experience/experience-edit.tpl.html',
          resolve: {
            projects: ['experienceDetail', 'Restangular', function (experienceDetail, Restangular) {
              return Restangular.one('Projects').getList('indexByLocation', {idLocation: experienceDetail.idLocation})
                .then(function (result) {
                  return result.plain();
                }, function () {
                  return [];
                });
            }]
          }
        },
        validationDetail: {
          id: 'map-panel-experience-validation-detail',
          templateUrl: 'map/panels/experience/experience-validation-detail.tpl.html'
        }
      };

    self.panels = PanelsFactory.get(panels, targetContainerId, true, false, true, function () {
      $state.go('map.experience');
    });
  }

})();
