(function () {
  'use strict';

  /* @ngdoc object
   * @name intro
   * @description
   *
   */
  angular
    .module('intro', [
    ]);
}());
