angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/location/location.tpl.html',
    '<div ng-controller="LocationCtrl as location" class="map-panel-controller">\n' +
    '  <div id="map-panel-location-container" class="map-panel map-primary-panel" ng-swipe-left="location.closePanel()" ng-swipe-disable-mouse>\n' +
    '    <div class="row map-panel-heading">\n' +
    '      <div class="small-2 columns">\n' +
    '        <a class="arrow-back" ng-click="location.closePanel()">\n' +
    '          <i class="fa fa-angle-double-left"></i>\n' +
    '        </a>\n' +
    '      </div>\n' +
    '      <div class="small-6 columns">\n' +
    '        <h3>Location</h3>\n' +
    '      </div>\n' +
    '      <div class="small-7 text-right columns">\n' +
    '        <button ng-show="location.projectsLoaded" class="button positive location-worked-here-button" ng-click="location.addExperience()">\n' +
    '          I WORKED HERE\n' +
    '        </button>\n' +
    '        <button ng-show="location.projectsLoaded" class="button positive location-worked-here-button" ng-click="location.placeWork()">\n' +
    '          PLACE WORK\n' +
    '        </button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="map-primary-panel-content">\n' +
    '      <div class="row" ng-repeat="project in location.projects">\n' +
    '        <div class="small-15 columns">\n' +
    '          <h3>{{project.name}}</h3>\n' +
    '          <h4 ng-if="project.name != project.owner.companyName">{{project.owner.companyName}}</h4>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="location-replies-new">\n' +
    '        <div class="row">\n' +
    '          <div class="small-15 columns text-right location-section-heading">\n' +
    '            Updates\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="small-15 columns">\n' +
    '            <form name="locationForm" role="locationForm">\n' +
    '              <div class="row">\n' +
    '                <div class="small-15 columns location-new-message-textarea-container">\n' +
    '                  <div>\n' +
    '                    <textarea id="location-new-message" name="newMessage" ng-model="location.newReply" ng-required="true"\n' +
    '                              rows="1"  placeholder="Post new update" msd-elastic=""></textarea>\n' +
    '                    <div class="location-reply-button-container">\n' +
    '                      <div class="location-upload-selected-files" ng-show="location.selectedFiles.length">\n' +
    '                      <span ng-repeat="file in location.selectedFiles">{{file.name}}\n' +
    '                        <a ng-click="location.removeFile($index)" ng-hide="location.isPosting"> <i class="fa fa-close"></i></a>\n' +
    '                        <br/>\n' +
    '                      </span>\n' +
    '                      </div>\n' +
    '                      <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-location-new"></span>\n' +
    '                    <span class="location-upload-container" ng-hide="location.isPosting">\n' +
    '                      <input type="file" id="locationPostFile" multiple ng-file-select="location.onFileSelect($files)" accept="image/*"/>\n' +
    '                      <a class="location-upload-button"><i class="fa fa-image"></i></a>\n' +
    '                    </span>\n' +
    '                      <button class="location-reply-new-button" ng-click="location.post()" ng-disabled=\'locationForm.$invalid\' ng-hide="location.isPosting">POST</button>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </form>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row update-row" ng-repeat="update in location.updates">\n' +
    '          <div class="small-5 columns map-panel-profile-photo">\n' +
    '            <img ng-src="{{update.userCreated | profilePicture:45}}" class="circle45">\n' +
    '          </div>\n' +
    '          <div class="small-10 columns">\n' +
    '          <span class="info-text">\n' +
    '            <i class="fa fa-clock-o"></i> {{update.created}} ago\n' +
    '            <span ng-show="update.relatedUpdatesCount">\n' +
    '              <i class="fa fa-reply-all"></i>  {{update.relatedUpdatesCount}}\n' +
    '            </span>\n' +
    '            <span ng-show="update.mediaCountTotal">\n' +
    '              <i class="fa fa-image"></i> {{update.mediaCountTotal}}\n' +
    '            </span>\n' +
    '          </span><br/>\n' +
    '            {{update.textContent | truncate: 50: \'...\': true}}\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row" ng-if="location.updates.length > 0 && location.hasMoreUpdates">\n' +
    '          <div class="small-15 columns text-right">\n' +
    '            <a ng-click="location.moreUpdates()">View More</a>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row" ng-if="location.updates.length === 0">\n' +
    '          <div class="small-15 columns text-right">\n' +
    '            Be the first one to post an update at this location\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '\n' +
    '  <div id="map-panel-location-actions" class="map-secondary-panel-container"></div>\n' +
    '</div>\n' +
    '');
}]);
