(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Panels.Messages.controller:MessagesThreadCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Messages')
    .controller('MessagesThreadCtrl', MessagesThreadCtrl);

  function MessagesThreadCtrl(MessagesManager, $scope) {
    var vm = this;
    vm.contactProfile = $scope.contactProfile;
    vm.myProfile = $scope.userProfile.profile;

    vm.closePanel = function () {
      MessagesManager.panels.close('messagesThread');
    };

  }

})();
