(function () {
  'use strict';

  /* @ngdoc object
   * @name LostPassword
   * @requires $stateProvider
   *
   * @description
   *
   */
  angular
    .module('LostPassword', [
      'ui.router',
      'reCAPTCHA'
    ]);

  angular
    .module('LostPassword')
    .config(config);

  function config($stateProvider, reCAPTCHAProvider, Defaults) {
    $stateProvider
      .state('lostPassword', {
        url: '/lostpassword',
        templateUrl: 'lost-password/lost-password.tpl.html',
        controller: 'LostPasswordCtrl as lostPassword',
        authenticate: false
      })
      .state('lostPasswordReset', {
        url: '/lostpassword/reset/{token}',
        templateUrl: 'lost-password/lost-password-reset.tpl.html',
        controller: 'LostPasswordResetCtrl as lostPasswordReset',
        authenticate: false
      });

    reCAPTCHAProvider.setPublicKey(Defaults.recaptchaPublicKey);
    reCAPTCHAProvider.setOptions({
      theme: 'clean'
    });
  }

})();
