(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name panels.myWork.controller:MyWorkAddCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.MyWork')
    .controller('MyWorkAddCtrl', MyWorkAddCtrl);

  function MyWorkAddCtrl(Defaults, LocationManager, $scope, $filter, usSpinnerService, WorkManager, $analytics) {
    var
      vm = this,
      jobTitleTouched = false;
    vm.jobTitleValid = false;
    vm.selectedFiles = [];
    vm.data = {
      reference: '',
      idJobTitle: 0,
      jobTitleName: null,
      description: null,
      idProject: 0,
      startDate: null,
      endDate: null,
      idProjectOwner: 0,
      projectOwnerName: ''
    };

    vm.searchProjectOwnerUrl = Defaults.baseUrl + '/Customers/index?search[query]=';
    vm.searchPositionUrl = Defaults.baseUrl + '/JobTitles/index?search[query]=';
    vm.selectProject = false;
    vm.selectProjectOther = false;
    vm.initialProjectOwnerName = '';

    if (angular.isDefined($scope.projects) && angular.isArray($scope.projects)) {
      vm.projects = $scope.projects;
      vm.selectProject = true;
      if (vm.projects.length === 1) {
        vm.data.idProject = vm.projects[0].id;
        vm.data.idProjectOwner = vm.projects[0].owner.id;
        vm.initialProjectOwnerName = vm.projects[0].owner.companyName;
        vm.data.projectOwnerName = vm.projects[0].owner.companyName;
      }
    } else {
      vm.projects = [];
    }

    if (angular.isDefined($scope.location) && angular.isObject($scope.location)) {
      if (angular.isDefined($scope.location.id)) {
        vm.data.idLocation = $scope.location.id;
      }
    }

    vm.closePanel = function () {
      LocationManager.panels.close('workAdd');
    };

    vm.onFocusJobTitle = function () {
      if (vm.data.idJobTitle !== 0) {
        vm.data.jobTitleName = '';
        vm.data.idJobTitle = 0;
        $scope.$broadcast('angucomplete-alt:clearInput', 'work-position-input');
        vm.jobTitleValid = false;
        jobTitleTouched = false;
      }
    };

    vm.onFocusOutJobTitle = function () {
      jobTitleTouched = true;
    };

    vm.onFocusProjectOwner = function () {
      if (vm.data.idProjectOwner !== 0) {
        vm.data.projectOwnerName = '';
        vm.data.idProjectOwner = 0;
        $scope.$broadcast('angucomplete-alt:clearInput', 'work-company-input');
      }
    };

    vm.onSearchResponse = function (response) {
      return response.content.records;
    };

    vm.onProjectOwnerSearchSelect = function (selected) {
      if (!angular.isDefined(selected)) {
        return;
      }
      if (angular.isObject(selected.originalObject)) {
        vm.data.idProjectOwner = selected.originalObject.id;
      }
    };

    vm.onProjectOwnerChanged = function (query) {
      vm.data.projectOwnerName = query;
    };

    vm.onPositionSearchSelect = function (selected) {
      if (!angular.isDefined(selected)) {
        return;
      }
      if (angular.isObject(selected.originalObject)) {
        vm.data.idJobTitle = selected.originalObject.id;
      }
    };

    vm.onPositionChanged = function (query) {
      vm.data.jobTitleName = query;
      jobTitleTouched = true;
      if (!vm.data.jobTitleName && !vm.data.idJobTitle) {
        vm.jobTitleValid = false;
      } else {
        vm.jobTitleValid = true;
        jobTitleTouched = true;
      }
    };

    vm.isPositionInvalid = function () {
      return (jobTitleTouched && !vm.jobTitleValid);
    };

    vm.isProjectOwnerInvalid = function () {
      var
        idProject = parseInt(vm.data.idProject),
        idProjectOwner = parseInt(vm.data.idProjectOwner);

      if (isNaN(idProject) || idProject <= 0) {
        if ((isNaN(idProjectOwner) || idProjectOwner <= 0) && vm.data.projectOwnerName.trim() === '') {
          return false;
        }
      }

      return true;
    };

    vm.save = function () {
      usSpinnerService.spin('spinner-work-add');
      vm.isSaving = true;
      var
        data = angular.copy(vm.data),
        parts;
      if (data.startDate) {
        parts = data.startDate.split('/');
        data.startDate = $filter('date')(new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0])),
          'yyyy-MM-dd');
      }

      if (data.endDate) {
        parts = data.endDate.split('/');
        data.endDate = $filter('date')(new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0])),
          'yyyy-MM-dd');
      }
      WorkManager.addWork(data, vm.selectedFiles, function () {
        usSpinnerService.stop('spinner-work-add');
        vm.closePanel();
        $analytics.eventTrack('Add', {category: 'My Work'});
      }, function (reason) {
        vm.errorMessage = reason;
        usSpinnerService.stop('spinner-work-add');
        vm.isSaving = false;
      });
    };

    vm.removeFile = function ($index) {
      vm.selectedFiles.splice($index, 1);
    };

    vm.onFileSelect = function ($files) {
      angular.forEach($files, function ($file) {
        if ($file.size < Defaults.maxFileUploadSize) {
          vm.selectedFiles.push($file);
        }
      });
    };

    (function () {
      var
        startDate,
        endDate,
        datePickerParas = {
          format: 'dd/mm/yyyy',
          weekStart: 1
        };

      startDate = $('#work-start-date').fdatepicker(datePickerParas)
        .on('changeDate', function (ev) {
          var newDate = new Date(ev.date);
          if (ev.date.valueOf() > endDate.date.valueOf()) {
            newDate.setDate(newDate.getDate() + 1);
            endDate.update(newDate);
          }
          startDate.hide();
          $('#work-end-date')[0].focus();
        })
        .data('datepicker');
      $('#work-start-date').fdatepicker('update', new Date());
      endDate = $('#work-end-date').fdatepicker(angular.extend(datePickerParas, {
        onRender: function (date) {
          return date.valueOf() <= startDate.date.valueOf() ? 'disabled' : '';
        }
      })).on('changeDate', function () {
        endDate.hide();
      }).data('datepicker');
    })();
  }

})();
