(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Components.service:InitHelper
   *
   * @description
   *
   */
  angular
    .module('Components')
    .service('InitHelper', InitHelper);

  /**
   * InitHelper service
   * @param QueueItem
   * @param $q
   * @param PanelsManager
   * @param DashboardsManager
   * @param $rootScope
   * @param $timeout
   * @constructor
   */
  function InitHelper(QueueItem, $q, PanelsManager, DashboardsManager, $rootScope, $timeout, Defaults) {
    var self = this;

    /**
     * Find and starts queue execution
     * @param {object} params {Parameters to search in
     */
    self.executeQueue = function (params) {
      if (angular.isObject(params) && angular.isDefined(params.nextSteps) && angular.isObject(params.nextSteps)) {
        if (angular.isFunction(params.nextSteps.start)) {
          params.nextSteps.start();
        }
      }
    };

    /**
     * Generates a new queue item opening a panel
     * @param {string} panel
     * @param {string} tab
     * @param {object} params
     * @returns {QueueItem}
     */
    self.getOpenPanelQueueItem = function (panel, tab, params) {
      var panelParams = {
        tab: tab,
        params: angular.copy(DashboardsManager.getParams())
      };

      if (angular.isDefined(params) && angular.isObject(params)) {
        panelParams = params;
      }

      return new QueueItem(function () {
        var deferred = $q.defer();
        PanelsManager.ready.then(function () {
          PanelsManager.panels.open(panel, panelParams);
          deferred.resolve(true);
        });

        return deferred.promise;
      });
    };

    /**
     * Generates a new queue item opening a tab
     * @param {string} panel
     * @param {string} tab
     * @returns {QueueItem}
     */
    self.getOpenPanelTabQueueItem = function (panel, tab) {
      return new QueueItem(function (panelReady) {
        var deferred = $q.defer();
        $q.when(panelReady).then(function () {
          $timeout(function () {
            $rootScope.$broadcast('event:' + panel + '-open-tab:' + tab);
            deferred.resolve(true);
          }, 100);
        });

        return deferred.promise;
      });
    };

    /**
     * Encrypt ID
     * @param {int} id
     * @returns {string}
     */
    self.encryptId = function (id) {
      return Base64.encode(CryptoJS.MD5(id.toString()) + id.toString());
    };

    /**
     * Decrypt ID
     * @param {string} encryptedId
     * @returns {Number}
     */
    self.decryptId = function (encryptedId) {
      // first try to decrypt AES (initial implementation)
      try {
        var decryptedId = parseInt(CryptoJS.AES.decrypt(encryptedId, Defaults.passPhrase).
          toString(CryptoJS.enc.Utf8), 10);

        // if not success use base64
        if (isNaN(decryptedId)) {
          return decodeIdBase64(encryptedId);
        }

        return decryptedId;
      } catch (e) {
        // if not success use base64
        return decodeIdBase64(encryptedId);
      }
    };

    /**
     * Decoded ID
     *
     * @param {string} encodedId
     * @returns {Number}
     */
    function decodeIdBase64 (encodedId) {
      return parseInt(Base64.decode(encodedId).substr(32), 10);
    }
  }
}());
