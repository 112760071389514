angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/messages/messages-thread-directive-list.tpl.html',
    '<div class="message-thread-list">\n' +
    '\n' +
    '  <div class="row" ng-repeat="message in messagesThread.getMessages()">\n' +
    '    <div class="small-3 columns message-thread-picture">\n' +
    '      <user-widget image-class="circle45" user="messagesThread.getProfile(message.idUserSender)" width="45"\n' +
    '                   height="45"></user-widget>\n' +
    '    </div>\n' +
    '    <div class="small-12 columns">\n' +
    '      <span class="info-text">\n' +
    '        <i class="fa fa-clock-o"></i> {{message.created}} ago\n' +
    '      </span>\n' +
    '      <br/>\n' +
    '      {{message.message}}\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row" ng-if="messagesThread.autoLoader === false">\n' +
    '    <div class="small-15 columns text-right">\n' +
    '      <a ng-if="messagesThread.messagesLoader.hasMore() && !messagesThread.messagesLoader.isLoading"\n' +
    '         ng-click="messagesThread.messagesLoader.load()">Load older messages ...</a>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '</div>\n' +
    '');
}]);
