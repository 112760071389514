angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/my-work/my-work-edit-form.tpl.html',
    '<div class="row">\n' +
    '\n' +
    '  <div class="small-15 columns" ng-if="!myWorkEdit.allowEdit">\n' +
    '    <div class="row">\n' +
    '      <div class="small-15 columns">\n' +
    '        <h3>{{myWorkEdit.data.jobTitleName}}, {{myWorkEdit.data.reference}}</h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row">\n' +
    '      <div class="small-15 columns">\n' +
    '        {{myWorkEdit.data.description}}\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row" ng-if="myWorkEdit.existingFiles.recordsInfo.selectedRecords > 0">\n' +
    '      <div class="small-15 columns">\n' +
    '        <h4>Files</h4>\n' +
    '\n' +
    '        <div ng-repeat="file in myWorkEdit.existingFiles.records">\n' +
    '          <file-download url="myWorkEdit.jobPositionFileUrl(file)" label="file.fileName" type="textLink"\n' +
    '                         class="file-download-link"\n' +
    '                         event-category="Edit Work" event-name="Download Work File"></file-download>\n' +
    '          <br/>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="small-15 columns" ng-if="myWorkEdit.allowEdit">\n' +
    '    <form name="editWorkForm" role="editWorkForm">\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <input type="text" name="reference" ng-model="myWorkEdit.data.reference" ng-required="true"\n' +
    '                 placeholder="REFERENCE"/>\n' +
    '        </div>\n' +
    '        <div class="small-15 columns">\n' +
    '          <small class="error" ng-show="editWorkForm.reference.$dirty && editWorkForm.reference.$error.required">\n' +
    '            Missing reference\n' +
    '          </small>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <angucomplete-alt\n' +
    '            id="work-position-input"\n' +
    '            placeholder="SELECT POSITION OR ADD NEW"\n' +
    '            pause="100"\n' +
    '            minlength="2"\n' +
    '            remote-url="{{myWorkEdit.searchPositionUrl}}"\n' +
    '            title-field="name"\n' +
    '            remote-url-response-formatter="myWorkEdit.onSearchResponse"\n' +
    '            text-searching="SEARCHING ..."\n' +
    '            selected-object="myWorkEdit.onPositionSearchSelect"\n' +
    '            match-class="text-orange"\n' +
    '            text-no-results=" "\n' +
    '            override-suggestions="true"\n' +
    '            input-changed="myWorkEdit.onPositionChanged"\n' +
    '            initial-value="{{myWorkEdit.data.jobTitleName}}"\n' +
    '            focus-in="myWorkEdit.onFocusJobTitle()"\n' +
    '            focus-out="myWorkEdit.onFocusOutJobTitle()"\n' +
    '            />\n' +
    '        </div>\n' +
    '        <div class="small-15 columns">\n' +
    '          <small class="error" ng-show="myWorkEdit.isPositionInvalid()">Missing position</small>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-8 columns">\n' +
    '          <input type="text" name="startDate" ng-model="myWorkEdit.data.startDate" id="work-start-date"\n' +
    '                 placeholder="START DATE" title="START DATE"/>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="small-7 columns">\n' +
    '          <input type="text" name="endDate" ng-model="myWorkEdit.data.endDate" id="work-end-date"\n' +
    '                 placeholder="END DATE" title="END DATE"/>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '              <textarea name="description" ng-model="myWorkEdit.data.description" msd-elastic\n' +
    '                        placeholder="DESCRIPTION OF WORK"></textarea>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <h4>Files</h4>\n' +
    '\n' +
    '          <div ng-repeat="file in\n' +
    '        myWorkEdit.existingFiles.records">\n' +
    '                <span ng-class="{strikethrough: myWorkEdit.isPositionFileDeleted($index)}">\n' +
    '                <file-download url="myWorkEdit.jobPositionFileUrl(file)" label="file.fileName" type="textLink"\n' +
    '                               class="file-download-link"\n' +
    '                               event-category="Edit Work" event-name="Download Work File"></file-download></span>\n' +
    '            <a ng-click="myWorkEdit.removeExistingFile($index)" ng-if="!myWorkEdit.isPositionFileDeleted($index)"\n' +
    '               title="Click to here if you want to delete the file" ng-hide="myWorkEdit.isSaving"> <i\n' +
    '              class="fa fa-close"></i></a>\n' +
    '            <a ng-click="myWorkEdit.resetExistingFile($index)" ng-if="myWorkEdit.isPositionFileDeleted($index)"\n' +
    '               title="Click here if you want to keep the file" ng-hide="myWorkEdit.isSaving"> <i\n' +
    '              class="fa fa-undo"></i></a>\n' +
    '            <br/>\n' +
    '          </div>\n' +
    '          <div>\n' +
    '                <span ng-repeat="file in myWorkEdit.selectedFiles">{{file.name}}\n' +
    '                  <a ng-click="myWorkEdit.removeFile($index)" ng-hide="myWorkEdit.isSaving"\n' +
    '                     title="Delete the file"> <i\n' +
    '                    class="fa fa-close"></i></a>\n' +
    '                  <br/>\n' +
    '                </span>\n' +
    '          </div>\n' +
    '          <div class="custom-file-upload-button" ng-file-select="myWorkEdit.onFileSelect($files)"\n' +
    '               ng-multiple="true" ng-hide="myWorkEdit.isSaving">\n' +
    '            <i class="fa fa-paperclip"></i> Attach Files\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <small class="error" ng-show="myWorkEdit.errorMessage"\n' +
    '                 ng-bind-html="myWorkEdit.errorMessage"></small>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns text-right">\n' +
    '                <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}"\n' +
    '                      spinner-key="spinner-work-edit"></span>\n' +
    '          <button class="button important" ng-hide="myWorkEdit.isSaving" ng-click="myWorkEdit.remove()">Remove\n' +
    '          </button>\n' +
    '          <button class="button positive" ng-hide="myWorkEdit.isSaving"\n' +
    '                  ng-disabled="editWorkForm.$invalid || !myWorkEdit.jobTitleValid"\n' +
    '                  ng-click="myWorkEdit.save()">\n' +
    '            SAVE\n' +
    '          </button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
