(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name intro.service:IntroManager
   *
   * @description
   *
   */
  angular
    .module('intro')
    .service('IntroManager', IntroManager);

  function IntroManager($modal) {
    var self = this;

    self.openPopup = function () {
      $modal.open({
        templateUrl: 'intro/intro.tpl.html',
        windowClass: 'dialog-container intro-dialog',
        controller: 'IntroDialogCtrl as introDialog'
      });
    };
  }
}());
