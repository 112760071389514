angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/dashboards/dashboards.tpl.html',
    '<div id="dashboards-container" ng-controller="DashboardsCtrl as dashboards" >\n' +
    '  <div class="large-3 medium-3 small-3 columns dashboard" ng-include="\'map/dashboards/updates.tpl.html\'"></div>\n' +
    '  <div class="large-3 medium-3 small-3 columns dashboard" ng-include="\'map/dashboards/people.tpl.html\'"></div>\n' +
    '  <div class="large-3 medium-3 small-3 columns dashboard" ng-include="\'map/dashboards/experience.tpl.html\'"></div>\n' +
    '  <div class="large-3 medium-3 small-3 columns dashboard" ng-include="\'map/dashboards/work.tpl.html\'"></div>\n' +
    '  <div class="large-3 medium-3 small-3 columns dashboard mineler-dashboard" ng-include="\'map/dashboards/mineler.tpl.html\'"></div>\n' +
    '</div>\n' +
    '');
}]);
