angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('feedback/feedback.tpl.html',
    '<div class="row ">\n' +
    '  <div class="small-15">\n' +
    '    <div class="row">\n' +
    '      <div class="small-6 small-push-9 columns text-right">\n' +
    '        <img src="images/mineler.png" class="home-page-logo"/>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <form name="feedbackForm" role="feedbackForm" novalidate ng-if="!feedback.isSent">\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <h4>Contact Mineler</h4>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          Your thoughts and suggestions are important to us. We would love to hear from you on how to make Mineler\n' +
    '        better. Please use the form below to get in touch with us.\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row" ng-if="feedback.isEditable">\n' +
    '        <div class="small-15 columns">\n' +
    '            <input type="text" ng-required="feedback.isEditable" ng-model="feedback.data.name" name="name" placeholder="YOUR NAME"/>\n' +
    '          <small class="error" ng-show="feedbackForm.name.$dirty && feedbackForm.name.$error.required">Missing name\n' +
    '          </small>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row" ng-if="feedback.isEditable">\n' +
    '        <div class="small-15 columns">\n' +
    '            <input type="email" ng-required="feedback.isEditable" ng-model="feedback.data.email" name="email" placeholder="EMAIL"/>\n' +
    '          <small class="error" ng-show="feedbackForm.email.$dirty && feedbackForm.email.$error.required">Missing email\n' +
    '          </small>\n' +
    '          <small class="error" ng-show="feedbackForm.email.$dirty && feedbackForm.email.$error.email">This is not a\n' +
    '            valid email\n' +
    '          </small>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <input type="text" ng-required="true" ng-model="feedback.data.subject" name="subject" placeholder="SUBJECT"/>\n' +
    '          <small class="error" ng-show="feedbackForm.subject.$dirty && feedbackForm.subject.$error.required">Missing\n' +
    '            subject\n' +
    '          </small>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <textarea name="message" ng-required="true" ng-model="feedback.data.message" placeholder="MESSAGE" msd-elastic></textarea>\n' +
    '          <small class="error" ng-show="feedbackForm.message.$dirty && feedbackForm.message.$error.required">Missing\n' +
    '            message\n' +
    '          </small>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <div class="custom-file-upload-button" ng-file-select="feedback.onFileSelect($files)" ng-multiple="true"><i class="fa fa-paperclip"></i> Attachments (Optional)</div>\n' +
    '          <div class="custom-file-upload-selected-files" ng-show="feedback.selectedFiles.length">\n' +
    '            <span ng-repeat="file in feedback.selectedFiles">{{file.name}}\n' +
    '              <a ng-click="feedback.removeFile($index)" ng-hide="feedback.isSending"> <i\n' +
    '                class="fa fa-close"></i></a>\n' +
    '              <br/>\n' +
    '            </span>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <small class="error" ng-show="feedback.errorMessage">{{feedback.errorMessage}}</small>\n' +
    '        </div>\n' +
    '        <div class="small-10 columns text-right buttons-container">\n' +
    '          <span us-spinner="{radius:5, width:5, length: 13, color: \'#ffffff\'}"\n' +
    '                spinner-key="spinner-feedback-submit"></span>\n' +
    '          <button ng-hide="feedback.isSending" class="button" ng-click="feedback.close()">Cancel</button>\n' +
    '          <button ng-hide="feedback.isSending" class="button positive" ng-click="feedback.submit()" ng-disabled=\'feedbackForm.$invalid\'> CONTINUE\n' +
    '          </button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </form>\n' +
    '\n' +
    '    <div class="row" ng-if="feedback.isSent">\n' +
    '      <div class="small-6 medium-3 medium-offset-3 columns message-icon">\n' +
    '        <i class="fa fa-check-circle"></i>\n' +
    '      </div>\n' +
    '      <div class="small-9 medium-9 end columns message-text text-left">\n' +
    '        <h3>Thank you for your message!</h3>\n' +
    '        All done.\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row" ng-if="feedback.isSent">\n' +
    '      <div class="small-15 column text-right">\n' +
    '        <button class="button positive" ng-click="feedback.close()">Close</button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '');
}]);
