angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/messages/messages-thread-directive-textarea.tpl.html',
    '<div class="message-thread-new-message">\n' +
    '  <div class="row">\n' +
    '    <div class="small-3 columns message-thread-picture">\n' +
    '      <img class="circle45" ng-src="{{messagesThread.myProfile | profilePicture:45}}"/>\n' +
    '    </div>\n' +
    '    <div class="small-12 columns">\n' +
    '      <form name="threadMessageForm" role="threadMessageForm">\n' +
    '        <div class="row">\n' +
    '          <div class="small-15 columns">\n' +
    '            <textarea name="newMessage" ng-model="messagesThread.newMessage" ng-required="true" rows="5"></textarea>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="small-10 columns">\n' +
    '            <small class="profile-edit-error" ng-show="messagesThread.errorMessage">{{messagesThread.errorMessage}}</small>\n' +
    '          </div>\n' +
    '          <div class="small-5 columns message-thread-button-container">\n' +
    '              <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}"\n' +
    '                    spinner-key="spinner-messages-new"></span>\n' +
    '            <button ng-hide="messagesThread.isSending" class="button positive" ng-click="messagesThread.send()"\n' +
    '                    ng-disabled=\'threadMessageForm.$invalid\'>SEND\n' +
    '            </button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
