(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Feedback.service:FeedbackManager
   *
   * @description
   *
   */
  angular
    .module('Feedback')
    .service('FeedbackManager', FeedbackManager);

  function FeedbackManager($modal, Session, $upload, Restangular, Defaults, $analytics) {
    var self = this;

    self.init = function () {
      $modal.open({
        templateUrl: 'feedback/feedback.tpl.html',
        controller: 'FeedbackCtrl as feedback',
        resolve: {
          userProfile: function (Auth) {
            return Auth.getSession();
          }
        },
        windowClass: 'dialog-container feedback-dialog'
      });
    };

    self.getAppData = function () {
      return JSON.stringify({
        user: Session.profile
      });
    };

    self.submit = function (data, files, successCallback, errorCallback) {
      $analytics.eventTrack('Send', {category: 'Feedback'});
      data = angular.extend(data, {
        data: self.getAppData()
      });
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;

      if (angular.isArray(files) && files.length > 0) {
        $upload.upload({
          url: Defaults.baseUrl + '/Feedback/index',
          data: data,
          file: files,
          fileFormDataName: 'attachment[]'
        })
          .success(function () {
            successCallback();
          })
          .error(function (result) {
            errorCallback(result.errorMessage);
          });
      } else {
        Restangular.one('Feedback').customPOST($.param(data), 'index', {}, {
          'Content-Type': 'application/x-www-form-urlencoded'
        }).then(function () {
          successCallback();
        }, function (result) {
          var reason = result.data.errorMessage;
          errorCallback(reason);
        });
      }
    };
  }

})();
