(function () {
  'use strict';

  /* @ngdoc object
   * @name Mineler
   * @requires $urlRouterProvider
   *
   * @description
   *
   */
  angular
    .module('Mineler', [
      'ui.router',
      'restangular',
      'angulartics',
      'angulartics.google.analytics',
      'http-auth-interceptor',
      'mm.foundation',
      'ngSanitize',
      'home',
      'Cache',
      'Configuration',
      'Security',
      'Map',
      'Filters',
      'iso-3166-country-codes',
      'angular.filter',
      'angularSpinner',
      'Components',
      'Feedback',
      'Network',
      'monospaced.elastic',
      'Experience',
      'Registration',
      'LostPassword',
      'ngRaven',
      'ngTouch',
      'Work',
      'Queue',
      'Help',
      'widgets',
      'socialIntegration',
      'directives',
      'intro'
    ]);

  angular
    .module('Mineler')
    .config(config)
    .run(run);

  function config($locationProvider, $urlRouterProvider, RestangularProvider, Defaults, msdElasticConfig,
                  $analyticsProvider, RavenProvider, IS_AUTHENTICATED) {
    $locationProvider.html5Mode(true);
    RavenProvider.development(false);

    RestangularProvider.setBaseUrl(Defaults.baseUrl);
    RestangularProvider.addResponseInterceptor(function (data, operation) {
      var extractedData;
      if (operation === 'getList') {
        extractedData = data.content.records;
        extractedData.meta = data.content.recordsInfo;
      } else {
        extractedData = angular.extend({}, data.content);
      }
      extractedData.contentEtag = data.contentEtag;
      extractedData.responseStatus = {
        code: data.content.statusCode,
        text: data.content.statusText
      };

      return extractedData;
    });
    RestangularProvider.setDefaultHeaders({Accept: 'application/json'});

    $analyticsProvider.firstPageview(true);
    $analyticsProvider.withAutoBase(true);

    if (IS_AUTHENTICATED) {
      $urlRouterProvider.otherwise('/app');
    } else {
      $urlRouterProvider.otherwise('/signin');
    }

    msdElasticConfig.append = '\n';
  }

  function run($rootScope, $state, Auth, QueueManager, IntroManager, $timeout) {
    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
      if (angular.isDefined(fromParams.logout) && fromParams.logout === true) {
        return;
      } else {
        Auth.getSession().then(function () {
          if (!Auth.isAuthenticated()) {
            if (toState.authenticate) {
              QueueManager.reset();
              $state.go('signin', {
                nextState: {
                  name: toState.name,
                  params: toParams
                }
              });
              event.preventDefault();
            }
          } else {
            if (toState.name === 'signin' || toState.name === 'signup') {
              $state.go('map');
              event.preventDefault();
            }
          }
        }, function () {
          if (toState.authenticate) {
            $state.transitionTo('signin', {}, {
              notify: false
            });
            event.preventDefault();
          }
        });
      }
    });

    /*
     * Bind sign in page to event
     */
    $rootScope.$on('event:auth-loginRequired', function () {
      Auth.logout(false);
      $state.go('signin');
    });

    $rootScope.$on('event:user-profileMissingData', function () {
      Auth.initMissingDataDialog();
    });

    $rootScope.$on('event:open-intro', function () {
      $timeout(function () {
        IntroManager.openPopup();
      }, 1000);
    });
  }

  /**
   * General callback called after successful operation
   * @callback successCallback
   * @param {misc} result - result of operation
   */

  /**
   * General callback called after failed operation
   * @callback errorCallback
   * @param {string} reason - Reason of failure
   */

})();
