angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/profile/subscription-settings.tpl.html',
    '<div id="map-panel-profile-edit" class="map-secondary-panel"\n' +
    '     ng-controller="SubscriptionSettingsCtrl as subscriptionSettings" ng-swipe-left="subscriptionSettings.closeEditPanel()" ng-swipe-disable-mouse>\n' +
    '  <div class="row map-panel-heading">\n' +
    '    <div class="small-2 columns">\n' +
    '      <a class="arrow-back" ng-click="subscriptionSettings.closeEditPanel()">\n' +
    '        <i class="fa fa-angle-double-left"></i>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '    <div class="small-13 columns">\n' +
    '      <h3>Notifications</h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row profile-edit profile-edit-about">\n' +
    '    <div class="small-15 columns">\n' +
    '      <form name="editSubscriptionSettingsForm" role="editSubscriptionSettingsForm">\n' +
    '        <div class="row">\n' +
    '          <div class="small-15 columns">\n' +
    '            <label>Do you want to receive weekly email updates?</label>\n' +
    '            <input type="radio" name="mailUpdate" id="mailUpdateYes" ng-model="subscriptionSettings.subscriptions.mailUpdate" ng-value="true" ng-required="true" />\n' +
    '            <label for="mailUpdateYes">Yes</label>\n' +
    '            <input type="radio" name="mailUpdate" id="mailUpdateNo" ng-model="subscriptionSettings.subscriptions.mailUpdate" ng-value="false" ng-required="true" />\n' +
    '            <label for="mailUpdateNo">No</label>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row">\n' +
    '          <div class="small-8 small-offset-2 columns">\n' +
    '            <small class="profile-edit-error" ng-show="subscriptionSettings.editProfileErrorMessage">{{subscriptionSettings.editProfileErrorMessage}}</small>\n' +
    '          </div>\n' +
    '          <div class="small-5 columns profile-edit-button-container">\n' +
    '            <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-profile-edit"></span>\n' +
    '            <button ng-hide="subscriptionSettings.isSaving" class="right button positive" ng-click="subscriptionSettings.save()" ng-disabled=\'editSubscriptionSettingsForm.$invalid\'>Save</button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
