(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Experience.controller:ExperienceCtrl
   *
   * @description
   *
   */
  angular
    .module('Experience')
    .controller('ExperienceValidationCtrl', ExperienceValidationCtrl);

  function ExperienceValidationCtrl(experienceDetail, ExperienceManager, usSpinnerService, $state, $analytics) {
    var
      vm = this,
      nextStep = function (result) {
        if (!angular.isDefined(result) || !angular.isObject(result)) {
          result = {};
        }

        if (angular.isDefined(result.nextStep) && angular.isObject(result.nextStep)) {
          if (angular.isDefined(result.nextStep.userRegistration)) {
            $state.go('verify', {
              token: result.nextStep.userRegistration
            });
          } else {
            $state.go('signin');
          }
        } else {
          $state.go('signin');
        }
      };

    vm.validationMessage = null;
    vm.validToken = true;
    vm.error301 = false;
    vm.error303 = false;
    vm.error304 = false;

    if (angular.isDefined(experienceDetail.errorCode)) {
      vm.validToken = false;
      switch (experienceDetail.errorCode) {
        case 301:
          vm.error301 = true;
          break;
        case 303:
          vm.error303 = true;
          break;
        case 304:
          vm.error304 = true;
          break;
        default:
          vm.error301 = true;
          break;

      }
    }

    vm.detail = experienceDetail;
    vm.isLoading = false;

    vm.getSkillList = function () {
      return vm.detail.skills
        .map(function (skill) {
          return skill.name;
        })
        .join(', ');
    };

    vm.continue = function () {
      nextStep();
    };

    vm.validate = function () {
      vm.errorMessage = null;
      vm.isLoading = true;
      usSpinnerService.spin('spinner-experience-validation');
      ExperienceManager.validate(experienceDetail.reqParams.token, vm.validationMessage, function (result) {
        vm.validToken = false;
        vm.error304 = true;
        vm.continue = function () {
          nextStep(result);
        };
        $analytics.eventTrack('Validate', {category: 'Experience'});
      }, function (error) {
        vm.errorMessage = error;
        usSpinnerService.stop('spinner-experience-validation');
        vm.isLoading = false;
      });
    };

    vm.ignore = function () {
      vm.errrorMessage = null;
      vm.isLoading = true;
      usSpinnerService.spin('spinner-experience-validation');
      ExperienceManager.ignore(experienceDetail.reqParams.token, vm.validationMessage, function (result) {
        vm.validToken = false;
        vm.error304 = true;
        vm.continue = function () {
          nextStep(result);
        };
        $analytics.eventTrack('Ignore Validation', {category: 'Experience'});
      }, function (error) {
        vm.errorMessage = error;
        usSpinnerService.stop('spinner-experience-validation');
        vm.isLoading = false;
      });
    };

  }

})();
