(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Map.Toolbars.service:FavouriteUsers
   *
   * @description
   *
   */
  angular
    .module('Map.Toolbars.FavouriteUsers')
    .service('FavouriteUsers', FavouriteUsers);

  function FavouriteUsers(Cache, Restangular, $rootScope, MapComponent, $analytics) {
    var self = this;
    self.cache = Cache.create('favouriteUsers');
    self.list = [];
    self.usersIds = [];

    self.isFavourite = function (idUser) {
      idUser = parseInt(idUser);
      return (-1 < self.usersIds.indexOf(idUser));
    };

    self.isVisible = MapComponent.isUserVisible;

    self.toggle = function (idUser) {
      idUser = parseInt(idUser);
      var $index = self.usersIds.indexOf(idUser);
      if (-1 === $index) {
        return self.addUser(idUser);
      } else {
        return self.removeUser($index);
      }
    };

    self.toggleVisibility = MapComponent.toggleVisibility;

    self.removeUserReference = function ($index, id) {
      self.list.splice($index, 1);
      self.usersIds.splice($index, 1);
      self.saveToCache();
      $rootScope.$broadcast('event:favouriteUsers-removed', {index: $index, id: id});
      $analytics.eventTrack('Remove', {
        category: 'Favourite Users', label: 'Favourite user removed', value: self.usersIds.length
      });
    };

    self.removeUser = function ($index) {
      var id = self.list[$index].id;
      return Restangular.one('FavouriteUsers').one('index').remove({id: id}).then(function () {
        self.removeUserReference($index, id);
      });
    };

    self.addUser = function (idUser) {
      idUser = parseInt(idUser);
      return Restangular.one('FavouriteUsers').post('index', {
        id: idUser
      }).then(function (result) {
        self.list.unshift(result.user);
        self.usersIds.unshift(idUser);
        self.saveToCache();
        $rootScope.$broadcast('event:favouriteUsers-added', result.user);
        $analytics.eventTrack('Add', {
          category: 'Favourite Users', label: 'Favourite user added', value: self.usersIds.length
        });
      });
    };

    self.loadFromCache = function () {
      self.list = self.cache.get('list');
      self.usersIds = self.cache.get('usersIds');
      var list = [], usersIds = [];
      Restangular.one('FavouriteUsers').all('index').getList({limit: 1000}).then(function (users) {
        for (var i = 0, max = users.meta.selectedRecords; i < max; i++) {
          list.push(users[i].user);
          usersIds.push(users[i].user.id);
        }
        self.list = list;
        self.usersIds = usersIds;
        self.saveToCache();
        $rootScope.$broadcast('event:favouriteUsers-loaded', usersIds);
      });
    };

    self.saveToCache = function () {
      self.cache.put('list', self.list);
      self.cache.put('usersIds', self.usersIds);
    };

    $rootScope.$on('event:networkContact-removed', function ($event, user) {
      var $index = _.findIndex(self.list, {id: user.id});
      if ($index !== -1) {
        self.removeUserReference($index, user.id);
      }
    });

    $rootScope.$on('event:auth-logoutSuccess', function () {
      self.cache.removeAll();
    });
  }

})();
