(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name socialShare.provider:SocialShare
   *
   * @description SocialShare provider, generic functionality to share content via social service
   *
   */
  angular
    .module('socialShare')
    .provider('SocialShare', SocialShare);

  function SocialShare() {
    var
      /**
       * List of supported services, key is a identifier of a service, value is a name of its provider
       * @type {{facebook: string, linkedin: string, twitter: string, google: string}}
       */
      socialServices = {
        facebook: 'FacebookSocialShare',
        linkedin: 'LinkedinSocialShare',
        twitter: 'TwitterSocialShare',
        google: 'GooglePlusSocialShare'
      },
      config = {};

    /**
     * Get instance name for social service
     * @param {string} name Social service identifier
     * @returns {string}
     */
    function getInstanceNameForSocialService(name) {
      if (angular.isDefined(socialServices[name])) {
        return socialServices[name];
      }

      throw 'Unknown provider';
    }

    return {
      /**
       * Set configuration
       * @param {string} socialService Social service identifier
       * @param {string} paramName Name of configuration option
       * @param {*} value Value of configuration option
       */
      set: function (socialService, paramName, value) {
        if (!angular.isDefined(config[socialService])) {
          config[socialService] = {};
        }

        config[socialService][paramName] = value;
      },
      $get: ['$q', '$rootScope', '$injector',
        function ($q, $rootScope, $injector) {
          var $socialShare = $q.defer();

          /**
           * Initialisation - iterates over all supported providers and initialises them
           */
          $socialShare.init = function () {
            var promises = [];
            angular.forEach(socialServices, function (instanceName, name) {
              var instance = $injector.get(instanceName);
              promises.push(instanceName.promise);
              instance.init(config[name] || {});
            });

            $q.all(promises).then(function () {
              $rootScope.$broadcast('socialShare-init');
            });
          };

          $rootScope.$on('socialShare-init', function () {
            $socialShare.resolve();
          });

          /**
           * Share a content
           * @param {string} socialService Social service identifier
           * @param {object} data Data to share
           */
          $socialShare.share = function (socialService, data) {
            $injector.get(getInstanceNameForSocialService(socialService)).share(data);
          };

          return $socialShare;
        }]
    };
  }
}());
