angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('security/user/missing-data.tpl.html',
    '<div class="row">\n' +
    '  <div class="small-15 columns">\n' +
    '\n' +
    '    <div class="row">\n' +
    '      <div class="small-15 columns">\n' +
    '\n' +
    '        <form name="missingDataForm" role="missingDataForm" novalidate>\n' +
    '          <div class="row" ng-if="missingData.showCountry">\n' +
    '            <div class="small-15 columns text-center">\n' +
    '              <h2>Select your home country for a personalised experience</h2>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <small class="error" ng-show="missingData.errorMessage">{{missingData.errorMessage}}</small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row" ng-if="missingData.showCountry">\n' +
    '            <div class="small-15 small-centered columns">\n' +
    '              <select ng-model="missingData.countryCode" name="countryCode" ng-required="true">\n' +
    '                <option ng-repeat="country in missingData.countries" ng-value="country.code">{{country.name | lowercase | ucfirst}}</option>\n' +
    '              </select>\n' +
    '              <small class="error"\n' +
    '                     ng-show="missingDataForm.countryCode.$dirty && missingDataForm.countryCode.$error.required">Missing\n' +
    '                country\n' +
    '              </small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row" ng-if="missingData.showCompanyName">\n' +
    '            <div class="small-15 columns text-center">\n' +
    '              <h2>Set your company name</h2>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row" ng-if="missingData.showCompanyName">\n' +
    '            <div class="small-15 small-centered columns">\n' +
    '              <input type="text" ng-model="missingData.companyName" name="companyName" ng-required="true" />\n' +
    '              <small class="error"\n' +
    '                     ng-show="missingDataForm.companyName.$dirty && missingDataForm.companyName.$error.required">Missing company name</small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row" ng-show="missingDataForm.$valid">\n' +
    '            <div class="small-11 small-centered columns text-center missing-data-buttons-container">\n' +
    '              <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-missing-data"></span>\n' +
    '              <button ng-hide="missingData.isLoading" class="button positive" ng-click="missingData.save()" ng-disabled=\'missingDataForm.$invalid\'>CONTINUE</button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </form>\n' +
    '\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
