(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Registration.controller:AccountVerificationCtrl
   *
   * @description
   *
   */
  angular
    .module('Registration')
    .controller('AccountVerificationCtrl', AccountVerificationCtrl);

  function AccountVerificationCtrl($stateParams, Defaults, Restangular, usSpinnerService, Auth, $state, userDetail) {
    var vm = this;
    vm.data = {};
    vm.passwordPattern = new RegExp(Defaults.passwordPattern);
    vm.passwordMessage = Defaults.passwordMessage;
    vm.isVerifying = false;
    vm.isVerified = false;
    vm.validToken = true;
    vm.error102 = false;
    vm.error103 = false;

    if (angular.isDefined(userDetail.errorCode)) {
      vm.validToken = false;
      switch (userDetail.errorCode) {
        case 102:
          vm.error102 = true;
          break;
        case 103:
          vm.error103 = true;
          break;
        default:
          vm.error102 = true;
          break;
      }
    }

    vm.detail = userDetail;

    vm.verify = function () {
      vm.errorMessage = null;
      vm.isVerifying = true;
      usSpinnerService.spin('spinner-email-verify');
      Restangular.one('Users').customPOST(vm.data, 'verify', {
        token: $stateParams.token
      }).then(function (result) {
        Auth.loginByData(result);
        $state.go('map');
      }, function (result) {
        usSpinnerService.stop('spinner-email-verify');
        vm.errorMessage = result.data.errorMessage;
        vm.isVerifying = false;
      });
    };

    vm.continue = function () {
      $state.go('signin');
    };
  }

})();
