(function () {
  'use strict';

  /* @ngdoc object
   * @name Map.Toolbars.FavouriteUsers
   * @requires $stateProvider
   *
   * @description
   *
   */
  angular
    .module('Map.Toolbars.FavouriteUsers', [
      'Map.Panels'
    ]);

  angular
    .module('Map.Toolbars.FavouriteUsers')
    .config(config);

  function config() {
  }

})();
