angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/work/work.tpl.html',
    '<div ng-controller="WorkCtrl as work" class="map-panel-controller">\n' +
    '  <div id="map-panel-work-container" class="map-panel map-primary-panel" ng-swipe-left="work.closePanel()" ng-swipe-disable-mouse>\n' +
    '    <div class="row map-panel-heading">\n' +
    '      <div class="small-2 columns">\n' +
    '        <a class="arrow-back" ng-click="work.closePanel()">\n' +
    '          <i class="fa fa-angle-double-left"></i>\n' +
    '        </a>\n' +
    '      </div>\n' +
    '      <div class="small-13 columns">\n' +
    '        <ul>\n' +
    '          <li class="cursor-pointer" ng-class="{\'panel-heading-active\': work.tab===\'work\'}"\n' +
    '              ><a ui-sref="map.work">Work</a>\n' +
    '          </li>\n' +
    '          <li class="cursor-pointer" ng-class="{\'panel-heading-active\': work.tab===\'myBids\'}"\n' +
    '              ><a ui-sref="map.workMyBids">My Bids</a>\n' +
    '          </li>\n' +
    '          <li class="cursor-pointer" ng-class="{\'panel-heading-active\': work.tab===\'placedWork\'}"\n' +
    '              ><a ui-sref="map.workPlacedWork">Placed Work</a>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="map-primary-panel-content" ng-show="work.tab === \'work\'" ng-include="\'map/panels/work/work-tab-work.tpl.html\'">\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="map-primary-panel-content" ng-show="work.tab === \'myBids\'" ng-include="\'map/panels/my-work/my-work-tab-my-bids.tpl.html\'">\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="map-primary-panel-content" ng-show="work.tab === \'placedWork\'" ng-include="\'map/panels/my-work/my-work-tab-placed-work.tpl.html\'">\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '\n' +
    '  <div id="map-panel-work-actions" class="map-secondary-panel-container"></div>\n' +
    '</div>\n' +
    '\n' +
    '');
}]);
