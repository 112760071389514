angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/map.tpl.html',
    '<ui-gmap-google-map center="gmap.center" zoom="gmap.zoom" options="gmap.options" control="gmap.control">\n' +
    '\n' +
    '  <ui-gmap-map-control position="top-center" template="map/toolbars/toolbars.tpl.html"\n' +
    '                       index="1"></ui-gmap-map-control>\n' +
    '\n' +
    '  <ui-gmap-map-control position="right-bottom" template="map/toolbars/buttons.tpl.html"\n' +
    '                       index="2"></ui-gmap-map-control>\n' +
    '\n' +
    '  <ui-gmap-map-control position="bottom-center" template="map/dashboards/dashboards.tpl.html"\n' +
    '                       index="3"></ui-gmap-map-control>\n' +
    '\n' +
    '  <ui-gmap-map-control position="left-bottom" template="map/panels/panels.tpl.html"\n' +
    '                       index="4"></ui-gmap-map-control>\n' +
    '</ui-gmap-google-map>\n' +
    '');
}]);
