(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name help.service:HelpManager
   *
   * @description
   *
   */
  angular
    .module('Help')
    .service('HelpManager', HelpManager);

  function HelpManager($modal) {
    var self = this;

    self.open = function get() {
      $modal.open({
        templateUrl: 'help/help.tpl.html',
        windowClass: 'help-dialog'
      });
    };
  }

})();
