(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Panels.Updates.controller:UpdateRepliesCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Updates')
    .controller('UpdateRepliesCtrl', UpdateRepliesCtrl);

  function UpdateRepliesCtrl(UpdatesManager, $scope, Restangular, $timeout, usSpinnerService, $q, Defaults,
                             $analytics) {
    var
      totalLoaded = 0,
      offset = 0,
      listElement = $('#map-panel-update-replies > .update-replies-list'),
      loadingEnabled = false;

    $scope.errorMessage = null;
    $scope.replies = [];
    $scope.isLoading = false;
    $scope.selectedFiles = [];
    $scope.isPosting = false;

    function scrollToBottom() {
      $timeout(function () {
        listElement.scrollTop(listElement.height());
      }, 1000);
    }

    function loadReplies(offsetIncrement, scroll) {
      if (scroll !== false) {
        scroll = true;
      }

      if (!angular.isNumber(offsetIncrement)) {
        offsetIncrement = 10;
      }

      offset += offsetIncrement;
      $scope.isLoading = true;
      Restangular.one('Updates').getList('index', {
        'entityFilter[idUpdate]': $scope.parentUpdate.id,
        limit: 10,
        offset: offset,
        orderby: 'idNote ASC'
      }).then(function (result) {
        angular.forEach(result, function (value) {
          $scope.replies.push(value);
        });

        totalLoaded += result.meta.selectedRecords;
        loadingEnabled = (result.meta.totalRecords > totalLoaded);

        if (scroll) {
          scrollToBottom();
        }
        $scope.isLoading = false;
      });
    }

    function editUpdate(id, textContent) {
      var deferred = $q.defer();
      UpdatesManager.editUpdate(id, textContent, function () {
        deferred.resolve(true);
      }, function () {
        deferred.resolve(false);
      });

      return $q.when(deferred.promise).then(function (editResult) {
        return editResult;
      });
    }

    loadReplies(0, true);

    $scope.onFileSelect = function ($files) {
      angular.forEach($files, function ($file) {
        if ($file.size < Defaults.maxFileUploadSize) {
          $scope.selectedFiles.push($file);
        }
      });
    };

    $scope.removeFile = function ($index) {
      $scope.selectedFiles.splice($index, 1);
    };

    $scope.closePanel = function () {
      $scope.panelsManager.close('updateReplies');
    };

    $scope.editAllowed = function (update) {
      return ($scope.userProfile.idUser === update.userCreated.id);
    };

    $scope.post = function () {
      $scope.isPosting = true;
      $scope.errorMessage = null;
      usSpinnerService.spin('spinner-reply-new');

      UpdatesManager.postUpdate({
        idUpdate: $scope.parentUpdate.id,
        text: $scope.newReply
      }, $scope.selectedFiles, function (newUpdate) {
        $scope.replies.push(newUpdate);
        $scope.reset();
        $scope.isPosting = false;
        usSpinnerService.stop('spinner-reply-new');
        $analytics.eventTrack('Post a Reply', {category: 'Updates'});
      }, function () {
        $scope.isPosting = false;
        $scope.errorMessage = 'An error occurred';
        usSpinnerService.stop('spinner-reply-new');
      });
    };

    $scope.reset = function () {
      $scope.newReply = null;
      $scope.selectedFiles = [];
    };

    $scope.editUpdate = function ($data) {
      $analytics.eventTrack('Edit an Update', {category: 'Updates'});
      return editUpdate($scope.parentUpdate.id, $data.textContent);
    };

    $scope.editReply = function ($index, $data) {
      $analytics.eventTrack('Edit a Reply', {category: 'Updates'});
      return editUpdate($scope.replies[$index].id, $data.textContent);
    };

    $scope.deleteUpdate = function () {
      UpdatesManager.deleteUpdate($scope.parentUpdate.id, function () {
        $scope.panelsManager.close('updateReplies', 'deleted');
        $analytics.eventTrack('Delete an Update', {category: 'Updates'});
      });
    };

    $scope.deleteReply = function ($index) {
      UpdatesManager.deleteUpdate($scope.replies[$index].id, function () {
        $scope.replies.splice($index, 1);
        $analytics.eventTrack('Delete a Reply', {category: 'Updates'});
      });
    };

    $scope.lightBoxStart = function (type, id, $index) {
      var spinnerId = type + '-' + id + '-' + $index;
      usSpinnerService.spin(spinnerId);
      $(document.body).one('opened.fndtn.clearing', function () {
        usSpinnerService.stop(spinnerId);
      });
    };

    $scope.getNameForSharing = function () {
      return ($scope.parentUpdate.userCreated.isCompany ?
        $scope.parentUpdate.userCreated.companyName :
        $scope.parentUpdate.userCreated.firstName + ' ' + $scope.parentUpdate.userCreated.lastName) +
        ' Mineler Update';
    };

    (function () {
      listElement.scroll(angular.bind($scope, function (event) {
        if (!$scope.isLoading && loadingEnabled && event.currentTarget.scrollTop < 50) {
          loadReplies(10, false);
          $analytics.eventTrack('Load More Replies', {category: 'Updates'});
        }
      }));

      $('#reply-new-message').keydown(angular.bind($scope, function (e) {
        if (e.ctrlKey && e.keyCode === 13 && $scope.newReply) {
          $scope.post();
        }

        if (e.keyCode === 27) {
          e.stopPropagation();
          $scope.reset();
          e.currentTarget.blur();
        }
      }));
    })();
  }

})();
