(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Panels.Messages.controller:MessagesCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Messages')
    .controller('MessagesCtrl', MessagesCtrl);

  function MessagesCtrl(PanelsManager, Restangular, MessagesManager, $scope, RecordsSet, $state, InitHelper) {
    var vm = this;
    vm.listElement = $('#map-panel-messages > .map-primary-panel-content');
    vm.conversationsLoader = RecordsSet.getLoader('Messages', 'conversations');

    vm.openContactConversation = function (contactProfile) {
      MessagesManager.panels.open('messagesThread', {
        contactProfile: contactProfile
      });
    };

    vm.openConversationByIndex = function ($index) {
      $state.go('map.messages.detail', {
        id: InitHelper.encryptId(vm.conversationsLoader.records[$index].contactProfile.id)
      });
    };

    vm.openConversationById = function (id) {
      var $index = _.findIndex(vm.conversationsLoader.records, {contactProfile: {id: id}});
      if ($index !== -1) {
        vm.openContactConversation(vm.conversationsLoader.records[$index].contactProfile);
      } else {
        Restangular.one('Users').one('profile').get({id: id}).then(function (result) {
          vm.openContactConversation(result.plain());
        });
      }
    };

    vm.closePanel = function () {
      PanelsManager.panels.close('messages');
    };

    $scope.$on('event:messages-open-detail', function ($event, data) {
      vm.openConversationById(data.id);
    });

    vm.conversationsLoader.load();

    (function () {
      vm.listElement.scroll(angular.bind(vm, function (event) {
        if (vm.conversationsLoader.hasMore() && event.currentTarget.scrollTop < 50) {
          vm.conversationsLoader.load();
        }
      }));
    })();

  }

})();
