angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/profile/profile.tpl.html',
    '<div ng-controller="ProfileCtrl as profile" class="map-panel-controller">\n' +
    '  <div id="map-panel-profile" class="map-panel map-primary-panel" ng-swipe-left="profile.closePanel()" ng-swipe-disable-mouse>\n' +
    '    <div class="row map-panel-heading">\n' +
    '      <div class="small-2 columns">\n' +
    '        <a class="arrow-back" ng-click="profile.closePanel()">\n' +
    '          <i class="fa fa-angle-double-left"></i>\n' +
    '        </a>\n' +
    '      </div>\n' +
    '      <div class="small-13 columns">\n' +
    '        <h3>Profile</h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="map-primary-panel-content">\n' +
    '    <div class="row">\n' +
    '      <div class="small-5 columns map-panel-profile-photo cursor-pointer" ng-click="profile.openEditPictureForm()">\n' +
    '        <img ng-src="{{profile.profile | profilePicture:45}}" class="circle45">\n' +
    '      </div>\n' +
    '      <div class="small-10 columns cursor-pointer profile-name-head" ng-click="profile.openEditNameForm()">\n' +
    '        <user-widget-name user="profile.profile" one-line="false"></user-widget-name>\n' +
    '        <br/>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row cursor-pointer" ng-click="profile.openEditContactForm()">\n' +
    '      <div class="small-5 columns">\n' +
    '      </div>\n' +
    '      <div class="small-10 columns">\n' +
    '        <h3>Contact</h3>\n' +
    '        <span ng-show="profile.profile.phoneHome"><i class="fa fa-phone-square"></i> {{profile.profile.phoneHome}}<br/></span>\n' +
    '        <span ng-show="profile.profile.phoneMobile"><i\n' +
    '          class="fa fa-mobile"></i> {{profile.profile.phoneMobile}}<br/></span>\n' +
    '        <i class="fa fa-envelope-square"></i> {{profile.profile.email}}\n' +
    '        <br/><br/>\n' +
    '\n' +
    '        <h3>Address</h3>\n' +
    '\n' +
    '        <div class="map-panel-profile-address">\n' +
    '        <span>\n' +
    '        <i class="fa fa-home"></i>\n' +
    '          </span>\n' +
    '        <span>\n' +
    '          <span ng-show="profile.profile.streetAddress">{{profile.profile.streetAddress}}<br/></span>\n' +
    '          <span ng-show="profile.profile.suburb">{{profile.profile.suburb}}</span> <span\n' +
    '          ng-show="profile.profile.postCode">{{profile.profile.postCode}}<br/></span>\n' +
    '          <span ng-show="profile.profile.state.name">{{profile.profile.state.name}}<br/></span>\n' +
    '          {{profile.profile.country.name}}\n' +
    '        </span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row cursor-pointer" ng-click="profile.openEditAboutForm()">\n' +
    '      <div class="small-5 columns">\n' +
    '      </div>\n' +
    '      <div class="small-10 columns">\n' +
    '        <h3>About</h3>\n' +
    '        {{profile.profile.personalMessage | truncate: 200: \'...\': true}}\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row">\n' +
    '      <div class="small-5 columns">\n' +
    '      </div>\n' +
    '      <div class="small-10 columns">\n' +
    '        <h3>Resume</h3>\n' +
    '        <button class="button positive" ng-click="profile.addResume()">Upload</button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row cursor-pointer" ng-repeat="resume in profile.resume" ng-click="profile.updateResume($index)">\n' +
    '      <div class="small-5 columns"></div>\n' +
    '      <div class="small-10 columns">\n' +
    '        {{resume.name}}<br/>\n' +
    '      <span class="info-text">\n' +
    '        <i class="fa fa-clock-o"></i> Uploaded {{resume.lastUpdated}} ago\n' +
    '      </span>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row">\n' +
    '      <div class="small-5 columns">\n' +
    '      </div>\n' +
    '      <div class="small-10 columns">\n' +
    '        <h3>Social Networks</h3>\n' +
    '        <div class="row">\n' +
    '          <div class="small-15 columns">\n' +
    '            <ul class="small-block-grid-3 signin-social">\n' +
    '              <li ng-class="{\'social-linked\': profile.profile.isFacebook}">\n' +
    '                <a ng-click="profile.toggleFacebook()"><i class="fa fa-facebook"></i></a>\n' +
    '              </li>\n' +
    '              <li ng-class="{\'social-linked\': profile.profile.isLinkedin}">\n' +
    '                <a ng-click="profile.toggleLinkedin()"><i class="fa fa-linkedin"></i></a>\n' +
    '              </li>\n' +
    '              <li ng-class="{\'social-linked\': profile.profile.isGoogleplus}">\n' +
    '                <a ng-click="profile.toggleGoogleplus()"><i class="fa fa-google-plus"></i></a>\n' +
    '              </li>\n' +
    '            </ul>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row">\n' +
    '      <div class="small-5 columns"></div>\n' +
    '      <div class="small-10 columns">\n' +
    '        <a ui-sref="map.profile.subscriptions">Notifications</a><br/>\n' +
    '        <a ui-sref="map.profile.privacy">Privacy Settings</a><br/>\n' +
    '        <a ui-sref="map.profile.password">Change Password</a>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div id="map-panel-profile-edit" class="map-secondary-panel-container">\n' +
    '\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
