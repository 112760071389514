(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Panels.Experience.controller:ExperienceAddCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Experience')
    .controller('ExperienceAddCtrl', ExperienceAddCtrl);

  function ExperienceAddCtrl(Restangular, ExperiencePanelManager, Defaults, $state,
                             ExperienceManager, $scope, usSpinnerService, LocationManager, $analytics) {
    var vm = this;
    vm.isAdding = false;
    vm.errorMessage = null;
    vm.searchCompanyUrl = Defaults.baseUrl + '/Customers/index?search[query]=';
    vm.searchPositionUrl = Defaults.baseUrl + '/JobTitles/index?search[query]=';
    vm.initialCompany = '';
    vm.data = {
      skills: [],
      dateFrom: '0000-00-00',
      dateTo: '0000-00-00',
      employerName: '',
      projectName: '',
      jobTitleName: '',
      description: '',
      isCurrentExperience: false,
      idEmployer: 0,
      idProject: 0,
      idJobTitle: 0
    };
    vm.errors = {
      isError: false
    };
    vm.selectProject = false;
    vm.selectProjectOther = false;

    if (angular.isDefined($scope.experience)) {
      $scope.location = {
        id: $scope.experience.idLocation
      };
      $scope.projects = [
        {
          id: $scope.experience.idProject,
          name: $scope.experience.projectName,
          owner: {
            id: $scope.experience.idEmployer,
            companyName: $scope.experience.employerName
          }
        }
      ];
    }

    if (angular.isDefined($scope.location) && angular.isObject($scope.location)) {
      vm.data.latitude = $scope.location.latitude;
      vm.data.longitude = $scope.location.longitude;

      if (angular.isDefined($scope.location.id)) {
        vm.data.idLocation = $scope.location.id;
      }

      if (angular.isDefined($scope.location.countryCode)) {
        $scope.location.countryCode.then(function (result) {
          vm.data.countryCode = result;
        });
      }
    }

    if (angular.isDefined($scope.projects) && angular.isArray($scope.projects)) {
      vm.projects = $scope.projects;
      vm.selectProject = true;
      if (vm.projects.length === 1) {
        vm.data.idProject = vm.projects[0].id;
        vm.data.idEmployer = vm.projects[0].owner.id;
        vm.initialCompany = vm.projects[0].owner.companyName;
        vm.data.employerName = vm.projects[0].owner.companyName;
      }
    } else {
      vm.projects = [];
    }

    vm.errorMessage = null;

    vm.closePanel = function () {
      var callback = angular.noop;
      if ($state.includes('map.experience') || $state.includes('map.experienceMy')) {
        callback = function () {
          $state.go('map.experienceMy');
        };
      }
      ExperiencePanelManager.panels.close('experienceAdd', {}, callback);
      LocationManager.panels.close('experienceAdd');
    };

    vm.loadSkills = function ($query) {
      return Restangular.one('Tags').getList('index', {
        query: $query,
        limit: 15
      }).then(function (result) {
        return result.plain();
      });
    };

    vm.onSearchResponse = function (response) {
      return response.content.records;
    };

    vm.onCompanySearchSelect = function (selected) {
      if (!angular.isDefined(selected)) {
        return;
      }
      if (angular.isObject(selected.originalObject)) {
        vm.data.idEmployer = selected.originalObject.id;
      }
    };

    vm.onCompanyChanged = function (query) {
      vm.data.employerName = query;
    };

    vm.onFocusCompany = function () {
      if (vm.data.idEmployer !== 0) {
        vm.data.employerName = '';
        vm.data.idEmployer = 0;
        $scope.$broadcast('angucomplete-alt:clearInput', 'experience-company-input');
      }
    };

    vm.onPositionSearchSelect = function (selected) {
      if (!angular.isDefined(selected)) {
        return;
      }
      if (angular.isObject(selected.originalObject)) {
        vm.data.idJobTitle = selected.originalObject.id;
      }
    };

    vm.onPositionChanged = function (query) {
      vm.data.jobTitleName = query;
    };

    vm.add = function () {
      var toAdd = angular.copy(vm.data);
      vm.errorMessage = null;
      vm.errors = ExperienceManager.validateExperienceData(toAdd);

      if (vm.errors.isError === false) {
        vm.isAdding = true;
        toAdd.dateFrom = {date: toAdd.dateFrom};
        toAdd.dateTo = {date: toAdd.dateTo};
        usSpinnerService.spin('spinner-experience-add');
        ExperienceManager.addExperience(toAdd, function () {
          vm.closePanel();
          $analytics.eventTrack('Add', {category: 'Experience'});
        }, function (reason) {
          vm.errorMessage = reason;
          usSpinnerService.stop('spinner-experience-add');
          vm.isAdding = false;
        });
      }
    };

    $scope.$watch(function () {
      return vm.data.idProject;
    }, function (newIdProject, oldIdProject) {
      newIdProject = parseInt(newIdProject, 10);
      oldIdProject = parseInt(oldIdProject, 10);
      if (newIdProject > 0 && (oldIdProject === 0 || newIdProject !== oldIdProject)) {
        var $index = _.findIndex(vm.projects, {id: parseInt(newIdProject, 10)});
        if ($index !== -1) {
          vm.data.employerName = vm.projects[$index].owner.companyName;
          vm.data.idEmployer = vm.projects[$index].owner.id;
          $('#experience-company-input_value').val(vm.projects[$index].owner.companyName);
        }
      }
    });

  }

})();
