(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Map.Panels.MyWork.controller:MyWorkBidDetailCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.MyWork')
    .controller('MyWorkBidDetailCtrl', MyWorkBidDetailCtrl);

  function MyWorkBidDetailCtrl($scope, $filter, MyWorkPanelManager, $state, usSpinnerService, WorkManager,
                               PromptDialog, $analytics) {
    var vm = this;
    vm.isSaving = false;
    vm.errorMessage = null;
    vm.bidDetail = $scope.detail;
    vm.messagesParameters = {
      idJobApplication: $scope.detail.id
    };

    vm.closePanel = function (data) {
      if (!angular.isObject(data)) {
        data = {};
      }
      MyWorkPanelManager.panels.close('bidDetail', data, function () {
        $state.go('map.workMyBids');
      });
    };

    vm.applicationDate = function () {
      var d = new Date(vm.bidDetail.dateApplication);
      return $filter('date')(d, 'dd/MM/yyyy');
    };

    vm.bidFileUrl = function (file) {
      return '/JobApplicationFiles/download?id=' + file.id;
    };

    vm.withDraw = function () {
      PromptDialog.open('Are you sure?').result
        .then(function () {
          vm.isSaving = true;
          vm.errorMessage = null;
          usSpinnerService.spin('spinner-my-work-detail');
          WorkManager.withDrawBid(vm.bidDetail, function (result) {
            usSpinnerService.stop('spinner-my-work-detail');
            $analytics.eventTrack('Withdraw Bid', {category: 'My Bid Detail'});
            vm.closePanel({
              action: 'withdraw',
              bidDetail: result
            });
          }, function (reason) {
            usSpinnerService.stop('spinner-my-work-detail');
            vm.errorMessage = reason;
            vm.isSaving = false;
          });
        });
    };
  }

})();
