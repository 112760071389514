angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/people/people-invite.tpl.html',
    '<div id="map-panel-people-invite-action" class="map-secondary-panel"\n' +
    '     ng-controller="InviteCtrl as vm" ng-swipe-left="vm.closePanel()" ng-swipe-disable-mouse>\n' +
    '  <div class="row map-panel-heading">\n' +
    '    <div class="small-2 columns">\n' +
    '      <a class="arrow-back" ng-click="vm.closePanel()">\n' +
    '        <i class="fa fa-angle-double-left"></i>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '    <div class="small-13 columns">\n' +
    '      <h3>\n' +
    '        <user-widget-name user="vm.user"></user-widget-name>\n' +
    '      </h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="invite-detail-container">\n' +
    '\n' +
    '    <div ng-include="\'map/panels/people/user-profile.tpl.html\'"></div>\n' +
    '\n' +
    '    <div class="row invite-resend-form" ng-if="vm.inviteDetail.isMyRequest">\n' +
    '      <div class="small-15 columns">\n' +
    '        <form name="inviteResendForm" role="inviteResendForm">\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <textarea name="requestMessage" ng-model="vm.requestMessage" ng-required="true"\n' +
    '                        placeholder="Request message" msd-elastic></textarea>\n' +
    '              <small class="invite-resend-error" ng-show="inviteResendForm.requestMessage.$dirty && requestMessage.requestMessage.$error.required">\n' +
    '                Missing request message\n' +
    '              </small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns invites-buttons-container text-right">\n' +
    '              <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-invite-action"></span>\n' +
    '              <button ng-hide="vm.isSending" class="button positive" ng-click="vm.resend()"\n' +
    '                      ng-disabled="inviteResendForm.$invalid">RE-SEND\n' +
    '              </button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <span class="info-text">\n' +
    '                <i class="fa fa-clock-o"></i> {{vm.inviteDetail.lastSent}} ago\n' +
    '              </span>\n' +
    '              <br/>\n' +
    '              {{vm.inviteDetail.requestMessage}}\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </form>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row invite-resend-form" ng-if="!vm.inviteDetail.isMyRequest">\n' +
    '      <div class="small-15 columns">\n' +
    '        {{vm.inviteDetail.requestMessage}}\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row invite-resend-form" ng-if="!vm.inviteDetail.isMyRequest">\n' +
    '      <div class="small-15 columns invites-buttons-container text-right">\n' +
    '        <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-invite-action"></span>\n' +
    '        <button ng-hide="vm.isSending" class="button" ng-click="vm.ignore()">IGNORE</button>\n' +
    '        <button ng-hide="vm.isSending" class="button positive" ng-click="vm.accept()">ACCEPT</button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
