angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/dashboards/mineler.tpl.html',
    '<div ng-controller="MinelerDashboardCtrl as minelerDashboard" class="mineler-dashboard-container">\n' +
    '  <div class="row mineler-dashboard-logo">\n' +
    '    <div class="small-15 columns text-center">\n' +
    '      <a ng-if="minelerDashboard.isMineler" ng-click="minelerDashboard.goHome()"\n' +
    '         analytics-on\n' +
    '         analytics-event="Mineler Logo"\n' +
    '         analytics-category="Mineler Dashboard">\n' +
    '        <img src="/images/mineler.png" class="show-for-medium-up"/>\n' +
    '        <img src="/images/mineler-gear-white.png" class="show-for-small-only"/>\n' +
    '      </a>\n' +
    '      <span ng-if="!minelerDashboard.isMineler">\n' +
    '        <company-logo-widget company="minelerDashboard.company" height="120"></company-logo-widget>\n' +
    '      </span>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div ng-if="minelerDashboard.isMineler" class="row mineler-dashboard-social hide-for-small-only">\n' +
    '    <ul class="medium-block-grid-4 large-block-grid-7">\n' +
    '      <li><a target="_blank" href="https://www.facebook.com/minelerapp"\n' +
    '             analytics-on\n' +
    '             analytics-event="Social Icon"\n' +
    '             analytics-category="Mineler Dashboard"\n' +
    '             analytics-label="Facebook">\n' +
    '        <i class="fa fa-facebook-square"></i>\n' +
    '      </a></li>\n' +
    '      <li><a target="_blank" href="https://twitter.com/MinelerApp"\n' +
    '             analytics-on\n' +
    '             analytics-event="Social Icon"\n' +
    '             analytics-category="Mineler Dashboard"\n' +
    '             analytics-label="Twitter">\n' +
    '        <i class="fa fa-twitter"></i>\n' +
    '      </a></li>\n' +
    '      <li><a target="_blank" href="https://plus.google.com/+Mineler/about"\n' +
    '             analytics-on\n' +
    '             analytics-event="Social Icon"\n' +
    '             analytics-category="Mineler Dashboard"\n' +
    '             analytics-label="Google+">\n' +
    '        <i class="fa fa-google-plus"></i>\n' +
    '      </a></li>\n' +
    '      <li><a target="_blank" href="https://www.youtube.com/channel/UCj_JO1XT-ulvV1OHfgaEwlw"\n' +
    '             analytics-on\n' +
    '             analytics-event="Social Icon"\n' +
    '             analytics-category="Mineler Dashboard"\n' +
    '             analytics-label="YouTube">\n' +
    '        <i class="fa fa-youtube"></i>\n' +
    '      </a></li>\n' +
    '      <li><a target="_blank" href="https://www.linkedin.com/company/mineler"\n' +
    '             analytics-on\n' +
    '             analytics-event="Social Icon"\n' +
    '             analytics-category="Mineler Dashboard"\n' +
    '             analytics-label="LinkedIn">\n' +
    '        <i class="fa fa-linkedin"></i>\n' +
    '      </a></li>\n' +
    '      <li><a target="_blank" href="https://vimeo.com/mineler"\n' +
    '             analytics-on\n' +
    '             analytics-event="Social Icon"\n' +
    '             analytics-category="Mineler Dashboard"\n' +
    '             analytics-label="Vimeo">\n' +
    '        <i class="fa fa-vimeo-square"></i>\n' +
    '      </a></li>\n' +
    '      <li><a target="_blank" href="http://blog.mineler.com"\n' +
    '             analytics-on\n' +
    '             analytics-event="Social Icon"\n' +
    '             analytics-category="Mineler Dashboard"\n' +
    '             analytics-label="Mineler Blog">\n' +
    '        <i class="fa fa-bookmark"></i>\n' +
    '      </a></li>\n' +
    '    </ul>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
