(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name socialIntegration.service:SocialContacts
   *
   * @description
   *
   */
  angular
    .module('socialIntegration')
    .service('SocialContacts', SocialContacts);

  function SocialContacts($q, Defaults, SocialAccounts, socialProviders, Restangular, AlertDialog, $upload,
                          $analytics) {
    var
      self = this;

    function doImport(provider, accessToken) {
      var deferred = $q.defer();
      Restangular.one('Network').post('importContactsFromSocialNetwork', {
        oauth2: {
          oauth2AccessToken: accessToken,
          provider: socialProviders[provider].backendProviderIdentifier
        }
      });

      $analytics.eventTrack('Import from social service', {category: 'People'});
      AlertDialog.open('Your contacts will be imported. We will send you an email when the import is complete.')
        .result.then(function () {
          SocialAccounts.isConnected(provider)
            .then(function (isConnected) {
              if (isConnected) {
                deferred.resolve(true);
              } else {
                SocialAccounts.connect(provider, accessToken)
                  .then(function () {
                    deferred.resolve(true);
                  });
              }
            });
        });

      return deferred.promise;
    }

    function importContacts(provider) {
      var
        deferred = $q.defer(),
        options = {
          redirect_uri: Defaults.appUrl,
          force: true,
          scope: 'friends'
        };

      hello.login(provider, options, function (result) {
        if (angular.isDefined(result.authResponse) && angular.isDefined(result.authResponse.access_token)) {
          var accessToken = result.authResponse.access_token;
          doImport(provider, accessToken)
            .then(function () {
              deferred.resolve(true);
            });
        } else {
          deferred.reject('An error occurred');
        }
      });

      return deferred.promise;
    }

    self.importContacts = function (provider) {
      return importContacts(provider);
    };

    self.importContactsFromVCard = function ($file) {
      var deferred = $q.defer();

      if ($file.size >= Defaults.maxFileUploadSize) {
        deferred.reject(Defaults.maxFileUploadMessage);
      } else if ($file.type !== 'text/vcard' && $file.type !== 'text/x-vcard' && $file.type !== 'application/vcard') {
        deferred.reject('Unsupported file type');
      } else {
        $upload.upload({
          url: Defaults.baseUrl + '/Network/importContactsFromFile',
          file: $file,
          fileFormDataName: 'file'
        });

        AlertDialog.open('Your contacts will be imported. We will send you an email when the import is complete.');
        $analytics.eventTrack('Import from vCard', {category: 'People'});
        deferred.resolve();
      }

      return deferred.promise;
    };
  }
}());
