(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Panels.Updates.service:UpdatesManager
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Updates')
    .service('UpdatesManager', UpdatesManager);

  function UpdatesManager(PanelsFactory, $upload, Restangular, $q, Defaults, $state) {
    var
      self = this,
      targetContainerId = 'map-panel-replies-container',
      panels = {
        updateReplies: {
          id: 'map-panel-update-replies',
          templateUrl: 'map/panels/updates/updates-replies.tpl.html',
          resolve: {
            userProfile: ['Auth', function (Auth) {
              return Auth.getSession();
            }]
          }
        }
      };

    self.panels = PanelsFactory.get(panels, targetContainerId, true, false, true, function () {
      $state.go('map.updates');
    });

    self.postUpdate = function (data, $files, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;

      Restangular.one('Updates').customPOST(data, 'index').then(function (result) {
        if ($files.length > 0) {
          var promises = [];
          angular.forEach($files, function (file) {
            var deferred = $q.defer();
            promises.push(deferred.promise);
            $upload.upload({
              url: Defaults.baseUrl + '/UpdateFiles/file?idUpdate=' + result.id,
              file: file
            })
              .success(function (data) {
                deferred.resolve(data.content);
              })
              .error(function () {
                deferred.resolve(false);
              });
          });

          $q.all(promises).then(function (resolved) {
            angular.forEach(resolved, function (r) {
              if (r !== false) {
                result.files.recordsInfo.selectedRecords++;
                result.files.recordsInfo.totalRecords++;
                result.files.records.push(r);
                result.mediaCount++;
                result.mediaCountTotal++;
              }
            });
            successCallback(result);
          });
        } else {
          successCallback(result);
        }
      }, function () {
        errorCallback();
      });
    };

    self.deleteUpdate = function (id, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;
      Restangular.one('Updates').one('index').remove({id: id}).then(function () {
        successCallback();
      }, function () {
        errorCallback();
      });
    };

    self.editUpdate = function (id, text, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;
      Restangular.one('Updates').customPUT({
        text: text
      }, 'index', {id: id}).then(function (result) {
        successCallback(result);
      }, function () {
        errorCallback();
      });
    };
  }

})();
