angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/toolbars/search/search-filters.tpl.html',
    '<div class="row">\n' +
    '  <div class="small-15 columns map-search-filter-title">\n' +
    '    FILTER BY\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="row">\n' +
    '  <div class="small-15 columns map-search-filter map-search-filter-country">\n' +
    '    <tags-input\n' +
    '      ng-model="search.filters.idCountry"\n' +
    '      add-from-autocomplete-only="true"\n' +
    '      placeholder="COUNTRY"\n' +
    '      display-property="name"\n' +
    '      replace-spaces-with-dashes="false">\n' +
    '      <auto-complete source="search.loadCountries($query)" min-length="2"></auto-complete>\n' +
    '    </tags-input>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row">\n' +
    '  <div class="small-15 columns map-search-filter map-search-filter-commodity">\n' +
    '    <tags-input\n' +
    '      ng-model="search.filters.idCommodity"\n' +
    '      add-from-autocomplete-only="true"\n' +
    '      placeholder="COMMODITY"\n' +
    '      display-property="name"\n' +
    '      replace-spaces-with-dashes="false">\n' +
    '      <auto-complete source="search.loadCommodities($query)" min-length="2"></auto-complete>\n' +
    '    </tags-input>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row">\n' +
    '  <div class="small-15 columns map-search-filter map-search-filter-project-type">\n' +
    '    <tags-input\n' +
    '      ng-model="search.filters.idProjectType"\n' +
    '      add-from-autocomplete-only="true"\n' +
    '      placeholder="PROJECT TYPE"\n' +
    '      display-property="name"\n' +
    '      replace-spaces-with-dashes="false">\n' +
    '      <auto-complete source="search.loadProjectTypes($query)" min-length="2"></auto-complete>\n' +
    '    </tags-input>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row">\n' +
    '  <div class="small-15 columns map-search-filter">\n' +
    '    <div class="right">\n' +
    '      <button class="button positive" ng-click="search.applyFilter()"\n' +
    '              ng-disabled="search.isApplyFilterDisabled()">\n' +
    '        <i class="fa fa-search"></i>\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
