(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name Toolbars.FavouriteUsers.directive:favouriteStar
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="Toolbars.FavouriteUsers">
       <file name="index.html">
        <favourite-star></favourite-star>
       </file>
     </example>
   *
   */
  angular
    .module('Map.Toolbars.FavouriteUsers')
    .directive('favouriteStar', favouriteStar);

  function favouriteStar(FavouriteUsers) {
    return {
      restrict: 'E',
      scope: {},
      template: '<a ng-click="toggle($event)" ng-hide="isLoading">' +
        '<i class="fa fa-star" ng-class="{\'text-orange\': isFavourite()}"></i>' +
        '</a>',
      replace: true,
      link: function (scope, element, attrs) {
        var idUser = attrs.iduser;
        scope.isLoading = false;

        scope.toggle = function ($event) {
          $event.stopPropagation();
          scope.isLoading = true;
          FavouriteUsers.toggle(idUser).then(function () {
            scope.isLoading = false;
          });
        };

        scope.isFavourite = function () {
          return FavouriteUsers.isFavourite(idUser);
        };
      }
    };
  }

})();
