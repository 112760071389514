(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Panels.Profile.controller:ProfileEditNameCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Profile')
    .controller('ProfileEditNameCtrl', ProfileEditNameCtrl);

  function ProfileEditNameCtrl(PanelsProfileManager, $scope, Auth, usSpinnerService, $analytics) {
    var vm = this;
    vm.editProfileErrorMessage = null;
    vm.isSaving = false;
    vm.profile = angular.copy($scope.userProfile.profile);
    vm.closeEditPanel = function () {
      PanelsProfileManager.panels.close('profileEditName');
    };

    vm.isValidName = function () {
      if (vm.profile.isCompany === true) {
        return (angular.isDefined(vm.profile.companyName) && vm.profile.companyName !== '');
      } else {
        return (angular.isDefined(vm.profile.firstName) && vm.profile.firstName !== '' &&
        angular.isDefined(vm.profile.lastName) && vm.profile.lastName !== '');
      }

      return false;
    };

    vm.saveProfile = function () {
      vm.isSaving = true;
      usSpinnerService.spin('spinner-profile-edit');
      vm.editProfileErrorMessage = null;
      Auth.saveSession(vm.profile, function () {
        vm.isSaving = false;
        usSpinnerService.stop('spinner-profile-edit');
        vm.closeEditPanel();
        $analytics.eventTrack('Update Name', {category: 'Profile'});
      }, function (error) {
        vm.isSaving = false;
        vm.editProfileErrorMessage = error;
        usSpinnerService.stop('spinner-profile-edit');
      });
    };
  }

})();
