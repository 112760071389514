angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/toolbars/search/search-angucomplete.tpl.html',
    '<div class="angucomplete-holder" ng-class="{\'angucomplete-dropdown-visible\': showDropdown}">\n' +
    '  <input id="{{id}}_value" ng-model="searchStr" ng-disabled="disableInput" type="search" placeholder="{{placeholder}}"\n' +
    '         ng-focus="onFocusHandler()" class="{{inputClass}}" ng-focus="resetHideResults()" ng-blur="hideResults($event)"\n' +
    '         autocapitalize="off" autocorrect="off" autocomplete="off" ng-change="inputChangeHandler(searchStr)"/>\n' +
    '\n' +
    '  <div id="{{id}}_dropdown" class="angucomplete-dropdown" ng-show="showDropdown">\n' +
    '    <div class="angucomplete-searching" ng-show="searching" ng-bind="textSearching"></div>\n' +
    '    <div class="angucomplete-searching" ng-show="!searching && (!results || results.length == 0)"\n' +
    '         ng-bind="textNoResults"></div>\n' +
    '    <div class="angucomplete-row" ng-repeat="result in results" ng-click="selectResult(result)"\n' +
    '         ng-mouseenter="hoverRow($index)" ng-class="{\'angucomplete-selected-row\': $index == currentIndex}">\n' +
    '      <div ng-if="imageField" class="angucomplete-image-holder">\n' +
    '        <img ng-if="result.image && result.image != \'\'" ng-src="{{result.image}}" class="angucomplete-image"/>\n' +
    '\n' +
    '        <div ng-if="!result.image && result.image != \'\'" class="angucomplete-image-default"></div>\n' +
    '      </div>\n' +
    '      <div class="angucomplete-title" ng-if="matchClass" ng-bind-html="result.title"></div>\n' +
    '      <div class="angucomplete-title" ng-if="!matchClass">{{ result.title }}</div>\n' +
    '      <div ng-if="matchClass && result.description && result.description != \'\'" class="angucomplete-description"\n' +
    '           ng-bind-html="result.description"></div>\n' +
    '      <div ng-if="!matchClass && result.description && result.description != \'\'" class="angucomplete-description">\n' +
    '        {{result.description}}\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
