(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name panels.myWork.controller:MyWorkMyBidsCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.MyWork')
    .controller('MyWorkMyBidsCtrl', MyWorkMyBidsCtrl);

  function MyWorkMyBidsCtrl($filter, RecordsSet, MyWorkPanelManager, $scope, $state, Restangular, InitHelper,
                            $analytics, $rootScope, $timeout) {
    var vm = this;

    vm.myApplicationsLoader = RecordsSet.getLoader('JobApplications');

    vm.currentBids = function () {
      return _.filter(vm.myApplicationsLoader.records, {jobPosition: {enabled: true}});
    };

    vm.closedBids = function () {
      return _.filter(vm.myApplicationsLoader.records, {jobPosition: {enabled: false}});
    };

    vm.applicationDate = function (application) {
      var d = new Date(application.dateApplication);
      return $filter('date')(d, 'dd/MM/yyyy');
    };

    vm.myApplicationsLoader.load();

    vm.openBidById = function (id) {
      var $index = _.findIndex(vm.myApplicationsLoader.records, {id: id});
      if ($index !== -1) {
        vm.openBid(vm.myApplicationsLoader.records[$index]);
      } else {
        Restangular.one('JobApplications').one('index').get({id: id})
          .then(function (result) {
            vm.openBid(result.plain());
          });
      }
    };

    vm.openMyBidById = function (myBid) {
      $state.go('map.workMyBids.detail', {
        id: InitHelper.encryptId(myBid.id)
      });
    };

    vm.openBidByIndex = function ($index) {
      $state.go('map.workMyBids.detail', {
        id: InitHelper.encryptId(vm.myApplicationsLoader.records[$index].id)
      });
    };

    vm.openBid = function (bid) {
      MyWorkPanelManager.panels.open('bidDetail', {
        detail: bid
      })
        .then(function (result) {
          if (angular.isObject(result) && angular.isDefined(result.action)) {
            var $index = _.findIndex(vm.myApplicationsLoader.records, {id: bid.id});
            switch (result.action) {
              case 'withdraw':
                if ($index !== -1) {
                  vm.myApplicationsLoader.records[$index] = result.bidDetail;
                }
                break;
            }
          }
        });

      if (angular.isDefined(bid.jobPosition.project.location) &&
        angular.isObject(bid.jobPosition.project.location)) {
        $timeout(function () {
          $rootScope.$broadcast('event:center-map', {
            idLocation: bid.jobPosition.project.location.id,
            offsetX: $('#map-panel-work').width()
          });
        }, 100);
      }

      $analytics.eventTrack('Open My Bid Detail', {category: 'Work'});
    };

    $scope.$on('event:work-my-bid-open-detail', function ($event, data) {
      vm.openBidById(data.id);
    });
  }
})();
