(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Map.Panels.MyWork.service:MyWorkEditPanelManager
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.MyWork')
    .service('MyWorkEditPanelManager', MyWorkEditPanelManager);

  function MyWorkEditPanelManager(PanelsFactory, $state) {
    var
      self = this,
      targetContainerId = 'map-panel-work-edit-actions',
      panels = {
        updateReplies: {
          id: 'map-panel-update-replies',
          templateUrl: 'map/panels/updates/updates-replies.tpl.html',
          resolve: {
            userProfile: ['Auth', function (Auth) {
              return Auth.getSession();
            }]
          }
        },
        bidMessages: {
          id: 'map-panel-update-messages',
          templateUrl: 'map/panels/my-work/bid-messages.tpl.html',
          resolve: {
            userProfile: ['Auth', function (Auth) {
              return Auth.getSession();
            }]
          }
        }
      };

    self.panels = PanelsFactory.get(panels, targetContainerId, true, false, true, function () {
      $state.go('^');
    });
  }
}());
