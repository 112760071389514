angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('network/network_request.tpl.html',
    '<div id="network-request" class="image-background-connection">\n' +
    '  <div class="row outside-container">\n' +
    '    <div class="small-15 medium-9 large-8 columns outside-form" ng-if="networkRequest.validToken">\n' +
    '      <div class="row">\n' +
    '        <div class="small-6 small-push-9 columns text-right">\n' +
    '          <img src="images/mineler.png" class="home-page-logo"/>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns text-right">\n' +
    '          <small>First time here? <a href="/info" target="_blank">Learn more.</a></small>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns text-left">\n' +
    '          <h4>Good News!</h4>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns text-left">\n' +
    '          <h4><user-widget-name user="networkRequest.contactRequest.userFrom" only-first-name="true"></user-widget-name> would like to connect with you on Mineler.</h4>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns text-left">\n' +
    '          Connect with <user-widget-name user="networkRequest.contactRequest.userFrom" only-first-name="true"></user-widget-name> to share messages and experiences on Mineler, the world\'s first social marketplace for mining people.\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row content">\n' +
    '        <div class="small-7 medium-6 columns">\n' +
    '          <img class="circle150" ng-src="{{networkRequest.contactRequest.userFrom | profilePicture:150}}"/>\n' +
    '        </div>\n' +
    '        <div class="small-8 medium-9 columns text-left">\n' +
    '          <b>Message from <user-widget-name user="networkRequest.contactRequest.userFrom" only-first-name="true"></user-widget-name>:</b><br/>\n' +
    '          {{networkRequest.contactRequest.requestMessage}}\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row" ng-show="networkRequest.errorMessage">\n' +
    '        <div class="small-15 columns">\n' +
    '          <small class="error">{{networkRequest.errorMessage}}</small>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns text-right">\n' +
    '          <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-network-accept"></span>\n' +
    '          <button ng-hide="networkRequest.isLoading" class="button" ng-click="networkRequest.ignore();">Ignore</button>\n' +
    '          <button ng-hide="networkRequest.isLoading" class="button positive" ng-click="networkRequest.accept();">CONNECT</button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns text-right">\n' +
    '          <small><a href="/info" target="_blank">Learn more</a> about Mineler.</small>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="small-15 medium-11 large-10 columns outside-message" ng-if="!networkRequest.validToken">\n' +
    '      <div class="row">\n' +
    '        <div class="small-6 small-push-9 columns text-right">\n' +
    '          <img src="images/mineler.png" class="home-page-logo"/>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row" ng-if="networkRequest.error201">\n' +
    '        <div class="small-6 medium-3 columns message-icon">\n' +
    '          <i class="fa fa-minus-circle"></i>\n' +
    '        </div>\n' +
    '        <div class="small-9 medium-12 columns message-text text-left">\n' +
    '          <h3>Oops,</h3>\n' +
    '          Some information is missing. Please try again.\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row" ng-if="networkRequest.error202">\n' +
    '        <div class="small-6 medium-3 columns message-icon">\n' +
    '          <i class="fa fa-exclamation-circle"></i>\n' +
    '        </div>\n' +
    '        <div class="small-9 medium-12 columns message-text text-left">\n' +
    '          <h3>We\'re sorry, your request cannot be located.</h3>\n' +
    '          Please help us improve your Mineler experience by contacting us.\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row" ng-if="networkRequest.error203">\n' +
    '        <div class="small-6 medium-3 columns message-icon">\n' +
    '          <i class="fa fa-check-circle"></i>\n' +
    '        </div>\n' +
    '        <div class="small-9 medium-12 columns message-text text-left">\n' +
    '          <h3>Great Work!</h3>\n' +
    '          All done.\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns text-right">\n' +
    '          <button class="button positive" ng-click="networkRequest.continue()">Continue</button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
