(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Map.Toolbars.controller:SearchCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Toolbars.Search')
    .controller('SearchCtrl', SearchCtrl);

  function SearchCtrl(Defaults, Restangular, MapComponent, PanelsManager, $rootScope, Auth, SearchManager, $analytics) {
    var
      vm = this,
      defaultLimit = 3;

    vm.isFilterVisible = false;
    vm.isResultVisible = false;
    vm.isSearching = false;
    vm.searchUrl = Defaults.baseUrl + '/Search/history?query=';

    vm.filters = {
      idCountry: [],
      idCommodity: [],
      idProjectType: []
    };
    vm.initialQuery = SearchManager.getInitialQuery();
    vm.searchQuery = null;

    vm.results = {};

    vm.closeFilter = function () {
      vm.isFilterVisible = false;
    };

    vm.toggleFilter = function () {
      if (!vm.isFilterVisible) {
        vm.closeResult();
        PanelsManager.panels.closeAll();
      }
      vm.isFilterVisible = !vm.isFilterVisible;
    };

    vm.closeResult = function () {
      vm.results = {};
      vm.isResultVisible = false;
    };

    vm.toggleResult = function () {
      vm.isResultVisible = !vm.isResultVisible;
    };

    vm.onFocusIn = function () {
      $('#map-search-input_value').select();
      PanelsManager.panels.closeAll();
      vm.closeFilter();
    };

    vm.isFilterEmpty = function () {
      return (vm.filters.idCountry.length === 0 &&
        vm.filters.idCommodity.length === 0 &&
        vm.filters.idProjectType.length === 0);
    };

    vm.isResultEmpty = function () {
      return (vm.results.network &&
        vm.results.network.recordsInfo.selectedRecords === 0 &&
        vm.results.suggestions.recordsInfo.selectedRecords === 0 &&
        vm.results.companies.recordsInfo.selectedRecords === 0 &&
        vm.results.projects.recordsInfo.selectedRecords === 0);
    };

    vm.onSearchResponse = function (response) {
      return response.content.records;
    };

    vm.onSearchSelect = function (selected) {
      if (!angular.isDefined(selected)) {
        return;
      }

      if (angular.isDefined(selected.title)) {
        vm.searchQuery = selected.title;
      } else if (angular.isDefined(selected.originalObject)) {
        if (angular.isString(selected.originalObject)) {
          vm.searchQuery = selected.originalObject;
        } else if (angular.isObject(selected.originalObject) && angular.isDefined(selected.originalObject.query)) {
          vm.searchQuery = selected.originalObject.query;
        }
      }
      vm.search();
    };

    vm.onSearchChanged = function (query) {
      vm.searchQuery = query;
    };

    vm.loadCountries = function ($query) {
      return Restangular.one('Countries').getList('index', {
        query: $query,
        limit: 15
      }).then(function (result) {
        return result.plain();
      });
    };

    vm.loadCommodities = function ($query) {
      return Restangular.one('Tags').getList('index', {
        query: $query,
        limit: 15
      }).then(function (result) {
        return result.plain();
      });
    };

    vm.loadProjectTypes = function ($query) {
      return Restangular.one('ProjectTypes').getList('index', {
        query: $query,
        limit: 15
      }).then(function (result) {
        return result.plain();
      });
    };

    vm.formatFilterForSearch = function () {
      var
        filters = {},
        idCountry = [],
        idCommodity = [],
        idProjectType = [];
      if (vm.filters.idCountry.length > 0) {
        angular.forEach(vm.filters.idCountry, function (country) {
          idCountry.push(country.id);
        });
        filters['idCountry'] = idCountry;
      }

      if (vm.filters.idCommodity.length > 0) {
        angular.forEach(vm.filters.idCommodity, function (commodity) {
          idCommodity.push(commodity.id);
        });
        filters['idCommodity'] = idCommodity;
      }

      if (vm.filters.idProjectType.length > 0) {
        angular.forEach(vm.filters.idProjectType, function (projectType) {
          idProjectType.push(projectType.id);
        });
        filters['idProjectType'] = idProjectType;
      }

      return filters;
    };

    vm.search = function () {
      var
        filters = {};
      PanelsManager.panels.closeAll();
      vm.closeFilter();
      if (vm.searchQuery) {
        $analytics.eventTrack('Search', {category: 'Search', label: 'Search'});
        vm.isResultVisible = true;
        vm.isSearching = true;
        filters = vm.formatFilterForSearch();

        Restangular.one('Search').customPOST({
          filters: filters
        }, 'index', {
          query: vm.searchQuery
        }).then(function (result) {
          vm.results = result.results;
          vm.isSearching = false;
        }, function () {
          vm.isSearching = false;
          vm.isResultVisible = false;
        });
      } else {
        vm.isResultVisible = false;
      }
    };

    vm.applyFilter = function () {
      if (vm.searchQuery) {
        vm.search();
      } else if (!vm.isFilterEmpty()) {
        $analytics.eventTrack('Filter', {category: 'Search', label: 'Apply filters only, no search query'});
        vm.closeFilter();
        var filters = vm.formatFilterForSearch();
        MapComponent.loadFiltersLocations(filters);
      }
    };

    vm.isApplyFilterDisabled = function () {
      return (!vm.searchQuery && vm.isFilterEmpty());
    };

    vm.loadMore = function (type) {
      $analytics.eventTrack('Result', {category: 'Search', label: 'Load more results'});
      var
        types = {},
        results;

      types[type] = {
        limit: defaultLimit,
        offset: vm.results[type].recordsInfo.selectedRecords
      };

      Restangular.one('Search').customPOST({
        filters: vm.formatFilterForSearch(),
        types: types
      }, 'index', {
        query: vm.searchQuery
      }).then(function (result) {
        results = result.results;
        vm.results[type].records = vm.results[type].records.concat(results[type].records);
        vm.results[type].recordsInfo.selectedRecords += results[type].recordsInfo.selectedRecords;
        vm.results[type].recordsInfo.totalRecords = results[type].recordsInfo.totalRecords;
      });
    };

    vm.hasMore = function (type) {
      if (angular.isDefined(vm.results[type])) {
        if (vm.results[type].recordsInfo.selectedRecords < vm.results[type].recordsInfo.totalRecords) {
          return true;
        }
      }
      return false;
    };

    vm.updateMapForCompany = function (company) {
      $analytics.eventTrack('Result', {category: 'Search', label: 'Click on company, show company pins'});
      MapComponent.loadCompanyLocations(company);
    };

    vm.updateMapForProject = function (idProject) {
      $analytics.eventTrack('Result', {category: 'Search', label: 'Click on project, show project pins'});
      MapComponent.loadProjectLocations([idProject]);
    };

    vm.updateMapForUser = function (idUser) {
      $analytics.eventTrack('Result', {category: 'Search', label: 'Click on user, show user pins'});
      MapComponent.loadUserLocations([idUser]);
    };

    $rootScope.$on('event:panel-activated', function () {
      vm.closeFilter();
      vm.closeResult();
    });

    $rootScope.$on('event:map-reset-filter', function () {
      vm.closeFilter();
      vm.closeResult();
      vm.searchQuery = null;
      vm.filters = {
        idCountry: [],
        idCommodity: [],
        idProjectType: []
      };
      $rootScope.$broadcast('angucomplete-alt:clearInput', 'map-search-input');
    });

    $rootScope.$on('event:map-reset-home', function () {
      Auth.getSession().then(function (session) {
        vm.filters.idCountry = [session.profile.country];
        MapComponent.loadFiltersLocations(vm.formatFilterForSearch());
      });
    });

    $rootScope.$on('event:map-search', function (event, data) {
      SearchManager.setInitialQuery(data.query);
      vm.initialQuery = SearchManager.getInitialQuery();
      vm.searchQuery = data.query;
      vm.filters = {
        idCountry: [],
        idCommodity: [],
        idProjectType: []
      };
      vm.search();
    });

    SearchManager.setReady();
  }

})();
