(function () {
  'use strict';

  /* @ngdoc object
   * @name home
   * @requires $stateProvider
   *
   * @description
   *
   */
  angular
    .module('home', [
      'ui.router',
      'Configuration',
      'angular.filter'
    ]);

  angular
    .module('home')
    .config(config)
    .run(run);

  function config(Defaults, $stateProvider) {
    $stateProvider
      .state('signup', {
        url: '/signup',
        params: {
          inPopup: false,
          apiKey: ''
        },
        templateUrl: 'home/signup.tpl.html',
        controller: 'SignUpCtrl as vm',
        authenticate: false,
        resolve: {
          preRegistration: function () {
            return false;
          }
        }
      })
      .state('signupToken', {
        url: '/signup/{token}',
        params: {
          inPopup: false
        },
        templateUrl: 'home/signup.tpl.html',
        controller: 'SignUpCtrl as vm',
        authenticate: false,
        resolve: {
          preRegistration: ['$stateParams', 'Restangular', function ($stateParams, Restangular) {
            return Restangular.one('Users').one('preregistration', $stateParams.token).get().then(function (result) {
              return result;
            }, function () {
              return false;
            });
          }]
        }
      })
      .state('signin', {
        url: '/signin',
        params: {
          nextState: {},
          inPopup: false,
          apiKey: ''
        },
        templateUrl: 'home/signin.tpl.html',
        controller: 'SignInCtrl as vm',
        authenticate: false
      });

    hello.init({
      facebook: Defaults.facebookAppId,
      google: Defaults.googlePlusClientId,
      linkedin: Defaults.linkedInAppKey
    }, {
      oauth_proxy: Defaults.linkedInProxy
    });
  }

  function run() {
  }

})();
