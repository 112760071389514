(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Security.factory:ApiKeyInterceptor
   *
   * @description
   *
   */
  angular
    .module('Security')
    .factory('ApiKeyInterceptor', ApiKeyInterceptor)
    .config(config);

  function ApiKeyInterceptor($injector) {
    var ApiKeyInterceptorBase = {};
    ApiKeyInterceptorBase.request = function (config) {
      var apiKey = $injector.get('Defaults').apiKey;
      config.headers['X-Ff3-Api-Key'] = apiKey;

      return config;
    };

    return ApiKeyInterceptorBase;
  }

  function config($httpProvider) {
    $httpProvider.interceptors.push('ApiKeyInterceptor');
  }

})();
