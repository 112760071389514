(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name intro.controller:IntroDialogCtrl
   *
   * @description
   *
   */
  angular
    .module('intro')
    .controller('IntroDialogCtrl', IntroDialogCtrl);

  function IntroDialogCtrl($modalInstance) {
    var vm = this;

    vm.close = function () {
      $modalInstance.close();
    };
  }

})();
