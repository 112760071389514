(function () {
  'use strict';

  /* @ngdoc object
   * @name Work
   * @requires $stateProvider
   *
   * @description Work module, covers functionality for job application
   *
   */
  angular
    .module('Work', []);

  angular
    .module('Work')
    .config(config);

  function config($stateProvider, Defaults) {
    $stateProvider
      .state('workApplication', angular.extend({}, Defaults.mapStateParams, {
        url: '/work/application/{token}',
        resolve: {
          jobApplicationData: ['Restangular', '$stateParams', 'SearchManager',
            function (Restangular, $stateParams, SearchManager) {
              return Restangular.one('JobApplications').one('detail').get({token: $stateParams.token})
                .then(function (result) {
                  SearchManager.setInitialQuery(result.firstName + ' ' + result.lastName);

                  return result;
                });
            }]
        },
        onEnter: ['$q', 'QueueManager', 'QueueItem', 'InitHelper', 'MapComponent', 'jobApplicationData',
          function ($q, QueueManager, QueueItem, InitHelper, MapComponent, jobApplicationData) {
            QueueManager.add(new QueueItem(function () {
              return MapComponent.loadUserLocations([jobApplicationData.idUserApplicant]);
            }));

            QueueManager.add(InitHelper.getOpenPanelQueueItem('experience', 'experience'));
          }]
      }));
  }

})();
