(function () {
  'use strict';

  /* @ngdoc object
   * @name Panels.Location
   * @description
   *
   */
  angular
    .module('Map.Panels.Location', [
    ]);

  angular
    .module('Map.Panels.Location')
    .config(config);

  function config($stateProvider, Defaults) {
    $stateProvider
      .state('map.location', angular.extend({}, Defaults.mapStateParams, {
        url: '/location/{id}/detail',
        params: {zoomToLocation: true},
        onEnter: ['QueueManager', 'InitHelper', '$stateParams', '$q', '$timeout', '$rootScope', 'QueueItem',
          function (QueueManager, InitHelper, $stateParams, $q, $timeout, $rootScope, QueueItem) {
            var id = InitHelper.decryptId($stateParams.id);
            QueueManager.add(InitHelper.getOpenPanelQueueItem('location', '', {
              id: id
            }));
            QueueManager.add(new QueueItem(function (panelReady) {
              $q.when(panelReady).then(function () {
                $timeout(function () {
                  if (angular.isDefined($stateParams.zoomToLocation) && $stateParams.zoomToLocation === true) {
                    $rootScope.$broadcast('event:center-map', {
                      idLocation: id,
                      offsetX: $('#map-panel-location').width()
                    });
                  }
                });
              });

              return $q.when(true);
            }));
          }]
      }));
  }

})();
