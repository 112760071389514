(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name socialShare.provider:TwitterSocialShare
   *
   * @description
   *
   */
  angular
    .module('socialShare.providers')
    .provider('TwitterSocialShare', TwitterSocialShare);

  function TwitterSocialShare() {
    var config = {
      popupHeight: 500,
      popupWidth: 500
    };

    function setConfig (newConfig) {
      if (angular.isObject(newConfig)) {
        angular.forEach(newConfig, function (value, paramName) {
          if (angular.isDefined(config[paramName])) {
            config[paramName] = value;
          } else {
            throw 'Invalid configuration option';
          }
        });
      }
    }

    return {
      $get: ['$q', '$window', function ($q, $window) {
        var $twitter = $q.defer();

        $twitter.init = function (newConfig) {
          setConfig(newConfig);
          $twitter.resolve(true);
        };

        $twitter.share = function (data) {
          var
            url = '//twitter.com/share?url=' + encodeURIComponent(data.url),
            text = '';

          text += data.name;

          if (data.description) {
            text += ' - ' + data.description;
          }

          url += '&text=' + encodeURIComponent(text);

          if (data.source) {
            url += '&via=' + encodeURI(data.source);
          }

          if (data.hashTags) {
            url += '&hashtags=' + encodeURI(data.hashTags);
          }

          $window.open(url
            , 'sharer', 'toolbar=0,status=0,width=' + config.popupWidth + ',height=' + config.popupHeight);
        };

        return $twitter;
      }]
    };
  }
}());
