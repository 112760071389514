(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Network.controller:NetworkQuickConnectCtrl
   *
   * @description
   *
   */
  angular
    .module('Network')
    .controller('NetworkQuickConnectCtrl', NetworkQuickConnectCtrl);

  function NetworkQuickConnectCtrl($modalInstance, userProfile, NetworkManager, usSpinnerService) {
    var vm = this;
    vm.userProfile = userProfile;
    vm.message = 'I would like to connect with you on Mineler.';
    vm.isSending = false;
    vm.errorMessage = null;

    vm.close = function () {
      $modalInstance.dismiss();
    };

    vm.send = function () {
      vm.isSending = true;
      usSpinnerService.spin('spinner-network-send');
      NetworkManager.connect(vm.userProfile.id, '', '', '', vm.message, function () {
        $modalInstance.close(true);
      }, function (reason, errorCode) {
        if (angular.isDefined(errorCode) && errorCode === 205) {
          $modalInstance.close(true);
        } else {
          vm.errorMessage = reason;
          vm.isSending = false;
          usSpinnerService.stop('spinner-network-send');
        }
      });
    };

    vm.addressNotEmpty = function () {
      return (
        vm.userProfile.streetAddress ||
        vm.userProfile.suburb ||
        vm.userProfile.postCode ||
        vm.userProfile.state ||
        vm.userProfile.country
      );
    };
  }

})();
