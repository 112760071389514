(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Map.Panels.Work.controller:WorkMessagesCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Work')
    .controller('WorkMessagesCtrl', WorkMessagesCtrl);

  function WorkMessagesCtrl(WorkDetailPanelManager, $scope) {
    var vm = this;
    vm.contactProfile = $scope.contactProfile;
    vm.myProfile = $scope.userProfile.profile;
    vm.messagesParameters = $scope.messagesParameters;

    vm.closePanel = function () {
      WorkDetailPanelManager.panels.close('messagesThread');
    };
  }
}());
