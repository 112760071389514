(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name map.dashboards.service:DashboardsManager
   *
   * @description
   *
   */
  angular
    .module('Map.Dashboards')
    .service('DashboardsManager', DashboardsManager);

  function DashboardsManager($q) {
    var
      self = this,
      deferred = $q.defer(),
      dashboardParams = {};

    self.ready = deferred.promise;
    self.getParamsForMapEntity = function (mapEntity) {
      var params = {};
      if (angular.isObject(mapEntity) && angular.isDefined(mapEntity.entity)) {
        switch (mapEntity.entity) {
          case 'filter':
            params = {
              search: {
                filter: mapEntity.data,
                network: 'suggestions'
              }
            };
            break;
          case 'company':
            params = {
              entityFilter: {
                idCompany: mapEntity.data[0],
                network: 'suggestions'
              }
            };
            break;
          case 'project':
            params = {
              entityFilter: {
                idProject: mapEntity.data[0],
                network: 'suggestions'
              }
            };
            break;
          case 'user':
            params = {
              entityFilter: {
                idUser: mapEntity.data[0],
                network: 'suggestions'
              }
            };
            break;
        }
      }

      return params;
    };

    self.setParams = function (params) {
      dashboardParams = params;
    };

    self.getParams = function () {
      return dashboardParams;
    };

    /**
     * Dashboards are ready
     */
    self.setReady = function () {
      deferred.resolve();
    };
  }

})();
