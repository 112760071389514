(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Panels.People.controller:InviteCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.People')
    .controller('InviteCtrl', InviteCtrl);

  function InviteCtrl(PeopleManager, $scope, NetworkManager, usSpinnerService, $analytics, $state) {
    var vm = this;
    vm.inviteDetail = $scope.inviteDetail;
    vm.user = ($scope.inviteDetail.isMyRequest ? $scope.inviteDetail.userTo : $scope.inviteDetail.userFrom);
    vm.isSending = false;
    vm.requestMessage = 'I would like to connect with you on Mineler.';

    vm.closePanel = function (data) {
      if (!angular.isObject(data)) {
        data = {};
      }

      if (!angular.isDefined(data.remove)) {
        data.remove = false;
      }
      PeopleManager.panels.close('peopleInvite', data, function () {
        $state.go('map.people.invites');
      });
    };

    vm.resend = function () {
      vm.isSending = true;
      usSpinnerService.spin('spinner-invite-action');
      NetworkManager.resendRequest(vm.user.id, vm.requestMessage, function (result) {
        vm.closePanel({remove: false, invite: result.plain(), update: true});
        $analytics.eventTrack('Resend Invitation', {category: 'People'});
      }, function () {
        vm.closePanel({remove: false});
      });
    };

    vm.accept = function () {
      vm.isSending = true;
      usSpinnerService.spin('spinner-invite-action');
      NetworkManager.acceptRequest(vm.inviteDetail.token, function () {
        vm.closePanel({remove: true});
        $analytics.eventTrack('Accept Invitation', {category: 'People', label: 'From People Panel'});
      }, function () {
        vm.closePanel({remove: false});
      });
    };

    vm.ignore = function () {
      vm.isSending = true;
      usSpinnerService.spin('spinner-invite-action');
      NetworkManager.ignoreRequest(vm.inviteDetail.token, function () {
        vm.closePanel({remove: true});
        $analytics.eventTrack('Ignore Invitation', {category: 'People', label: 'From People Panel'});
      }, function () {
        vm.closePanel({remove: false});
      });
    };
  }

})();
