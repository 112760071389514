(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Panels.Profile.controller:ProfileEditPictureCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Profile')
    .controller('ProfileEditPictureCtrl', ProfileEditPictureCtrl);

  function ProfileEditPictureCtrl(PanelsProfileManager, $scope, Auth, usSpinnerService, $analytics) {
    var handleFileSelect;

    $scope.profilePicture = '';
    $scope.croppedProfilePicture = '';
    $scope.profilePictureLoaded = false;
    $scope.editProfileErrorMessage = null;
    $scope.isSaving = false;

    $scope.closeEditPanel = function () {
      PanelsProfileManager.panels.close('profileEditPicture');
    };

    handleFileSelect = function (evt) {
      var
        target  = evt.dataTransfer || evt.target,
        file    = target && target.files && target.files[0],
        options = {canvas:true},
        displayImg = function (img) {
          $scope.$apply(function ($scope) {
            $scope.profilePicture = img.toDataURL();
          });
        };

      loadImage.parseMetaData(file, function (data) {
        if (data.exif) {
          options.orientation = data.exif.get('Orientation');
        }
        loadImage(file, displayImg, options);
      });
    };
    angular.element(document.querySelector('#fileInput')).on('change', handleFileSelect);

    $scope.onLoadDone = function () {
      $scope.profilePictureLoaded = true;
    };

    $scope.savePicture = function () {
      if ($scope.profilePictureLoaded === true) {
        $scope.isSaving = true;
        $scope.editProfileErrorMessage = null;
        usSpinnerService.spin('spinner-profile-edit');
        $('button.map-profile-edit-button').hide();
        Auth.savePicture($scope.croppedProfilePicture, function (session) {
          $scope.profile = session.profile;
          usSpinnerService.stop('spinner-profile-edit');
          $scope.isSaving = false;
          $scope.closeEditPanel();
          $analytics.eventTrack('Update Profile Picture', {category: 'Profile'});
        }, function (error) {
          $scope.editProfileErrorMessage = error;
          usSpinnerService.stop('spinner-profile-edit');
          $scope.isSaving = false;
        });
      }
    };
  }

})();
