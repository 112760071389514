(function () {
  'use strict';

  /* @ngdoc object
   * @name Security
   * @requires $stateProvider
   *
   * @description
   *
   */
  angular
    .module('Security', [
      'Cache',
      'Security.User'
    ]);

  angular
    .module('Security')
    .config(config);

  function config() {
  }

})();
