angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/my-work/my-work-edit-updates.tpl.html',
    '<div>\n' +
    '  <div class="updates-replies-new">\n' +
    '    <div class="row">\n' +
    '      <div class="small-15 columns">\n' +
    '        <form name="updateForm" role="updateForm">\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns update-new-message-textarea-container">\n' +
    '              <div>\n' +
    '                  <textarea id="update-new-message" name="newMessage" ng-model="myWorkEdit.newReply" ng-required="true"\n' +
    '                            rows="1"  placeholder="Post new update" msd-elastic=""></textarea>\n' +
    '                <div class="text-right">\n' +
    '                  <div class="update-upload-selected-files" ng-show="myWorkEdit.selectedUpdateFiles.length">\n' +
    '                      <span ng-repeat="file in myWorkEdit.selectedUpdateFiles">{{file.name}}\n' +
    '                        <a ng-click="myWorkEdit.removeUpdateFile($index)" ng-hide="myWorkEdit.isPosting"> <i class="fa fa-close"></i></a>\n' +
    '                        <br/>\n' +
    '                      </span>\n' +
    '                  </div>\n' +
    '                  <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-my-work-edit-update"></span>\n' +
    '                    <span class="update-upload-container" ng-hide="myWorkEdit.isPosting">\n' +
    '                      <input type="file" id="updatePostFile" multiple ng-file-select="myWorkEdit.onUpdateFileSelect($files)" accept="image/*"/>\n' +
    '                      <a class="update-upload-button"><i class="fa fa-image"></i></a>\n' +
    '                    </span>\n' +
    '                  <button class="update-reply-new-button" ng-click="myWorkEdit.post()" ng-disabled=\'updateForm.$invalid\' ng-hide="myWorkEdit.isPosting">POST</button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </form>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="update-replies-list">\n' +
    '    <div class="row cursor-pointer update-row"\n' +
    '         ng-repeat="update in myWorkEdit.updatesLoader.records | activeRecord: \'map.workPlacedWork.detail-updates.detail\' : \'idUpdate\'"\n' +
    '         ng-class="{\'is-active-record\': update.isActiveRecord}"\n' +
    '         ng-click="myWorkEdit.openUpdateByIndex($index)">\n' +
    '      <div class="small-4 columns map-panel-profile-photo">\n' +
    '        <img ng-src="{{update.userCreated | profilePicture:45}}" class="circle45">\n' +
    '      </div>\n' +
    '      <div class="small-11 columns">\n' +
    '        <user-widget-name user="update.userCreated"></user-widget-name>\n' +
    '        <br/>\n' +
    '          <span class="info-text">\n' +
    '            <i class="fa fa-clock-o"></i> {{update.modified}} ago\n' +
    '            <span ng-show="update.relatedUpdatesCount">\n' +
    '              <i class="fa fa-reply-all"></i>  {{update.relatedUpdatesCount}}\n' +
    '            </span>\n' +
    '            <span ng-show="update.mediaCountTotal">\n' +
    '              <i class="fa fa-image"></i> {{update.mediaCountTotal}}\n' +
    '            </span>\n' +
    '            <span ng-show="update.connectedRecords.location">\n' +
    '              <i class="fa fa-map-marker"></i>\n' +
    '            </span>\n' +
    '          </span><br/>\n' +
    '          <span class="update-text-content">\n' +
    '            {{update.textContent | truncate: 50: \'...\': true}}\n' +
    '          </span>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row">\n' +
    '      <div class="small-15 columns text-center">\n' +
    '        <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}"\n' +
    '              spinner-key="spinner-work-edit-updates"></span>\n' +
    '        <a ng-show="myWorkEdit.updatesLoader.hasMore() && !myWorkEdit.updatesLoader.isLoading"\n' +
    '           ng-click="myWorkEdit.loadUpdates()">Load more ...</a>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
