angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('components/prompt-dialog.tpl.html',
    '<div class="prompt-dialog-content">\n' +
    '  <div class="prompt-message" ng-bind-html="promptDialog.content" ng-if="!promptDialog.isConfirmed"></div>\n' +
    '\n' +
    '  <div class="prompt-buttons" ng-if="!promptDialog.isConfirmed">\n' +
    '    <button class="button" ng-click="promptDialog.cancel()">NO</button>\n' +
    '    <button class="button positive" ng-click="promptDialog.confirm()">YES</button>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="prompt-message-success" ng-if="promptDialog.isConfirmed">\n' +
    '    {{promptDialog.onConfirmMessage}}\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
