(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Map.Panels.Profile.controller:ProfileCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Profile')
    .controller('ProfileCtrl', ProfileCtrl);

  function ProfileCtrl(PanelsManager, $scope, PanelsProfileManager, Restangular, $state, SocialAccounts, Session) {
    var vm = this,
      data = null;

    vm.profile = $scope.userProfile.profile;
    vm.result = [];

    Restangular.one('Resume').one('index').getList().then(function (result) {
      vm.resume = result;
    });

    vm.closePanel = function () {
      PanelsManager.panels.close('profile');
    };

    vm.openEditPictureForm = function () {
      $state.go('map.profile.picture');
    };

    vm.openEditNameForm = function () {
      $state.go('map.profile.name');
    };

    vm.openEditContactForm = function () {
      $state.go('map.profile.contact');
    };

    vm.openEditAboutForm = function () {
      $state.go('map.profile.about');
    };

    vm.closeEditPanels = function () {
      PanelsProfileManager.panels.closeAll();
    };

    vm.openEditPanel = function (name) {
      PanelsProfileManager.panels.open(name);
    };

    vm.addResume = function () {
      PanelsProfileManager.panels.open('addResume').then(function (newResume) {
        if (newResume) {
          vm.resume.unshift(newResume);
        }
      });
    };

    vm.updateResume = function ($index) {
      PanelsProfileManager.panels.closeAll();
      if (angular.isDefined(vm.resume[$index])) {
        data = (angular.isFunction(vm.resume[$index].plain) ? vm.resume[$index].plain() : vm.resume[$index]);

        PanelsProfileManager.panels.open('editResume', {
          resume: angular.copy(data)
        }).then(function (result) {
          if (result) {
            if (result.status === 'updated') {
              vm.resume[$index] = result.updatedResume;
            } else if (result.status === 'deleted') {
              vm.resume.splice($index, 1);
            }
          }
        });
      }
    };

    vm.toggleFacebook = function () {
      SocialAccounts.toggleConnection('facebook');
    };

    vm.toggleLinkedin = function () {
      SocialAccounts.toggleConnection('linkedin');
    };

    vm.toggleGoogleplus = function () {
      SocialAccounts.toggleConnection('google');
    };

    $scope.$on('event:panel-activated:profile', angular.bind(vm, function () {
      vm.closeEditPanels();
    }));

    $scope.$on('event:user-profileUpdated', angular.bind(vm, function (event, session) {
      vm.profile = session.profile;
    }));

    $scope.$on('event:profile-open-name', angular.bind(vm, function () {
      vm.openEditPanel('profileEditName');
    }));

    $scope.$on('event:profile-open-contact', angular.bind(vm, function () {
      vm.openEditPanel('profileEditContact');
    }));

    $scope.$on('event:profile-open-about', angular.bind(vm, function () {
      vm.openEditPanel('profileEditAbout');
    }));

    $scope.$on('event:profile-open-privacy', angular.bind(vm, function () {
      vm.openEditPanel('privacySettings');
    }));

    $scope.$on('event:profile-open-password', angular.bind(vm, function () {
      vm.openEditPanel('changePassword');
    }));

    $scope.$on('event:profile-open-picture', angular.bind(vm, function () {
      vm.openEditPanel('profileEditPicture');
    }));

    $scope.$on('event:profile-open-subscriptions', angular.bind(vm, function () {
      vm.openEditPanel('subscriptionSettings');
    }));

    $scope.$watch(function () {
      return Session.profile;
    }, function (newProfile) {
      vm.profile = newProfile;
    }, true);

  }

})();
