angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/experience/experience-request-validation.tpl.html',
    '<div id="map-panel-experience-request-action" class="map-secondary-panel"\n' +
    '     ng-controller="ExperienceRequestValidationCtrl as requestValidation" ng-swipe-left="requestValidation.closePanel()" ng-swipe-disable-mouse>\n' +
    '  <div class="row map-panel-heading">\n' +
    '    <div class="small-2 columns">\n' +
    '      <a class="arrow-back" ng-click="requestValidation.closePanel()">\n' +
    '        <i class="fa fa-angle-double-left"></i>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '    <div class="small-13 columns">\n' +
    '      <h3>Request Validation</h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="experience-detail-container">\n' +
    '    <div class="row">\n' +
    '      <div class="small-15 columns">\n' +
    '        <h3>{{requestValidation.detail.jobTitleName}}</h3>\n' +
    '        <h3>{{requestValidation.detail.projectName}}, {{requestValidation.detail.employerName}}</h3>\n' +
    '        <h4>{{requestValidation.detail.dateFrom | experienceDate}} - {{requestValidation.detail.dateTo | experienceDate}}</h4>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row request-validation-form-container">\n' +
    '      <div class="small-15 columns">\n' +
    '        <form name="requestValidationForm" role="requestValidationForm">\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns text-center">\n' +
    '              To validate this experience, select someone from your network\n' +
    '                <angucomplete-alt\n' +
    '                  id="request-validation-input"\n' +
    '                  placeholder="Search"\n' +
    '                  pause="100"\n' +
    '                  minlength="2"\n' +
    '                  remote-url="{{requestValidation.searchUrl}}"\n' +
    '                  title-field="firstName,lastName"\n' +
    '                  image-field="profilePicture"\n' +
    '                  remote-url-response-formatter="requestValidation.onSearchResponse"\n' +
    '                  text-searching="SEARCHING ..."\n' +
    '                  selected-object="requestValidation.onSearchSelect"\n' +
    '                  match-class="text-orange"\n' +
    '                  focus-in="requestValidation.onFocusIn()"\n' +
    '                  text-no-results=" "\n' +
    '                  override-suggestions="true"\n' +
    '                  />\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns text-center">\n' +
    '              or send a request directly via email\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <input type="text" name="firstName" ng-model="requestValidation.data.firstName" placeholder="First name" />\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <input type="text" name="lastName" ng-model="requestValidation.data.lastName" placeholder="Last name" />\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <input name="email" type="email" ng-model="requestValidation.data.email" placeholder="Email Address" />\n' +
    '              <small class="request-validation-error" ng-show="requestValidationForm.email.$dirty && requestValidationForm.email.$error.email">\n' +
    '                This is not a valid email address\n' +
    '              </small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <textarea name="message" ng-required="true" ng-model="requestValidation.data.message" placeholder="Message"\n' +
    '                        msd-elastic></textarea>\n' +
    '              <small class="request-validation-error" ng-show="requestValidationForm.message.$dirty && requestValidationForm.message.$error.required">\n' +
    '                Missing request message\n' +
    '              </small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row request-validation-form-buttons">\n' +
    '            <div class="small-10 columns">\n' +
    '              <small class="request-validation-error" ng-show="requestValidation.errorMessage">{{requestValidation.errorMessage}}</small>\n' +
    '            </div>\n' +
    '            <div class="small-5 columns text-right">\n' +
    '              <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-request-validation"></span>\n' +
    '              <button ng-hide="requestValidation.isSending" class="button positive" ng-disabled="requestValidationForm.$invalid" ng-click="requestValidation.send()">SEND</button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </form>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
