(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name widgets.service:widgets
   *
   * @description
   *
   */
  angular
    .module('widgets')
    .service('WidgetsHelper', WidgetsHelper);

  function WidgetsHelper($window, Restangular) {
    var self = this;

    self.authorise = function (apiKey) {
      Restangular.one('Users').customPOST({
        apiKey: apiKey
      }, 'authoriseApp').then(function (result) {
        if (angular.isDefined($window.opener) && angular.isFunction($window.opener.minelerHelperAuthCallback)) {
          $window.opener.minelerHelperAuthCallback({authorised: true, accessToken: result.authToken});
        }
      }, function () {
        $window.opener.minelerHelperAuthCallback({authorised: false});
      });
    };
  }

})();
