angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/toolbars/menu.tpl.html',
    '<div ng-controller="MenuCtrl as menu">\n' +
    '  <div id="map-menu" class="map-toolbar">\n' +
    '    <a ng-click="menu.toggle()">\n' +
    '      <span id="map-menu-name">\n' +
    '        <i class="fa fa-chevron-down"></i> {{ menu.profile.displayName }}\n' +
    '      </span>\n' +
    '      <span id="map-menu-image">\n' +
    '        <img ng-src="{{menu.profile | profilePicture:30}}" class="circle30" />\n' +
    '      </span>\n' +
    '    </a>\n' +
    '  </div>\n' +
    '  <div id="map-menu-items" ng-show="menu.isOpen">\n' +
    '    <div class="map-menu-item">\n' +
    '      <a ng-click="menu.openIntro()">HOW TO USE MINELER <i class="fa fa-info pull-right"></i></a>\n' +
    '    </div>\n' +
    '    <div class="map-menu-item">\n' +
    '      <a  ng-click="menu.openProfile()" ui-sref="map.profile">PROFILE <i class="fa fa-user pull-right"></i></a>\n' +
    '    </div>\n' +
    '    <div class="map-menu-item">\n' +
    '      <a ng-click="menu.openMessages()" ui-sref="map.messages">MESSAGES <i class="fa fa-comments pull-right"></i></a>\n' +
    '    </div>\n' +
    '    <div class="map-menu-item">\n' +
    '      <a ng-click="menu.openMyWork()">WORK <i class="fa fa-trophy pull-right"></i></a>\n' +
    '    </div>\n' +
    '    <div class="map-menu-item">\n' +
    '      <a ng-click="menu.openFeedback()">CONTACT MINELER <i class="fa fa-bullhorn pull-right"></i></a>\n' +
    '    </div>\n' +
    '    <div class="map-menu-item">\n' +
    '      <a ng-click="menu.signOut()">SIGN OUT <i class="fa fa-ban pull-right"></i></a>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '');
}]);
