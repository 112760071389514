angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/experience/experience-validation-detail.tpl.html',
    '<div id="map-panel-experience-validation-detail-action" class="map-secondary-panel"\n' +
    '     ng-controller="ExperienceValidationDetailCtrl as validationDetail" ng-swipe-left="validationDetail.closePanel()"\n' +
    '     ng-swipe-disable-mouse>\n' +
    '  <div class="row map-panel-heading">\n' +
    '    <div class="small-2 columns">\n' +
    '      <a class="arrow-back" ng-click="validationDetail.closePanel()">\n' +
    '        <i class="fa fa-angle-double-left"></i>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-if="validationDetail.isReceivedRequest" class="small-13 columns map-panel-heading-experience-name">\n' +
    '      <h3>\n' +
    '        <user-widget-name user="validationDetail.detail.experience.user"></user-widget-name>\n' +
    '      </h3>\n' +
    '    </div>\n' +
    '    <div ng-if="!validationDetail.isReceivedRequest" class="small-13 columns map-panel-heading-experience-name">\n' +
    '      <h3>\n' +
    '        <user-widget-name user="validationDetail.detail.userValidatedBy"></user-widget-name>\n' +
    '      </h3>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '  <div class="experience-detail-container">\n' +
    '    <div class="row">\n' +
    '      <div ng-if="validationDetail.isReceivedRequest" class="small-15 columns">\n' +
    '        <user-widget image-class="circle45" user="validationDetail.detail.experience.user" width="45"\n' +
    '                     height="45"></user-widget>\n' +
    '      </div>\n' +
    '      <div ng-if="!validationDetail.isReceivedRequest" class="small-15 columns">\n' +
    '        <user-widget image-class="circle45" user="validationDetail.detail.userValidatedBy" width="45"\n' +
    '                     height="45"></user-widget>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="row">\n' +
    '      <div class="small-15 columns">\n' +
    '        <h3>{{validationDetail.detail.experience.jobTitleName}}</h3>\n' +
    '\n' +
    '        <h3>{{validationDetail.detail.experience.projectName}}, {{validationDetail.detail.experience.employerName}}</h3>\n' +
    '        <h4>{{validationDetail.detail.experience.dateFrom | experienceDate}} -\n' +
    '          {{validationDetail.detail.experience.dateTo | experienceDate}}</h4>\n' +
    '\n' +
    '        <div>\n' +
    '          {{validationDetail.detail.experience.description}}\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row experience-detail-skill" ng-show="validationDetail.detail.experience.skills.length">\n' +
    '      <div class="small-15 columns">\n' +
    '        <h3>Skills</h3>\n' +
    '\n' +
    '        <div ng-repeat="skill in validationDetail.detail.experience.skills">{{skill.name}}</div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row" ng-if="validationDetail.isReceivedRequest">\n' +
    '      <div class="small-15 columns">\n' +
    '        <form name="validationProcessForm" role="validationProcessForm">\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <div class="validation-request-message">{{validationDetail.detail.requestMessage}}</div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <textarea name="validationMessage" ng-model="validationDetail.validationMessage" ng-required="false"\n' +
    '                        placeholder="You could also provide additional comments to help {{validationDetail.detail.experience.user.firstName}} build a better profile"\n' +
    '                        msd-elastic></textarea>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns text-right">\n' +
    '              <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-validation-action"></span>\n' +
    '              <button ng-hide="validationDetail.isSending" class="button" ng-click="validationDetail.ignore()">IGNORE</button>\n' +
    '              <button ng-hide="validationDetail.isSending" class="button positive" ng-click="validationDetail.validate()">Validate</button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </form>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row" ng-if="!validationDetail.isReceivedRequest">\n' +
    '      <div class="small-15 columns">\n' +
    '        <form name="validationResendForm" role="validationResendForm">\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <textarea name="requestMessage" ng-model="validationDetail.requestMessage" ng-required="true"\n' +
    '                        placeholder="Request message" msd-elastic></textarea>\n' +
    '              <small class="invite-resend-error" ng-show="validationResendForm.requestMessage.$dirty && validationResendForm.requestMessage.$error.required">\n' +
    '                Missing request message\n' +
    '              </small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns text-right">\n' +
    '              <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-validation-action"></span>\n' +
    '              <button ng-hide="validationDetail.isSending" class="button positive" ng-click="validationDetail.resend()"\n' +
    '                      ng-disabled="validationResendForm.$invalid">RE-SEND\n' +
    '              </button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <span class="info-text">\n' +
    '                <i class="fa fa-clock-o"></i> {{validationDetail.detail.lastSent}} ago\n' +
    '              </span>\n' +
    '              <br/>\n' +
    '              {{validationDetail.detail.requestMessage}}\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </form>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
