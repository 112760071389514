(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name directives.directive:companyWidget
   * @restrict EA
   * @element
   *
   * @description Generates a link with company name from experience data,
   * when clicked shows pins for company and set company name into search bar
   *
   * @example
     <example module="directives">
       <file name="index.html">
        <company-widget></company-widget>
       </file>
     </example>
   *
   */
  angular
    .module('directives')
    .directive('companyWidget', companyWidget);

  function companyWidget() {
    return {
      restrict: 'EA',
      scope: {
        experience: '=experience'
      },
      template: '<a ng-click="companyWidget.showLocations()" title="Show locations">{{companyWidget.companyName}}</a>',
      replace: true,
      controllerAs: 'companyWidget',
      controller: function ($scope, MapComponent) {
        var vm = this;
        vm.companyName = $scope.experience.employerName;
        vm.showLocations = function () {
          MapComponent.loadCompanyLocations($scope.experience.employer);
          $('#map-search-input_value').val($scope.experience.employerName);
        };
      }
    };
  }
}());
