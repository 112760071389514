(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Panels.Experience.controller:ExperienceDetailCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Experience')
    .controller('ExperienceValidationDetailCtrl', ExperienceDetailCtrl);

  function ExperienceDetailCtrl(ExperiencePanelManager, $scope, ExperienceManager,
                                PromptDialog, $analytics, $state, usSpinnerService) {
    var vm = this;
    vm.detail = $scope.detail;
    vm.isReceivedRequest = $scope.detail.isReceivedRequest;
    vm.requestMessage = $scope.detail.requestMessage;
    vm.isSending = false;
    vm.validationMessage = null;

    vm.closePanel = function (result) {
      ExperiencePanelManager.panels.close('validationDetail', result, function () {
        $state.go('map.experienceValidations');
      });
    };

    vm.ignore = function () {
      vm.isSending = true;
      usSpinnerService.spin('spinner-validation-action');
      ExperienceManager.ignore(vm.detail.token, vm.validationMessage, function () {
        vm.closePanel({action: 'processed'});
      }, function (reason) {
        vm.isSending = false;
        vm.errorMessage = reason;
        usSpinnerService.stop('spinner-validation-action');
      });
    };

    vm.validate = function () {
      vm.isSending = true;
      usSpinnerService.spin('spinner-validation-action');
      ExperienceManager.validate(vm.detail.token, vm.validationMessage, function () {
        vm.closePanel({action: 'processed'});
      }, function (reason) {
        vm.isSending = false;
        vm.errorMessage = reason;
        usSpinnerService.stop('spinner-validation-action');
      });
    };

    vm.resend = function () {
      vm.isSending = true;
      usSpinnerService.spin('spinner-validation-action');
      ExperienceManager.resend(vm.detail.id, vm.requestMessage, function (result) {
        vm.closePanel({
          action: 'resent',
          validation: result
        });
      }, function (reason) {
        vm.isSending = false;
        vm.errorMessage = reason;
        usSpinnerService.stop('spinner-validation-action');
      });
    };
  }

})();
