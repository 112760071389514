(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Security.service:Auth
   *
   * @description
   *
   */
  angular
    .module('Security')
    .service('Auth', Auth);

  function Auth($rootScope, $q, Session, Cache, Restangular, $modal, Defaults) {
    var
      self = this,
      modalInstance = null;

    self.cache = Cache.create('security');

    self.initMissingDataDialog = function () {
      if (!angular.isObject(modalInstance)) {
        modalInstance = $modal.open({
          templateUrl: 'security/user/missing-data.tpl.html',
          controller: 'MissingDataCtrl as missingData',
          windowClass: 'missing-data-dialog',
          keyboard: false,
          backdrop: 'static',
          resolve: {
            userProfile: ['Auth', function (Auth) {
              return Auth.getSession();
            }]
          }
        });

        modalInstance.result.then(function (userProfile) {
          modalInstance = null;
          $rootScope.$broadcast('event:user-profileMissingDataSet', userProfile);
          $rootScope.$broadcast('event:open-intro');
        }, function () {
          modalInstance = null;
          $rootScope.$broadcast('event:user-profileMissingData');
        });
      }
    };

    self.signUp = function (signupData, successCallback, errorCallback) {
      signupData.isWebApp = true;
      self.login('signup', signupData, successCallback, errorCallback);
    };

    self.loginByData = function (data) {
      self.saveLoginToken(data.authToken);
      Session.create(data.profile.idUser, data.profile, 'user');
      $rootScope.$broadcast('event:auth-loginConfirmed');
    };

    self.login = function (endPoint, data, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;
      Restangular.one('Users').post(endPoint, data)
        .then(function (result) {
          self.loginByData(result);
          successCallback(result);
        }, function (result) {
          $rootScope.$broadcast('event:auth-loginFailed');
          // TODO: replace with function loading message base on error code
          var reason = result.data.errorMessage;
          errorCallback(reason);
        });
    };

    self.passwordLogin = function (username, password, successCallback, errorCallback) {
      self.login('signin', {
        username: username,
        password: password
      }, successCallback, errorCallback);
    };

    self.socialLogin = function (provider, accessToken, isSignupAsCompany, preRegistrationToken,
                                 successCallback, errorCallback) {
      var data = {
        oauth2: {
          oauth2AccessToken: accessToken,
          provider: provider
        },
        isWebApp: true
      };
      if (angular.isDefined(preRegistrationToken)) {
        data.preregistrationToken = preRegistrationToken;
      }

      if (angular.isDefined(isSignupAsCompany)) {
        data.isSignupAsCompany = isSignupAsCompany;
      }
      self.login('OAuth2AccessTokenSignin', data, successCallback, errorCallback);
    };

    self.logout = function (serverLogout) {
      if (angular.isUndefined(serverLogout)) {
        serverLogout = true;
      }

      if (serverLogout) {
        Restangular.one('Users').one('signout').post().then(function () {
          self.deleteLoginToken();
          Session.destroy();
          self.cache.removeAll();
          $rootScope.$broadcast('event:auth-logoutSuccess');
        });
      } else {
        self.deleteLoginToken();
        Session.destroy();
        self.cache.removeAll();
        $rootScope.$broadcast('event:auth-logoutSuccess');
      }
    };

    self.isAuthenticated = function () {
      return !!Session.idUser;
    };

    self.isAuthorised = function (authorisedRoles) {
      if (!angular.isArray(authorisedRoles)) {
        authorisedRoles = [authorisedRoles];
      }

      return (self.isAuthenticated() && -1 !== authorisedRoles.indexOf(Session.userRole));
    };

    self.deleteLoginToken = function () {
      self.cache.remove('loginToken');
    };

    self.saveLoginToken = function (loginToken) {
      self.cache.put('loginToken', loginToken);
    };

    self.getLoginToken = function () {
      return self.cache.get('loginToken');
    };

    self.loadSession = function () {
      return Restangular.one('Users').one('check').get().then(function (result) {
        if (result.status === true) {
          return Restangular.one('Users').one('profile').get();
        }

        return null;
      })
        .then(function (userProfile) {
          if (userProfile !== null) {
            Session.create(userProfile.idUser, userProfile.plain(), 'user');
          }
          return Session;
        });
    };

    self.getSession = function () {
      if (!self.isAuthenticated()) {
        return self.loadSession();
      }

      return $q.when(Session);
    };

    self.saveSession = function (profile, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;
      return Restangular.one('Users').customPUT(profile, 'profile', '').then(function (userProfile) {
        Session.create(userProfile.idUser, userProfile.plain(), 'user');
        $rootScope.$broadcast('event:user-profileUpdated', Session);
        successCallback(Session);
      }, function (result) {
        var reason = result.data.errorMessage;
        errorCallback(reason);
      });
    };

    self.savePicture = function (pictureData, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;
      return Restangular.one('Users').customPUT({
        data: pictureData
      }, 'photoBase64', '').then(function (result) {
        $rootScope.$broadcast('event:user-profilePictureUpdated', result.userImage);
        Session.profile.profilePictureToken = result.userImage;
        successCallback(Session);
      }, function (result) {
        var reason = result.data.errorMessage;
        errorCallback(reason);
      });
    };

    self.updatePassword = function (data, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;
      return Restangular.one('Users').customPUT(data, 'password', '').then(function (result) {
        self.saveLoginToken(result.authToken);
        successCallback();
      }, function (result) {
        var reason = result.data.errorMessage;
        errorCallback(reason);
      });
    };

    self.getSecurityParamsForUrl = function () {
      return {
        'X-Jwt-Assertion': self.getLoginToken(),
        'X-Ff3-Api-Key': Defaults.apiKey
      };
    };

    self.disconnectSocialService = function (provider, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;
      Restangular.one('Users').post('socialAccountUnlink', {
        provider: provider
      })
        .then(function () {
          switch (provider) {
            case 'facebook':
              Session.profile.isFacebook = false;
              Session.profile.idUserFacebook = null;
              break;
            case 'googleplus':
              Session.profile.isGoogleplus = false;
              Session.profile.idUserGoogleplus = null;
              break;
            case 'linkedin':
              Session.profile.isLinkedin = false;
              Session.profile.idUserLinkedin = null;
              break;
          }
          successCallback();
        }, function (result) {
          errorCallback(result.data.errorMessage);
        });
    };

    self.connectSocialService = function (provider, accessToken, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;
      var data = {
        oauth2AccessToken: accessToken,
        provider: provider
      };
      Restangular.one('Users').post('OAuth2AccessTokenAccountLink', data)
        .then(function (result) {
          Session.profile = result.plain();
          successCallback();
        }, function (result) {
          errorCallback(result.data.errorMessage);
        });
    };
  }

})();
