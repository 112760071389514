(function () {
  'use strict';

  /* @ngdoc object
   * @name Map.Panels.Messages
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Messages', [
    ]);

  angular
    .module('Map.Panels.Messages')
    .config(config);

  function config($stateProvider, Defaults) {
    $stateProvider
      .state('map.messages', angular.extend({}, Defaults.mapStateParams, {
        url: '/messages',
        onEnter: ['QueueManager', 'InitHelper', function (QueueManager, InitHelper) {
          QueueManager.add(InitHelper.getOpenPanelQueueItem('messages'));
        }]
      }))
      .state('map.messages.detail', angular.extend({}, Defaults.mapStateParams, {
        url: '/{id}/detail',
        onEnter: ['QueueManager', 'InitHelper', 'QueueItem', '$q', '$timeout', '$rootScope', '$stateParams',
          function (QueueManager, InitHelper, QueueItem, $q, $timeout, $rootScope, $stateParams) {
            QueueManager.add(new QueueItem(function (panelReady) {
              $q.when(panelReady).then(function () {
                $timeout(function () {
                  $rootScope.$broadcast('event:messages-open-detail', {
                    id: InitHelper.decryptId($stateParams.id)
                  });
                }, 100);
              });

              return $q.when(true);
            }));
          }]
      }));
  }

})();
