(function () {
  'use strict';

  /* @ngdoc object
   * @name Map.Toolbars.Search
   *
   * @description
   *
   */
  angular
    .module('Map.Toolbars.Search', [
      'angucomplete-alt',
      'ngTagsInput',
      'Network',
      'Map.Panels'
    ]);

  angular
    .module('Map.Toolbars.Search')
    .config(config);

  function config($stateProvider, Defaults) {
    $stateProvider
      .state('map.search', angular.extend({}, Defaults.mapStateParams, {
        url: '/search/{query}',
        onEnter: ['$q', '$stateParams', 'QueueManager', 'QueueItem', '$rootScope', 'SearchManager',
          function ($q, $stateParams, QueueManager, QueueItem, $rootScope, SearchManager) {
            QueueManager.add(new QueueItem(function () {
              SearchManager.ready.then(function () {
                $rootScope.$broadcast('event:map-search', {
                  query: $stateParams.query
                });
              });
              return $q.when(true);
            }));
          }]
      }));
  }

})();
