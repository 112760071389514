(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name Toolbars.Search.filter:searchFilterPreview
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('Map.Toolbars.Search')
    .filter('searchFilterPreview', searchFilterPreview);

  function searchFilterPreview() {
    return function (input) {
      var output = '';
      if (angular.isArray(input) && input.length > 0) {
        output = input[0].name;
        if (input.length > 1) {
          output += ' + ' + (input.length - 1) + '...';
        }
      }
      return output;
    };
  }

})();
