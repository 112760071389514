(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Map.Panels.controller:WorkCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Work')
    .controller('WorkCtrl', WorkCtrl);

  function WorkCtrl(PanelsManager, WorkPanelManager, $scope, $analytics, RecordsSet, $state, Restangular, InitHelper,
                    $timeout, $rootScope) {
    var
      vm = this,
      params,
      workTabLoaded = false,
      myBidsTabLoaded = false,
      placedWorkTabLoaded = false;
    vm.tab = '';

    if (angular.isObject($scope.params)) {
      params = $scope.params;
    }

    vm.workLoader = RecordsSet.getLoader('Work', 'index', params);

    function openWorkTab() {
      if (workTabLoaded === false) {
        vm.workLoader.load();
        workTabLoaded = true;
        $analytics.eventTrack('Work Tab', {category: 'Work'});
      }
    }

    function openMyBidsTab() {
      if (myBidsTabLoaded === false) {
        myBidsTabLoaded = true;
        $analytics.eventTrack('My Bids Tab', {category: 'Work'});
      }
    }

    function openPlacedWorkTab() {
      if (placedWorkTabLoaded === false) {
        placedWorkTabLoaded = true;
        $analytics.eventTrack('Placed Work Tab', {category: 'Work'});
      }
    }

    vm.openTab = function (tab) {
      WorkPanelManager.panels.closeAll(angular.noop);
      if (tab === vm.tab) {
        return;
      }
      vm.tab = tab;
      switch (tab) {
        case 'work':
          openWorkTab();
          break;
        case 'myBids':
          openMyBidsTab();
          break;
        case 'placedWork':
          openPlacedWorkTab();
          break;
      }
    };

    vm.loadMore = function () {
      vm.workLoader.load();
      $analytics.eventTrack('Load More', {category: 'Work'});
    };

    vm.closePanel = function () {
      PanelsManager.panels.close('work');
    };

    vm.openDetailById = function (id, deferred) {
      var $index = _.findIndex(vm.workLoader.records, {id: id});
      if ($index !== -1) {
        vm.openDetail(vm.workLoader.records[$index], deferred);
      } else {
        Restangular.one('Work').one('index').get({id: id})
          .then(function (result) {
            vm.openDetail(result.plain(), deferred);
          });
      }
    };

    vm.openDetailByIndex = function ($index) {
      $state.go('map.work.detail', {
        id: InitHelper.encryptId(vm.workLoader.records[$index].id)
      });
    };

    vm.openDetail = function (work, deferred) {
      WorkPanelManager.panels.open('detail', {
        detail: work
      });

      if (angular.isDefined(deferred)) {
        deferred.resolve(true);
      }

      if (angular.isDefined(work.project.location) &&
        angular.isObject(work.project.location)) {
        $timeout(function () {
          $rootScope.$broadcast('event:center-map', {
            idLocation: work.project.location.id,
            offsetX: $('#map-panel-work').width()
          });
        }, 100);
      }

      $analytics.eventTrack('Open Work Detail', {category: 'Work'});
    };

    if (angular.isDefined($scope.tab) && $scope.tab !== '') {
      vm.openTab($scope.tab);
    } else {
      vm.openTab('work');
    }

    if (angular.isDefined($scope.onOpen) && angular.isFunction($scope.onOpen)) {
      $scope.onOpen();
    }

    $scope.$on('event:panel-activated:work', function ($event, data) {
      if (angular.isObject(data.params.params) && !angular.equals(params, data.params.params)) {
        params = data.params.params;
        vm.workLoader.reset(params);
        vm.workLoader.load(0);
      }

      if (angular.isDefined(data.params) && data.newPanel === true) {
        if (angular.isDefined(data.params.onOpen) && angular.isFunction(data.params.onOpen)) {
          data.params.onOpen();
        }
      }
    });

    $scope.$on('event:work-open-tab:work', function () {
      vm.openTab('work');
    });

    $scope.$on('event:work-open-tab:myBids', function () {
      vm.openTab('myBids');
    });

    $scope.$on('event:work-open-tab:placedWork', function () {
      vm.openTab('placedWork');
    });

    $scope.$on('event:work-open-detail', function ($event, data) {
      vm.openDetailById(data.id, data.deferred);
    });
  }

})();
