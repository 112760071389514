(function () {
  'use strict';

  /* @ngdoc object
   * @name directives
   * @description
   *
   */
  angular
    .module('directives', [
    ]);
}());
