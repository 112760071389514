(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Map.Panels.controller:PeopleCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.People')
    .controller('PeopleCtrl', PeopleCtrl);

  function PeopleCtrl(PanelsManager, $scope, NetworkManager, PeopleManager, Restangular, $analytics, $state,
                      RecordsSet, InitHelper) {
    var vm = this;
    vm.tab = '';
    vm.peopleTabLoaded = false;
    vm.suggestionsTabLoaded = false;
    vm.invitesTabLoaded = false;
    vm.invites = [];
    vm.params = {};

    if (angular.isObject($scope.params)) {
      vm.params = $scope.params;
    }

    function getParams (contactType) {
      var params = angular.copy(vm.params);
      if (angular.isDefined(params.search)) {
        params.search.network = contactType;
      } else if (angular.isDefined(params.entityFilter)) {
        params.entityFilter.network = contactType;
      } else {
        params.search = {
          network: contactType
        };
      }

      return params;
    }

    vm.peopleLoader = RecordsSet.getLoader('Users', 'index', getParams('network'));
    vm.suggestionsLoader = RecordsSet.getLoader('Users', 'index', getParams('suggestions'));

    vm.loadMorePeople = function () {
      vm.peopleLoader.load();
      $analytics.eventTrack('Load More People', {category: 'People'});
    };

    vm.openPeopleTab = function () {
      if (vm.peopleTabLoaded === false) {
        vm.peopleLoader.load();
        vm.peopleTabLoaded = true;
        $analytics.eventTrack('People Tab', {category: 'People'});
      }
    };

    vm.loadMoreSuggestions = function () {
      vm.suggestionsLoader.load();
      $analytics.eventTrack('Load More Suggestions', {category: 'People'});
    };

    vm.openSuggestionsTab = function () {
      if (vm.suggestionsTabLoaded === false) {
        vm.suggestionsLoader.load();
        vm.suggestionsTabLoaded = true;
        $analytics.eventTrack('Suggestions Tab', {category: 'People'});
      }
    };

    vm.openInvitesTab = function () {
      if (vm.invitesTabLoaded === false) {
        NetworkManager.getInvitations()
          .then(function (result) {
            vm.invites = result.plain();
          });
        vm.invitesTabLoaded = true;
        $analytics.eventTrack('Invites Tab', {category: 'People'});
      }
    };

    vm.openTab = function (tab) {
      PeopleManager.panels.closeAll(angular.noop);
      if (tab === vm.tab) {
        return;
      }
      vm.tab = tab;
      switch (tab) {
        case 'people':
          vm.openPeopleTab();
          break;
        case 'suggestions':
          vm.openSuggestionsTab();
          break;
        case 'invites':
          vm.openInvitesTab();
          break;
      }
    };

    if (angular.isDefined($scope.tab) && $scope.tab !== '') {
      vm.openTab($scope.tab);
    } else {
      vm.openTab('people');
    }

    if (angular.isDefined($scope.onOpen) && angular.isFunction($scope.onOpen)) {
      $scope.onOpen();
    }

    vm.closePanel = function () {
      PanelsManager.panels.close('people');
    };

    vm.inviteToJoin = function () {
      PeopleManager.panels.open('inviteToJoin')
        .then(function (result) {
          if (angular.isObject(result)) {
            if (result.isSent === true) {
              if (angular.isObject(result.invite)) {
                vm.invites.unshift(result.invite);
              }
            }
          }
        });
    };

    vm.openInvite = function (invite) {
      PeopleManager.panels.open('peopleInvite', {
        inviteDetail: invite
      })
        .then(function (result) {
          var $index;
          if (angular.isObject(result)) {
            if (result.remove === true) {
              $index = _.findIndex(vm.invites, invite);
              if ($index !== -1) {
                vm.invites.splice($index, 1);
              }
            } else {
              if (angular.isDefined(result.update) && result.update === true) {
                $index = _.findIndex(vm.invites, invite);
                if ($index !== -1) {
                  vm.invites[$index] = result.invite;
                }
              }
            }
          }
        });
      $analytics.eventTrack('Open Invitation', {category: 'People'});
    };

    vm.openProfile = function (userProfile, callback) {
      PeopleManager.panels.open('peopleDetail', {
        user: userProfile
      })
        .then(callback);
      $analytics.eventTrack('Open User Profile', {category: 'People'});
    };

    vm.openSuggestion = function ($index) {
      vm.openProfile(vm.suggestionsLoader.records[$index], function (result) {
        if (angular.isObject(result)) {
          if (result.inviteSent === true) {
            vm.suggestionsLoader.records.splice($index, 1);
            if (angular.isObject(result.invite)) {
              vm.invites.unshift(result.invite);
            }
          }
        }
      });
    };

    vm.getSuggestionTitle = function ($index) {
      if (vm.suggestionsLoader.records[$index].profileRelation === 'unconfirmed_contact') {
        return 'Invite already sent';
      }
      return 'Click to open and send an invite';
    };

    vm.openContactById = function (id) {
      var $index = _.findIndex(vm.peopleLoader.records, {id: id});
      if ($index !== -1) {
        vm.openContact(vm.peopleLoader.records[$index]);
      } else {
        Restangular.one('Users').one('index').get({id: id}).then(function (result) {
          vm.openContact(result.plain());
        });
      }
    };

    vm.openContactByIndex = function ($index) {
      $state.go('map.people.detail', {
        id: InitHelper.encryptId(vm.peopleLoader.records[$index].id)
      });
    };

    vm.openContact = function (contact) {
      vm.openProfile(contact, function (result) {
        if (angular.isObject(result)) {
          if (result.removeContact === true) {
            var $index = _.findIndex(vm.peopleLoader.records, {id: contact.id});
            if ($index !== -1) {
              vm.peopleLoader.records.splice($index, 1);
            }
          }
        }
      });
    };

    $scope.$on('event:panel-activated:people', function ($event, data) {
      if (angular.isObject(data.params.params) && !angular.equals(vm.params, data.params.params)) {
        vm.params = data.params.params;
        vm.peopleTabLoaded = false;
        vm.suggestionsTabLoaded = false;
        vm.tab = '';
        vm.peopleLoader.reset(getParams('network'));
        vm.suggestionsLoader.reset(getParams('suggestions'));
      }

      if (angular.isDefined(data.params) && data.newPanel === true) {
        if (angular.isDefined(data.params.tab) && data.params.tab !== '') {
          vm.openTab(data.params.tab);
        }
        if (angular.isDefined(data.params.onOpen) && angular.isFunction(data.params.onOpen)) {
          data.params.onOpen();
        }
      }
    });

    $scope.$on('event:people-open-tab:people', function () {
      vm.openTab('people');
    });

    $scope.$on('event:people-open-tab:suggestions', function () {
      vm.openTab('suggestions');
    });

    $scope.$on('event:people-open-tab:invites', function () {
      vm.openTab('invites');
    });

    $scope.$on('event:people-network-open-detail', function ($event, data) {
      vm.openContactById(data.id);
    });

    $scope.$on('event:people-network-invite', function () {
      vm.inviteToJoin();
    });
  }

})();
