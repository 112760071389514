(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Components.service:RecordsSet
   *
   * @description Provides generic way to load records from the server and post new ones
   *
   */
  angular
    .module('Components')
    .service('RecordsSet', RecordsSet);

  /**
   * RecordsSet service
   * @param Restangular
   * @param $q
   * @constructor
   */
  function RecordsSet(Restangular, $q) {
    var self = this;

    /**
     * Creates a new instance of records loader
     * @param entity
     * @param action
     * @param params
     * @constructor
     */
    function RecordsSetManager(entity, action, params) {
      var
        self = this,
        loadingEnabled = true,
        totalLoaded = 0,
        queryParams = {
          limit: 10
        };
      self.records = [];
      self.isLoading = false;

      if (angular.isObject(params)) {
        queryParams = angular.extend(queryParams, params);
      }

      if (!angular.isDefined(action) || action.trim() === '') {
        action = 'index';
      }

      /**
       * Load records, support for loading additional records
       * @returns {Document.promise|*|k.promise|{then, catch, finally}}
       */
      self.load = function () {
        var deferred = $q.defer();
        if (self.hasMore()) {
          self.isLoading = true;
          Restangular.one(entity).getList(action + '?' + $.param(angular.extend(queryParams, {
              offset: totalLoaded
            }))).then(function (result) {
            angular.forEach(result, function (value) {
              self.records.push(value.plain());
            });
            totalLoaded += result.meta.selectedRecords;
            if (result.meta.totalRecords <= totalLoaded) {
              loadingEnabled = false;
            }
            self.isLoading = false;
            deferred.resolve(result);
          }, function (result) {
            deferred.reject(result.data.errorMessage);
          });
        } else {
          deferred.resolve([]);
        }

        return deferred.promise;
      };

      /**
       * Creates a new record
       *
       * @param {object} data Data to be sent to server
       * @param {object} queryParams Additional query params, if not provided then params used to initialise loader will be used
       * @param {string} appendTo One of 'beginning' or 'end', indicates the position of new record within existing records, if not provided then the record won't be inserted into the list
       * @returns {Document.promise|*|k.promise|{then, catch, finally}}
       */
      self.post = function (data, queryParams, appendTo) {
        var
          deferred = $q.defer(),
          qParams = params;
        if (angular.isDefined(queryParams) && angular.isObject(queryParams)) {
          qParams = queryParams;
        }

        Restangular.one(entity).customPOST(data, action, qParams)
          .then(function (result) {
            if (appendTo === 'beginning') {
              self.records.unshift(result.plain());
              totalLoaded += 1;
            } else if (appendTo === 'end') {
              self.records.push(result.plain());
              totalLoaded += 1;
            }
            deferred.resolve(result.plain());
          }, function (result) {
            deferred.reject(result.data.errorMessage);
          });

        return deferred.promise;
      };

      /**
       * Check whether there is more records to load
       * @returns {boolean}
       */
      self.hasMore = function () {
        return loadingEnabled;
      };

      /**
       * Resets the loader, removes already loaded records from the list, resets counter
       *
       * @param {object} params New params for the loader, overrides initial params from loader initialisation
       */
      self.reset = function (params) {
        self.records = [];
        totalLoaded = 0;
        loadingEnabled = true;
        queryParams = {
          limit: 10
        };
        if (angular.isObject(params)) {
          queryParams = angular.extend(queryParams, params);
        }
      };
    }

    /**
     * Creates a new instance of records loader
     * @param {string} entity Identifies entity within API endpoint /{Entity}/{action}
     * @param {string} action Identifies action within entity in API endpoint
     * @param {object} params
     * @returns {RecordsSetManager}
     */
    self.getLoader = function (entity, action, params) {
      return new RecordsSetManager(entity, action, params);
    };

  }

})();
