angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/my-work/my-work-edit.tpl.html',
    '<div ng-controller="MyWorkEditCtrl as myWorkEdit" class="map-panel-controller">\n' +
    '  <div id="map-panel-my-work-edit-action" class="map-secondary-panel" ng-swipe-left="myWorkEdit.closePanel()"\n' +
    '       ng-swipe-disable-mouse>\n' +
    '    <div class="row map-panel-heading">\n' +
    '      <div class="small-2 columns">\n' +
    '        <a class="arrow-back" ng-click="myWorkEdit.closePanel()">\n' +
    '          <i class="fa fa-angle-double-left"></i>\n' +
    '        </a>\n' +
    '      </div>\n' +
    '      <div class="small-13 columns">\n' +
    '        <ul>\n' +
    '          <li class="cursor-pointer" ng-class="{\'panel-heading-active\': myWorkEdit.tab===\'bids\'}"\n' +
    '              ng-click="myWorkEdit.openTab(\'bids\');">Bids\n' +
    '          </li>\n' +
    '          <li class="cursor-pointer" ng-class="{\'panel-heading-active\': myWorkEdit.tab===\'edit\'}"\n' +
    '              ng-click="myWorkEdit.openTab(\'edit\');">\n' +
    '            <span ng-if="myWorkEdit.allowEdit">Edit Work</span>\n' +
    '            <span ng-if="!myWorkEdit.allowEdit">Work</span>\n' +
    '          </li>\n' +
    '          <li class="cursor-pointer" ng-class="{\'panel-heading-active\': myWorkEdit.tab===\'updates\'}"\n' +
    '              ng-click="myWorkEdit.openTab(\'updates\');">\n' +
    '            Updates\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '\n' +
    '    </div>\n' +
    '    <div class="my-work-edit-container" ng-if="myWorkEdit.tab === \'bids\'"\n' +
    '         ng-include="\'map/panels/my-work/my-work-edit-bids.tpl.html\'"></div>\n' +
    '\n' +
    '    <div class="my-work-edit-container" ng-if="myWorkEdit.tab === \'edit\'"\n' +
    '         ng-include="\'map/panels/my-work/my-work-edit-form.tpl.html\'"></div>\n' +
    '\n' +
    '    <div class="my-work-edit-container" ng-if="myWorkEdit.tab === \'updates\'"\n' +
    '         ng-include="\'map/panels/my-work/my-work-edit-updates.tpl.html\'"></div>\n' +
    '  </div>\n' +
    '  <div id="map-panel-work-edit-actions" class="map-tertiary-panel-container"></div>\n' +
    '</div>\n' +
    '');
}]);
