(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Map.Dashboards.controller:DashboardsCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Dashboards')
    .controller('DashboardsCtrl', DashboardsCtrl);

  function DashboardsCtrl($scope, Restangular, MapComponent, DashboardsManager) {
    var
      params;

    function notifyDashboards (params) {
      $scope.$broadcast('event:dashboard-load-started');
      Restangular.one('Dashboards').customPOST(angular.extend({
        limit: 2,
        dashboards: [
          'updates',
          'network',
          'experience',
          'work'
        ]
      }, params), 'index')
        .then(function (result) {
          $scope.$broadcast('event:dashboard-updates-loaded', {
            params: params,
            result: result.updates
          });
          $scope.$broadcast('event:dashboard-network-loaded', {
            params: params,
            result: result.network
          });
          $scope.$broadcast('event:dashboard-experience-loaded', {
            params: params,
            result: result.experience
          });
          $scope.$broadcast('event:dashboard-work-loaded', {
            params: params,
            result: result.work
          });
        });
    }

    function reloadDashboard(dashboard, params) {
      $scope.$broadcast('event:dashboard-' + dashboard + 'load-started');
      Restangular.one('Dashboards').customPOST(angular.extend({
        limit: 2,
        dashboards: [
          dashboard
        ]
      }, params), 'index')
        .then(function (result) {
          $scope.$broadcast('event:dashboard-' + dashboard + '-loaded', {
            params: params,
            result: result[dashboard]
          });
        });
    }

    $scope.$on('event:map-location-clicked', function ($event, data) {
      params = {
        entityFilter: {
          idLocation: data.id,
          network: 'suggestions'
        }
      };
      notifyDashboards(params);
      DashboardsManager.setParams(params);
    });

    $scope.$on('event:work-added', function () {
      reloadDashboard('work', params);
      reloadDashboard('updates', params);
    });

    $scope.$watch(function () {
      return MapComponent.getCurrentMapEntity();
    }, function (mapEntity) {
      params = DashboardsManager.getParamsForMapEntity(mapEntity);
      notifyDashboards(params);
      DashboardsManager.setParams(params);
    });

    DashboardsManager.setReady();
  }

})();
