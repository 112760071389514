(function () {
  'use strict';

  /* @ngdoc object
   * @name Map.Panels
   * @description
   *
   */
  angular
    .module('Map.Panels', [
      'Components',
      'Map.Panels.Profile',
      'Map.Panels.Work',
      'Map.Panels.Messages',
      'Map.Panels.Updates',
      'Map.Panels.People',
      'Map.Panels.Experience',
      'Map.Panels.Location',
      'Map.Panels.MyWork'
    ]);

  angular
    .module('Map.Panels')
    .config(config);

  function config() {
  }

})();
