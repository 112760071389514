angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/my-work/my-work-tab-placed-work.tpl.html',
    '<div ng-controller="MyWorkPlacedWorkCtrl as placedWork">\n' +
    '  <div class="group-heading" ng-show="placedWork.currentWork().length">Current</div>\n' +
    '  <div class="row cursor-pointer"\n' +
    '       ng-repeat="work in placedWork.currentWork() | activeRecord: \'map.workPlacedWork.detail\' : \'id\'"\n' +
    '       ng-click="placedWork.openWorkById(work)"\n' +
    '       ng-class="{\'is-active-record\': work.isActiveRecord}">\n' +
    '    <div ng-include="\'map/panels/my-work/my-work-work-item.tpl.html\'"></div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="group-heading" ng-show="placedWork.closedWork().length">Closed</div>\n' +
    '  <div class="row cursor-pointer"\n' +
    '       ng-repeat="work in placedWork.closedWork() | activeRecord: \'map.workPlacedWork.detail\' : \'id\'"\n' +
    '       ng-click="placedWork.openWorkById(work)"\n' +
    '       ng-class="{\'is-active-record\': work.isActiveRecord}">\n' +
    '    <div ng-include="\'map/panels/my-work/my-work-work-item.tpl.html\'"></div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row" ng-show="placedWork.placedWorkLoader.hasMore() && !placedWork.placedWorkLoader.isLoading">\n' +
    '    <div class="small-15 columns text-center">\n' +
    '      <a ng-click="placedWork.placedWorkLoader.load()">Load more ...</a>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
