angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/people/user-action-button-directive.tpl.html',
    '<span>\n' +
    '  <a ng-if="userActionButton.isSuggestion" ng-click="userActionButton.connect($event)" ng-hide="userActionButton.isRequestSent">\n' +
    '    <i class="fa fa-plus"></i>\n' +
    '  </a>\n' +
    '\n' +
    '  <a ng-if="userActionButton.isContact" ng-click="userActionButton.toggleFavourite($event)" ng-hide="userActionButton.isLoading">\n' +
    '    <i class="fa fa-star" ng-class="{\'text-orange\': userActionButton.isFavourite()}"></i>\n' +
    '  </a>\n' +
    '</span>\n' +
    '');
}]);
