(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Panels.Location.service:LocationManager
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Location')
    .service('LocationManager', LocationManager);

  function LocationManager(PanelsFactory) {
    var
      self = this,
      targetContainerId = 'map-panel-location-actions',
      panels = {
        experienceAdd: {
          id: 'map-panel-location-experience-add',
          templateUrl: 'map/panels/experience/experience-add.tpl.html'
        },
        workAdd: {
          id: 'map-panel-location-work-add',
          templateUrl: 'map/panels/my-work/my-work-add.tpl.html'
        }
      };

    self.panels = PanelsFactory.get(panels, targetContainerId, true, false, true);
  }

})();
