(function () {
  'use strict';

  /* @ngdoc object
   * @name queue
   * @requires $stateProvider
   *
   * @description
   *
   */
  angular
    .module('Queue', [
    ]);

  angular
    .module('Queue')
    .config(config);

  function config() {
  }

})();
