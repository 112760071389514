(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Components.controller:AlertDialogCtrl
   *
   * @description
   *
   */
  angular
    .module('Components')
    .controller('AlertDialogCtrl', AlertDialogCtrl);

  function AlertDialogCtrl($modalInstance, content) {
    var vm = this;
    vm.content = content;

    vm.confirm = function () {
      $modalInstance.close();
    };
  }

})();
