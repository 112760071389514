angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/updates/updates-replies.tpl.html',
    '<div id="map-panel-update-replies" class="map-secondary-panel"\n' +
    '     ng-controller="UpdateRepliesCtrl" ng-swipe-left="closePanel()" ng-swipe-disable-mouse>\n' +
    '  <div class="row map-panel-heading">\n' +
    '    <div class="small-2 columns">\n' +
    '      <a class="arrow-back" ng-click="closePanel()">\n' +
    '        <i class="fa fa-angle-double-left"></i>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '    <div class="small-13 columns">\n' +
    '      <h3>Update Details</h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="update-replies-list">\n' +
    '    <div class="row parent-update">\n' +
    '      <div class="small-2 columns update-reply-picture">\n' +
    '        <user-widget image-class="circle30" user="parentUpdate.userCreated" width="30" height="30"></user-widget>\n' +
    '      </div>\n' +
    '      <div class="small-10 columns">\n' +
    '        <user-widget-name user="parentUpdate.userCreated"></user-widget-name>\n' +
    '        <br/>\n' +
    '        <span class="info-text">\n' +
    '          <i class="fa fa-clock-o"></i> {{parentUpdate.created}} ago\n' +
    '        </span>\n' +
    '        <span ng-show="editAllowed(parentUpdate)">\n' +
    '          <a class="update-reply-menu-link" data-options="align:left" dropdown-toggle="#dropdown-reply-parent">\n' +
    '            <i class="fa fa-angle-down"></i>\n' +
    '          </a>\n' +
    '        </span>\n' +
    '        <br/>\n' +
    '        <form editable-form name="replyEditableParent" blur="cancel" onbeforesave="editUpdate($data)" class="editable-update-form">\n' +
    '          <ul id="dropdown-reply-parent" class="f-dropdown">\n' +
    '            <li><a ng-click="replyEditableParent.$show()">EDIT</a></li>\n' +
    '            <li><a ng-click="deleteUpdate()">DELETE</a></li>\n' +
    '          </ul>\n' +
    '          <span class="update-text-content" editable-textarea="parentUpdate.textContent" e-name="textContent" e-required>\n' +
    '            <span class="update-text" ng-bind-html="parentUpdate.textContent | linky:\'_blank\'"></span>\n' +
    '          </span>\n' +
    '          <div class="edit-update-container" ng-show="replyEditableParent.$visible">\n' +
    '            <button ng-disabled=\'replyEditableParent.$waiting\'>POST</button>\n' +
    '          </div>\n' +
    '        </form>\n' +
    '\n' +
    '        <ul class="clearing-thumbs" data-clearing>\n' +
    '          <li ng-repeat="image in parentUpdate.files.records" class="update-image-thumbnail">\n' +
    '            <a ng-href="{{image | updatePicture}}" ng-click="lightBoxStart(\'update\',parentUpdate.id, $index)" class="update-image-thumbnail-link">\n' +
    '              <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\', left: \'50%\'}" spinner-key="update-{{parentUpdate.id+\'-\'+$index}}"></span>\n' +
    '              <img ng-src="{{image | updatePicture:130}}" />\n' +
    '            </a>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '      <div class="small-3 columns">\n' +
    '        <social-share description="parentUpdate.textContent" name="getNameForSharing()" button-id="\'update\'"></social-share>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <div class="row child-updates" ng-repeat="reply in replies">\n' +
    '      <div class="small-2 columns update-reply-picture">\n' +
    '        <user-widget image-class="circle30" user="reply.userCreated" width="30" height="30"></user-widget>\n' +
    '      </div>\n' +
    '      <div class="small-13 columns">\n' +
    '        <user-widget-name user="reply.userCreated"></user-widget-name>\n' +
    '        <br/>\n' +
    '        <span class="info-text">\n' +
    '          <i class="fa fa-clock-o"></i> {{reply.created}} ago\n' +
    '        </span>\n' +
    '        <span ng-show="editAllowed(reply)">\n' +
    '          <a class="update-reply-menu-link" data-options="align:left" dropdown-toggle="#dropdown-reply-{{$index}}"><i class="fa fa-angle-down"></i></a>\n' +
    '        </span>\n' +
    '        <br/>\n' +
    '        <form editable-form name="replyEditable" blur="cancel" onbeforesave="editReply($index, $data)" class="editable-update-form">\n' +
    '          <ul id="dropdown-reply-{{$index}}" class="f-dropdown">\n' +
    '            <li><a ng-click="replyEditable.$show()">EDIT</a></li>\n' +
    '            <li><a ng-click="deleteReply($index)">DELETE</a></li>\n' +
    '          </ul>\n' +
    '          <span class="update-text-content" editable-textarea="reply.textContent" e-name="textContent" e-required>\n' +
    '            <span class="update-text" ng-bind-html="reply.textContent | linky:\'_blank\'"></span>\n' +
    '          </span>\n' +
    '          <div class="edit-update-container" ng-show="replyEditable.$visible">\n' +
    '            <button ng-disabled=\'replyEditable.$waiting\'>POST</button>\n' +
    '          </div>\n' +
    '        </form>\n' +
    '\n' +
    '        <ul class="clearing-thumbs" data-clearing>\n' +
    '          <li ng-repeat="image in reply.files.records" class="update-image-thumbnail">\n' +
    '            <a ng-href="{{image | updatePicture}}" ng-click="lightBoxStart(\'reply\',reply.id, $index)" class="update-image-thumbnail-link">\n' +
    '              <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\', left: \'50%\'}" spinner-key="reply-{{reply.id+\'-\'+$index}}"></span>\n' +
    '              <img ng-src="{{image | updatePicture:130}}" />\n' +
    '            </a>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="updates-replies-new">\n' +
    '    <div class="row">\n' +
    '      <div class="small-15 columns">\n' +
    '        <form name="updateReplyForm" role="updateReplyForm">\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <div class="small-15 columns update-new-message-textarea-container">\n' +
    '                <div>\n' +
    '                  <textarea id="reply-new-message" name="newMessage" ng-model="newReply" ng-required="true"\n' +
    '                            rows="1"  placeholder="Reply" msd-elastic=""></textarea>\n' +
    '                  <div class="update-reply-button-container">\n' +
    '                    <div class="update-upload-selected-files" ng-show="selectedFiles.length">\n' +
    '                      <span ng-repeat="file in selectedFiles">{{file.name}}\n' +
    '                        <a ng-click="removeFile($index)" ng-hide="isPosting"> <i class="fa fa-close"></i></a>\n' +
    '                        <br/>\n' +
    '                      </span>\n' +
    '                    </div>\n' +
    '                    <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-reply-new"></span>\n' +
    '                    <span class="update-upload-container" ng-hide="isPosting">\n' +
    '                      <input type="file" id="replyPostFile" multiple ng-file-select="onFileSelect($files)" accept="image/*"/>\n' +
    '                      <a class="update-upload-button"><i class="fa fa-image"></i></a>\n' +
    '                    </span>\n' +
    '                    <button class="update-reply-new-button" ng-click="post()" ng-disabled=\'updateReplyForm.$invalid\' ng-hide="isPosting">POST</button>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </form>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<script type="text/javascript">\n' +
    '  $(document).foundation(\'clearing\', \'reflow\');\n' +
    '</script>\n' +
    '');
}]);
