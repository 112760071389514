angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/dashboards/updates.tpl.html',
    '<div class="row dashboard-container" ng-controller="UpdatesDashboardCtrl as updatesDashboard"\n' +
    '     ng-click="updatesDashboard.openPanel()">\n' +
    '  <div class="small-15">\n' +
    '    <div class="row">\n' +
    '      <div class="small-15 small-centered medium-2 medium-uncentered large-2 large-uncentered columns icon-container text-center"><i\n' +
    '        class="fa fa-flag"></i></div>\n' +
    '      <div class="medium-13 large-13 hide-for-small-only columns text-right">\n' +
    '        <h4 class="dashboard-header text-white">Updates\n' +
    '          <span\n' +
    '            us-spinner="{lines: 17, length: 0, width: 3,radius: 7, corners: 1, trail: 100, hwaccel: true, color: \'#ffffff\', left: \'-20px\'}"\n' +
    '            spinner-key="spinner-dashboard-updates"></span>\n' +
    '        </h4>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row hide-for-small-only">\n' +
    '      <div class="small-15 columns dashboard-content-container">\n' +
    '        <div class="row" ng-show="updatesDashboard.isEmpty">\n' +
    '          <div class="small-5 columns text-left">\n' +
    '            <img src="/images/dashboards/updates.png" />\n' +
    '          </div>\n' +
    '          <div class="small-10 columns dashboard-message text-right">\n' +
    '            {{updatesDashboard.message}}\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row" ng-show="!updatesDashboard.isEmpty">\n' +
    '          <div class="small-15 columns">\n' +
    '            <div class="row dashboard-content-row" ng-repeat="update in updatesDashboard.updates">\n' +
    '              <div class="small-5 columns">\n' +
    '                <img ng-src="{{update.userCreated | profilePicture:45}}" class="circle45">\n' +
    '              </div>\n' +
    '              <div class="small-10 columns">\n' +
    '                <user-widget-name user="update.userCreated"></user-widget-name>\n' +
    '                <br/>\n' +
    '                <span class="info-text">\n' +
    '                  <i class="fa fa-clock-o"></i> {{update.modified}} ago\n' +
    '                  <span ng-show="update.relatedUpdatesCount">\n' +
    '                    <i class="fa fa-reply-all"></i>  {{update.relatedUpdatesCount}}\n' +
    '                  </span>\n' +
    '                  <span ng-show="update.mediaCountTotal">\n' +
    '                    <i class="fa fa-image"></i> {{update.mediaCountTotal}}\n' +
    '                  </span>\n' +
    '                </span><br/>\n' +
    '                <span class="update-text-content">\n' +
    '                  {{update.textContent | truncate: 25: \'...\': true}}\n' +
    '                </span>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '');
}]);
