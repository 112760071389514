angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('home/signup.tpl.html',
    '<div id="home-page" class="image-background">\n' +
    '  <div id="signup-form" class="signin-form small-14 medium-10 large-7">\n' +
    '    <div class="row">\n' +
    '      <div class="small-15 columns">\n' +
    '        <form name="signupForm" role="signupForm" novalidate>\n' +
    '          <div class="row">\n' +
    '            <div class="small-4 columns">\n' +
    '              <h3>SIGN UP</h3>\n' +
    '            </div>\n' +
    '            <div class="small-11 columns text-right">\n' +
    '              <a href="/info/" target="{{vm.linksTarget}}"><img src="images/mineler.png" class="home-page-logo" /></a>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row" ng-show="vm.isSignupAsCompany || vm.isSignupAsIndividual">\n' +
    '            <div class="small-15 columns">\n' +
    '              <ul ng-hide="vm.isLoading" class="small-block-grid-3 signin-social">\n' +
    '                <li><a ng-click="vm.socialSignIn(\'facebook\')"><i class="fa fa-facebook"></i></a></li>\n' +
    '                <li><a ng-click="vm.socialSignIn(\'linkedin\')"><i class="fa fa-linkedin"></i></a></li>\n' +
    '                <li><a ng-click="vm.socialSignIn(\'google\')"><i class="fa fa-google-plus"></i></a></li>\n' +
    '              </ul>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns text-right">\n' +
    '              First time here? <a href="/info/" target="{{vm.linksTarget}}"\n' +
    '                                  analytics-on\n' +
    '                                  analytics-category="Sign Up">Learn more.</a>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row" ng-if="vm.isSignupAsIndividual">\n' +
    '            <div class="small-15 columns">\n' +
    '              <ul class="small-block-grid-2">\n' +
    '                <li>\n' +
    '                  <input type="text" ng-model="vm.signupData.firstName" name="firstName" placeholder="First Name"/>\n' +
    '                  </small>\n' +
    '                </li>\n' +
    '                <li>\n' +
    '                  <input type="text" ng-model="vm.signupData.lastName" name="lastName" placeholder="Last Name"/>\n' +
    '                </li>\n' +
    '              </ul>\n' +
    '              <small class="signin-error" ng-if="signupForm.firstName.$dirty && signupForm.lastName.$dirty && !vm.isValidName()">\n' +
    '                Missing first or last name\n' +
    '              </small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row" ng-if="vm.isSignupAsCompany">\n' +
    '            <div class="small-15 columns">\n' +
    '              <input type="text" ng-model="vm.signupData.companyName" name="companyName" placeholder="Company Name" />\n' +
    '              <small class="signin-error" ng-if="signupForm.companyName.$dirty && !vm.isValidName()">Missing company name</small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row" ng-show="vm.isSignupAsCompany || vm.isSignupAsIndividual">\n' +
    '            <div class="small-15 columns">\n' +
    '              <input type="email" required="required" ng-model="vm.signupData.email" name="email" placeholder="Email Address"/>\n' +
    '              <small class="signin-error" ng-show="signupForm.email.$dirty && signupForm.email.$error.required">Missing email\n' +
    '              </small>\n' +
    '              <small class="signin-error" ng-show="signupForm.email.$dirty && signupForm.email.$error.email">This is not a\n' +
    '                valid email\n' +
    '              </small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row" ng-show="vm.isSignupAsCompany || vm.isSignupAsIndividual">\n' +
    '            <div class="small-15 columns">\n' +
    '              <select ng-model="vm.signupData.countryCode" name="countryCode" ng-required="true">\n' +
    '                <option value="">Country</option>\n' +
    '                <option ng-repeat="country in vm.countries"\n' +
    '                        ng-value="country.code"\n' +
    '                        ng-selected="vm.signupData.countryCode === country.code">{{country.name | lowercase | ucfirst}}</option>\n' +
    '              </select>\n' +
    '              <small class="signin-error" ng-show="signupForm.countryCode.$dirty && signupForm.countryCode.$error.required">\n' +
    '                Missing country\n' +
    '              </small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row" ng-show="vm.isSignupAsCompany || vm.isSignupAsIndividual">\n' +
    '            <div class="small-10 columns">\n' +
    '              <small class="signin-error" ng-show="vm.errorMessage">{{vm.errorMessage}}</small>\n' +
    '            </div>\n' +
    '            <div class="small-5 columns text-right sign-buttons-container">\n' +
    '              <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-signup"></span>\n' +
    '              <button ng-hide="vm.isLoading" class="button positive" ng-click="vm.signUp()" ng-disabled=\'signupForm.$invalid || !vm.isValidName()\'>\n' +
    '                SIGN UP\n' +
    '              </button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row" ng-if="!vm.isSignupAsCompany && !vm.isSignupAsIndividual">\n' +
    '            <div class="small-15 colums text-center">\n' +
    '              <br/>\n' +
    '              <h3>I am signing up as</h3>\n' +
    '              <br/><br/>\n' +
    '\n' +
    '              <ul class="small-block-grid-2">\n' +
    '                <li class="text-right">\n' +
    '                  <button class="button positive" ng-click="vm.signupAsIndividual()">Individual</button>\n' +
    '                </li>\n' +
    '                <li class="text-left">\n' +
    '                  <button class="button positive" ng-click="vm.signupAsCompany()">Company</button>\n' +
    '                </li>\n' +
    '              </ul>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </form>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row">\n' +
    '      <div class="small-15 columns">\n' +
    '        <span ng-if="vm.isSignupAsCompany">Sign Up as individual? Click <a ng-click="vm.signupAsIndividual()">here</a></span>\n' +
    '        <span ng-if="vm.isSignupAsIndividual">Sign Up as company? Click <a ng-click="vm.signupAsCompany()">here</a></span>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <div class="row">\n' +
    '      <div class="small-15 columns">\n' +
    '        Already on Mineler? Sign In <a ng-click="vm.goToSignin()" analytics-on\n' +
    '                                       analytics-event="Open Sign In Form"\n' +
    '                                       analytics-category="Sign Up">here</a>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="row">\n' +
    '      <div class="small-15 columns">\n' +
    '        By clicking Sign Up, you agree to our <a target="_blank"\n' +
    '                                                 href="https://www.mineler.com/info/terms_and_conditions.html"\n' +
    '                                                 analytics-on\n' +
    '                                                 analytics-category="Sign Up">Terms</a> and\n' +
    '        that you have read our <a target="_blank" href="https://www.mineler.com/info/privacy.html"\n' +
    '                                  analytics-on\n' +
    '                                  analytics-category="Sign Up">Privacy Policy</a>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
