(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name toolbars.search.service:SearchManager
   *
   * @description
   *
   */
  angular
    .module('Map.Toolbars.Search')
    .service('SearchManager', SearchManager);

  function SearchManager($q) {
    var
      self = this,
      initialQuery = '',
      deferred = $q.defer();
    self.ready = deferred.promise;

    self.getInitialQuery = function () {
      return initialQuery;
    };

    self.setInitialQuery = function (query) {
      initialQuery = query;
    };

    /**
     * Search are ready
     */
    self.setReady = function () {
      deferred.resolve();
    };
  }

})();
