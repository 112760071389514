(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Work.controller:ApplyForJobCtrl
   *
   * @description
   *
   */
  angular
    .module('Work')
    .controller('ApplyForJobCtrl', ApplyForJobCtrl);

  function ApplyForJobCtrl(Defaults, WorkManager, resume, $modalInstance,
                           usSpinnerService, jobApplicationFiles, $timeout, $analytics, jobPosition, userSession) {
    var vm = this;
    vm.data = {
      idJobPosition: null,
      saveAttachments: false,
      emailCopy: true,
      additionalFiles: [],
      firstName: userSession.profile.firstName,
      lastName: userSession.profile.lastName
    };
    vm.additionalJobApplicationFiles = [];
    vm.isSending = false;
    vm.isSent = false;
    vm.errorMessage = null;
    vm.resumeList = resume.plain();
    vm.resume = null;
    vm.jobApplicationFiles = jobApplicationFiles.plain();
    vm.isNameVisible = (userSession.profile.isCompany === true);

    vm.searchPositionUrl = Defaults.baseUrl + '/Work/index?query=';
    vm.selectedFiles = [];
    if (angular.isObject(jobPosition)) {
      vm.jobPosition = jobPosition;
      vm.data.idJobPosition = jobPosition.id;
      vm.isSearchVisible = false;
    } else {
      vm.jobPosition = null;
      vm.isSearchVisible = true;
    }

    vm.onSearchResponse = function (response) {
      var records = [];
      angular.forEach(response.content.records, function (record) {
        record.fullInfo = record.reference + ', ' + record.jobTitle.name + ', ' + record.customer.companyName;
        records.push(record);
      });
      return records;
    };

    vm.onPositionSearchSelect = function (selected) {
      if (!angular.isDefined(selected)) {
        return;
      }
      if (angular.isObject(selected.originalObject)) {
        vm.jobPosition = selected.originalObject;
        vm.data.idJobPosition = selected.originalObject.id;
        $analytics.eventTrack('Search for Job', {category: 'Work'});
      }
    };

    vm.onFileSelect = function ($files) {
      angular.forEach($files, function ($file) {
        if ($file.size < Defaults.maxFileUploadSize) {
          vm.selectedFiles.push($file);
        }
      });
    };

    vm.removeFile = function ($index) {
      vm.selectedFiles.splice($index, 1);
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };

    vm.apply = function () {
      vm.data.additionalFiles = [];
      vm.errorMessage = null;
      vm.isSending = true;
      usSpinnerService.spin('spinner-job-apply');
      if (angular.isObject(vm.resume)) {
        vm.data.additionalFiles.push(vm.resume.id);
      }
      if (angular.isArray(vm.additionalJobApplicationFiles)) {
        angular.forEach(vm.additionalJobApplicationFiles, function (value, key) {
          if (value === true) {
            vm.data.additionalFiles.push(key);
          }
        });
      }

      WorkManager.applyForJob(vm.data, vm.selectedFiles, function () {
        vm.isSent = true;
        $analytics.eventTrack('Apply for Job', {category: 'Work'});
      }, function (reason) {
        vm.errorMessage = reason;
        usSpinnerService.stop('spinner-job-apply');
        vm.isSending = false;
      });
    };

    vm.continue = function () {
      $modalInstance.close();
    };

    vm.jobPositionFileUrl = function (file) {
      return '/WorkFiles/download?id=' + file.id;
    };

    $modalInstance.opened.then(function () {
      $timeout(function () {
        $('#job-application-reference_value').focus();
      }, 100);
    });
  }

})();
