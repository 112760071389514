angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('work/apply-for-job.tpl.html',
    '<div class="row  work-dialog">\n' +
    '  <div class="small-15">\n' +
    '    <div class="row">\n' +
    '      <div class="small-6 small-push-9 columns text-right">\n' +
    '        <img src="images/mineler.png" class="home-page-logo"/>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <form name="applyForJobForm" role="applyForJobForm" ng-if="!vm.isSent">\n' +
    '      <div class="row" ng-if="vm.isSearchVisible">\n' +
    '        <div class="small-15 columns">\n' +
    '          <h4>APPLY FOR WORK</h4>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row" ng-if="!vm.isSearchVisible">\n' +
    '        <div class="small-15 columns">\n' +
    '          <h3>{{vm.jobPosition.jobTitle.name}}, {{vm.jobPosition.reference}} -\n' +
    '          <span ng-if="vm.jobPosition.project">{{vm.jobPosition.project.name}}, </span>{{vm.jobPosition.customer.companyName}}</h3>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row" ng-if="vm.isSearchVisible">\n' +
    '        <div class="small-15 columns">\n' +
    '          Start with a reference number, position or company.\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row" ng-if="vm.isSearchVisible">\n' +
    '        <div class="small-15 columns">\n' +
    '          <angucomplete-alt\n' +
    '            id="job-application-reference"\n' +
    '            pause="100"\n' +
    '            minlength="2"\n' +
    '            remote-url="{{vm.searchPositionUrl}}"\n' +
    '            title-field="fullInfo"\n' +
    '            remote-url-response-formatter="vm.onSearchResponse"\n' +
    '            text-searching="SEARCHING ..."\n' +
    '            selected-object="vm.onPositionSearchSelect"\n' +
    '            match-class="text-orange"\n' +
    '            text-no-results=" "\n' +
    '            override-suggestions="false"\n' +
    '            />\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row" ng-if="vm.jobPosition">\n' +
    '        <div class="small-15 medium-7 columns">\n' +
    '          <div class="job-application-description">\n' +
    '            {{vm.jobPosition.description}}\n' +
    '          </div>\n' +
    '          <div>\n' +
    '            <div ng-repeat="file in vm.jobPosition.jobPositionFiles.records">\n' +
    '              <file-download url="vm.jobPositionFileUrl(file)" label="file.fileName" type="textLink"\n' +
    '                             class="file-download-link"\n' +
    '                             event-category="Work" event-name="Download File"></file-download>\n' +
    '              <br/>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="small-15 medium-8 columns">\n' +
    '          <div class="row">\n' +
    '            <div class="small-3 columns text-right">\n' +
    '              <span class="bid-label">My Bid $</span>\n' +
    '            </div>\n' +
    '            <div class="small-12 columns">\n' +
    '              <input type="number" name="bid" ng-model="vm.data.bid" min="0" ng-required="true" />\n' +
    '              <small class="error" ng-show="applyForJobForm.bid.$dirty && applyForJobForm.bid.$error.required">\n' +
    '                Missing your bid\n' +
    '              </small>\n' +
    '              <small class="error" ng-show="applyForJobForm.bid.$dirty && applyForJobForm.bid.$error.number">\n' +
    '                Not valid number\n' +
    '              </small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row" ng-if="vm.jobPosition && vm.isNameVisible">\n' +
    '            <div class="small-7 columns">\n' +
    '              <input type="text" name="firstName" placeholder="FIRST NAME" ng-required="true" ng-model="vm.data.firstName" />\n' +
    '              <small class="error" ng-show="applyForJobForm.firstName.$dirty && applyForJobForm.firstName.$error.required">Missing first name</small>\n' +
    '            </div>\n' +
    '            <div class="small-8 columns">\n' +
    '              <input type="text" name="lastName" placeholder="LAST NAME" ng-required="true" ng-model="vm.data.lastName" />\n' +
    '              <small class="error" ng-show="applyForJobForm.lastName.$dirty && applyForJobForm.lastName.$error.required">Missing last name</small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <textarea name="message" placeholder="PUT IN MORE DETAILS HERE TO STAND OUT FROM THE REST AND TO IMPROVE YOUR CHANCES OF WINNING THE WORK" ng-model="vm.data.message" msd-elastic></textarea>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row" ng-if="vm.jobPosition && vm.resumeList.length">\n' +
    '        <div class="small-15 columns">\n' +
    '          <select name="resume" ng-model="vm.resume" ng-options="resume.name for resume in vm.resumeList">\n' +
    '            <option value="">-- SELECT RESUME --</option>\n' +
    '          </select>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row" ng-if="vm.jobPosition">\n' +
    '        <div class="small-15 columns">\n' +
    '          <div class="custom-file-upload-button" ng-file-select="vm.onFileSelect($files)" ng-multiple="true"><i class="fa fa-paperclip"></i> Click here to attach supporting documents (certificates, permits, images)</div>\n' +
    '          <div class="custom-file-upload-selected-files" ng-show="vm.selectedFiles.length">\n' +
    '            <span ng-repeat="file in vm.selectedFiles">{{file.name}}\n' +
    '              <a ng-click="vm.removeFile($index)" ng-hide="vm.isSending"> <i\n' +
    '                class="fa fa-close"></i></a>\n' +
    '              <br/>\n' +
    '            </span>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row" ng-if="vm.jobPosition && vm.selectedFiles.length">\n' +
    '        <div class="small-15 columns">\n' +
    '          <label>\n' +
    '            <input type="checkbox" name="saveAttachments" ng-model="vm.data.saveAttachments" /> Click here to store attached files\n' +
    '          </label>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row" ng-if="vm.jobPosition && vm.jobApplicationFiles.length">\n' +
    '        <div class="small-3 columns">\n' +
    '          Stored files\n' +
    '        </div>\n' +
    '        <div class="small-12 columns">\n' +
    '          <ul class="small-block-grid-1 medium-block-grid-2">\n' +
    '            <li ng-repeat="file in vm.jobApplicationFiles">\n' +
    '              <label>\n' +
    '                <input type="checkbox" value="file.id" ng-model="vm.additionalJobApplicationFiles[file.id]" /> {{file.fileName}}\n' +
    '              </label>\n' +
    '            </li>\n' +
    '          </ul>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-10 columns">\n' +
    '          <small class="error" ng-show="vm.errorMessage">{{vm.errorMessage}}</small>\n' +
    '        </div>\n' +
    '        <div class="small-5 columns text-right buttons-container">\n' +
    '          <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-job-apply"></span>\n' +
    '          <button ng-hide="vm.isSending" class="button" ng-click="vm.cancel()">Cancel</button>\n' +
    '          <button ng-if="vm.jobPosition" ng-hide="vm.isSending" class="button positive" ng-click="vm.apply()" ng-disabled="!vm.data.idJobPosition || applyForJobForm.$invalid">Submit</button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </form>\n' +
    '\n' +
    '    <div class="row" ng-if="vm.isSent">\n' +
    '      <div class="small-6 medium-3 medium-offset-3 columns message-icon">\n' +
    '        <i class="fa fa-check-circle"></i>\n' +
    '      </div>\n' +
    '      <div class="small-9 medium-9 end columns message-text text-left">\n' +
    '        <h3>Your bid has been received!</h3>\n' +
    '        Good luck.\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row" ng-if="vm.isSent">\n' +
    '      <div class="small-15 column text-right">\n' +
    '        <button class="button positive" ng-click="vm.continue()">Close</button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
