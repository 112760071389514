(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Panels.Location.controller:LocationCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Location')
    .controller('LocationCtrl', LocationCtrl);

  function LocationCtrl(PanelsManager, $scope, LocationManager, Restangular,
                        usSpinnerService, UpdatesManager, Defaults, $analytics, $state) {
    var vm = this;
    vm.locationDetail = $scope.locationDetail;
    vm.projectsLoaded = false;
    vm.projects = [];
    vm.newReply = null;
    vm.selectedFiles = [];
    vm.isPosting = false;
    vm.errorMessage = null;
    vm.updates = [];
    vm.hasMoreUpdates = false;

    vm.initProjects = function () {
      LocationManager.panels.closeAll();
      vm.projectsLoaded = false;
      vm.projects = [];
      Restangular.one('Projects').getList('indexByLocation', {idLocation: vm.locationDetail.id})
        .then(function (result) {
          vm.projects = result.plain();
          vm.projectsLoaded = true;
        });
    };

    vm.initUpdates = function () {
      vm.updates = [];
      Restangular.one('Updates').getList('index?' + $.param({
        entityFilter: {
          idLocation: vm.locationDetail.id
        },
        limit: 2,
        offset: 0
      })).then(function (result) {
        vm.hasMoreUpdates = (result.meta.totalRecords > result.meta.selectedRecords);
        angular.forEach(result, function (value) {
          vm.updates.push(value);
        });
      });
    };

    vm.initProjects();
    vm.initUpdates();

    vm.closePanel = function () {
      LocationManager.panels.closeAll();
      PanelsManager.panels.close('location');
    };

    vm.addExperience = function () {
      LocationManager.panels.open('experienceAdd', {
        projects: vm.projects,
        location: vm.locationDetail
      });
      $analytics.eventTrack('Add Experience', {category: 'Location'});
    };

    vm.placeWork = function () {
      LocationManager.panels.open('workAdd', {
        projects: vm.projects,
        location: vm.locationDetail
      });
      $analytics.eventTrack('Place Work', {category: 'Location'});
    };

    vm.onFileSelect = function ($files) {
      angular.forEach($files, function ($file) {
        if ($file.size < Defaults.maxFileUploadSize) {
          vm.selectedFiles.push($file);
        }
      });
    };

    vm.removeFile = function ($index) {
      vm.selectedFiles.splice($index, 1);
    };

    vm.post = function () {
      var toPost = {
        text: vm.newReply,
        idLocation: vm.locationDetail.id
      };

      vm.errorMessage = null;
      vm.isPosting = true;
      usSpinnerService.spin('spinner-location-new');
      UpdatesManager.postUpdate(toPost, vm.selectedFiles, function (newUpdate) {
        vm.updates.unshift(newUpdate);
        vm.reset();
        vm.isPosting = false;
        usSpinnerService.stop('spinner-location-new');
        $analytics.eventTrack('Post an Update', {category: 'Location'});
      }, function () {
        vm.isPosting = false;
        vm.errorMessage = 'An error occurred';
        usSpinnerService.stop('spinner-location-new');
      });
    };

    vm.reset = function () {
      vm.newReply = null;
      vm.selectedFiles = [];
    };

    vm.moreUpdates = function () {
      $state.go('map.updates');
    };

    $scope.$on('event:panel-activated:location', function ($event, data) {
      if (data.newPanel === true) {
        vm.locationDetail = data.params.locationDetail;
        vm.initProjects();
        vm.initUpdates();
      }
    });

    $scope.$on('event:work-added', function () {
      vm.initUpdates();
    });

    (function () {
      $('#location-new-message').keydown(angular.bind(vm, function (e) {
        if (e.ctrlKey && e.keyCode === 13 && vm.newReply) {
          vm.post();
          e.currentTarget.blur();
        }

        if (e.keyCode === 27) {
          e.stopPropagation();
          vm.reset();
          e.currentTarget.blur();
        }
      }));
    })();
  }

})();
