(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Components.factory:RequestHeadersInterceptor
   *
   * @description
   *
   */
  angular
    .module('Components')
    .factory('RequestHeadersInterceptor', RequestHeadersInterceptor)
    .config(config);

  function RequestHeadersInterceptor() {
    var RequestHeadersInterceptorBase = {};

    RequestHeadersInterceptorBase.request = function (config) {
      config.headers['Accept'] = 'application/json';

      return config;
    };

    return RequestHeadersInterceptorBase;
  }

  function config($httpProvider) {
    $httpProvider.interceptors.push('RequestHeadersInterceptor');
  }

})();
