(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name Filters.filter:newLines
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('Filters')
    .filter('newLines', newLines);

  function newLines() {
    function convertNewLines (input) {
      var output = input.replace(/(\r)?\n/g, '<br/>');
      return output;
    }

    return function (input) {
      return convertNewLines(input);
    };
  }
}());
