(function () {
  'use strict';

  /* @ngdoc object
   * @name Cache
   * @requires $stateProvider
   *
   * @description
   *
   */
  angular
    .module('Cache', [
      'angular-data.DSCacheFactory'
    ]);

  angular
    .module('Cache')
    .config(config);

  function config(DSCacheFactoryProvider) {
    DSCacheFactoryProvider.setCacheDefaults({
      storagePrefix: 'mineler.',
      maxAge: 259200000 // 3 days
    });
  }

})();
