(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Map.controller:MapCtrl
   *
   * @description
   *
   */
  angular
    .module('Map')
    .controller('MapCtrl', MapCtrl);

  function MapCtrl($scope, uiGmapGoogleMapApi, MapComponent, QueueManager, $timeout, PanelsManager) {
    var ready = false;

    $scope.gmap = {
      center: {
        latitude: -31.930018,
        longitude: 115.893702
      },
      zoom: 8,
      options: {
        panControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        zoomControlOptions: {
          position: 8,
          style: 0
        },
        mapTypeId: 'hybrid'
      },
      control: {}
    };

    uiGmapGoogleMapApi.then(function (maps) {
      var
        unwatch,
        init = function (mapControl) {
          if (angular.isFunction(mapControl.getGMap)) {
            unwatch();
            MapComponent.init(mapControl, maps);
            $timeout(function () {
              QueueManager.start();
            }, 1500);
            ready = true;
          }
        };
      unwatch = $scope.$watch(function () {
        return $scope.gmap.control;
      }, init);
    });

    $scope.$on('$stateChangeSuccess', function ($event, toState) {
      if (ready && QueueManager.queue.length > 0) {
        QueueManager.start();
      }

      if (toState.name === 'map') {
        PanelsManager.panels.closeAll(angular.noop);
      }
    });
  }

})();
