(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name Components.directive:fileDownload
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="Components">
       <file name="index.html">
        <file-download></file-download>
       </file>
     </example>
   *
   */
  angular
    .module('Components')
    .directive('fileDownload', fileDownload);

  function fileDownload($window, $compile, $analytics, Defaults, Auth) {
    var
      buttonTemplate = '<button ng-click="download()" ng-class="class" title="{{title}}">{{label}}</button>',
      textLinkTemplate = '<a ng-click="download()" ng-class="class" title="{{title}}">{{label}}</a>';

    return {
      restrict: 'EA',
      scope: {
        url: '=',
        label: '='
      },
      replace: true,
      link: function (scope, element, attrs) {
        var template, e;
        scope.class = attrs.class;
        scope.title = attrs.title || 'Click to download';
        scope.download = function () {
          var
            iFrame,
            tempUrl = Defaults.baseUrl,
            paramsStr = $.param(Auth.getSecurityParamsForUrl());

          tempUrl += scope.url;
          if (paramsStr !== '') {
            if (tempUrl.indexOf('?') !== -1) {
              tempUrl += '&' + paramsStr;
            } else {
              tempUrl += '?' + paramsStr;
            }
          }

          if (attrs.target === 'frame') {
            iFrame = element.find('iframe');
            if (!(iFrame && iFrame.length > 0)) {
              iFrame = $('<iframe style="position:fixed;display:none;top:-1px;left:-1px;"/>');
              element.append(iFrame);
            }
            iFrame.attr('src', tempUrl);
          } else {
            $window.open(tempUrl);
          }

          if (attrs.eventCategory && attrs.eventName) {
            $analytics.eventTrack(attrs.eventName, {category: attrs.eventCategory});
          }
        };

        if (!angular.isDefined(attrs.type)) {
          attrs.type = 'button';
        }

        switch (attrs.type) {
          case 'button':
            template = buttonTemplate;
            break;
          case 'textLink':
            template = textLinkTemplate;
            break;
        }

        e = $compile(template)(scope);
        element.replaceWith(e);
      }
    };
  }

})();
