angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/work/work-detail.tpl.html',
    '<div ng-controller="WorkDetailCtrl as workDetail" class="map-panel-controller">\n' +
    '  <div id="map-panel-work-detail-action" class="map-secondary-panel" ng-swipe-left="workDetail.closePanel()" ng-swipe-disable-mouse>\n' +
    '    <div class="row map-panel-heading">\n' +
    '      <div class="small-2 columns">\n' +
    '        <a class="arrow-back" ng-click="workDetail.closePanel()">\n' +
    '          <i class="fa fa-angle-double-left"></i>\n' +
    '        </a>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="small-6 columns map-panel-heading-position-name">\n' +
    '        <h3>Work</h3>\n' +
    '      </div>\n' +
    '      <div class="small-7 text-right columns">\n' +
    '        <button class="button positive" ng-click="workDetail.placeBid()">\n' +
    '          PLACE A BID\n' +
    '        </button>\n' +
    '      </div>\n' +
    '\n' +
    '    </div>\n' +
    '    <div class="work-detail-container">\n' +
    '      <div class="row">\n' +
    '        <div class="small-12 columns">\n' +
    '          <user-widget image-class="circle45" user="workDetail.jobPosition.customer.user" width="45"\n' +
    '                       height="45"></user-widget>\n' +
    '        </div>\n' +
    '        <div class="small-3 columns">\n' +
    '          <br/>\n' +
    '          <social-share description="workDetail.jobPosition.description" name="workDetail.getTitleForSharing()"\n' +
    '                        title="\'Mineler Work\'" button-id="\'work\'"></social-share>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <h3>{{workDetail.jobPosition.jobTitle.name}}, {{workDetail.jobPosition.reference}}</h3>\n' +
    '\n' +
    '          <h3>\n' +
    '            <span ng-if="workDetail.jobPosition.project.name">{{workDetail.jobPosition.project.name}}, </span>\n' +
    '            {{workDetail.jobPosition.customer.companyName}}\n' +
    '          </h3>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          {{workDetail.jobPosition.description}}\n' +
    '          <br/><br/>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <div ng-repeat="file in workDetail.jobPosition.jobPositionFiles.records">\n' +
    '            <file-download url="workDetail.jobPositionFileUrl(file)" label="file.fileName" type="textLink"\n' +
    '                           class="file-download-link"\n' +
    '                           event-category="Work" event-name="Download File"></file-download>\n' +
    '            <br/>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div ng-include="\'map/panels/work/work-detail-bids.tpl.html\'"></div>\n' +
    '      <div ng-include="\'map/panels/work/work-detail-updates.tpl.html\'"></div>\n' +
    '      <div ng-include="\'map/panels/work/work-detail-messages.tpl.html\'"></div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div id="map-panel-work-detail-actions" class="map-tertiary-panel-container"></div>\n' +
    '</div>\n' +
    '');
}]);
