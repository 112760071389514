angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/profile/profile-edit-name.tpl.html',
    '<div id="map-panel-profile-edit" class="map-secondary-panel"\n' +
    '     ng-controller="ProfileEditNameCtrl as editName" ng-swipe-left="editName.closeEditPanel()" ng-swipe-disable-mouse>\n' +
    '  <div class="row map-panel-heading">\n' +
    '    <div class="small-2 columns">\n' +
    '      <a class="arrow-back" ng-click="editName.closeEditPanel()">\n' +
    '        <i class="fa fa-angle-double-left"></i>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '    <div class="small-13 columns">\n' +
    '      <h3>Edit My Profile</h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row profile-edit profile-edit-name">\n' +
    '    <div class="small-15 columns">\n' +
    '      <form name="editProfileNameForm" role="editProfileNameForm">\n' +
    '        <div class="row" ng-if="!editName.profile.isCompany">\n' +
    '          <div class="small-15 columns">\n' +
    '            <input type="text" name="firstName" ng-model="editName.profile.firstName" ng-required="true"\n' +
    '                   placeholder="First Name"/>\n' +
    '            <small class="profile-edit-error" ng-if="editProfileNameForm.firstName.$dirty && editProfileNameForm.firstName.$error.required">Missing first name</small>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row" ng-if="!editName.profile.isCompany">\n' +
    '          <div class="small-15 columns">\n' +
    '            <input type="text" name="lastName" ng-model="editName.profile.lastName" ng-required="true"\n' +
    '                   placeholder="Last Name"/>\n' +
    '            <small class="profile-edit-error" ng-if="editProfileNameForm.lastName.$dirty && editProfileNameForm.lastName.$error.required">Missing last name</small>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row" ng-if="editName.profile.isCompany">\n' +
    '          <div class="small-15 columns">\n' +
    '            <input type="text" name="companyName" ng-model="editName.profile.companyName" ng-required="true"\n' +
    '                   placeholder="Company Name"/>\n' +
    '            <small class="profile-edit-error" ng-if="editProfileNameForm.companyName.$dirty && editProfileNameForm.companyName.$error.required">Missing company name</small>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="small-8 small-offset-2 columns">\n' +
    '            <small class="profile-edit-error" ng-show="editName.editProfileErrorMessage">{{editName.editProfileErrorMessage}}</small>\n' +
    '          </div>\n' +
    '          <div class="small-5 columns profile-edit-button-container">\n' +
    '            <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-profile-edit"></span>\n' +
    '            <button ng-hide="editName.isSaving" class="right button positive" ng-click="editName.saveProfile()"\n' +
    '                    ng-disabled=\'editProfileNameForm.$invalid || !editName.isValidName()\'>Save</button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
