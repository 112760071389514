angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/my-work/my-work-tab-my-bids.tpl.html',
    '<div ng-controller="MyWorkMyBidsCtrl as myBids">\n' +
    '\n' +
    '  <div class="group-heading" ng-show="myBids.currentBids().length">Current</div>\n' +
    '  <div class="row cursor-pointer"\n' +
    '       ng-repeat="application in myBids.currentBids() | activeRecord: \'map.workMyBids.detail\' : \'id\'"\n' +
    '       ng-class="{\'accepted-bid\': application.accepted, \'withdrawn-bid\': application.withDrawn, \'is-active-record\': application.isActiveRecord}"\n' +
    '       ng-click="myBids.openMyBidById(application)">\n' +
    '    <div ng-include="\'map/panels/my-work/my-work-my-bid-item.tpl.html\'"></div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="group-heading" ng-show="myBids.closedBids().length">Closed</div>\n' +
    '  <div class="row cursor-pointer"\n' +
    '       ng-repeat="application in myBids.closedBids() | activeRecord: \'map.workMyBids.detail\' : \'id\'"\n' +
    '       ng-class="{\'accepted-bid\': application.accepted, \'withdrawn-bid\': application.withDrawn, \'is-active-record\': application.isActiveRecord}"\n' +
    '       ng-click="myBids.openMyBidById(application)">\n' +
    '    <div ng-include="\'map/panels/my-work/my-work-my-bid-item.tpl.html\'"></div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row" ng-show="myBids.myApplicationsLoader.hasMore() && !myBids.myApplicationsLoader.isLoading">\n' +
    '    <div class="small-15 columns text-center">\n' +
    '      <a ng-click="myBids.myApplicationsLoader.load()">Load more ...</a>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
