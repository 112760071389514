(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name Panels.Experience.directive:datePicker
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="Panels.Experience">
   <file name="index.html">
   <date-picker></date-picker>
   </file>
   </example>
   *
   */
  angular
    .module('Map.Panels.Experience')
    .directive('datePicker', datePicker);

  function datePicker($parse) {
    return {
      restrict: 'E',
      scope: true,
      templateUrl: 'map/panels/experience/date-picker-directive.tpl.html',
      replace: false,
      controllerAs: 'datePicker',
      link: function (scope, elem, attrs) {

        scope.modelValueGetter = $parse(attrs.ngModel);
        scope.modelValue = scope.modelValueGetter(scope);
        scope.maxYear = new Date().getFullYear();

        var
          token = Math.random().toString(36).substr(2),
          dateParts = scope.modelValue.split('-'),
          year = (!isNaN(parseInt(dateParts[0], 10)) ? parseInt(dateParts[0], 10) : 0),
          month = (!isNaN(parseInt(dateParts[1], 10)) ? parseInt(dateParts[1], 10) : 0),
          day = (!isNaN(parseInt(dateParts[2], 10)) ? parseInt(dateParts[2], 10) : 0),
          i;

        scope.days = [];
        for (i = 1; i <= 31; i++) {
          scope.days.push({value: i, name: i});
        }

        month = (month < 0 ? '' : month);
        year = (year > 0 ? year : '');

        scope.token = token;
        scope.monthEnabled = false;
        scope.dayEnabled = false;

        scope.val = {
          year: '',
          month: 0,
          day: 0
        };

        scope.updateDate = function () {
          var
            year = (!isNaN(parseInt(scope.val.year, 10)) ? parseInt(scope.val.year, 10) : 0),
            month = (!isNaN(parseInt(scope.val.month, 10)) ? parseInt(scope.val.month, 10) : 0),
            day = (!isNaN(parseInt(scope.val.day, 10)) ? parseInt(scope.val.day, 10) : 0),
            f = sprintf('%04d-%02d-%02d', year, month, day);
          return f;
        };

        scope.showSelectMonthLink = function () {
          return (!scope.monthEnabled);
        };

        scope.selectMonth = function () {
          scope.monthEnabled = true;
        };

        scope.showSelectMonth = function () {
          return scope.monthEnabled;
        };

        scope.showSelectDayLink = function () {
          return (scope.monthEnabled && !scope.dayEnabled);
        };

        scope.selectDay = function () {
          scope.dayEnabled = true;
        };

        scope.showSelectDay = function () {
          return (scope.monthEnabled && scope.dayEnabled);
        };

        scope.val = {
          year: year,
          month: month,
          day: day
        };
        if (parseInt(dateParts[1], 10) > 0) {
          scope.selectMonth();
          if (parseInt(dateParts[2], 10) > 0) {
            scope.selectDay();
          }
        }

        scope.months = [
          {value: 1, name: 'January'},
          {value: 2, name: 'February'},
          {value: 3, name: 'March'},
          {value: 4, name: 'April'},
          {value: 5, name: 'May'},
          {value: 6, name: 'Jun'},
          {value: 7, name: 'July'},
          {value: 8, name: 'August'},
          {value: 9, name: 'September'},
          {value: 10, name: 'October'},
          {value: 11, name: 'November'},
          {value: 12, name: 'December'}
        ];

        scope.$watch('val.year', function () {
          scope.modelValueGetter.assign(scope, scope.updateDate());
        });

        scope.$watch('val.month', function () {
          scope.modelValueGetter.assign(scope, scope.updateDate());
        });

        scope.$watch('val.day', function () {
          scope.modelValueGetter.assign(scope, scope.updateDate());
        });
      }
    };
  }

})();
