(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Components.service:AlertDialog
   *
   * @description
   *
   */
  angular
    .module('Components')
    .service('AlertDialog', AlertDialog);

  /**
   * AlertDialog service
   * @param $modal
   * @constructor
   */
  function AlertDialog($modal) {
    var self = this;

    /**
     * Opens up a modal dialog
     * @param {string} content
     * @param {string} windowClass
     * @returns {*}
     */
    self.open = function (content, windowClass) {
      if (!angular.isString(windowClass)) {
        windowClass = 'prompt-dialog';
      }
      return $modal.open({
        templateUrl: 'components/alert-dialog.tpl.html',
        controller: 'AlertDialogCtrl as alertDialog',
        windowClass: windowClass,
        keyboard: true,
        resolve: {
          content: function () {
            return content;
          }
        }
      });
    };
  }

})();
