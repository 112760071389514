angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('widgets/authorise.tpl.html',
    '<div id="home-page" class="image-background">\n' +
    '  <div class="row outside-container">\n' +
    '    <div class="small-15 columns outside-form">\n' +
    '      <div class="row">\n' +
    '        <div class="small-4 columns">\n' +
    '          <h3>SIGN IN</h3>\n' +
    '        </div>\n' +
    '        <div class="small-11 columns text-right">\n' +
    '          <a href="/info/" target="_blank"><img src="images/mineler.png" class="home-page-logo" /></a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          You are already signed in as\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <br/>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <h3><user-widget-name user="vm.userProfile"></user-widget-name></h3>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <br/>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <ul class="small-block-grid-2">\n' +
    '            <li>\n' +
    '              <button class="button" ng-click="vm.switch();">Switch user</button>\n' +
    '            </li>\n' +
    '            <li>\n' +
    '              <button class="button positive" ng-click="vm.authorise();">Continue</button>\n' +
    '            </li>\n' +
    '          </ul>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
