(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Map.Dashboards.controller:WorkDashboardCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Dashboards')
    .controller('WorkDashboardCtrl', WorkDashboardCtrl);

  function WorkDashboardCtrl($modal, $analytics, usSpinnerService, $scope, $state) {
    var vm = this;
    vm.message = null;
    vm.isLoading = true;
    vm.isEmpty = true;
    vm.params = null;

    vm.openPanel = function () {
      if (vm.isEmpty) {
        $modal.open({
          templateUrl: 'map/dashboards/work-dialog.tpl.html',
          windowClass: 'work-dialog',
          controller: 'WorkDialogCtrl as workDialog'
        });
      } else {
        $state.go('map.work');
      }

      $analytics.eventTrack('Open Dashboard', {category: 'Work'});
    };

    $scope.$on('event:dashboard-work-load-started', function () {
      vm.isLoading = true;
      usSpinnerService.spin('spinner-dashboard-work');
    });

    $scope.$on('event:dashboard-load-started', function () {
      vm.isLoading = true;
      usSpinnerService.spin('spinner-dashboard-work');
    });

    $scope.$on('event:dashboard-work-loaded', function ($event, data) {
      vm.params = data.params;
      vm.message = data.result.message;
      vm.counter = data.result.counters;
      vm.isEmpty = (data.result.data.recordsInfo.selectedRecords === 0);
      usSpinnerService.stop('spinner-dashboard-work');
      vm.isLoading = false;
    });
  }

})();
