angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/people/people-detail.tpl.html',
    '<div id="map-panel-people-detail-action" class="map-secondary-panel"\n' +
    '     ng-controller="PeopleDetailCtrl as vm" ng-swipe-left="vm.closePanel()" ng-swipe-disable-mouse>\n' +
    '  <div class="row map-panel-heading">\n' +
    '    <div class="small-2 columns">\n' +
    '      <a class="arrow-back" ng-click="vm.closePanel()">\n' +
    '        <i class="fa fa-angle-double-left"></i>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '    <div class="small-6 columns">\n' +
    '      <h3>\n' +
    '        <user-widget-name user="vm.user"></user-widget-name>\n' +
    '      </h3>\n' +
    '    </div>\n' +
    '    <div class="small-7 columns">\n' +
    '      <button ng-hide="vm.isSending" class="button positive" ng-click="vm.showExperience()"\n' +
    '              ng-if="vm.user.profileRelation === \'confirmed_contact\'">SHOW EXPERIENCE</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="invite-detail-container">\n' +
    '    <div ng-include="\'map/panels/people/user-profile.tpl.html\'"></div>\n' +
    '\n' +
    '    <div class="row invite-resend-form" ng-if="vm.user.profileRelation === \'confirmed_contact\'">\n' +
    '      <div class="small-15 columns invites-buttons-container text-right">\n' +
    '        <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}"\n' +
    '              spinner-key="spinner-people-detail-action"></span>\n' +
    '        <button ng-hide="vm.isSending" class="button important" ng-click="vm.disConnect()">DISCONNECT</button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row invite-resend-form" ng-if="vm.user.profileRelation !== \'confirmed_contact\' && vm.user.profileRelation !== \'owner\'">\n' +
    '      <div class="small-15 columns">\n' +
    '        <form name="suggestionForm" role="suggestionForm">\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <textarea name="requestMessage" ng-model="vm.requestMessage" ng-required="true"\n' +
    '                        placeholder="Request message" msd-elastic></textarea>\n' +
    '              <small class="invite-resend-error" ng-show="suggestionForm.requestMessage.$dirty && suggestionForm.requestMessage.$error.required">\n' +
    '                Missing request message\n' +
    '              </small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-10 columns">\n' +
    '              <small class="invite-resend-error" ng-show="vm.errorMessage">{{vm.errorMessage}}</small>\n' +
    '            </div>\n' +
    '            <div class="small-5 columns invites-buttons-container text-right">\n' +
    '              <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-people-detail-action"></span>\n' +
    '              <button ng-hide="vm.isSending" class="button positive" ng-click="vm.connect()"\n' +
    '                      ng-disabled="suggestionForm.$invalid">{{vm.connectButtonLabel}}\n' +
    '              </button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </form>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row" ng-if="vm.user.profileRelation === \'confirmed_contact\'">\n' +
    '      <div class="small-15 columns">\n' +
    '        <h3>Messages</h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-if="vm.user.profileRelation === \'confirmed_contact\'" messages-thread contact-profile="vm.user"\n' +
    '         my-profile="vm.myProfile.profile" auto-loader="false" reversed="false"></div>\n' +
    '\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
