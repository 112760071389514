(function () {
  'use strict';

  /* @ngdoc object
   * @name Map
   * @requires $stateProvider
   *
   * @description
   *
   */
  angular
    .module('Map', [
      'ui.router',
      'uiGmapgoogle-maps',
      'Map.Dashboards',
      'Map.Toolbars',
      'Map.Panels',
      'Security',
      'Filters',
      'socialShare',
      'urlShorteners'
    ]);

  angular
    .module('Map')
    .config(config);

  function config($stateProvider, uiGmapGoogleMapApiProvider, Defaults, SocialShareProvider,
                  BitlyUrlShortenerProvider) {
    $stateProvider.state('map', Defaults.mapStateParams);

    uiGmapGoogleMapApiProvider.configure({
      key: Defaults.googleMapsApiKey,
      v: '3.22'
    });

    SocialShareProvider.set('facebook', 'appId', Defaults.facebookAppId);
    BitlyUrlShortenerProvider.setAccessToken(Defaults.bitlyGenericAccessToken);
  }

})();
