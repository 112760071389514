angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/my-work/my-work-bid-detail.tpl.html',
    '<div ng-controller="MyWorkBidDetailCtrl as bidDetail" class="map-panel-controller">\n' +
    '  <div id="map-panel-my-work-bid-detail-action" class="map-secondary-panel" ng-swipe-left="bidDetail.closePanel()" ng-swipe-disable-mouse>\n' +
    '    <div class="row map-panel-heading">\n' +
    '      <div class="small-2 columns">\n' +
    '        <a class="arrow-back" ng-click="bidDetail.closePanel()">\n' +
    '          <i class="fa fa-angle-double-left"></i>\n' +
    '        </a>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="small-6 columns map-panel-heading-position-name">\n' +
    '        <h3>My Bid</h3>\n' +
    '      </div>\n' +
    '      <div class="small-7 text-right columns">\n' +
    '        <h4>{{bidDetail.bidDetail.bid | currency:\'$\':0}}</h4>\n' +
    '      </div>\n' +
    '\n' +
    '    </div>\n' +
    '    <div class="work-detail-container">\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <user-widget image-class="circle45" user="bidDetail.bidDetail.jobPosition.customer.user" width="45"\n' +
    '                       height="45"></user-widget>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <h3>{{bidDetail.bidDetail.jobPosition.jobTitle.name}}, {{bidDetail.bidDetail.jobPosition.reference}}</h3>\n' +
    '\n' +
    '          <h3>\n' +
    '            <span ng-if="workDetail.jobPosition.project.name">{{bidDetail.bidDetail.jobPosition.project.name}}, </span>\n' +
    '            {{bidDetail.bidDetail.jobPosition.customer.companyName}}\n' +
    '          </h3>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          {{bidDetail.bidDetail.jobPosition.description}}\n' +
    '          <br/><br/>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <h4>My Bid Detail</h4>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '        <span class="info-text">\n' +
    '            <i class="fa fa-clock-o"></i> {{bidDetail.applicationDate()}}\n' +
    '          </span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          {{bidDetail.bidDetail.comments}}\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <div ng-repeat="file in bidDetail.bidDetail.candidateFiles.records">\n' +
    '            <file-download url="bidDetail.bidFileUrl(file)" label="file.name" type="textLink"\n' +
    '                           class="file-download-link"\n' +
    '                           event-category="My Bids" event-name="Download File"></file-download>\n' +
    '            <br/>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <br/><br/>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row" ng-if="!bidDetail.bidDetail.accepted && !bidDetail.bidDetail.withDrawn\n' +
    '        && bidDetail.bidDetail.jobPosition.enabled">\n' +
    '        <div ng-if="bidDetail.errorMessage" class="small-15 columns">\n' +
    '          <small class="error">{{bidDetail.errorMessage}}</small>\n' +
    '        </div>\n' +
    '        <div class="small-15 columns text-right">\n' +
    '        <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}"\n' +
    '              spinner-key="spinner-my-work-detail"></span>\n' +
    '          <button ng-hide="bidDetail.isSaving" class="button important" ng-click="bidDetail.withDraw()">Withdraw</button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <h4>Messages</h4>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div messages-thread contact-profile="bidDetail.bidDetail.jobPosition.customer.user"\n' +
    '           my-profile="bidDetail.bidDetail.userApplicant" auto-loader="false" reversed="false" parameters="bidDetail.messagesParameters"></div>\n' +
    '\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
