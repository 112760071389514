(function () {
  'use strict';

  /* @ngdoc object
   * @name Map.Panels.Work
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Work', [
      'angularFileUpload'
    ]);

  angular
    .module('Map.Panels.Work')
    .config(config);

  function config($stateProvider, Defaults) {
    $stateProvider
      .state('map.work', angular.extend({}, Defaults.mapStateParams, {
        url: '/work',
        onEnter: ['QueueManager', 'InitHelper', function (QueueManager, InitHelper) {
          QueueManager.add(InitHelper.getOpenPanelQueueItem('work', 'work'));
          QueueManager.add(InitHelper.getOpenPanelTabQueueItem('work', 'work'));
        }]
      }))
      .state('map.work.detail', angular.extend({}, Defaults.mapStateParams, {
        url: '/{id}/detail',
        onEnter: ['QueueManager', 'InitHelper', 'QueueItem', '$q', '$timeout', '$rootScope', '$stateParams',
          function (QueueManager, InitHelper, QueueItem, $q, $timeout, $rootScope, $stateParams) {
            QueueManager.add(new QueueItem(function (panelReady) {
              var deferred = $q.defer();
              $q.when(panelReady).then(function () {
                $timeout(function () {
                  $rootScope.$broadcast('event:work-open-detail', {
                    id: InitHelper.decryptId($stateParams.id),
                    deferred: deferred
                  });
                }, 100);
              });

              return deferred.promise;
            }));
          }]
      }))
      .state('map.work.detail.updates', angular.extend({}, Defaults.mapStateParams, {
        url: '/updates/{idUpdate}/replies',
        onEnter: ['QueueManager', 'InitHelper', 'QueueItem', '$q', '$timeout', '$rootScope', '$stateParams',
          function (QueueManager, InitHelper, QueueItem, $q, $timeout, $rootScope, $stateParams) {
            QueueManager.add(new QueueItem(function (panelReady) {
              $q.when(panelReady).then(function () {
                $timeout(function () {
                  $rootScope.$broadcast('event:work-open-detail-updates', {
                    id: InitHelper.decryptId($stateParams.idUpdate)
                  });
                }, 100);
              });

              return $q.when(true);
            }));
          }]
      }))
      .state('map.work.detail.messages', angular.extend({}, Defaults.mapStateParams, {
        url: '/messages/{idUser}/detail',
        onEnter: ['QueueManager', 'InitHelper', 'QueueItem', '$q', '$timeout', '$rootScope', '$stateParams',
          function (QueueManager, InitHelper, QueueItem, $q, $timeout, $rootScope, $stateParams) {
            QueueManager.add(new QueueItem(function (panelReady) {
              $q.when(panelReady).then(function () {
                $timeout(function () {
                  $rootScope.$broadcast('event:work-open-detail-messages', {
                    id: InitHelper.decryptId($stateParams.idUser)
                  });
                }, 100);
              });

              return $q.when(true);
            }));
          }]
      }))
      .state('map.workMyBids', angular.extend({}, Defaults.mapStateParams, {
        url: '/work/my-bids',
        onEnter: ['QueueManager', 'InitHelper', function (QueueManager, InitHelper) {
          QueueManager.add(InitHelper.getOpenPanelQueueItem('work', 'myBids'));
          QueueManager.add(InitHelper.getOpenPanelTabQueueItem('work', 'myBids'));
        }]
      }))
      .state('map.workMyBids.detail', angular.extend({}, Defaults.mapStateParams, {
        url: '/{id}/detail',
        onEnter: ['QueueManager', 'InitHelper', 'QueueItem', '$q', '$timeout', '$rootScope', '$stateParams',
          function (QueueManager, InitHelper, QueueItem, $q, $timeout, $rootScope, $stateParams) {
            QueueManager.add(new QueueItem(function (panelReady) {
              $q.when(panelReady).then(function () {
                $timeout(function () {
                  $rootScope.$broadcast('event:work-my-bid-open-detail', {
                    id: InitHelper.decryptId($stateParams.id)
                  });
                }, 100);
              });

              return $q.when(true);
            }));
          }]
      }))
      .state('map.workPlacedWork', angular.extend({}, Defaults.mapStateParams, {
        url: '/work/placed-work',
        onEnter: ['QueueManager', 'InitHelper', function (QueueManager, InitHelper) {
          QueueManager.add(InitHelper.getOpenPanelQueueItem('work', 'placedWork'));
          QueueManager.add(InitHelper.getOpenPanelTabQueueItem('work', 'placedWork'));
        }]
      }))
      .state('map.workPlacedWork.detail', angular.extend({}, Defaults.mapStateParams, {
        url: '/{id}/detail',
        onEnter: ['QueueManager', 'InitHelper', 'QueueItem', '$q', '$timeout', '$rootScope', '$stateParams',
          function (QueueManager, InitHelper, QueueItem, $q, $timeout, $rootScope, $stateParams) {
            QueueManager.add(new QueueItem(function (panelReady) {
              $q.when(panelReady).then(function () {
                $timeout(function () {
                  $rootScope.$broadcast('event:work-placed-open-detail', {
                    id: InitHelper.decryptId($stateParams.id)
                  });
                }, 100);
              });

              return $q.when(true);
            }));
          }]
      }))
      .state('map.workPlacedWork.detail-bids', angular.extend({}, Defaults.mapStateParams, {
        parent: 'map.workPlacedWork.detail',
        url: '/{id}/bids',
        onEnter: ['QueueManager', 'InitHelper', 'QueueItem', '$q', '$timeout', '$rootScope', '$stateParams',
          function (QueueManager, InitHelper, QueueItem, $q, $timeout, $rootScope, $stateParams) {
            QueueManager.add(new QueueItem(function (panelReady) {
              $q.when(panelReady).then(function () {
                $timeout(function () {
                  $rootScope.$broadcast('event:work-placed-open-detail', {
                    id: InitHelper.decryptId($stateParams.id),
                    tab: 'bids'
                  });
                }, 100);
              });

              return $q.when(true);
            }));
          }]
      }))
      .state('map.workPlacedWork.detail.messages', angular.extend({}, Defaults.mapStateParams, {
        url: '/messages/{idJobApplication}/detail',
        onEnter: ['QueueManager', 'InitHelper', 'QueueItem', '$q', '$timeout', '$rootScope', '$stateParams',
          function (QueueManager, InitHelper, QueueItem, $q, $timeout, $rootScope, $stateParams) {
            QueueManager.add(new QueueItem(function (panelReady) {
              $q.when(panelReady).then(function () {
                $timeout(function () {
                  $rootScope.$broadcast('event:work-edit-open-messages', {
                    idJobApplication: InitHelper.decryptId($stateParams.idJobApplication)
                  });
                }, 100);
              });

              return $q.when(true);
            }));
          }]
      }))
      .state('map.workPlacedWork.detail-edit', angular.extend({}, Defaults.mapStateParams, {
        parent: 'map.workPlacedWork.detail',
        url: '/{id}/edit',
        onEnter: ['QueueManager', 'InitHelper', 'QueueItem', '$q', '$timeout', '$rootScope', '$stateParams',
          function (QueueManager, InitHelper, QueueItem, $q, $timeout, $rootScope, $stateParams) {
            QueueManager.add(new QueueItem(function (panelReady) {
              $q.when(panelReady).then(function () {
                $timeout(function () {
                  $rootScope.$broadcast('event:work-placed-open-detail', {
                    id: InitHelper.decryptId($stateParams.id),
                    tab: 'edit'
                  });
                }, 100);
              });

              return $q.when(true);
            }));
          }]
      }))
      .state('map.workPlacedWork.detail-updates', angular.extend({}, Defaults.mapStateParams, {
        parent: 'map.workPlacedWork.detail',
        url: '/{id}/updates',
        onEnter: ['QueueManager', 'InitHelper', 'QueueItem', '$q', '$timeout', '$rootScope', '$stateParams',
          function (QueueManager, InitHelper, QueueItem, $q, $timeout, $rootScope, $stateParams) {
            QueueManager.add(new QueueItem(function (panelReady) {
              var deferred = $q.defer();
              $q.when(panelReady).then(function () {
                $timeout(function () {
                  $rootScope.$broadcast('event:work-placed-open-detail', {
                    id: InitHelper.decryptId($stateParams.id),
                    tab: 'updates',
                    deferred: deferred
                  });
                }, 100);
              });

              return deferred.promise;
            }));
          }]
      }))
      .state('map.workPlacedWork.detail-updates.detail', angular.extend({}, Defaults.mapStateParams, {
        url: '/{idUpdate}/replies',
        onEnter: ['QueueManager', 'InitHelper', 'QueueItem', '$q', '$timeout', '$rootScope', '$stateParams',
          function (QueueManager, InitHelper, QueueItem, $q, $timeout, $rootScope, $stateParams) {
            QueueManager.add(new QueueItem(function (panelReady) {
              $q.when(panelReady).then(function () {
                $timeout(function () {
                  $rootScope.$broadcast('event:work-edit-open-update', {
                    id: InitHelper.decryptId($stateParams.idUpdate)
                  });
                }, 100);
              });

              return $q.when(true);
            }));
          }]
      }));
  }

})();
