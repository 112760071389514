angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/profile/profile-edit-about.tpl.html',
    '<div id="map-panel-profile-edit" class="map-secondary-panel"\n' +
    '     ng-controller="ProfileEditAboutCtrl as editAbout" ng-swipe-left="editAbout.closeEditPanel()" ng-swipe-disable-mouse>\n' +
    '  <div class="row map-panel-heading">\n' +
    '    <div class="small-2 columns">\n' +
    '      <a class="arrow-back" ng-click="editAbout.closeEditPanel()">\n' +
    '        <i class="fa fa-angle-double-left"></i>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '    <div class="small-13 columns">\n' +
    '      <h3>About Me</h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row profile-edit profile-edit-about">\n' +
    '    <div class="small-15 columns">\n' +
    '      <form name="editProfileAboutForm" role="editProfileAboutForm">\n' +
    '        <div class="row">\n' +
    '          <div class="small-15 columns">\n' +
    '            <textarea name="personalMessage" ng-model="editAbout.profile.personalMessage" rows="20"></textarea>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="small-8 small-offset-2 columns">\n' +
    '            <small class="profile-edit-error" ng-show="editAbout.editProfileErrorMessage">{{editAbout.editProfileErrorMessage}}</small>\n' +
    '          </div>\n' +
    '          <div class="small-5 columns profile-edit-button-container">\n' +
    '            <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-profile-edit"></span>\n' +
    '            <button ng-hide="editAbout.isSaving" class="right button positive" ng-click="editAbout.saveProfile()" ng-disabled=\'editProfileAboutForm.$invalid\'>Save</button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
