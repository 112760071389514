(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Components.service:PromptDialog
   *
   * @description
   *
   */
  angular
    .module('Components')
    .service('PromptDialog', PromptDialog);

  /**
   * PromptDialog service
   * @param $modal
   * @constructor
   */
  function PromptDialog($modal) {
    var self = this;

    /**
     * Opens up a modal dialog
     * @param {string} content
     * @param {string} onConfirmMessage
     * @param {string} windowClass
     * @returns {*}
     */
    self.open = function (content, onConfirmMessage, windowClass) {
      if (!angular.isString(windowClass)) {
        windowClass = 'prompt-dialog';
      }
      return $modal.open({
        templateUrl: 'components/prompt-dialog.tpl.html',
        controller: 'PromptDialogCtrl as promptDialog',
        windowClass: windowClass,
        keyboard: true,
        resolve: {
          content: function () {
            return content;
          },
          onConfirmMessage: function () {
            return onConfirmMessage;
          }
        }
      });
    };
  }

})();
