(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name configuration.filter:activeRecord
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('Filters')
    .filter('activeRecord', activeRecord);

  function activeRecord($state, InitHelper) {
    return function (data, stateName, paramName, subLevel) {
      if (angular.isUndefined(paramName)) {
        paramName = 'id';
      }

      if (angular.isArray(data)) {
        angular.forEach(data, function (item) {
          item.isActiveRecord = false;
        });
      } else if (angular.isObject(data)) {
        data.isActiveRecord = false;
      }

      if (angular.isUndefined($state.params[paramName]) || !$state.includes(stateName)) {
        return data;
      }

      var paramValue = InitHelper.decryptId($state.params[paramName]);

      if (angular.isArray(data)) {
        angular.forEach(data, function (item) {
          item.isActiveRecord = (paramValue === (subLevel ? item[subLevel].id : item.id));
        });
      } else if (angular.isObject(data)) {
        data.isActiveRecord = (paramValue === (subLevel ? data[subLevel].id : data.id));
      }

      return data;
    };
  }
}());
