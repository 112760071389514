(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name panels.myWork.controller:MyWorkPlacedWorkCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.MyWork')
    .controller('MyWorkPlacedWorkCtrl', MyWorkPlacedWorkCtrl);

  function MyWorkPlacedWorkCtrl(RecordsSet, MyWorkPanelManager, $scope, Restangular, $state, InitHelper,
                                $analytics, $rootScope, $timeout) {
    var vm = this;
    vm.placedWorkLoader = RecordsSet.getLoader('Work', 'index', {posted: true});

    vm.currentWork = function () {
      return _.filter(vm.placedWorkLoader.records, {enabled: true});
    };

    vm.closedWork = function () {
      return _.filter(vm.placedWorkLoader.records, {enabled: false});
    };

    vm.openWorkEditById = function (id, deferred, tab) {
      var $index = _.findIndex(vm.placedWorkLoader.records, {id: id});
      if ($index !== -1) {
        vm.openWorkEdit(vm.placedWorkLoader.records[$index], deferred, tab);
      } else {
        Restangular.one('Work').one('index').get({id: id})
          .then(function (result) {
            vm.openWorkEdit(result.plain(), deferred, tab);
          });
      }
    };

    vm.openWorkById = function (work) {
      $state.go('map.workPlacedWork.detail', {
        id: InitHelper.encryptId(work.id)
      });
    };

    vm.openWorkEdit = function (work, deferred, tab) {
      MyWorkPanelManager.panels.open('editWork', {
        dataToEdit: work,
        tab: tab
      }).then(function (result) {
        if (angular.isObject(result) && angular.isDefined(result.action)) {
          var $index = _.findIndex(vm.placedWorkLoader.records, {id: work.id});
          switch (result.action) {
            case 'update':
              if ($index !== -1) {
                vm.placedWorkLoader.records[$index] = result.work;
              }
              break;
            case 'remove':
              if ($index !== -1) {
                vm.placedWorkLoader.records[$index].enabled = false;
              }
              break;
            case 'accept':
              if ($index !== -1) {
                vm.placedWorkLoader.records[$index].bidAccepted = true;
                vm.placedWorkLoader.records[$index].enabled = false;
              }
              break;
          }
        }
      });

      if (angular.isDefined(deferred)) {
        deferred.resolve(true);
      }

      if (angular.isDefined(work.project.location) &&
        angular.isObject(work.project.location)) {
        $timeout(function () {
          $rootScope.$broadcast('event:center-map', {
            idLocation: work.project.location.id,
            offsetX: $('#map-panel-work').width()
          });
        }, 100);
      }

      $analytics.eventTrack('Open Placed Work Detail', {category: 'Work'});
    };

    vm.placedWorkLoader.load();

    $scope.$on('event:work-placed-open-detail', function ($event, data) {
      var tab = data.tab || 'bids';
      vm.openWorkEditById(data.id, data.deferred, tab);
    });
  }

})();
