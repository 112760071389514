angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('experience/experience-validation.tpl.html',
    '<div id="experience-validation" class="image-background-connection">\n' +
    '  <div class="row outside-container">\n' +
    '    <div class="small-15 medium-9 large-8 columns outside-form" ng-if="experienceValidation.validToken">\n' +
    '      <form name="experienceValidationForm" role="experienceValidationForm">\n' +
    '        <div class="row">\n' +
    '          <div class="small-6 small-push-9 columns text-right">\n' +
    '            <img src="images/mineler.png" class="home-page-logo"/>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="small-15 columns text-right">\n' +
    '            <small>First time here? <a href="/info" target="_blank">Learn more.</a></small>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row">\n' +
    '          <div class="small-15 columns text-left">\n' +
    '            <h4>Can you vouch for <user-widget-name user="experienceValidation.detail.user" only-first-name="true"></user-widget-name>?</h4>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row">\n' +
    '          <div class="small-15 columns text-left">\n' +
    '            <b><user-widget-name user="experienceValidation.detail.user"></user-widget-name></b> would\n' +
    '            like you to verify their experience.\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row content">\n' +
    '          <div class="small-7 medium-6 columns">\n' +
    '            <img class="circle150" ng-src="{{experienceValidation.detail.user | profilePicture:150}}"/>\n' +
    '          </div>\n' +
    '          <div class="small-8 medium-9 columns text-left">\n' +
    '            <b>{{experienceValidation.detail.jobTitleName}}</b><br/>\n' +
    '            <span>{{experienceValidation.detail.projectName}}, {{experienceValidation.detail.employerName}}</span><br/>\n' +
    '            <span>{{experienceValidation.detail.dateFrom | experienceDate}} - {{experienceValidation.detail.dateTo | experienceDate}}</span><br/>\n' +
    '            <span>{{experienceValidation.detail.description}}</span>\n' +
    '\n' +
    '            <div ng-if="experienceValidation.detail.skills.length">\n' +
    '              <b>Skills</b><br/>\n' +
    '              {{experienceValidation.getSkillList()}}\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row content">\n' +
    '          <div class="small-15 columns text-left">\n' +
    '            Message from <user-widget-name user="experienceValidation.detail.user" only-first-name="true"></user-widget-name>:\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row content">\n' +
    '          <div class="small-15 columns text-left">\n' +
    '            {{experienceValidation.detail.requestMessage}}\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row">\n' +
    '          <div class="small-15 columns text-left">\n' +
    '            <textarea ng-model="experienceValidation.validationMessage" name="validationMessage" maxlength="255" ng-maxlength="255" msd-elastic\n' +
    '                      placeholder="You could also provide additional comments to help {{experienceValidation.detail.user.firstName}} build a better profile"></textarea>\n' +
    '            <small class="error" ng-show="experienceValidationForm.validationMessage.$dirty && experienceValidationForm.validationMessage.$error.maxlength">Max 255\n' +
    '              characters allowed\n' +
    '            </small>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row" ng-show="experienceValidation.errorMessage">\n' +
    '          <div class="small-15 columns text-left">\n' +
    '            <small class="experience-validation-error">{{experienceValidation.errorMessage}}</small>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row">\n' +
    '          <div class="small-9 columns">\n' +
    '            <div class="disclaimer">\n' +
    '              Disclaimer: By verifying this experience, you agree to knowing this person and confirming they\'ve\n' +
    '              completed the tasks they described.\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="small-6 columns text-right">\n' +
    '            <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}"\n' +
    '                  spinner-key="spinner-experience-validation"></span>\n' +
    '            <button ng-hide="experienceValidation.isLoading" class="button" ng-click="experienceValidation.ignore();">\n' +
    '              Ignore\n' +
    '            </button>\n' +
    '            <button ng-hide="experienceValidation.isLoading" class="button positive"\n' +
    '                    ng-click="experienceValidation.validate();">Validate\n' +
    '            </button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row">\n' +
    '          <div class="small-15 columns text-right">\n' +
    '            <small><a href="/info" target="_blank">Learn more</a> about Mineler.</small>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="small-15 medium-11 large-10 columns outside-message" ng-if="!experienceValidation.validToken">\n' +
    '      <div class="row">\n' +
    '        <div class="small-6 small-push-9 columns text-right">\n' +
    '          <img src="images/mineler.png" class="home-page-logo"/>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row" ng-if="experienceValidation.error301">\n' +
    '        <div class="small-6 medium-3 columns message-icon">\n' +
    '          <i class="fa fa-minus-circle"></i>\n' +
    '        </div>\n' +
    '        <div class="small-9 medium-12 columns message-text text-left">\n' +
    '          <h3>Oops,</h3>\n' +
    '          Some information is missing. Please try again.\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row" ng-if="experienceValidation.error303">\n' +
    '        <div class="small-6 medium-3 columns message-icon">\n' +
    '          <i class="fa fa-exclamation-circle"></i>\n' +
    '        </div>\n' +
    '        <div class="small-9 medium-12 columns message-text text-left">\n' +
    '          <h3>We\'re sorry, your request cannot be located.</h3>\n' +
    '          Please help us improve your Mineler experience by contacting us.\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row" ng-if="experienceValidation.error304">\n' +
    '        <div class="small-6 medium-3 columns message-icon">\n' +
    '          <i class="fa fa-check-circle"></i>\n' +
    '        </div>\n' +
    '        <div class="small-9 medium-12 columns message-text text-left">\n' +
    '          <h3>Great Work!</h3>\n' +
    '          All done.\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns text-right">\n' +
    '          <button class="button positive" ng-click="experienceValidation.continue()">Continue</button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
