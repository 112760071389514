(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name map.panels.controller:PanelsCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels')
    .controller('PanelsCtrl', PanelsCtrl);

  function PanelsCtrl(PanelsManager) {
    /**
     * Panels are ready
     */
    PanelsManager.setReady();
  }

})();
