(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name panels.myWork.controller:MyWorkEditCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.MyWork')
    .controller('MyWorkEditCtrl', MyWorkEditCtrl);

  function MyWorkEditCtrl(Defaults, MyWorkPanelManager, $scope, $filter, usSpinnerService, WorkManager, $analytics,
                          RecordsSet, $state, UpdatesManager, $timeout, MyWorkEditPanelManager, InitHelper,
                          Restangular) {
    var
      vm = this,
      bidsTabLoaded = false,
      jobTitleTouched = false,
      editWorkTabLoaded = false,
      updatesTabLoaded = false,
      existingFilesToRemove = {},
      applicationCommentExpanded = {};

    vm.searchPositionUrl = Defaults.baseUrl + '/JobTitles/index?search[query]=';
    vm.jobTitleValid = true;
    vm.tab = '';
    vm.isSending = false;
    vm.data = {
      reference: $scope.dataToEdit.reference,
      idJobTitle: $scope.dataToEdit.jobTitle.id,
      jobTitleName: $scope.dataToEdit.jobTitle.name,
      description: $scope.dataToEdit.description
    };
    vm.existingFiles = $scope.dataToEdit.jobPositionFiles || [];
    vm.selectedFiles = [];

    vm.allowEdit = ($scope.dataToEdit.bidAccepted !== true && $scope.dataToEdit.enabled === true);

    vm.bidsLoader = RecordsSet.getLoader('JobApplications', 'index', {
      idJobPosition: $scope.dataToEdit.id,
      allBids: true
    });

    vm.updatesLoader = RecordsSet.getLoader('Updates', 'index', {
      entityFilter: {
        idJobPosition: $scope.dataToEdit.id
      }
    });
    vm.selectedUpdateFiles = [];
    vm.isPosting = false;

    if ($scope.dataToEdit.startDate) {
      vm.data.startDate = $filter('date')(new Date($scope.dataToEdit.startDate), 'dd/MM/yyyy');
    }

    if ($scope.dataToEdit.endDate) {
      vm.data.endDate = $filter('date')(new Date($scope.dataToEdit.endDate), 'dd/MM/yyyy');
    }

    function openBidsTab() {
      if (bidsTabLoaded === false) {
        vm.bidsLoader.load(0);
        bidsTabLoaded = true;
        $analytics.eventTrack('Bids Tab', {category: 'My Work Edit'});
      }
    }

    function openEditWorkTab() {
      if (editWorkTabLoaded === false) {
        (function () {
          var
            startDate,
            endDate,
            datePickerParas = {
              format: 'dd/mm/yyyy',
              weekStart: 1
            };

          startDate = $('#work-start-date').fdatepicker(datePickerParas)
            .on('changeDate', function (ev) {
              var newDate = new Date(ev.date);
              if (ev.date.valueOf() > endDate.date.valueOf()) {
                newDate.setDate(newDate.getDate() + 1);
                endDate.update(newDate);
              }
              startDate.hide();
              $('#work-end-date')[0].focus();
            })
            .data('datepicker');
          $('#work-start-date').fdatepicker('update', vm.data.startDate);
          endDate = $('#work-end-date').fdatepicker(angular.extend(datePickerParas, {
            onRender: function (date) {
              return date.valueOf() <= startDate.date.valueOf() ? 'disabled' : '';
            }
          })).on('changeDate', function () {
            endDate.hide();
          }).data('datepicker');
          $('#work-end-date').fdatepicker('update', vm.data.endDate);
        })();
        editWorkTabLoaded = true;
        $analytics.eventTrack('Edit Tab', {category: 'My Work Edit'});
      }
    }

    function openUpdatesTab() {
      if (updatesTabLoaded === false) {
        $timeout(function () {
          vm.loadUpdates();
        }, 100);
        updatesTabLoaded = true;
        $analytics.eventTrack('Updates Tab', {category: 'My Work Edit'});
      }
    }

    function openTab(tab) {
      if (tab === vm.tab) {
        return;
      }
      vm.tab = tab;
      switch (tab) {
        case 'bids':
          openBidsTab();
          break;
        case 'edit':
          openEditWorkTab();
          break;
        case 'updates':
          openUpdatesTab();
          break;
      }
    }

    vm.closePanel = function (data) {
      if (!angular.isObject(data)) {
        data = {};
      }
      MyWorkPanelManager.panels.close('editWork', data, function () {
        $state.go('map.workPlacedWork');
      });
    };

    vm.openTab = function (tab) {
      $state.go('map.workPlacedWork.detail-' + tab, {
        id: InitHelper.encryptId($scope.dataToEdit.id)
      });
    };

    vm.onFocusJobTitle = function () {
      if (vm.data.idJobTitle !== 0) {
        vm.data.jobTitleName = '';
        vm.data.idJobTitle = 0;
        $scope.$broadcast('angucomplete-alt:clearInput', 'work-position-input');
        vm.jobTitleValid = false;
        jobTitleTouched = false;
      }
    };

    vm.onFocusOutJobTitle = function () {
      jobTitleTouched = true;
    };

    vm.onSearchResponse = function (response) {
      return response.content.records;
    };

    vm.onPositionSearchSelect = function (selected) {
      if (!angular.isDefined(selected)) {
        return;
      }
      if (angular.isObject(selected.originalObject)) {
        vm.data.idJobTitle = selected.originalObject.id;
      }
    };

    vm.onPositionChanged = function (query) {
      vm.data.jobTitleName = query;
      jobTitleTouched = true;
      if (!vm.data.jobTitleName && !vm.data.idJobTitle) {
        vm.jobTitleValid = false;
      } else {
        vm.jobTitleValid = true;
      }
    };

    vm.isPositionInvalid = function () {
      return (jobTitleTouched && !vm.jobTitleValid);
    };

    vm.toggleBidComment = function (application) {
      if (!angular.isDefined(applicationCommentExpanded[application.id])) {
        applicationCommentExpanded[application.id] = true;
      } else {
        applicationCommentExpanded[application.id] = !applicationCommentExpanded[application.id];
      }
    };

    vm.isBidCommentExpanded = function (application) {
      return (angular.isDefined(applicationCommentExpanded[application.id]) &&
      applicationCommentExpanded[application.id] === true);
    };

    vm.save = function () {
      usSpinnerService.spin('spinner-work-edit');
      vm.isSaving = true;
      var
        data = angular.copy(vm.data),
        parts,
        filesIdsToRemove = [];
      if (data.startDate) {
        parts = data.startDate.split('/');
        data.startDate = $filter('date')(new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0])),
          'yyyy-MM-dd');
      }

      angular.forEach(vm.existingFiles.records, function (file, $index) {
        if (angular.isDefined(existingFilesToRemove[$index]) && existingFilesToRemove[$index] === true) {
          filesIdsToRemove.push(file.id);
        }
      });

      if (data.endDate) {
        parts = data.endDate.split('/');
        data.endDate = $filter('date')(new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0])),
          'yyyy-MM-dd');
      }
      WorkManager.updateWork($scope.dataToEdit.id, data, filesIdsToRemove, vm.selectedFiles, function (result) {
        usSpinnerService.stop('spinner-work-edit');
        vm.closePanel({action: 'update', work: result});
        $analytics.eventTrack('Edit', {category: 'My Work'});
      }, function (reason) {
        vm.errorMessage = reason;
        usSpinnerService.stop('spinner-work-edit');
        vm.isSaving = false;
      });
    };

    vm.remove = function () {
      usSpinnerService.spin('spinner-work-edit');
      vm.isSaving = true;
      WorkManager.removeWork($scope.dataToEdit.id, function () {
        usSpinnerService.stop('spinner-work-edit');
        vm.closePanel({action: 'remove'});
        $analytics.eventTrack('Remove', {category: 'My Work'});
      }, function (reason) {
        vm.errorMessage = reason;
        usSpinnerService.stop('spinner-work-edit');
        vm.isSaving = false;
      });
    };

    vm.applicationDate = function (application) {
      var d = new Date(application.dateApplication);
      return $filter('date')(d, 'dd/MM/yyyy');
    };

    vm.bidFileUrl = function (file) {
      return '/JobApplicationFiles/download?id=' + file.id;
    };

    vm.jobPositionFileUrl = function (file) {
      return '/WorkFiles/download?id=' + file.id;
    };

    vm.acceptedBids = function () {
      return _.filter(vm.bidsLoader.records, {accepted: true});
    };

    vm.bids = function () {
      return _.filter(vm.bidsLoader.records, {accepted: false});
    };

    vm.acceptBid = function ($index) {
      vm.isSending = true;
      WorkManager.acceptBid(vm.bidsLoader.records[$index], function () {
        vm.closePanel({action: 'accept'});
      }, function () {
        vm.closePanel();
      });
    };

    vm.removeExistingFile = function ($index) {
      existingFilesToRemove[$index] = true;
    };

    vm.resetExistingFile = function ($index) {
      existingFilesToRemove[$index] = false;
    };

    vm.isPositionFileDeleted = function ($index) {
      if (angular.isDefined(existingFilesToRemove[$index]) && existingFilesToRemove[$index] === true) {
        return true;
      }

      return false;
    };

    vm.removeFile = function ($index) {
      vm.selectedFiles.splice($index, 1);
    };

    vm.onFileSelect = function ($files) {
      angular.forEach($files, function ($file) {
        if ($file.size < Defaults.maxFileUploadSize) {
          vm.selectedFiles.push($file);
        }
      });
    };

    vm.onUpdateFileSelect = function ($files) {
      angular.forEach($files, function ($file) {
        if ($file.size < Defaults.maxFileUploadSize) {
          vm.selectedUpdateFiles.push($file);
        }
      });
    };

    vm.removeUpdateFile = function ($index) {
      vm.selectedUpdateFiles.splice($index, 1);
    };

    vm.post = function () {
      var toPost = {
        text: vm.newReply,
        idJobPosition: $scope.dataToEdit.id
      };

      vm.errorMessage = null;
      vm.isPosting = true;
      usSpinnerService.spin('spinner-my-work-edit-update');
      UpdatesManager.postUpdate(toPost, vm.selectedUpdateFiles, function (newUpdate) {
        vm.updatesLoader.records.unshift(newUpdate);
        vm.reset();
        vm.isPosting = false;
        usSpinnerService.stop('spinner-my-work-edit-update');
        $analytics.eventTrack('Post an Update', {category: 'Placed Work'});
      }, function () {
        vm.isPosting = false;
        vm.errorMessage = 'An error occurred';
        usSpinnerService.stop('spinner-my-work-edit-update');
      });
    };

    vm.reset = function () {
      vm.newReply = null;
      vm.selectedUpdateFiles = [];
    };

    vm.loadUpdates = function () {
      usSpinnerService.spin('spinner-work-edit-updates');
      vm.updatesLoader.load()
        .then(function () {
          usSpinnerService.stop('spinner-work-edit-updates');
        }, function () {
          usSpinnerService.stop('spinner-work-edit-updates');
        });
    };

    vm.openUpdateDetail = function (update) {
      MyWorkEditPanelManager.panels.open('updateReplies', {
        parentUpdate: update,
        panelsManager: MyWorkEditPanelManager.panels
      }).then(function (result) {
        if (result === 'deleted') {
          var $index = _.findIndex(vm.updatesLoader.records, {id: update.id});
          if ($index !== -1) {
            vm.updatesLoader.records.splice($index, 1);
          }
        }
      });
    };

    vm.openUpdateByIndex = function ($index) {
      $state.go('map.workPlacedWork.detail-updates.detail', {
        id: InitHelper.encryptId($scope.dataToEdit.id),
        idUpdate: InitHelper.encryptId(vm.updatesLoader.records[$index].id)
      });
    };

    vm.openUpdateById = function (id) {
      var $index = _.findIndex(vm.updatesLoader.records, {id: id});
      if ($index !== -1) {
        vm.openUpdateDetail(vm.updatesLoader.records[$index]);
      } else {
        Restangular.one('Updates').one('index').get({id: id}).then(function (result) {
          vm.openUpdateDetail(result.plain());
        });
      }
    };

    vm.openConversation = function (bid) {
      MyWorkEditPanelManager.panels.open('bidMessages', {
        contactProfile: bid.userApplicant,
        messagesParameters: {
          idJobApplication: bid.id
        }
      });
    };

    vm.openConversationById = function (id) {
      var $index = _.findIndex(vm.bidsLoader.records, {id: id});
      if ($index !== -1) {
        vm.openConversation(vm.bidsLoader.records[$index]);
      } else {
        Restangular.one('JobApplications').one('index').get({id: id}).then(function (result) {
          vm.openConversation(result.plain());
        });
      }
    };

    vm.openConversationByIndex = function ($index, accepted) {
      $state.go('map.workPlacedWork.detail.messages', {
        id: InitHelper.encryptId($scope.dataToEdit.id),
        idJobApplication: InitHelper.encryptId((accepted ? vm.acceptedBids()[$index].id : vm.bids()[$index].id))
      });
    };

    if (angular.isDefined($scope.tab) && $scope.tab !== '') {
      openTab($scope.tab);
    } else {
      openTab('bids');
    }

    $scope.$on('event:work-edit-open-update', function ($event, data) {
      vm.openUpdateById(data.id);
    });

    $scope.$on('event:work-edit-open-messages', function ($event, data) {
      vm.openConversationById(data.idJobApplication);
    });
  }

})();
