(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Map.Dashboards.controller:ExperienceDashboardCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Dashboards')
    .controller('ExperienceDashboardCtrl', ExperienceDashboardCtrl);

  function ExperienceDashboardCtrl($scope, usSpinnerService, $analytics, $state) {
    var vm = this;
    vm.message = null;
    vm.isLoading = true;
    vm.isEmpty = true;
    vm.params = null;

    vm.openPanel = function () {
      $state.go('map.experience');
      $analytics.eventTrack('Open Dashboard', {category: 'Experience'});
    };

    $scope.$on('event:dashboard-load-started', function () {
      vm.isLoading = true;
      usSpinnerService.spin('spinner-dashboard-experience');
    });

    $scope.$on('event:dashboard-experience-loaded', function ($event, data) {
      vm.params = data.params;
      vm.message = data.result.message;
      vm.counter = data.result.counters;
      vm.isEmpty = (data.result.data.recordsInfo.selectedRecords === 0);
      usSpinnerService.stop('spinner-dashboard-experience');
      vm.isLoading = false;
    });
  }

})();
