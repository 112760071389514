(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Panels.Profile.controller:ProfileAddResumeCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Profile')
    .controller('ProfileAddResumeCtrl', ProfileAddResumeCtrl);

  function ProfileAddResumeCtrl(PanelsProfileManager, usSpinnerService, $upload, Defaults, $analytics) {
    var vm = this;
    vm.editProfileErrorMessage = null;
    vm.selectedFile = null;
    vm.data = null;
    vm.isSaving = false;

    vm.closeEditPanel = function () {
      PanelsProfileManager.panels.close('addResume');
    };

    vm.onFileSelect = function ($files) {
      vm.editProfileErrorMessage = null;
      if (angular.isDefined($files[0])) {
        if ($files[0].size >= Defaults.maxFileUploadSize) {
          vm.editProfileErrorMessage = Defaults.maxFileUploadMessage;
        } else {
          vm.selectedFile = $files[0];
        }
      }
    };

    vm.removeFile = function () {
      vm.selectedFile = null;
    };

    vm.save = function () {
      vm.isSaving = true;
      usSpinnerService.spin('spinner-profile-edit');
      vm.editProfileErrorMessage = null;
      $upload.upload({
        url: Defaults.baseUrl + '/Resume/index',
        data: {
          name: vm.data.name
        },
        file: vm.selectedFile,
        fileFormDataName: 'resume'
      })
        .success(function (data) {
          usSpinnerService.stop('spinner-profile-edit');
          vm.isSaving = false;
          PanelsProfileManager.panels.close('addResume', data.content);
          $analytics.eventTrack('Add Resume', {category: 'Profile'});
        })
        .error(function (result) {
          vm.editProfileErrorMessage = result.errorMessage;
          usSpinnerService.stop('spinner-profile-edit');
          vm.isSaving = false;
        });
    };
  }

})();
