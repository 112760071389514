(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name Panels.People.filter:userAddressNotEmpty
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('Map.Panels.People')
    .filter('userAddressNotEmpty', userAddressNotEmpty);

  function userAddressNotEmpty() {
    return function (userProfile) {
      if (!angular.isObject(userProfile) || !angular.isDefined(userProfile)) {
        return false;
      }

      return (
        userProfile.streetAddress ||
        userProfile.suburb ||
        userProfile.postCode ||
        userProfile.state ||
        userProfile.country
      );
    };
  }

})();
