angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/toolbars/search/search-results-network.tpl.html',
    '<div id="map-search-results-network" class="row" ng-show="search.results.network.recordsInfo.selectedRecords">\n' +
    '  <div class="small-15 columns">\n' +
    '    <div class="map-search-result-container">\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <span class="map-search-results-title right">\n' +
    '            Connected People\n' +
    '          </span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row map-search-result-user-row" ng-repeat="user in search.results.network.records">\n' +
    '        <div class="small-2 medium-4 large-3 columns">\n' +
    '          <user-widget image-class="circle30" user="user" width="30" height="30"></user-widget>\n' +
    '        </div>\n' +
    '        <div class="small-10 medium-9 large-10 columns">\n' +
    '          <a ng-click="search.updateMapForUser(user.id)">\n' +
    '            <user-widget-name user="user"></user-widget-name>\n' +
    '          </a>\n' +
    '        </div>\n' +
    '        <div class="small-2 medium-2 large-2 columns">\n' +
    '          <span class="map-search-result-action right">\n' +
    '            <favourite-star iduser="{{user.id}}"></favourite-star>\n' +
    '          </span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row map-search-result-user-row" ng-show="search.hasMore(\'network\')">\n' +
    '        <div class="small-11 small-offset-2 columns end">\n' +
    '          <a class="load-more-link" ng-click="search.loadMore(\'network\')">View more results ...</a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
