(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name Panels.People.directive:userWidget
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="Panels.People">
   <file name="index.html">
   <user-widget></user-widget>
   </file>
   </example>
   *
   */
  angular
    .module('Map.Panels.People')
    .directive('userWidget', userWidget);

  function userWidget() {
    return {
      restrict: 'E',
      template: '<a ng-click="userWidget.openProfile($event)" title="{{userWidget.displayName}}">' +
      '<img ng-src="{{userWidget.profilePictureUrl}}" class="{{userWidget.imageClass}}" />' +
      '</a>',
      scope: {
        user: '=user',
        imageClass: '@',
        width: '@',
        height: '@'
      },
      replace: true,
      controllerAs: 'userWidget',
      controller: ['$scope', '$filter', '$state', 'InitHelper', function ($scope, $filter, $state, InitHelper) {
        var vm = this;
        vm.displayName = $scope.user.displayName;
        vm.profilePictureUrl = $filter('profilePicture')($scope.user, $scope.width, $scope.height);
        vm.imageClass = $scope.imageClass;
        vm.openProfile = function ($event) {
          $event.stopPropagation();
          $state.go('map.people.detail', {id: InitHelper.encryptId($scope.user.id)});
        };
      }]
    };
  }

})();
