(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name widgets.controller:AuthoriseCtrl
   *
   * @description
   *
   */
  angular
    .module('widgets')
    .controller('AuthoriseCtrl', AuthoriseCtrl);

  function AuthoriseCtrl($state, Auth, $stateParams, WidgetsHelper, userSession) {
    var vm = this;
    vm.userProfile = userSession.profile;

    if (!Auth.isAuthenticated()) {
      $state.go('signin', {
        apiKey: $stateParams.apiKey,
        inPopup: true
      });
      return;
    }

    vm.authorise = function () {
      WidgetsHelper.authorise($stateParams.apiKey);
    };

    vm.switch = function () {
      Auth.logout();
      $state.go('signin', {
        apiKey: $stateParams.apiKey,
        inPopup: true
      });
    };
  }

})();
