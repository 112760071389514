(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name Filters.filter:fullName
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('Filters')
    .filter('fullName', fullName);

  function fullName() {
    return function (input) {
      var temp = '';

      if (input.isCompany) {
        if (angular.isDefined(input.companyName)) {
          temp = input.companyName;
        }
      } else {
        if (angular.isDefined(input.fullName)) {
          temp = input.fullName;
        } else if (angular.isDefined(input.firstName) && angular.isDefined(input.lastName)) {
          temp = input.firstName + ' ' + input.lastName;
        }
      }

      return temp;
    };
  }

})();
