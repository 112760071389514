angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/updates/updates.tpl.html',
    '<div ng-controller="UpdatesCtrl as updates" class="map-panel-controller">\n' +
    '  <div id="map-panel-updates" class="map-panel map-primary-panel" ng-swipe-left="updates.closePanel()" ng-swipe-disable-mouse>\n' +
    '    <div class="row map-panel-heading">\n' +
    '      <div class="small-2 columns">\n' +
    '        <a class="arrow-back" ng-click="updates.closePanel()">\n' +
    '          <i class="fa fa-angle-double-left"></i>\n' +
    '        </a>\n' +
    '      </div>\n' +
    '      <div class="small-13 columns">\n' +
    '        <h3>Updates</h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="updates-replies-new">\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <form name="updateForm" role="updateForm">\n' +
    '            <div class="row">\n' +
    '              <div class="small-15 columns update-new-message-textarea-container">\n' +
    '                <div>\n' +
    '                  <textarea id="update-new-message" name="newMessage" ng-model="updates.newReply" ng-required="true"\n' +
    '                          rows="1"  placeholder="Post new update" msd-elastic=""></textarea>\n' +
    '                  <div class="update-reply-button-container">\n' +
    '                    <div class="update-upload-selected-files" ng-show="updates.selectedFiles.length">\n' +
    '                      <span ng-repeat="file in updates.selectedFiles">{{file.name}}\n' +
    '                        <a ng-click="updates.removeFile($index)" ng-hide="updates.isPosting"> <i class="fa fa-close"></i></a>\n' +
    '                        <br/>\n' +
    '                      </span>\n' +
    '                    </div>\n' +
    '                    <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-update-new"></span>\n' +
    '                    <span class="update-upload-container" ng-hide="updates.isPosting">\n' +
    '                      <input type="file" id="updatePostFile" multiple ng-file-select="updates.onFileSelect($files)" accept="image/*"/>\n' +
    '                      <a class="update-upload-button"><i class="fa fa-image"></i></a>\n' +
    '                    </span>\n' +
    '                    <button class="update-reply-new-button" ng-click="updates.post()" ng-disabled=\'updateForm.$invalid\' ng-hide="updates.isPosting">POST</button>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </form>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="map-primary-panel-content">\n' +
    '      <div class="row cursor-pointer update-row"\n' +
    '           ng-repeat="update in updates.updatesLoader.records | activeRecord: \'map.updates.replies\' : \'id\'"\n' +
    '           ng-class="{\'is-active-record\': update.isActiveRecord}"\n' +
    '           ng-click="updates.openUpdateByIndex($index)">\n' +
    '        <div class="small-5 columns map-panel-profile-photo">\n' +
    '          <img ng-src="{{update.userCreated | profilePicture:45}}" class="circle45">\n' +
    '        </div>\n' +
    '        <div class="small-10 columns">\n' +
    '          <user-widget-name user="update.userCreated"></user-widget-name>\n' +
    '          <br/>\n' +
    '          <span class="info-text">\n' +
    '            <i class="fa fa-clock-o"></i> {{update.modified}} ago\n' +
    '            <span ng-show="update.relatedUpdatesCount">\n' +
    '              <i class="fa fa-reply-all"></i>  {{update.relatedUpdatesCount}}\n' +
    '            </span>\n' +
    '            <span ng-show="update.mediaCountTotal">\n' +
    '              <i class="fa fa-image"></i> {{update.mediaCountTotal}}\n' +
    '            </span>\n' +
    '            <span ng-show="update.connectedRecords.location">\n' +
    '              <i class="fa fa-map-marker"></i>\n' +
    '            </span>\n' +
    '          </span><br/>\n' +
    '          <span class="update-text-content">\n' +
    '            {{update.textContent | truncate: 50: \'...\': true}}\n' +
    '          </span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div id="map-panel-replies-container" class="map-secondary-panel-container"></div>\n' +
    '</div>\n' +
    '');
}]);
