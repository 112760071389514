(function () {
  'use strict';

  /* @ngdoc object
   * @name Map.Toolbars
   *
   * @description
   *
   */
  angular
    .module('Map.Toolbars', [
      'Cache',
      'Map.Toolbars.FavouriteUsers',
      'Map.Toolbars.Search'
    ]);

  angular
    .module('Map.Toolbars')
    .config(config)
    .run(run);

  function config() {
  }

  function run() {
  }

})();
