(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name panels.profile.controller:SubscriptionSettingsCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Profile')
    .controller('SubscriptionSettingsCtrl', SubscriptionSettingsCtrl);

  function SubscriptionSettingsCtrl($scope, PanelsProfileManager, usSpinnerService, $analytics) {
    var vm = this;
    vm.isSaving = false;
    vm.editProfileErrorMessage = null;
    vm.subscriptions = $scope.subscriptions;

    vm.closeEditPanel = function () {
      PanelsProfileManager.panels.close('subscriptionSettings');
    };

    vm.save = function () {
      vm.isSaving = true;
      usSpinnerService.spin('spinner-profile-edit');
      vm.editProfileErrorMessage = null;
      vm.subscriptions.save().then(function () {
        usSpinnerService.stop('spinner-profile-edit');
        vm.isSaving = false;
        vm.closeEditPanel();
        $analytics.eventTrack('Update Subscription Settings - ' +
          (vm.subscriptions.mailUpdate ? 'subscribed' : 'unsubscribed'), {category: 'Profile'});
      }, function (result) {
        vm.editProfileErrorMessage = result.data.errorMessage;
        usSpinnerService.stop('spinner-profile-edit');
        vm.isSaving = false;
      });
    };
  }
}());
