angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/toolbars/favourite-users/favourite-users.tpl.html',
    '<div id="map-favourite-users" class="map-toolbar" ng-controller="FavouriteUsersCtrl as favourite">\n' +
    '  <div class="row">\n' +
    '    <div class="small-1 columns">\n' +
    '      <a class="map-favourite-users-arrow" ng-click="favourite.scrollLeft()">\n' +
    '        <i class="fa fa-chevron-left"></i>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '\n' +
    '    <div id="map-favourite-users-plus-container" class="small-1 columns">\n' +
    '      <a class="map-favourite-users-plus" ui-sref="map.people.network"\n' +
    '         analytics-on\n' +
    '         analytics-event="Open network panel"\n' +
    '         analytics-category="Favourite users">\n' +
    '        <span class="circle30">\n' +
    '          <i class="fa fa-plus"></i>\n' +
    '        </span>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '\n' +
    '    <div id="map-favourite-users-container" class="small-11 medium-10 large-12 columns">\n' +
    '      <div class="map-favourite-users-list">\n' +
    '        <div ng-repeat="user in favourite.list track by user.id" class="map-favourite-users-list-user" ng-class="{\'fav-user-active\': favourite.isVisible(user.id)}">\n' +
    '          <a ng-click="favourite.toggleVisibility(user.id)">\n' +
    '            <img ng-src="{{user | profilePicture:30}}" class="circle30" title="{{user | fullName}}" alt="{{user | fullName}}" />\n' +
    '          </a>\n' +
    '          <a class="map-favourite-users-delete" ng-click="favourite.removeUser($index)">\n' +
    '            <i class="fa fa-close"></i>\n' +
    '          </a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="small-1 columns">\n' +
    '      <a class="map-favourite-users-arrow" ng-click="favourite.scrollRight()">\n' +
    '        <i class="fa fa-chevron-right"></i>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '');
}]);
