angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/dashboards/work.tpl.html',
    '<div class="row dashboard-container" ng-controller="WorkDashboardCtrl as workDashboard"\n' +
    '     ng-click="workDashboard.openPanel()">\n' +
    '  <div class="small-15">\n' +
    '    <div class="row">\n' +
    '      <div class="small-15 small-centered medium-2 medium-uncentered large-2 large-uncentered columns icon-container text-center"><i\n' +
    '        class="fa fa-suitcase"></i></div>\n' +
    '      <div class="medium-13 large-13 hide-for-small-only columns text-right">\n' +
    '        <h4 class="dashboard-header text-white">Work\n' +
    '        <span\n' +
    '          us-spinner="{lines: 17, length: 0, width: 3,radius: 7, corners: 1, trail: 100, hwaccel: true, color: \'#ffffff\', left: \'-20px\'}"\n' +
    '          spinner-key="spinner-dashboard-work"></span>\n' +
    '        </h4>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row dashboard-content-container hide-for-small-only">\n' +
    '      <div class="small-15 columns">\n' +
    '        <div class="row" ng-show="workDashboard.isEmpty">\n' +
    '          <div class="small-5 columns text-left">\n' +
    '            <img src="/images/dashboards/work.png" />\n' +
    '          </div>\n' +
    '          <div class="small-10 columns dashboard-message text-right">\n' +
    '            {{workDashboard.message}}\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row" ng-show="!workDashboard.isEmpty">\n' +
    '          <div class="small-15 columns">\n' +
    '            <div class="row">\n' +
    '              <div class="small-15 columns text-center">\n' +
    '                <div class="hexagon work" title="{{workDashboard.counter.name}}">\n' +
    '                  <span>\n' +
    '                    {{workDashboard.counter.value}}\n' +
    '                  </span>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
