(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Map.Panels.controller:UpdatesCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Updates')
    .controller('UpdatesCtrl', UpdatesCtrl);

  function UpdatesCtrl(PanelsManager, Restangular, UpdatesManager, usSpinnerService,
                       $scope, Defaults, $rootScope, $timeout, $analytics, $state, RecordsSet, InitHelper) {
    var vm = this;
    vm.isPostButtonVisible = false;
    vm.newReply = null;
    vm.selectedFiles = [];
    vm.isPosting = false;
    vm.listElement = $('#map-panel-updates > .map-primary-panel-content');
    vm.params = {};
    vm.userProfile = $scope.userProfile;

    if (angular.isObject($scope.params)) {
      vm.params = $scope.params;
    }

    vm.updatesLoader = RecordsSet.getLoader('Updates', 'index', vm.params);

    vm.openUpdateDetail = function (update) {
      UpdatesManager.panels.open('updateReplies', {
        parentUpdate: update,
        panelsManager: UpdatesManager.panels
      }).then(function (result) {
        if (result === 'deleted') {
          var $index = _.findIndex(vm.updatesLoader.records, {id: update.id});
          if ($index !== -1) {
            vm.updatesLoader.records.splice($index, 1);
          }
        }
      });

      if (angular.isDefined(update.connectedRecords.location) &&
        update.connectedRecords.location.length > 0) {
        $timeout(function () {
          $rootScope.$broadcast('event:center-map', {
            idLocation: update.connectedRecords.location[0].id,
            offsetX: $('#map-panel-updates').width()
          });
        }, 100);
      }

      $analytics.eventTrack('Open Update Detail', {category: 'Updates'});
    };

    vm.openUpdateById = function (id) {
      var $index = _.findIndex(vm.updatesLoader.records, {id: id});
      if ($index !== -1) {
        vm.openUpdateDetail(vm.updatesLoader.records[$index]);
      } else {
        Restangular.one('Updates').one('index').get({id: id}).then(function (result) {
          vm.openUpdateDetail(result.plain());
        });
      }
    };

    vm.openUpdateByIndex = function ($index) {
      $state.go('map.updates.replies', {
        id: InitHelper.encryptId(vm.updatesLoader.records[$index].id)
      });
    };

    vm.onFileSelect = function ($files) {
      angular.forEach($files, function ($file) {
        if ($file.size < Defaults.maxFileUploadSize) {
          vm.selectedFiles.push($file);
        }
      });
    };

    vm.removeFile = function ($index) {
      vm.selectedFiles.splice($index, 1);
    };

    vm.post = function () {
      var toPost = {
        text: vm.newReply
      };

      if (angular.isDefined(vm.params.entityFilter) && angular.isObject(vm.params.entityFilter)) {
        toPost = angular.extend(toPost, vm.params.entityFilter);
      }

      vm.errorMessage = null;
      vm.isPosting = true;
      usSpinnerService.spin('spinner-update-new');
      UpdatesManager.postUpdate(toPost, vm.selectedFiles, function (newUpdate) {
        vm.updatesLoader.records.unshift(newUpdate);
        vm.reset();
        vm.isPosting = false;
        usSpinnerService.stop('spinner-update-new');
        $analytics.eventTrack('Post an Update', {category: 'Updates'});
      }, function () {
        vm.isPosting = false;
        vm.errorMessage = 'An error occurred';
        usSpinnerService.stop('spinner-update-new');
      });
    };

    vm.reset = function () {
      vm.newReply = null;
      vm.selectedFiles = [];
    };

    vm.closePanel = function () {
      PanelsManager.panels.close('updates');
    };

    vm.updatesLoader.load();

    $scope.$on('event:panel-activated:updates', function ($event, data) {
      if (angular.isObject(data.params.params) && !angular.equals(vm.params, data.params.params)) {
        vm.params = data.params.params;
        vm.updatesLoader.reset(vm.params);
        vm.updatesLoader.load();
      }
    });

    $scope.$on('event:updates-open-reply', function ($event, data) {
      vm.openUpdateById(data.idUpdate);
    });

    (function () {
      vm.listElement.scroll(angular.bind(vm, function (event) {
        if (!vm.updatesLoader.isLoading && vm.updatesLoader.hasMore() && event.currentTarget.scrollTop < 50) {
          vm.updatesLoader.load();
          $analytics.eventTrack('Load More Updates', {category: 'Updates'});
        }
      }));

      $('#update-new-message').keydown(angular.bind(vm, function (e) {
        if (e.ctrlKey && e.keyCode === 13 && vm.newReply) {
          vm.post();
          e.currentTarget.blur();
        }

        if (e.keyCode === 27) {
          e.stopPropagation();
          vm.reset();
          e.currentTarget.blur();
        }
      }));
    })();
  }

})();
