angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/messages/messages-thread.tpl.html',
    '<div id="map-panel-messages-thread" class="map-secondary-panel"\n' +
    '     ng-controller="MessagesThreadCtrl as thread" ng-swipe-left="thread.closePanel()" ng-swipe-disable-mouse>\n' +
    '  <div class="row map-panel-heading">\n' +
    '    <div class="small-2 columns">\n' +
    '      <a class="arrow-back" ng-click="thread.closePanel()">\n' +
    '        <i class="fa fa-angle-double-left"></i>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '    <div class="small-13 columns">\n' +
    '      <h3>\n' +
    '        <user-widget-name user="thread.contactProfile"></user-widget-name>\n' +
    '      </h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div messages-thread contact-profile="thread.contactProfile" my-profile="thread.myProfile" auto-loader="false"\n' +
    '     reversed="false"></div>\n' +
    '</div>\n' +
    '');
}]);
