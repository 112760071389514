(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Map.Panels.controller:ExperienceCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Experience')
    .controller('ExperienceCtrl', ExperienceCtrl);

  function ExperienceCtrl(PanelsManager, ExperiencePanelManager, $scope, ExperienceManager, $timeout, $rootScope,
                          $analytics, $state, RecordsSet, InitHelper) {
    var vm = this;
    vm.tab = '';
    vm.experienceTabLoaded = false;
    vm.myExperienceTabLoaded = false;
    vm.validationsTabLoaded = false;
    vm.isLoading = false;
    vm.params = {};

    if (angular.isObject($scope.params)) {
      vm.params = $scope.params;
    }

    vm.experienceLoader = RecordsSet.getLoader('Experience', 'index', vm.params);
    vm.myExperienceLoader = RecordsSet.getLoader('Experience', 'index', {
      idUser: $scope.userProfile.idUser
    });
    vm.validationsLoader = RecordsSet.getLoader('ExperienceValidations', 'index');

    vm.loadMore = function () {
      vm.experienceLoader.load();
      $analytics.eventTrack('Load More', {category: 'Experience'});
    };

    vm.openExperienceTab = function () {
      if (vm.experienceTabLoaded === false) {
        vm.experienceLoader.load();
        vm.experienceTabLoaded = true;
        $analytics.eventTrack('Experience Tab', {category: 'Experience'});
      }
    };

    vm.loadMoreMy = function () {
      vm.myExperienceLoader.load();
      $analytics.eventTrack('Load More my experience', {category: 'Experience'});
    };

    vm.openMyExperienceTab = function () {
      if (vm.myExperienceTabLoaded === false) {
        vm.myExperienceLoader.load();
        vm.myExperienceTabLoaded = true;
        $analytics.eventTrack('My Experience Tab', {category: 'Experience'});
      }
    };

    vm.loadMoreValidations = function () {
      vm.validationsLoader.load();
      $analytics.eventTrack('Load More validations', {category: 'Experience'});
    };

    vm.openValidationsTab = function () {
      if (vm.validationsTabLoaded === false) {
        vm.validationsLoader.load();
        vm.validationsTabLoaded = true;
        $analytics.eventTrack('Validations Tab', {category: 'Experience'});
      }
    };

    vm.openTab = function (tab) {
      ExperiencePanelManager.panels.closeAll(angular.noop);
      if (tab === vm.tab) {
        return;
      }
      vm.tab = tab;
      switch (tab) {
        case 'experience':
          vm.openExperienceTab();
          break;
        case 'myExperience':
          vm.openMyExperienceTab();
          break;
        case 'validations':
          vm.openValidationsTab();
          break;
      }
    };

    if (angular.isDefined($scope.tab) && $scope.tab !== '') {
      vm.openTab($scope.tab);
    } else {
      vm.openTab('experience');
    }

    if (angular.isDefined($scope.onOpen) && angular.isFunction($scope.onOpen)) {
      $scope.onOpen();
    }

    vm.closePanel = function () {
      ExperiencePanelManager.panels.closeAll();
      PanelsManager.panels.close('experience');
    };

    vm.openDetail = function (experience) {
      ExperiencePanelManager.panels.open('experienceDetail', {
        detail: experience,
        isMyExperience: false
      });
      $timeout(function () {
        $rootScope.$broadcast('event:center-map', {
          idLocation: experience.idLocation,
          offsetX: $('#map-panel-experience').width()
        });
      }, 100);
      $analytics.eventTrack('Open Detail', {category: 'Experience'});
    };

    vm.openDetailById = function (id) {
      var $index = _.findIndex(vm.experienceLoader.records, {id: id});
      if ($index !== -1) {
        vm.openDetail(vm.experienceLoader.records[$index]);
      } else {
        ExperienceManager.loadById(id, function (experience) {
          vm.openDetail(experience.plain());
        });
      }
    };

    vm.openDetailByIndex = function ($index) {
      $state.go('map.experience.detail', {
        id: InitHelper.encryptId(vm.experienceLoader.records[$index].id)
      });
    };

    vm.openMyDetailById = function (id) {
      var $index = _.findIndex(vm.myExperienceLoader.records, {id: id});
      if ($index !== -1) {
        vm.openMyDetail(vm.myExperienceLoader.records[$index]);
      } else {
        ExperienceManager.loadById(id, function (experience) {
          vm.openMyDetail(experience.plain());
        });
      }
    };

    vm.openMyDetailByIndex = function ($index) {
      $state.go('map.experienceMy.detail', {
        id: InitHelper.encryptId(vm.myExperienceLoader.records[$index].id)
      });
    };

    vm.openMyDetail = function (experience) {
      var $index = _.findIndex(vm.myExperienceLoader.records, {id: experience.id});

      ExperiencePanelManager.panels.open('experienceDetail', {
        detail: experience,
        isMyExperience: true
      }).then(function (result) {
        if (angular.isObject(result)) {
          if (result.action === 'remove') {
            if ($index !== -1) {
              vm.myExperienceLoader.records.splice($index, 1);
            }
          }

          if (result.action === 'edit') {
            ExperiencePanelManager.panels.open('experienceEdit', {
              experienceDetail: angular.copy(experience)
            })
              .then(function (result) {
                if (result.action === 'update') {
                  if ($index !== -1) {
                    vm.myExperienceLoader.records[$index] = result.data;
                  }
                }
              });
          }

          if (result.action === 'validate') {
            ExperiencePanelManager.panels.open('requestValidation', {
              detail: experience
            }).then(function () {
              vm.openMyDetail(experience);
            });
          }
        }
      });
      $timeout(function () {
        $rootScope.$broadcast('event:center-map', {
          idLocation: experience.idLocation,
          offsetX: $('#map-panel-experience').width()
        });
      }, 100);
      $analytics.eventTrack('Open Detail of my experience', {category: 'Experience'});
    };

    vm.openValidationById = function (id) {
      var $index = _.findIndex(vm.validationsLoader.records, {id: id});
      if ($index !== -1) {
        vm.openValidationDetail(vm.validationsLoader.records[$index]);
      } else {
        ExperienceManager.loadValidationById(id, function (validation) {
          vm.openValidationDetail(validation);
        });
      }
    };

    vm.openValidationByData = function (validation) {
      $state.go('map.experienceValidations.detail', {
        id: InitHelper.encryptId(validation.id)
      });
    };

    vm.openValidationDetail = function (validation) {
      ExperiencePanelManager.panels.open('validationDetail', {
        detail: validation
      })
        .then(function (result) {
          var $index = _.findIndex(vm.validationsLoader.records, {id: validation.id});
          if (angular.isObject(result)) {
            switch (result.action) {
              case 'processed':
                if ($index !== -1) {
                  vm.validationsLoader.records.splice($index, 1);
                }
                break;
              case 'resent':
                if (angular.isObject(result.validation)) {
                  vm.validationsLoader.records[$index] = result.validation;
                }
                break;
            }
          }
        });
      $analytics.eventTrack('Open Detail of validation', {category: 'Experience'});
    };

    $scope.$on('event:experience-added', function ($event, experience) {
      if (_.findIndex(vm.myExperienceLoader.records, {id: experience.id}) === -1) {
        vm.myExperienceLoader.records.push(experience);
      }
    });

    $scope.$on('event:panel-activated:experience', function ($event, data) {
      if (angular.isObject(data.params.params) && !angular.equals(vm.params, data.params.params)) {
        vm.params = data.params.params;
        vm.experienceLoader.reset(vm.params);
        vm.experienceTabLoaded = false;
        vm.tab = '';
      }

      if (angular.isDefined(data.params) && data.newPanel === true) {

        if (angular.isDefined(data.params.tab) && data.params.tab !== '') {
          vm.openTab(data.params.tab);
        }
        if (angular.isDefined(data.params.onOpen) && angular.isFunction(data.params.onOpen)) {
          data.params.onOpen();
        }
      }
    });

    $scope.$on('event:experience-open-detail', function ($event, data) {
      vm.openDetailById(data.idExperience);
    });

    $scope.$on('event:experience-open-detail-my', function ($event, data) {
      vm.openMyDetailById(data.idExperience);
    });

    $scope.$on('event:experience-open-detail-validation', function ($event, data) {
      vm.openValidationById(data.idExperienceValidation);
    });

    $scope.$on('event:experience-open-tab:experience', function () {
      vm.openTab('experience');
    });

    $scope.$on('event:experience-open-tab:myExperience', function () {
      vm.openTab('myExperience');
    });

    $scope.$on('event:experience-open-tab:validations', function () {
      vm.openTab('validations');
    });

    $scope.$on('event:experience-open-add-dropped-pin', function ($event, data) {
      ExperiencePanelManager.panels.open('experienceAdd', {
        location: data.location
      }).then(function () {
        $rootScope.$broadcast('event:experience-add-dropped-pin-cancelled');
      });
    });
  }

})();
