(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name Panels.People.directive:userActionButton
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="Panels.People">
       <file name="index.html">
        <user-action-button></user-action-button>
       </file>
     </example>
   *
   */
  angular
    .module('Map.Panels.People')
    .directive('userActionButton', userActionButton);

  function userActionButton() {
    return {
      restrict: 'EA',
      templateUrl: 'map/panels/people/user-action-button-directive.tpl.html',
      replace: true,
      controllerAs: 'userActionButton',
      scope: {
        user: '=user'
      },
      controller: function ($scope, NetworkManager, FavouriteUsers) {
        var vm = this;
        vm.isLoading = false;
        vm.isRequestSent = false;
        vm.isContact = false;
        vm.isSuggestion = false;

        vm.connect = function ($event) {
          $event.stopPropagation();
          NetworkManager.quickConnect($scope.user, function (result) {
            if (result === true) {
              vm.isRequestSent = true;
            }
          });
        };

        vm.toggleFavourite = function ($event) {
          $event.stopPropagation();
          vm.isLoading = true;
          FavouriteUsers.toggle($scope.user.id).then(function () {
            vm.isLoading = false;
          });
        };

        vm.isFavourite = function () {
          return FavouriteUsers.isFavourite($scope.user.id);
        };
      },
      link: function (scope, element, attrs, controller) {
        if (scope.user.profileRelation === 'confirmed_contact') {
          controller.isContact = true;
        } else if (scope.user.profileRelation !== 'owner') {
          controller.isSuggestion = true;
        }
      }
    };
  }

})();
