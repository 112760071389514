(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Work.service:WorkManager
   *
   * @description
   *
   */
  angular
    .module('Work')
    .service('WorkManager', WorkManager);

  function WorkManager($rootScope, $q, $modal, Restangular, $upload, Defaults) {
    var
      self = this;

    /**
     * Show apply for job dialog
     * @inner
     */
    self.showApplyForJobDialog = function (jobPosition) {
      $modal.open({
        templateUrl: 'work/apply-for-job.tpl.html',
        controller: 'ApplyForJobCtrl as vm',
        windowClass: 'dialog-container',
        resolve: {
          jobApplicationFiles: ['Restangular', function (Restangular) {
            return Restangular.one('JobApplications').all('files').getList();
          }],
          resume: ['Restangular', function (Restangular) {
            return Restangular.one('Resume').all('index').getList({limit: -1});
          }],
          jobPosition: [function () {
            if (angular.isDefined(jobPosition) && angular.isObject(jobPosition)) {
              return jobPosition;
            }

            return null;
          }],
          userSession: ['Auth', function (Auth) {
            return Auth.getSession();
          }]
        }
      });
    };

    /**
     * Apply for job
     * Request to the server can be done in two different ways depending on presence of uploaded files
     * @param {Object} applicationData
     * @param {Array} $files
     * @param {successCallback} successCallback - Tha callback handling successful response
     * @param {errorCallback} errorCallback - The callback handling error response
     */
    self.applyForJob = function (applicationData, $files, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;
      var dataEncoded;
      if (angular.isArray($files) && $files.length > 0) {
        $upload.upload({
          url: Defaults.baseUrl + '/JobApplications/index',
          data: applicationData,
          file: $files,
          method: 'POST',
          fileFormDataName: 'attachment[]',
          formDataAppender: function (formData, key, val) {
            if (angular.isArray(val)) {
              angular.forEach(val, function (v, k) {
                formData.append(key + '[' + k + ']', v);
              });
            } else {
              formData.append(key, val);
            }
          }
        })
          .success(function (result) {
            successCallback(result.content);
          })
          .error(function (result) {
            errorCallback(result.errorMessage);
          });
      } else {
        dataEncoded = $.param(applicationData);
        Restangular.one('JobApplications').customPOST(dataEncoded, 'index', {}, {
          'Content-Type': 'application/x-www-form-urlencoded'
        })
          .then(function (result) {
            successCallback(result);
          }, function (result) {
            errorCallback(result.data.errorMessage);
          });
      }
    };

    self.addWork = function (data, filesToUpload, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;
      Restangular.one('Work').customPOST(data, 'index')
        .then(function (result) {

          if (filesToUpload.length > 0) {
            var promises = [];
            angular.forEach(filesToUpload, function (file) {
              var deferred = $q.defer();
              promises.push(deferred.promise);
              $upload.upload({
                url: Defaults.baseUrl + '/WorkFiles/index?idJobPosition=' + result.id,
                file: file
              })
                .success(function (data) {
                  deferred.resolve(data.content);
                })
                .error(function () {
                  deferred.resolve(false);
                });
            });

            $q.all(promises).then(function (resolved) {
              angular.forEach(resolved, function (r) {
                if (r !== false) {
                  result.jobPositionFiles.recordsInfo.selectedRecords++;
                  result.jobPositionFiles.recordsInfo.totalRecords++;
                  result.jobPositionFiles.records.push(r);
                }
              });
              $rootScope.$broadcast('event:work-added');
              successCallback(result.plain());
            });
          } else {
            $rootScope.$broadcast('event:work-added');
            successCallback(result.plain());
          }
        }, function (result) {
          errorCallback(result.data.errorMessage);
        });
    };

    self.updateWork = function (id, data, filesIdsToRemove, filesToUpload, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;
      data.jobPositionFilesIdsToRemove = filesIdsToRemove || [];
      Restangular.one('Work').customPUT(data, 'index', {id: id})
        .then(function (result) {

          if (filesToUpload.length > 0) {
            var promises = [];
            angular.forEach(filesToUpload, function (file) {
              var deferred = $q.defer();
              promises.push(deferred.promise);
              $upload.upload({
                url: Defaults.baseUrl + '/WorkFiles/index?idJobPosition=' + id,
                file: file
              })
                .success(function (data) {
                  deferred.resolve(data.content);
                })
                .error(function () {
                  deferred.resolve(false);
                });
            });

            $q.all(promises).then(function (resolved) {
              angular.forEach(resolved, function (r) {
                if (r !== false) {
                  result.jobPositionFiles.recordsInfo.selectedRecords++;
                  result.jobPositionFiles.recordsInfo.totalRecords++;
                  result.jobPositionFiles.records.push(r);
                }
              });
              successCallback(result.plain());
            });
          } else {
            successCallback(result.plain());
          }
        }, function (result) {
          errorCallback(result.data.errorMessage);
        });
    };

    self.removeWork = function (id, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;
      Restangular.one('Work').one('index').remove({id: id}).then(function () {
        successCallback();
      }, function (result) {
        errorCallback(result.data.errorMessage);
      });
    };

    self.acceptBid = function (bid, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;
      Restangular.one('JobApplications').customPOST({}, 'accept', {id: bid.id})
        .then(function (result) {
          successCallback(result.plain());
        }, function (result) {
          errorCallback(result.data.errorMessage);
        });
    };

    self.withDrawBid = function (bid, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;
      Restangular.one('JobApplications').one('index').remove({id: bid.id})
        .then(function (result) {
          successCallback(result.plain());
        }, function (result) {
          errorCallback(result.data.errorMessage);
        });
    };
  }

})();
