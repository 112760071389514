(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Panels.Profile.controller:ProfileEditAboutCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Profile')
    .controller('ProfileEditAboutCtrl', ProfileEditAboutCtrl);

  function ProfileEditAboutCtrl(PanelsProfileManager, $scope, Auth, usSpinnerService, $analytics) {
    var vm = this;
    vm.editProfileErrorMessage = null;
    vm.isSaving = false;
    vm.profile = angular.copy($scope.userProfile.profile);
    vm.closeEditPanel = function () {
      PanelsProfileManager.panels.close('profileEditAbout');
    };

    vm.saveProfile = function () {
      vm.isSaving = true;
      usSpinnerService.spin('spinner-profile-edit');
      vm.editProfileErrorMessage = null;
      Auth.saveSession(vm.profile, function () {
        vm.isSaving = false;
        usSpinnerService.stop('spinner-profile-edit');
        vm.closeEditPanel();
        $analytics.eventTrack('Update About', {category: 'Profile'});
      }, function (error) {
        vm.isSaving = false;
        vm.editProfileErrorMessage = error;
        usSpinnerService.stop('spinner-profile-edit');
      });
    };
  }

})();
