angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/experience/experience-add.tpl.html',
    '<div id="map-panel-experience-add-action" class="map-secondary-panel"\n' +
    '     ng-controller="ExperienceAddCtrl as experienceAdd" ng-swipe-left="experienceAdd.closePanel()" ng-swipe-disable-mouse>\n' +
    '  <div class="row map-panel-heading">\n' +
    '    <div class="small-2 columns">\n' +
    '      <a class="arrow-back" ng-click="experienceAdd.closePanel()">\n' +
    '        <i class="fa fa-angle-double-left"></i>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '    <div class="small-13 columns">\n' +
    '      <h3>Add Experience</h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="experience-form-container">\n' +
    '    <div class="row">\n' +
    '      <div class="small-15 columns">\n' +
    '        <form name="addExperienceForm" role="addExperienceForm">\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns" ng-if="!experienceAdd.selectProject">\n' +
    '              <input type="text" name="projectName" ng-model="experienceAdd.data.projectName" ng-required="true" placeholder="PROJECT NAME" />\n' +
    '            </div>\n' +
    '            <div class="small-15 columns" ng-if="experienceAdd.selectProject">\n' +
    '              <select name="idProject" ng-model="experienceAdd.data.idProject">\n' +
    '                <option value="0">SELECT PROJECT OR ADD NEW</option>\n' +
    '                <option ng-repeat="project in experienceAdd.projects" ng-value="project.id"\n' +
    '                  ng-selected="project.id == experienceAdd.data.idProject">\n' +
    '                  {{project.name}}\n' +
    '                </option>\n' +
    '                <option value="-1">ADD NEW</option>\n' +
    '              </select>\n' +
    '              <input ng-if="experienceAdd.data.idProject == -1" type="text" name="projectName"\n' +
    '                     ng-model="experienceAdd.data.projectName" ng-required="true" placeholder="PROJECT NAME" />\n' +
    '\n' +
    '            </div>\n' +
    '            <div class="small-15 columns">\n' +
    '              <small class="request-validation-error">\n' +
    '                {{experienceAdd.errors.projectError}}\n' +
    '              </small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <angucomplete-alt\n' +
    '                id="experience-company-input"\n' +
    '                placeholder="SELECT COMPANY OR ADD NEW"\n' +
    '                pause="100"\n' +
    '                minlength="2"\n' +
    '                remote-url="{{experienceAdd.searchCompanyUrl}}"\n' +
    '                title-field="companyName"\n' +
    '                remote-url-response-formatter="experienceAdd.onSearchResponse"\n' +
    '                text-searching="SEARCHING ..."\n' +
    '                selected-object="experienceAdd.onCompanySearchSelect"\n' +
    '                match-class="text-orange"\n' +
    '                text-no-results=" "\n' +
    '                override-suggestions="true"\n' +
    '                input-changed="experienceAdd.onCompanyChanged"\n' +
    '                initial-value="{{experienceAdd.initialCompany}}"\n' +
    '                focus-in="experienceAdd.onFocusCompany()"\n' +
    '                />\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="small-15 columns">\n' +
    '              <small class="request-validation-error" ng-show="experienceAdd.errors.employerError">\n' +
    '                {{experienceAdd.errors.employerError}}\n' +
    '              </small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <angucomplete-alt\n' +
    '                id="experience-position-input"\n' +
    '                placeholder="SELECT POSITION OR ADD NEW"\n' +
    '                pause="100"\n' +
    '                minlength="2"\n' +
    '                remote-url="{{experienceAdd.searchPositionUrl}}"\n' +
    '                title-field="name"\n' +
    '                remote-url-response-formatter="experienceAdd.onSearchResponse"\n' +
    '                text-searching="SEARCHING ..."\n' +
    '                selected-object="experienceAdd.onPositionSearchSelect"\n' +
    '                match-class="text-orange"\n' +
    '                text-no-results=" "\n' +
    '                override-suggestions="true"\n' +
    '                input-changed="experienceAdd.onPositionChanged"\n' +
    '                />\n' +
    '\n' +
    '            </div>\n' +
    '            <div class="small-15 columns">\n' +
    '              <small class="request-validation-error" ng-show="experienceAdd.errors.jobTitleError">\n' +
    '                {{experienceAdd.errors.jobTitleError}}\n' +
    '              </small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row experience-date-picker-container">\n' +
    '              <div class="small-2 columns">FROM</div>\n' +
    '              <date-picker ng-model="experienceAdd.data.dateFrom"></date-picker>\n' +
    '\n' +
    '              <div class="small-15 columns">\n' +
    '                <small class="request-validation-error" ng-show="experienceAdd.errors.dateFromError">\n' +
    '                  {{experienceAdd.errors.dateFromError}}\n' +
    '                </small>\n' +
    '              </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row" ng-hide="experienceAdd.data.isCurrentExperience">\n' +
    '            <div class="small-2 columns">TO</div>\n' +
    '            <date-picker ng-model="experienceAdd.data.dateTo"></date-picker>\n' +
    '            <div class="small-15 columns">\n' +
    '              <small class="request-validation-error" ng-show="experienceAdd.errors.dateToError">\n' +
    '                {{experienceAdd.errors.dateToError}}\n' +
    '              </small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <input type="checkbox" id="isCurrentExperience" name="isCurrentExperience" ng-model="experienceAdd.data.isCurrentExperience" />\n' +
    '              <label for="isCurrentExperience">Currently working here</label>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <textarea name="description" ng-model="experienceAdd.data.description" msd-elastic\n' +
    '                        placeholder="ADD DESCRIPTION OF WORK"></textarea>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <tags-input\n' +
    '                ng-model="experienceAdd.data.skills"\n' +
    '                add-on-enter="true"\n' +
    '                placeholder="ADD A SKILL"\n' +
    '                display-property="name"\n' +
    '                replace-spaces-with-dashes="false"\n' +
    '                >\n' +
    '                <auto-complete source="experienceAdd.loadSkills($query)" min-length="2"></auto-complete>\n' +
    '              </tags-input>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row experience-form-buttons">\n' +
    '            <div class="small-10 columns">\n' +
    '              <small class="request-validation-error" ng-show="experienceAdd.errorMessage"\n' +
    '                     ng-bind-html="experienceAdd.errorMessage"></small>\n' +
    '            </div>\n' +
    '            <div class="small-5 columns text-right">\n' +
    '              <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-experience-add"></span>\n' +
    '              <button class="button positive" ng-hide="experienceAdd.isAdding"\n' +
    '                      ng-disabled="addExperienceForm.$invalid"\n' +
    '                      ng-click="experienceAdd.add()">\n' +
    '                SAVE\n' +
    '              </button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </form>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
