(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name urlShorteners.provider:BitlyUrlShortener
   *
   * @description
   *
   */
  angular
    .module('urlShorteners')
    .provider('BitlyUrlShortener', BitlyUrlShortener);

  function BitlyUrlShortener() {
    var config = {
      accessToken: null
    };

    return {
      setAccessToken: function (accessToken) {
        config.accessToken = accessToken;
      },
      $get: ['$http', 'Defaults', '$q', function ($http, Defaults, $q) {
        var cache = {};
        return {
          shorten: function (longUrl) {
            var deferred = $q.defer();

            if (angular.isDefined(cache[longUrl])) {
              deferred.resolve(cache[longUrl]);
            } else {
              $.get(
                'https://api-ssl.bitly.com/v3/shorten?' +
                'access_token=' + Defaults.bitlyGenericAccessToken +
                '&longUrl=' + encodeURIComponent(longUrl),
                {},
                function (result) {
                  cache[longUrl] = result.data.url;
                  deferred.resolve(result.data.url);
                }
              );
            }

            return deferred.promise;
          },
          expand: function (shortUrl) {
            var deferred = $q.defer();

            $.get(
              'https://api-ssl.bitly.com/v3/expand?' +
              'access_token=' + Defaults.bitlyGenericAccessToken +
              '&shortUrl=' + encodeURIComponent(shortUrl),
              {},
              function (result) {
                deferred.resolve(result.data.expand.long_url);
              }
            );

            return deferred.promise;
          }
        };
      }]
    };
  }
}());
