(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name map.dashboards.controller:WorkDialogCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Dashboards')
    .controller('WorkDialogCtrl', WorkDialogCtrl);

  function WorkDialogCtrl(WorkManager, $modalInstance) {
    var vm = this;

    vm.applyForJob = function () {
      $modalInstance.close();
      WorkManager.showApplyForJobDialog();
    };
  }

})();
