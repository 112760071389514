angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/profile/profile-edit-contact.tpl.html',
    '<div id="map-panel-profile-edit" class="map-secondary-panel"\n' +
    '     ng-controller="ProfileEditContactCtrl as editContact" ng-swipe-left="editContact.closeEditPanel()" ng-swipe-disable-mouse>\n' +
    '  <div class="row map-panel-heading">\n' +
    '    <div class="small-2 columns">\n' +
    '      <a class="arrow-back" ng-click="editContact.closeEditPanel()">\n' +
    '        <i class="fa fa-angle-double-left"></i>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '    <div class="small-13 columns">\n' +
    '      <h3>Contact Details</h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row profile-edit profile-edit-contact">\n' +
    '    <div class="small-15 columns">\n' +
    '      <form name="editProfileContactForm" role="editProfileContactForm">\n' +
    '        <div class="row">\n' +
    '          <div class="small-2 columns">\n' +
    '            <i class="fa fa-phone-square fa-2x"></i>\n' +
    '          </div>\n' +
    '          <div class="small-13 columns">\n' +
    '            <input type="text" name="phoneHome" ng-model="editContact.profile.phoneHome" ng-required="false"\n' +
    '                   placeholder="Phone"/>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="small-2 columns">\n' +
    '            <i class="fa fa-mobile fa-2x"></i>\n' +
    '          </div>\n' +
    '          <div class="small-13 columns">\n' +
    '            <input type="text" name="phoneMobile" ng-model="editContact.profile.phoneMobile" ng-required="false"\n' +
    '                   placeholder="Mobile"/>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="small-2 columns">\n' +
    '            <i class="fa fa-home fa-2x"></i>\n' +
    '          </div>\n' +
    '          <div class="small-13 columns">\n' +
    '            <textarea name="streetAddress" ng-model="editContact.profile.streetAddress" ng-required="false"\n' +
    '                      placeholder="Street Address" rows="2">\n' +
    '            </textarea>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="small-2 columns">\n' +
    '          </div>\n' +
    '          <div class="small-13 columns">\n' +
    '            <input type="text" name="suburb" ng-model="editContact.profile.suburb" ng-required="false" placeholder="Suburb"/>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="small-2 columns">\n' +
    '          </div>\n' +
    '          <div class="small-13 columns">\n' +
    '            <input type="text" name="postcode" ng-model="editContact.profile.postCode" ng-required="false"\n' +
    '                   placeholder="Post Code"/>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row" ng-show="editContact.states">\n' +
    '          <div class="small-2 columns">\n' +
    '          </div>\n' +
    '          <div class="small-13 columns">\n' +
    '            <select ng-model="editContact.profile.state.idState" name="idState" ng-required="true">\n' +
    '              <option ng-repeat="state in editContact.states" ng-value="state.idState" ng-selected="state.idState === editContact.profile.state.idState">\n' +
    '                {{state.name | lowercase | ucfirst}}\n' +
    '              </option>\n' +
    '            </select>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="small-2 columns">\n' +
    '            <i class="fa fa-globe fa-2x"></i>\n' +
    '          </div>\n' +
    '          <div class="small-13 columns">\n' +
    '            <select ng-model="editContact.profile.country.abbrevation" name="abbrevation" ng-required="true">\n' +
    '              <option ng-repeat="country in editContact.countries" ng-value="country.code" ng-selected="country.code === editContact.profile.country.abbrevation">\n' +
    '                {{country.name | lowercase | ucfirst}}\n' +
    '              </option>\n' +
    '            </select>\n' +
    '            <small class="profile-edit-error" ng-show="editProfileContactForm.abbrevation.$dirty && editProfileContactForm.abbrevation.$error.required">\n' +
    '              Missing country\n' +
    '            </small>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="small-8 small-offset-2 columns">\n' +
    '            <small class="profile-edit-error" ng-show="editContact.editProfileErrorMessage">{{editContact.editProfileErrorMessage}}</small>\n' +
    '          </div>\n' +
    '          <div class="small-5 columns profile-edit-button-container">\n' +
    '            <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-profile-edit"></span>\n' +
    '            <button ng-hide="editContact.isSaving" class="right button positive" ng-click="editContact.saveProfile()" ng-disabled=\'editProfileContactForm.$invalid\'>Save</button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
