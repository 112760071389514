angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/my-work/my-work-add.tpl.html',
    '<div id="map-panel-my-work-add-action" class="map-secondary-panel"\n' +
    '     ng-controller="MyWorkAddCtrl as myWorkAdd" ng-swipe-left="myWorkAdd.closePanel()" ng-swipe-disable-mouse>\n' +
    '  <div class="row map-panel-heading">\n' +
    '    <div class="small-2 columns">\n' +
    '      <a class="arrow-back" ng-click="myWorkAdd.closePanel()">\n' +
    '        <i class="fa fa-angle-double-left"></i>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '    <div class="small-13 columns">\n' +
    '      <h3>Place Work</h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="my-work-edit-container">\n' +
    '    <div class="row">\n' +
    '      <div class="small-15 columns">\n' +
    '        <form name="addWorkForm" role="addWorkForm">\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns" ng-if="!myWorkAdd.selectProject">\n' +
    '              <input type="text" name="projectName" ng-model="myWorkAdd.data.projectName" ng-required="true" placeholder="PROJECT NAME" />\n' +
    '            </div>\n' +
    '            <div class="small-15 columns" ng-if="myWorkAdd.selectProject">\n' +
    '              <select name="idProject" ng-model="myWorkAdd.data.idProject" ng-required="true">\n' +
    '                <option value="0">SELECT PROJECT</option>\n' +
    '                <option ng-repeat="project in myWorkAdd.projects" ng-value="project.id"\n' +
    '                        ng-selected="project.id == myWorkAdd.data.idProject">\n' +
    '                  {{project.name}}\n' +
    '                </option>\n' +
    '                <option value="-1">ADD NEW</option>\n' +
    '              </select>\n' +
    '              <input ng-if="myWorkAdd.data.idProject == -1" type="text" name="projectName"\n' +
    '                     ng-model="myWorkAdd.data.projectName" ng-required="true" placeholder="PROJECT NAME" />\n' +
    '            </div>\n' +
    '            <div class="small-15 columns">\n' +
    '              <small class="error" ng-show="myWorkAdd.idProject.$dirty && myWorkAdd.data.idProject==\'0\'">\n' +
    '                Project is required\n' +
    '              </small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row" ng-if="myWorkAdd.data.idProject == -1">\n' +
    '            <div class="small-15 columns">\n' +
    '              <angucomplete-alt\n' +
    '                id="work-company-input"\n' +
    '                placeholder="SELECT COMPANY OR ADD NEW"\n' +
    '                pause="100"\n' +
    '                minlength="2"\n' +
    '                remote-url="{{myWorkAdd.searchProjectOwnerUrl}}"\n' +
    '                title-field="companyName"\n' +
    '                remote-url-response-formatter="myWorkAdd.onSearchResponse"\n' +
    '                text-searching="SEARCHING ..."\n' +
    '                selected-object="myWorkAdd.onProjectOwnerSearchSelect"\n' +
    '                match-class="text-orange"\n' +
    '                text-no-results=" "\n' +
    '                override-suggestions="true"\n' +
    '                input-changed="myWorkAdd.onProjectOwnerChanged"\n' +
    '                initial-value="{{myWorkAdd.initialProjectOwnerName}}"\n' +
    '                focus-in="myWorkAdd.onFocusProjectOwner()"\n' +
    '                />\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="small-15 columns">\n' +
    '              <small class="request-validation-error" ng-show="addWorkForm.isProjectOwnerInvalid()">\n' +
    '                Company is required\n' +
    '              </small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <input type="text" name="reference" ng-model="myWorkAdd.data.reference" ng-required="true" placeholder="REFERENCE" />\n' +
    '            </div>\n' +
    '            <div class="small-15 columns">\n' +
    '              <small class="error" ng-show="addWorkForm.reference.$dirty && addWorkForm.reference.$error.required">\n' +
    '                Missing reference\n' +
    '              </small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <angucomplete-alt\n' +
    '                id="work-position-input"\n' +
    '                placeholder="SELECT POSITION OR ADD NEW"\n' +
    '                pause="100"\n' +
    '                minlength="2"\n' +
    '                remote-url="{{myWorkAdd.searchPositionUrl}}"\n' +
    '                title-field="name"\n' +
    '                remote-url-response-formatter="myWorkAdd.onSearchResponse"\n' +
    '                text-searching="SEARCHING ..."\n' +
    '                selected-object="myWorkAdd.onPositionSearchSelect"\n' +
    '                match-class="text-orange"\n' +
    '                text-no-results=" "\n' +
    '                override-suggestions="true"\n' +
    '                input-changed="myWorkAdd.onPositionChanged"\n' +
    '                initial-value="{{myWorkAdd.data.jobTitleName}}"\n' +
    '                focus-in="myWorkAdd.onFocusJobTitle()"\n' +
    '                focus-out="myWorkAdd.onFocusOutJobTitle()"\n' +
    '                />\n' +
    '            </div>\n' +
    '            <div class="small-15 columns">\n' +
    '              <small class="error" ng-show="myWorkAdd.isPositionInvalid()">Missing position</small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-8 columns">\n' +
    '              <input type="text" name="startDate" ng-model="myWorkAdd.data.startDate" id="work-start-date" placeholder="START DATE" title="START DATE" />\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="small-7 columns">\n' +
    '              <input type="text" name="endDate" ng-model="myWorkAdd.data.endDate" id="work-end-date" placeholder="END DATE" title="END DATE" />\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <textarea name="description" ng-model="myWorkAdd.data.description" msd-elastic\n' +
    '                        placeholder="DESCRIPTION OF WORK"></textarea>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <h4>Files</h4>\n' +
    '              <div>\n' +
    '                <span ng-repeat="file in myWorkAdd.selectedFiles">{{file.name}}\n' +
    '                  <a ng-click="myWorkAdd.removeFile($index)" ng-hide="myWorkAdd.isSaving"\n' +
    '                     title="Delete the file"> <i class="fa fa-close"></i></a>\n' +
    '                  <br/>\n' +
    '                </span>\n' +
    '              </div>\n' +
    '              <div class="custom-file-upload-button" ng-file-select="myWorkAdd.onFileSelect($files)" ng-multiple="true"\n' +
    '                   ng-hide="myWorkAdd.isSaving">\n' +
    '                <i class="fa fa-paperclip"></i> Attach Files\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <small class="error" ng-show="myWorkAdd.errorMessage"\n' +
    '                     ng-bind-html="myWorkAdd.errorMessage"></small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns text-right">\n' +
    '              <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-work-add"></span>\n' +
    '              <button class="button positive" ng-hide="myWorkAdd.isSaving"\n' +
    '                      ng-disabled="addWorkForm.$invalid || !myWorkAdd.jobTitleValid"\n' +
    '                      ng-click="myWorkAdd.save()">\n' +
    '                PLACE\n' +
    '              </button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </form>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
