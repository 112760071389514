(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Map.Dashboards.controller:UpdatesDashboardCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Dashboards')
    .controller('UpdatesDashboardCtrl', UpdatesDashboardCtrl);

  function UpdatesDashboardCtrl($scope, usSpinnerService, $analytics, $state) {
    var vm = this;
    vm.message = null;
    vm.isLoading = true;
    vm.isEmpty = true;
    vm.params = null;
    vm.updates = [];

    vm.openPanel = function () {
      $state.go('map.updates');
      $analytics.eventTrack('Open Dashboard', {category: 'Updates'});
    };

    $scope.$on('event:dashboard-load-started', function () {
      vm.isLoading = true;
      usSpinnerService.spin('spinner-dashboard-updates');
    });

    $scope.$on('event:dashboard-updates-loaded', function ($event, data) {
      vm.params = data.params;
      vm.message = data.result.message;
      vm.isEmpty = (data.result.data.recordsInfo.selectedRecords === 0);
      vm.updates = data.result.data.records;
      usSpinnerService.stop('spinner-dashboard-updates');
      vm.isLoading = false;
    });
  }

})();
