(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Security.factory:TokenInterceptor
   *
   * @description
   *
   */
  angular
    .module('Security')
    .factory('TokenInterceptor', TokenInterceptor)
    .config(config);

  function TokenInterceptor($injector, $q, $rootScope) {
    var TokenInterceptorBase = {};
    TokenInterceptorBase.request = function (config) {
      var token = $injector.get('Auth').getLoginToken();
      if (angular.isDefined(token) && token !== null && token !== '') {
        config.headers['X-Jwt-Assertion'] = token;
      }
      return config;
    };

    TokenInterceptorBase.responseError = function (rejection) {
      if (rejection.status === 403) {
        $rootScope.$broadcast('event:auth-loginRequired', rejection);
      }
      // otherwise, default behaviour
      return $q.reject(rejection);
    };

    return TokenInterceptorBase;
  }

  function config($httpProvider) {
    $httpProvider.interceptors.push('TokenInterceptor');
  }

})();
