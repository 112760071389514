window.deferredBootstrapper.bootstrap({
  element: window.document,
  module: 'Mineler',
  injectorModules: ['restangular', 'http-auth-interceptor', 'Security', 'mm.foundation', 'Configuration'],
  resolve: {
    IS_AUTHENTICATED: ['Auth', '$q', 'Restangular', 'Defaults',
                       function (Auth, $q, Restangular, Defaults) {
                         'use strict';
                         Restangular.setBaseUrl(Defaults.baseUrl);
                         Restangular.addResponseInterceptor(function (data, operation) {
                           var extractedData;
                           if (operation === 'getList') {
                             extractedData = data.content.records;
                             extractedData.meta = data.content.recordsInfo;
                           } else {
                             extractedData = angular.extend({}, data.content);
                           }
                           extractedData.contentEtag = data.contentEtag;
                           extractedData.responseStatus = {
                             code: data.content.statusCode,
                             text: data.content.statusText
                           };

                           return extractedData;
                         });
                         Restangular.setDefaultHeaders({Accept: 'application/json'});

                         var deferred = $q.defer();
                         Auth.getSession().then(function () {
                           deferred.resolve(Auth.isAuthenticated());
                         });
                         return deferred.promise;
                       }]
  }
});
