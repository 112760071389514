(function () {
  'use strict';

  /* @ngdoc object
   * @name Map.Panels.MyWork
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.MyWork', [
      'ui.router'
    ]);

  angular
    .module('Map.Panels.MyWork')
    .config(config);

  function config() {
  }

})();
