(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Panels.People.controller:PeopleInviteToJoinCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.People')
    .controller('PeopleInviteToJoinCtrl', PeopleInviteToJoinCtrl);

  function PeopleInviteToJoinCtrl(PeopleManager, usSpinnerService, NetworkManager, $analytics, SocialContacts) {
    var vm = this;
    vm.isSending = false;
    vm.isImporting = false;
    vm.errorMessage = null;
    vm.importErrorMessage = null;
    vm.data = {
      firstName: null,
      lastName: null,
      email: null,
      message: 'I would like to connect with you on Mineler.'
    };

    vm.closePanel = function (data) {
      if (!angular.isDefined(data)) {
        data = {};
      }
      if (!angular.isDefined(data.isSent)) {
        data.isSent = false;
      }

      PeopleManager.panels.close('inviteToJoin', data);
    };

    vm.send = function () {
      vm.isSending = true;
      usSpinnerService.spin('spinner-people-invite-to-join');
      NetworkManager.connect(null, vm.data.email, vm.data.firstName, vm.data.lastName, vm.data.message,
        function (result) {
          vm.closePanel({
            isSent: true,
            invite: result.plain()
          });
          $analytics.eventTrack('Invite To Join', {category: 'People'});
        }, function (reason) {
          vm.isSending = false;
          usSpinnerService.stop('spinner-people-invite-to-join');
          vm.errorMessage = reason;
        });
    };

    vm.importFacebook = function () {
      startImport();
      SocialContacts.importContacts('facebook')
        .then(finishImport, function (reason) {
          vm.importErrorMessage = reason;
          finishImport();
        });
    };

    vm.importGoogleplus = function () {
      startImport();
      SocialContacts.importContacts('google')
        .then(finishImport, function (reason) {
          vm.importErrorMessage = reason;
          finishImport();
        });
    };

    vm.importLinkedin = function () {
      startImport();
      SocialContacts.importContacts('linkedin')
        .then(finishImport, function (reason) {
          vm.importErrorMessage = reason;
          finishImport();
        });
    };

    vm.importVCard = function ($files) {
      if (angular.isDefined($files[0])) {
        startImport();
        SocialContacts.importContactsFromVCard($files[0])
          .then(finishImport, function (reason) {
            vm.importErrorMessage = reason;
            finishImport();
          });
      }
    };

    function startImport() {
      vm.isImporting = true;
      vm.importErrorMessage = null;
      usSpinnerService.spin('spinner-import-contacts');
    }

    function finishImport() {
      vm.isImporting = false;
      usSpinnerService.stop('spinner-import-contacts');
    }
  }

})();
