angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/people/user-profile.tpl.html',
    '<div class="row">\n' +
    '  <div class="small-15 columns">\n' +
    '    <img class="circle45" ng-src="{{vm.user | profilePicture:45}}"/>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="row" ng-if="vm.user.personalMessage">\n' +
    '  <div class="small-15 columns">\n' +
    '    <h3>About</h3>\n' +
    '    <div>\n' +
    '      {{vm.user.personalMessage}}\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="row">\n' +
    '  <div class="small-15 columns">\n' +
    '    <div ng-if="vm.user.phoneHome || vm.user.phoneMobile || vm.user.email">\n' +
    '      <h3>Contact</h3>\n' +
    '          <span ng-show="vm.user.phoneHome"><i\n' +
    '            class="fa fa-phone-square"></i> {{vm.user.phoneHome}}<br/></span>\n' +
    '          <span ng-show="vm.user.phoneMobile"><i\n' +
    '            class="fa fa-mobile"></i> {{vm.user.phoneMobile}}<br/></span>\n' +
    '          <span  ng-show="vm.user.email">\n' +
    '            <i class="fa fa-envelope-square"></i> {{vm.user.email}}</span>\n' +
    '      <br/><br/>\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-if="vm.user | userAddressNotEmpty">\n' +
    '      <h3>Address</h3>\n' +
    '      <div class="map-panel-profile-address">\n' +
    '          <span>\n' +
    '          <i class="fa fa-home"></i>\n' +
    '            </span>\n' +
    '          <span>\n' +
    '            <span ng-show="vm.user.streetAddress">{{vm.user.streetAddress}}<br/></span>\n' +
    '            <span ng-show="vm.user.suburb">{{vm.user.suburb}}</span> <span\n' +
    '            ng-show="vm.user.postCode">{{vm.user.postCode}}<br/></span>\n' +
    '            <span ng-show="vm.user.state.name">{{vm.user.state.name}}<br/></span>\n' +
    '            {{vm.user.country.name}}\n' +
    '          </span>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
