(function () {
  'use strict';

  /* @ngdoc object
   * @name Network
   * @requires $stateProvider
   *
   * @description
   *
   */
  angular
    .module('Network', [
    ]);

  angular
    .module('Network')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('networkAccept', {
        url: '/network/request/{token}',
        templateUrl: 'network/network_request.tpl.html',
        controller: 'NetworkRequestCtrl as networkRequest',
        authenticate: false,
        resolve: {
          contactRequest: ['$stateParams', 'NetworkManager', function ($stateParams, NetworkManager) {
            return NetworkManager.requestDetail($stateParams.token).then(function (result) {
                return result;
              }, function (result) {
                return {
                  errorMessageUser: result.data.errorMessageUser,
                  errorCode: result.data.errorCode
                };
              });
          }]
        }
      });
  }

})();
