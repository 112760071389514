(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name directives.directive:projectWidget
   * @restrict EA
   * @element
   *
   * @description Generates a link with project name from experience data,
   * when clicked shows pins for project and set project name into search bar
   *
   * @example
     <example module="directives">
       <file name="index.html">
        <project-widget></project-widget>
       </file>
     </example>
   *
   */
  angular
    .module('directives')
    .directive('projectWidget', projectWidget);

  function projectWidget() {
    return {
      restrict: 'EA',
      scope: {
        experience: '=experience'
      },
      template: '<a ng-click="projectWidget.showLocations()" title="Show locations">{{projectWidget.projectName}}</a>',
      replace: true,
      controllerAs: 'projectWidget',
      controller: function ($scope, MapComponent) {
        var vm = this;
        vm.projectName = $scope.experience.projectName;
        vm.showLocations = function () {
          MapComponent.loadProjectLocations([$scope.experience.idProject]);
          $('#map-search-input_value').val($scope.experience.projectName);
        };
      }
    };
  }
}());
