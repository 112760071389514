(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name directives.directive:companyLogoWidget
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="directives">
       <file name="index.html">
        <company-logo-widget></company-logo-widget>
       </file>
     </example>
   *
   */
  angular
    .module('directives')
    .directive('companyLogoWidget', companyLogoWidget);

  function companyLogoWidget() {
    return {
      restrict: 'E',
      template: '<span>' +
      '<img ng-if="companyLogoWidget.logoUrl" ng-src="{{companyLogoWidget.logoUrl}}" ' +
      'class="{{companyLogoWidget.imageClass}}" ' +
      'title="{{companyLogoWidget.title}}" />' +
      '<i ng-if="!companyLogoWidget.logoUrl" class="fa fa-building-o fa-2x"></i>' +
      '</span>',
      scope: {
        company: '=',
        imageClass: '@',
        width: '@',
        height: '@'
      },
      replace: true,
      controllerAs: 'companyLogoWidget',
      controller: ['$scope', '$filter', function ($scope, $filter) {
        var vm = this;
        vm.title = $scope.company.customerName;
        vm.logoUrl = $filter('companyLogo')($scope.company, $scope.width, $scope.height);
        vm.imageClass = $scope.imageClass;
      }]
    };
  }
}());
