angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/my-work/my-work-edit-bids.tpl.html',
    '<div class="my-work-edit-container-bids">\n' +
    '  <div class="row" ng-if="myWorkEdit.bidsLoader.records.length < 1 && !myWorkEdit.bidsLoader.isLoading">\n' +
    '    <div class="small-15 columns">\n' +
    '      <h4>No bids received</h4>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row cursor-pointer" ng-repeat="application in myWorkEdit.acceptedBids() | activeRecord: \'map.workPlacedWork.detail.messages\' : \'idJobApplication\'"\n' +
    '       ng-class="{\'accepted-bid\': application.accepted, \'withdrawn-bid\': application.withDrawn, \'is-active-record\': application.isActiveRecord}"\n' +
    '       ng-click="myWorkEdit.openConversationByIndex($index, true)">\n' +
    '    <div class="small-3 columns map-panel-profile-photo">\n' +
    '      <user-widget image-class="circle45" user="application.userApplicant" width="45" height="45"></user-widget>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="small-12 columns">\n' +
    '      <div class="small-8 columns">\n' +
    '          <span class="panel-text-heading">\n' +
    '            <user-widget-name user="application.userApplicant"></user-widget-name>\n' +
    '          </span>\n' +
    '      </div>\n' +
    '      <div class="small-7 columns text-right">\n' +
    '          <span class="panel-text-heading">\n' +
    '            {{application.bid | currency:\'$\':0}}\n' +
    '          </span>\n' +
    '      </div>\n' +
    '      <div class="small-15 columns">\n' +
    '          <span class="info-text">\n' +
    '            <i class="fa fa-clock-o"></i> {{myWorkEdit.applicationDate(application)}}\n' +
    '          </span><br/>\n' +
    '          <span class="cursor-pointer" ng-if="myWorkEdit.isBidCommentExpanded(application)"\n' +
    '                ng-click="myWorkEdit.toggleBidComment(application)" title="Click to collapse">\n' +
    '            {{application.comments}}\n' +
    '          </span>\n' +
    '          <span class="cursor-pointer" ng-if="!myWorkEdit.isBidCommentExpanded(application)"\n' +
    '                ng-click="myWorkEdit.toggleBidComment(application)" title="Click to expand">\n' +
    '            {{application.comments | truncate: 50: \'...\': true}}\n' +
    '          </span>\n' +
    '        <br/>\n' +
    '\n' +
    '        <div>\n' +
    '          <div ng-repeat="bidFile in\n' +
    '        application.candidateFiles.records">\n' +
    '            <file-download url="myWorkEdit.bidFileUrl(bidFile)" label="bidFile.name" type="textLink"\n' +
    '                           class="file-download-link"\n' +
    '                           event-category="Work" event-name="Download Bid File"></file-download>\n' +
    '            <br/>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row cursor-pointer" ng-repeat="application in myWorkEdit.bids() | activeRecord: \'map.workPlacedWork.detail.messages\' : \'idJobApplication\'"\n' +
    '       ng-class="{\'accepted-bid\': application.accepted, \'withdrawn-bid\': application.withDrawn, \'is-active-record\': application.isActiveRecord}"\n' +
    '       ng-click="myWorkEdit.openConversationByIndex($index, false)">\n' +
    '\n' +
    '    <div class="small-3 columns map-panel-profile-photo">\n' +
    '      <user-widget image-class="circle45" user="application.userApplicant" width="45" height="45"></user-widget>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="small-12 columns">\n' +
    '      <div class="small-8 columns">\n' +
    '          <span class="panel-text-heading">\n' +
    '            {{application.userApplicant.firstName}} {{application.userApplicant.lastName}}\n' +
    '          </span>\n' +
    '      </div>\n' +
    '      <div class="small-7 columns text-right">\n' +
    '          <span class="panel-text-heading">\n' +
    '            {{application.bid | currency:\'$\':0}}\n' +
    '          </span>\n' +
    '      </div>\n' +
    '      <div class="small-15 columns">\n' +
    '          <span class="info-text">\n' +
    '            <i class="fa fa-clock-o"></i> {{myWorkEdit.applicationDate(application)}}\n' +
    '          </span>\n' +
    '      </div>\n' +
    '      <div class="small-10 columns">\n' +
    '          <span class="cursor-pointer" ng-if="myWorkEdit.isBidCommentExpanded(application)"\n' +
    '                ng-click="myWorkEdit.toggleBidComment(application)" title="Click to collapse">\n' +
    '            {{application.comments}}\n' +
    '          </span>\n' +
    '          <span class="cursor-pointer" ng-if="!myWorkEdit.isBidCommentExpanded(application)"\n' +
    '                ng-click="myWorkEdit.toggleBidComment(application)" title="Click to expand">\n' +
    '            {{application.comments | truncate: 50: \'...\': true}}\n' +
    '          </span>\n' +
    '        <br/>\n' +
    '\n' +
    '        <div>\n' +
    '          <div ng-repeat="bidFile in\n' +
    '        application.candidateFiles.records">\n' +
    '            <file-download url="myWorkEdit.bidFileUrl(bidFile)" label="bidFile.name" type="textLink"\n' +
    '                           class="file-download-link"\n' +
    '                           event-category="Work" event-name="Download Bid File"></file-download>\n' +
    '            <br/>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="small-5 columns text-right">\n' +
    '        <button ng-if="myWorkEdit.allowEdit && !application.withDrawn" class="button positive"\n' +
    '                ng-disabled="myWorkEdit.isSending" ng-click="myWorkEdit.acceptBid($index)">ACCEPT\n' +
    '        </button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row" ng-show="myWorkEdit.bidsLoader.hasMore() && !myWorkEdit.bidsLoader.isLoading">\n' +
    '    <div class="small-15 columns text-center">\n' +
    '      <a ng-click="myWorkEdit.bidsLoader.load()">Load more ...</a>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
