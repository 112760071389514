angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/my-work/bid-messages.tpl.html',
    '<div id="map-panel-work-messages-action" class="map-tertiary-panel"\n' +
    '     ng-controller="BidMessagesCtrl as bidMessages" ng-swipe-left="bidMessages.closePanel()" ng-swipe-disable-mouse>\n' +
    '  <div class="row map-panel-heading">\n' +
    '    <div class="small-2 columns">\n' +
    '      <a class="arrow-back" ng-click="bidMessages.closePanel()">\n' +
    '        <i class="fa fa-angle-double-left"></i>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '    <div class="small-13 columns">\n' +
    '      <h3>\n' +
    '        <user-widget-name user="bidMessages.contactProfile"></user-widget-name>\n' +
    '      </h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div messages-thread contact-profile="bidMessages.contactProfile" my-profile="bidMessages.myProfile" auto-loader="false"\n' +
    '       reversed="false" parameters="bidMessages.messagesParameters"></div>\n' +
    '</div>\n' +
    '');
}]);
