angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/toolbars/search/search-results-projects.tpl.html',
    '<div id="map-search-results-projects" class="row" ng-show="search.results.projects.recordsInfo.selectedRecords">\n' +
    '  <div class="small-15 columns">\n' +
    '    <div class="map-search-result-container">\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <span class="map-search-results-title right">\n' +
    '            Projects\n' +
    '          </span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row cursor-pointer" ng-repeat="project in search.results.projects.records" ng-click="search.updateMapForProject(project.id)">\n' +
    '        <div class="small-2 medium-4 large-3 columns">\n' +
    '          <i class="fa fa-flag-checkered fa-2x"></i>\n' +
    '        </div>\n' +
    '        <div class="small-10 medium-9 large-10 columns">\n' +
    '          <a>{{project.name}}</a>\n' +
    '        </div>\n' +
    '        <div class="small-2 medium-2 large-2 columns">\n' +
    '\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row map-search-result-user-row" ng-show="search.hasMore(\'projects\')">\n' +
    '        <div class="small-11 small-offset-2 columns end">\n' +
    '          <a class="load-more-link" ng-click="search.loadMore(\'projects\')">View more results ...</a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
