(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Map.Dashboards.controller:PeopleDashboardCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Dashboards')
    .controller('PeopleDashboardCtrl', PeopleDashboardCtrl);

  function PeopleDashboardCtrl($scope, usSpinnerService, $analytics, $state) {
    var vm = this;
    vm.message = null;
    vm.isLoading = true;
    vm.isEmpty = true;
    vm.params = null;
    vm.suggestions = [];

    vm.openPanel = function () {
      $state.go('map.people.suggestions');
      $analytics.eventTrack('Open Dashboard', {category: 'People'});
    };

    $scope.$on('event:dashboard-load-started', function () {
      vm.isLoading = true;
      usSpinnerService.spin('spinner-dashboard-people');
    });

    $scope.$on('event:dashboard-network-loaded', function ($event, data) {
      vm.params = data.params;
      vm.message = data.result.message;
      vm.isEmpty = (data.result.data.recordsInfo.selectedRecords === 0);
      vm.suggestions = data.result.data.records;
      usSpinnerService.stop('spinner-dashboard-people');
      vm.isLoading = false;
    });
  }

})();
