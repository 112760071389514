(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Map.Dashboards.controller:MinelerDashboardCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Dashboards')
    .controller('MinelerDashboardCtrl', MinelerDashboardCtrl);

  function MinelerDashboardCtrl(MapComponent, $rootScope, Restangular) {
    var vm = this;
    vm.isMineler = true;
    vm.company = null;

    function resetLogo() {
      vm.isMineler = true;
      vm.company = null;
    }

    function setCompanyLogo(company) {
      if (angular.isObject(company) && angular.isString(company.logoToken)) {
        vm.company = company;
        vm.isMineler = false;
      } else {
        resetLogo();
      }
    }

    vm.goHome = function () {
      MapComponent.turnFilterOff();
      $rootScope.$broadcast('event:map-reset-home');
    };

    $rootScope.$on('event:map-entity-updated', function ($event, eventData) {
      if (eventData.currentMapEntity.entity === 'company') {
        if (angular.isUndefined(eventData.entityDetail)) {
          Restangular.one('Customers').one('index').get({id: eventData.currentMapEntity.data[0]})
            .then(function (result) {
              setCompanyLogo(result.plain());
            });
        }
        setCompanyLogo(eventData.entityDetail);
      } else {
        resetLogo();
      }
    });
  }

})();
