(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Components.controller:PromptDialogCtrl
   *
   * @description
   *
   */
  angular
    .module('Components')
    .controller('PromptDialogCtrl', PromptDialogCtrl);

  function PromptDialogCtrl($modalInstance, content, onConfirmMessage, $timeout) {
    var vm = this;
    vm.content = content;
    vm.onConfirmMessage = onConfirmMessage;
    vm.isConfirmed = false;

    vm.confirm = function () {
      vm.isConfirmed = true;
      if (vm.onConfirmMessage) {
        $timeout(function () {
          $modalInstance.close();
        }, 3000);
      } else {
        $modalInstance.close();
      }
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }

})();
