angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/toolbars/buttons.tpl.html',
    '<div id="map-buttons" ng-controller="ButtonsCtrl as buttons">\n' +
    '  <a ng-click="buttons.goHome()" title="Home Country"><i class="fa fa-home"></i></a>\n' +
    '  <a ng-click="buttons.toggleFilter()" ng-class="{\'map-button-active\': buttons.isFilter()}" title="Cancel Filter"><i class="fa fa-undo"></i></a>\n' +
    '  <a ng-click="buttons.setRoadView()" ng-show="buttons.isSatelliteView" title="Road View"><i class="fa fa-road"></i></a>\n' +
    '  <a ng-click="buttons.setSatelliteView()" ng-hide="buttons.isSatelliteView" title="Satellite View"><i class="fa fa-globe"></i></a>\n' +
    '  <a ng-click="buttons.resetMap()"><i class="fa fa-crosshairs" title="Center Map"></i></a>\n' +
    '  <a ng-click="buttons.dropPin()" ng-class="{\'map-button-active\': buttons.isDroppedPin()}" title="Drop Pin"><i class="fa fa-map-marker"></i></a>\n' +
    '  <a ng-click="buttons.toggleMyVisibility()" ng-class="{\'map-button-active\': buttons.myLocationsVisible()}" title="My Experience On/Off"><i class="fa fa-user"></i></a>\n' +
    '  <a ng-click="buttons.toggleCluster()" ng-class="{\'map-button-active\': buttons.isCluster()}" title="Cluster On/Off"><i class="fa fa-bullseye"></i></a>\n' +
    '  <a ng-click="buttons.openHelp()" title="Help"><i class="fa fa-question"></i></a>\n' +
    '</div>\n' +
    '');
}]);
