angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('lost-password/lost-password.tpl.html',
    '<div id="lost-password" class="image-background-connection">\n' +
    '  <div class="row outside-container">\n' +
    '    <div class="small-15 medium-9 large-8 columns outside-form" ng-if="!lostPassword.isSent">\n' +
    '      <form name="lostPasswordForm" role="lostPasswordForm">\n' +
    '        <div class="row">\n' +
    '          <div class="small-6 small-push-9 columns text-right">\n' +
    '            <img src="images/mineler.png" class="home-page-logo"/>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="small-15 columns text-left">\n' +
    '            <h4>LOST YOUR PASSWORD?</h4>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row">\n' +
    '          <div class="small-15 columns text-left">\n' +
    '            Please enter your email address and we will send you instructions on how to reset your password.\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row">\n' +
    '          <div class="small-15 columns">\n' +
    '            <input name="email" type="email" ng-model="lostPassword.email"\n' +
    '                   placeholder="Email" ng-required="true"  />\n' +
    '            <small class="error" ng-show="lostPasswordForm.email.$dirty && lostPasswordForm.email.$error.required">\n' +
    '              Missing email\n' +
    '            </small>\n' +
    '            <small class="error" ng-show="lostPasswordForm.email.$dirty && lostPasswordForm.email.$error.email">\n' +
    '              This is not a valid email\n' +
    '            </small>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row content">\n' +
    '          <div class="small-15 columns">\n' +
    '            <div re-captcha ng-model="lostPassword.captcha"></div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row">\n' +
    '          <div class="small-10 columns">\n' +
    '            <small class="error" ng-show="lostPassword.errorMessage">{{lostPassword.errorMessage}}</small>\n' +
    '          </div>\n' +
    '          <div class="small-5 columns text-right">\n' +
    '            <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-password-reset"></span>\n' +
    '            <button ng-hide="lostPassword.isSending" class="button positive" ng-click="lostPassword.send()"\n' +
    '                    ng-disabled=\'lostPasswordForm.$invalid\'>Continue</button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '      </form>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="small-15 medium-11 large-10 columns outside-message" ng-if="lostPassword.isSent">\n' +
    '      <div class="row">\n' +
    '        <div class="small-6 small-push-9 columns text-right">\n' +
    '          <img src="images/mineler.png" class="home-page-logo"/>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row">\n' +
    '        <div class="small-6 medium-3 columns message-icon">\n' +
    '          <i class="fa fa-check-circle"></i>\n' +
    '        </div>\n' +
    '        <div class="small-9 medium-12 columns message-text text-left">\n' +
    '          <h3>Great Work!</h3>\n' +
    '          Check your email for instructions.\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns text-right">\n' +
    '          <button class="button positive" ng-click="lostPassword.continue()">Continue</button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '</div>\n' +
    '');
}]);
