(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Feedback.controller:FeedbackCtrl
   *
   * @description
   *
   */
  angular
    .module('Feedback')
    .controller('FeedbackCtrl', FeedbackCtrl);

  function FeedbackCtrl(FeedbackManager, $modalInstance, userProfile, usSpinnerService, Defaults) {
    var vm = this;
    vm.errorMessage = null;
    vm.selectedFiles = [];
    vm.isEditable = true;
    vm.isSent = false;
    vm.isSending = false;
    vm.data = {
      name: '',
      email: '',
      subject: '',
      message: ''
    };

    if (angular.isDefined(userProfile) && userProfile.isLoaded()) {
      vm.isEditable = false;
      vm.data.name = userProfile.profile.firstName + ' ' + userProfile.profile.lastName;
      vm.data.email = userProfile.profile.email;
    }

    vm.removeFile = function ($index) {
      vm.selectedFiles.splice($index, 1);
    };

    vm.onFileSelect = function ($files) {
      angular.forEach($files, function ($file) {
        if ($file.size < Defaults.maxFileUploadSize) {
          vm.selectedFiles.push($file);
        }
      });
    };

    vm.close = function () {
      $modalInstance.close();
    };

    vm.submit = function () {
      vm.errorMessage = null;
      vm.isSending = true;
      usSpinnerService.spin('spinner-feedback-submit');
      FeedbackManager.submit(vm.data, vm.selectedFiles, function () {
        vm.isSent = true;
      }, function (reason) {
        vm.errorMessage = reason;
        vm.isSending = false;
        usSpinnerService.stop('spinner-feedback-submit');
      });
    };
  }

})();
