(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Security.Login.controller:MissingDataCtrl
   *
   * @description
   *
   */
  angular
    .module('Security.User')
    .controller('MissingDataCtrl', MissingDataCtrl);

  function MissingDataCtrl($modalInstance, Auth, ISO3166, usSpinnerService, userProfile) {
    var
      vm = this,
      profile = userProfile.profile;
    vm.countries = [];
    angular.forEach(ISO3166.codeToCountry, function (value, key) {
      vm.countries.push({code: key, name: value});
    });
    vm.isLoading = false;
    vm.countryCode = null;
    vm.companyName = null;
    vm.showCountry = (!angular.isDefined(profile.country.idCountry) || parseInt(profile.country.idCountry) < 1);
    vm.showCompanyName = (profile.isCompany === true && (profile.companyName === '' || !profile.companyName));

    vm.save = function () {
      vm.errorMessage = null;
      vm.isLoading = true;
      usSpinnerService.spin('spinner-missing-data');

      if (vm.showCountry) {
        if (!angular.isObject(profile.country)) {
          profile.country = {};
        }
        profile.country.abbrevation = vm.countryCode;
      }

      if (vm.showCompanyName) {
        profile.companyName = vm.companyName;
      }

      Auth.saveSession(profile, function (session) {
        $modalInstance.close(session.profile);
      }, function (error) {
        vm.errorMessage = error;
        vm.isLoading = false;
        usSpinnerService.stop('spinner-missing-data');
      });
    };

  }

})();
