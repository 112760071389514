(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name Filters.filter:companyLogo
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('Filters')
    .filter('companyLogo', companyLogo);

  function companyLogo(Defaults) {
    return function (input, width, height) {
      var
        temp = null,
        params = {},
        paramsStr;

      width = parseInt(width);
      height = parseInt(height);
      if (input !== null && angular.isDefined(input) && angular.isString(input.logoToken)) {
        temp = Defaults.baseUrl + '/Customers/logo/' + input.logoToken;
        if (!isNaN(width) && width > 0) {
          params.w = width;
        }

        if (!isNaN(height) && height > 0) {
          params.h = height;
        }

        paramsStr = $.param(params);
        if (paramsStr !== '') {
          temp += '?' + paramsStr;
        }

      }

      return temp;
    };
  }
}());
