(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home-module.controller:SignUpCtrl
   *
   * @description
   *
   */
  angular
    .module('home')
    .controller('SignUpCtrl', SignUpCtrl);

  function SignUpCtrl(Auth, $state, SocialAccounts, ISO3166, usSpinnerService,
                      preRegistration, $analytics, $stateParams, WidgetsHelper, $rootScope) {
    var vm = this;
    vm.isSignupAsCompany = false;
    vm.isSignupAsIndividual = false;

    if ($stateParams.inPopup === true) {
      vm.linksTarget = '_blank';
    } else {
      vm.linksTarget = '_self';
    }

    function successCallback(openIntro) {
      if ($stateParams.inPopup === true && angular.isDefined($stateParams.apiKey)) {
        WidgetsHelper.authorise($stateParams.apiKey);
      } else {
        $state.go('map');
        if (openIntro === true) {
          $rootScope.$broadcast('event:open-intro');
        }
      }
    }

    vm.countries = [];
    angular.forEach(ISO3166.codeToCountry, function (value, key) {
      vm.countries.push({code: key, name: value});
    });
    vm.errorMessage = null;
    vm.isLoading = false;
    if (preRegistration !== false && angular.isObject(preRegistration) && !angular.isDefined($stateParams.apiKey)) {
      vm.signupData = {
        idCountry: preRegistration.idCountry,
        countryCode: preRegistration.countryCode,
        companyName: preRegistration.companyName || null,
        firstName: preRegistration.firstName,
        lastName: preRegistration.lastName,
        email: preRegistration.email,
        preregistrationToken: preRegistration.preregistrationToken
      };
    } else {
      vm.signupData = {
        countryCode: '',
        preregistrationToken: null
      };
    }

    vm.clearSignup = function () {
      vm.signupData = {
        countryCode: ''
      };
      vm.errorMessage = null;
    };

    vm.signUp = function () {
      vm.isLoading = true;
      usSpinnerService.spin('spinner-signup');
      vm.signupData.isSignupAsCompany = vm.isSignupAsCompany;
      Auth.signUp(vm.signupData, function () {
        $analytics.eventTrack('Name and Email', {category: 'Sign Up'});
        successCallback(true);
      }, function (reason) {
        vm.errorMessage = reason;
        vm.isLoading = false;
        usSpinnerService.stop('spinner-signup');
      });
    };

    vm.socialSignIn = function (provider) {
      vm.isLoading = true;
      vm.errorMessage = null;
      usSpinnerService.spin('spinner-signup');
      SocialAccounts.signIn(provider, true, vm.isSignupAsCompany).then(function () {
        successCallback(false);
      }, function (reason) {
        vm.isLoading = false;
        usSpinnerService.stop('spinner-signup');
        vm.errorMessage = reason;
      });
    };

    vm.goToSignin = function () {
      $state.go('signin', {
        apiKey: $stateParams.apiKey,
        inPopup: $stateParams.inPopup
      });
    };

    vm.isValidName = function () {
      if (vm.isSignupAsCompany) {
        return (angular.isDefined(vm.signupData.companyName) && vm.signupData.companyName !== '');
      }

      if (vm.isSignupAsIndividual) {
        return (angular.isDefined(vm.signupData.firstName) && vm.signupData.firstName !== '' &&
        angular.isDefined(vm.signupData.lastName) && vm.signupData.lastName !== '');
      }

      return false;
    };

    vm.signupAsCompany = function () {
      vm.isSignupAsCompany = true;
      vm.isSignupAsIndividual = false;
      vm.signupData.firstName = null;
      vm.signupData.lastName = null;
    };

    vm.signupAsIndividual = function () {
      vm.isSignupAsIndividual = true;
      vm.isSignupAsCompany = false;
      vm.signupData.companyName = null;
    };

  }

})();
