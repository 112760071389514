(function () {
  'use strict';

  /* @ngdoc object
   * @name Panels.People
   * @requires $stateProvider
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.People', [
      'angularFileUpload'
    ]);

  angular
    .module('Map.Panels.People')
    .config(config);

  function config($stateProvider, Defaults) {
    $stateProvider
      .state('map.people', angular.extend({}, Defaults.mapStateParams, {
        url: '/people',
        onEnter: ['QueueManager', 'InitHelper', function (QueueManager, InitHelper) {
          QueueManager.add(InitHelper.getOpenPanelQueueItem('people'));
        }]
      }))
      .state('map.people.invite', angular.extend({}, Defaults.mapStateParams, {
        url: '/invite',
        onEnter: ['QueueManager', 'InitHelper', 'QueueItem', '$q', '$timeout', '$rootScope',
          function (QueueManager, InitHelper, QueueItem, $q, $timeout, $rootScope) {
            QueueManager.add(new QueueItem(function (panelReady) {
              $q.when(panelReady).then(function () {
                $timeout(function () {
                  $rootScope.$broadcast('event:people-network-invite');
                });
              });

              return $q.when(true);
            }));
          }]
      }))
      .state('map.people.detail', angular.extend({}, Defaults.mapStateParams, {
        url: '/{id}/detail',
        onEnter: ['QueueManager', 'InitHelper', 'QueueItem', '$q', '$timeout', '$rootScope', '$stateParams',
          function (QueueManager, InitHelper, QueueItem, $q, $timeout, $rootScope, $stateParams) {
            QueueManager.add(new QueueItem(function (panelReady) {
              $q.when(panelReady).then(function () {
                $timeout(function () {
                  $rootScope.$broadcast('event:people-network-open-detail', {
                    id: InitHelper.decryptId($stateParams.id)
                  });
                }, 100);
              });

              return $q.when(true);
            }));
          }]
      }))
      .state('map.people.network', angular.extend({}, Defaults.mapStateParams, {
        url: '/network',
        onEnter: ['QueueManager', 'InitHelper', function (QueueManager, InitHelper) {
          QueueManager.add(InitHelper.getOpenPanelTabQueueItem('people', 'people'));
        }]
      }))
      .state('map.people.suggestions', angular.extend({}, Defaults.mapStateParams, {
        url: '/suggestions',
        onEnter: ['QueueManager', 'InitHelper', function (QueueManager, InitHelper) {
          QueueManager.add(InitHelper.getOpenPanelTabQueueItem('people', 'suggestions'));
        }]
      }))
      .state('map.people.invites', angular.extend({}, Defaults.mapStateParams, {
        url: '/invites',
        onEnter: ['QueueManager', 'InitHelper', function (QueueManager, InitHelper) {
          QueueManager.add(InitHelper.getOpenPanelTabQueueItem('people', 'invites'));
        }]
      }));
  }

})();
