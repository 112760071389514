angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/people/people.tpl.html',
    '<div ng-controller="PeopleCtrl as people" class="map-panel-controller">\n' +
    '  <div id="map-panel-people-container" class="map-panel map-primary-panel" ng-swipe-left="people.closePanel()" ng-swipe-disable-mouse>\n' +
    '    <div class="row map-panel-heading">\n' +
    '      <div class="small-2 columns">\n' +
    '        <a class="arrow-back" ng-click="people.closePanel()">\n' +
    '          <i class="fa fa-angle-double-left"></i>\n' +
    '        </a>\n' +
    '      </div>\n' +
    '      <div class="small-13 columns">\n' +
    '        <ul>\n' +
    '          <li class="cursor-pointer" ng-class="{\'panel-heading-active\': people.tab===\'people\'}"\n' +
    '              ><a ui-sref="map.people.network">People</a>\n' +
    '          </li>\n' +
    '          <li class="cursor-pointer" ng-class="{\'panel-heading-active\': people.tab===\'suggestions\'}"\n' +
    '              ><a ui-sref="map.people.suggestions">Suggestions</a>\n' +
    '          </li>\n' +
    '          <li class="cursor-pointer" ng-class="{\'panel-heading-active\': people.tab===\'invites\'}"\n' +
    '              ><a ui-sref="map.people.invites">Invites</a>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row people-panel-invite-button">\n' +
    '      <div class="small-15 columns text-center">\n' +
    '        <a class="button positive" ui-sref="map.people.invite">Invite people to join Mineler</a>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="map-primary-panel-content" ng-show="people.tab === \'people\'">\n' +
    '      <div class="row cursor-pointer"\n' +
    '           ng-repeat="contact in people.peopleLoader.records | activeRecord: \'map.people.detail\' : \'id\'"\n' +
    '           ng-class="{\'is-active-record\': contact.isActiveRecord}"\n' +
    '           ng-click="people.openContactByIndex($index)">\n' +
    '        <div class="small-5 columns map-panel-profile-photo">\n' +
    '          <img ng-src="{{contact | profilePicture:45}}" class="circle45">\n' +
    '        </div>\n' +
    '        <div class="small-8 columns">\n' +
    '            <span class="panel-text-heading">\n' +
    '              <user-widget-name user="contact" one-line="false"></user-widget-name>\n' +
    '            </span>\n' +
    '        </div>\n' +
    '        <div class="small-2 columns">\n' +
    '          <favourite-star iduser="{{contact.id}}"></favourite-star>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row" ng-show="people.peopleLoader.hasMore() && !people.peopleLoader.isLoading">\n' +
    '        <div class="small-15 columns text-center">\n' +
    '          <a ng-click="people.loadMorePeople()">Load more ...</a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="map-primary-panel-content" ng-show="people.tab === \'suggestions\'">\n' +
    '      <div class="row cursor-pointer" ng-repeat="suggestion in people.suggestionsLoader.records"\n' +
    '           ng-click="people.openSuggestion($index)" title="{{people.getSuggestionTitle($index)}}">\n' +
    '        <div class="small-5 columns map-panel-profile-photo">\n' +
    '          <img ng-src="{{suggestion | profilePicture:45}}" class="circle45">\n' +
    '        </div>\n' +
    '        <div class="small-8 columns">\n' +
    '            <span class="panel-text-heading">\n' +
    '              <user-widget-name user="suggestion" one-line="false"></user-widget-name>\n' +
    '            </span>\n' +
    '        </div>\n' +
    '        <div class="small-2 columns" ng-class="{\'text-orange\': suggestion.profileRelation==\'unconfirmed_contact\'}">\n' +
    '          <i class="fa fa-plus"></i>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row" ng-show="people.suggestionsLoader.hasMore() && !people.suggestionsLoader.isLoading">\n' +
    '        <div class="small-15 columns text-center">\n' +
    '          <a ng-click="people.loadMoreSuggestions()">Load more ...</a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="map-primary-panel-content" ng-show="people.tab === \'invites\'">\n' +
    '      <div ng-repeat="(isMyRequest, invites) in people.invites | groupBy: \'isMyRequest\'" ng-class="{\'sent-invites-container\': isMyRequest==\'true\'}">\n' +
    '        <div ng-if="isMyRequest == \'true\'" class="invites-group-heading">Sent Invites</div>\n' +
    '        <div ng-if="isMyRequest == \'false\'" class="invites-group-heading">Received Invites</div>\n' +
    '        <div class="row cursor-pointer" ng-repeat="invite in invites" ng-click="people.openInvite(invite)">\n' +
    '          <div class="small-5 columns map-panel-profile-photo">\n' +
    '            <img ng-if="isMyRequest == \'true\'" ng-src="{{invite.userTo | profilePicture:45}}" class="circle45">\n' +
    '            <img ng-if="isMyRequest == \'false\'" ng-src="{{invite.userFrom | profilePicture:45}}" class="circle45">\n' +
    '          </div>\n' +
    '          <div class="small-5 columns">\n' +
    '            <span ng-if="isMyRequest == \'true\'" class="panel-text-heading">\n' +
    '              <user-widget-name user="invite.userTo" one-line="false"></user-widget-name>\n' +
    '            </span>\n' +
    '\n' +
    '            <span ng-if="isMyRequest == \'false\'" class="panel-text-heading">\n' +
    '              <user-widget-name user="invite.userFrom" one-line="false"></user-widget-name>\n' +
    '            </span>\n' +
    '          </div>\n' +
    '          <div class="small-5 columns text-right">\n' +
    '            <span class="info-text">\n' +
    '              <i class="fa fa-clock-o"></i> {{invite.lastSent}} ago\n' +
    '            </span>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '\n' +
    '  <div id="map-panel-people-actions" class="map-secondary-panel-container"></div>\n' +
    '</div>\n' +
    '');
}]);
