(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Configuration.constant:Defaults
   *
   * @description
   *
   */
  angular
    .module('Configuration')
    .constant('Defaults', {
      appUrl: 'https://www.mineler.com',
      baseUrl: 'https://login.mineler.com/dataapi/mineler/v1',
      facebookAppId: '575776945889074',
      linkedInAppKey: '75sogbef6vt9qz',
      linkedInProxy: 'https://login.mineler.com/dataapi/mineler/v1/Users/linkedinOAuthProxy',
      googlePlusClientId: '310397972279-6au9vf2dcg4clrcasbhs4grqatvm7vaa.apps.googleusercontent.com',
      passwordPattern: '^.*(?=.{6,}).*$',
      passwordMessage: 'Invalid format of password, it must be at least 6 characters long',
      apiKey: 'zRsMeAwjthhgoLoC3EHkUKmiFFnHkRtXF6oTCMHcoocqfVMDGP',
      recaptchaPublicKey: '6Lc1kv0SAAAAACbb47-TpK7ZVieZ28K3FkW7nwOP',
      googleAnalyticsKey: 'UA-50129432-2',
      linkedInScope: 'r_basicprofile,r_emailaddress',
      googlePlusScope: 'https://www.googleapis.com/auth/userinfo.email,' +
      'https://www.googleapis.com/auth/userinfo.profile',
      maxFileUploadSize: 10485760,
      maxFileUploadMessage: 'Max allowed size of file is 10M',
      mapStateParams: {
        url: '/app',
        params: {nextSteps: []},
        templateUrl: 'map/map.tpl.html',
        controller: 'MapCtrl',
        authenticate: true
      },
      passPhrase: '9eZxFYKuNsgcAgccJ6HfS8X7N',
      bitlyGenericAccessToken: '0afb347a91e218dc96fc8c728e42f7edc0521576',
      googleMapsApiKey: 'AIzaSyAz12wJRceMrZq8LRgFbQmmf-nZ2rj8qAA'
    });

})();
