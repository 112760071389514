angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/toolbars/search/search-results-companies.tpl.html',
    '<div id="map-search-results-companies" class="row" ng-show="search.results.companies.recordsInfo.selectedRecords">\n' +
    '  <div class="small-15 columns">\n' +
    '    <div class="map-search-result-container">\n' +
    '      <div class="row">\n' +
    '        <div class="small-15 columns">\n' +
    '          <span class="map-search-results-title right">\n' +
    '            Companies\n' +
    '          </span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row cursor-pointer" ng-repeat="company in search.results.companies.records" ng-click="search.updateMapForCompany(company)">\n' +
    '        <div class="small-3 medium-4 large-3 columns">\n' +
    '          <company-logo-widget company="company" width="30" height="30" image-class="circle30"></company-logo-widget>\n' +
    '        </div>\n' +
    '        <div class="small-10 medium-9 large-10 columns">\n' +
    '          <a>{{company.companyName}}</a>\n' +
    '        </div>\n' +
    '        <div class="small-2 medium-2 large-2 columns">\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row map-search-result-user-row" ng-show="search.hasMore(\'companies\')">\n' +
    '        <div class="small-11 small-offset-2 columns end">\n' +
    '          <a class="load-more-link" ng-click="search.loadMore(\'companies\')">View more results ...</a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
