(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name Network.directive:networkAddIcon
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="Network">
   <file name="index.html">
   <network-add-icon></network-add-icon>
   </file>
   </example>
   *
   */
  angular
    .module('Network')
    .directive('networkAddIcon', networkAddIcon);

  function networkAddIcon(NetworkManager) {
    return {
      restrict: 'E',
      template: '<a ng-click="connect($event)" ng-hide="isRequestSent">' +
        '<i class="fa fa-plus"></i>' +
        '</a>',
      replace: true,
      scope: {
        user: '=user'
      },
      link: function (scope) {
        scope.isLoading = false;
        scope.isRequestSent = false;

        scope.connect = function ($event) {
          $event.stopPropagation();
          NetworkManager.quickConnect(scope.user, function (result) {
            if (result === true) {
              scope.isRequestSent = true;
            }
          });
        };

      }
    };
  }

})();
