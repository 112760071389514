(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Map.Panels.Work.service:WorkDetailPanelManager
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Work')
    .service('WorkDetailPanelManager', WorkDetailPanelManager);

  function WorkDetailPanelManager(PanelsFactory, $state) {
    var
      self = this,
      targetContainerId = 'map-panel-work-detail-actions',
      panels = {
        messagesThread: {
          id: 'map-panel-work-messages',
          templateUrl: 'map/panels/work/work-messages.tpl.html',
          resolve: {
            userProfile: ['Auth', function (Auth) {
              return Auth.getSession();
            }]
          }
        },
        updateReplies: {
          id: 'map-panel-update-replies',
          templateUrl: 'map/panels/updates/updates-replies.tpl.html',
          resolve: {
            userProfile: ['Auth', function (Auth) {
              return Auth.getSession();
            }]
          }
        }
      };

    self.panels = PanelsFactory.get(panels, targetContainerId, true, false, false, function () {
      $state.go('^');
    });
  }
}());
