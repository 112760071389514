(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Security.service:Session
   *
   * @description
   *
   */
  angular
    .module('Security')
    .service('Session', Session);

  function Session($rootScope) {
    var self = this;

    self.idUser = null;
    self.profile = null;
    self.userRole = null;

    self.create = function (idUser, profile, userRole) {
      self.idUser = idUser;
      self.profile = profile;
      self.profile.fullName = self.profile.firstName + ' ' + self.profile.lastName;
      self.userRole = userRole;
      self.profile.displayName = (self.profile.isCompany === true ? self.profile.companyName : self.profile.fullName);

      if (!angular.isObject(profile.country)) {
        self.profile.country = {};
      }

      if (!angular.isDefined(self.profile.country.idCountry) || parseInt(self.profile.country.idCountry) < 1 ||
          (self.profile.isCompany === true && (self.profile.companyName === '' || !self.profile.companyName))) {
        $rootScope.$broadcast('event:user-profileMissingData');
      }

      if (!angular.isObject(profile.state)) {
        self.profile.state = {};
      }
    };

    self.destroy = function () {
      self.idUser = null;
      self.profile = null;
      self.userRole = null;
    };

    self.isLoaded = function () {
      return !!self.idUser;
    };
  }

})();
