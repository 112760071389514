(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Network.controller:NetworkAcceptCtrl
   *
   * @description
   *
   */
  angular
    .module('Network')
    .controller('NetworkRequestCtrl', NetworkRequestCtrl);

  function NetworkRequestCtrl(contactRequest, NetworkManager, usSpinnerService, $state, $analytics) {
    var
      vm = this,
      nextStep = function (result) {
        if (!angular.isDefined(result) || !angular.isObject(result)) {
          result = {};
        }
        if (angular.isDefined(result.nextStep) && angular.isObject(result.nextStep)) {
          if (angular.isDefined(result.nextStep.userRegistration)) {
            $state.go('verify', {
              token: result.nextStep.userRegistration
            });
          } else {
            $state.go('signin');
          }
        } else {
          $state.go('signin');
        }
      };

    vm.validToken = true;
    vm.error201 = false;
    vm.error202 = false;
    vm.error203 = false;

    if (angular.isDefined(contactRequest.errorCode)) {
      vm.validToken = false;
      switch (contactRequest.errorCode) {
        case 201:
          vm.error201 = true;
          break;
        case 202:
          vm.error202 = true;
          break;
        case 203:
          vm.error203 = true;
          break;
        default:
          vm.error201 = true;
          break;
      }
    }

    vm.contactRequest = contactRequest;
    vm.isLoading = false;

    vm.continue = function () {
      nextStep();
    };

    vm.accept = function () {
      vm.errorMessage = null;
      vm.isLoading = true;
      usSpinnerService.spin('spinner-network-accept');
      NetworkManager.acceptRequest(contactRequest.reqParams.token, function (result) {
        vm.validToken = false;
        vm.error203 = true;
        vm.continue = function () {
          nextStep(result);
        };
        $analytics.eventTrack('Accept Invitation', {category: 'People', label: 'Via Link'});
      }, function (error) {
        vm.errorMessage = error;
        usSpinnerService.stop('spinner-network-accept');
        vm.isLoading = false;
      });
    };

    vm.ignore = function () {
      vm.isLoading = true;
      usSpinnerService.spin('spinner-network-accept');
      NetworkManager.ignoreRequest(contactRequest.reqParams.token, function (result) {
        vm.validToken = false;
        vm.error203 = true;
        vm.continue = function () {
          nextStep(result);
        };
        $analytics.eventTrack('Ignore Invitation', {category: 'People', label: 'Via Link'});
      }, function (error) {
        vm.errorMessage = error;
        usSpinnerService.stop('spinner-network-accept');
        vm.isLoading = false;
      });
    };
  }

})();
