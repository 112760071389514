(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name socialShare.provider:Facebook
   *
   * @description
   *
   */
  angular
    .module('socialShare.providers')
    .provider('FacebookSocialShare', FacebookSocialShare);

  function FacebookSocialShare() {
    var config = {
      appId: null,
      version: 'v2.7',
      xfbml: true,
      popupHeight: 500,
      popupWidth: 500
    };

    function setConfig (newConfig) {
      if (angular.isObject(newConfig)) {
        angular.forEach(newConfig, function (value, paramName) {
          if (angular.isDefined(config[paramName])) {
            config[paramName] = value;
          } else {
            throw 'Invalid configuration option';
          }
        });
      }
    }

    return {
      $get: ['$q', '$window', '$rootScope', function ($q, $window, $rootScope) {
        var $facebook = $q.defer();

        $facebook.init = function (newConfig) {
          setConfig(newConfig);

          if (!angular.isDefined(config.appId) || config.appId === null) {
            throw 'Missing APP ID';
          }

          if (!$window.FB) {
            $.getScript('//connect.facebook.net/en_US/sdk.js', function () {
              $window.FB.init({
                appId: config.appId,
                version: config.version,
                xfbml: config.xfbml
              });
              $rootScope.$broadcast('fb.init');
            });
          }
        };

        $rootScope.$on('fb.init', function () {
          $facebook.resolve(true);
        });

        $facebook.share = function (data) {
          var params = {
            method: 'feed',
            link: data.url
          };
          if (data.title) {
            params.caption = data.title;
          }

          if (data.name) {
            params.name = data.name;
          }

          if (data.description) {
            params.description = data.description;
          }

          if (data.picture) {
            params.picture = data.picture;
          }

          $window.FB.ui(params);
        };

        return $facebook;
      }]
    };
  }
}());
