(function () {
  'use strict';

  /* @ngdoc object
   * @name Registration
   * @requires $stateProvider
   *
   * @description
   *
   */
  angular
    .module('Registration', [
      'ui.router',
      'match'
    ]);

  angular
    .module('Registration')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('verify', {
        url: '/verify/{token}',
        templateUrl: 'registration/account-verification.tpl.html',
        controller: 'AccountVerificationCtrl as accountVerification',
        authenticate: false,
        resolve: {
          userDetail: ['$stateParams', 'Restangular', function ($stateParams, Restangular) {
            return Restangular.one('Users').one('verify', $stateParams.token).get().then(function (result) {
              return result;
            }, function (result) {
              return {
                errorMessageUser: result.data.errorMessageUser,
                errorCode: result.data.errorCode
              };
            });
          }]
        }
      });
  }

})();
