angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/work/work-detail-bids.tpl.html',
    '<div class="work-detail-bids-container"  ng-if="workDetail.bidsLoader.records.length > 0">\n' +
    '  <div class="row">\n' +
    '    <div class="small-15 columns">\n' +
    '      <span class="panel-text-heading">My Bids</span>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row cursor-pointer" ng-repeat="application in workDetail.bidsLoader.records"\n' +
    '       ng-class="{\'accepted-bid\': application.accepted, \'withdrawn-bid\': application.withDrawn}"\n' +
    '       ng-click="workDetail.openMyBid($index);">\n' +
    '\n' +
    '    <div class="small-3 columns map-panel-profile-photo">\n' +
    '      <user-widget image-class="circle45" user="application.userApplicant" width="45" height="45"></user-widget>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="small-12 columns">\n' +
    '      <div class="small-8 columns">\n' +
    '          <span class="panel-text-heading">\n' +
    '            <user-widget-name user="application.userApplicant"></user-widget-name>\n' +
    '          </span>\n' +
    '      </div>\n' +
    '      <div class="small-7 columns text-right">\n' +
    '          <span class="panel-text-heading">\n' +
    '            {{application.bid | currency:\'$\':0}}\n' +
    '          </span>\n' +
    '      </div>\n' +
    '      <div class="small-15 columns">\n' +
    '          <span class="info-text">\n' +
    '            <i class="fa fa-clock-o"></i> {{workDetail.applicationDate(application)}}\n' +
    '          </span>\n' +
    '      </div>\n' +
    '      <div class="small-15 columns">\n' +
    '        {{application.comments}}\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row">\n' +
    '    <div class="small-15 columns text-center">\n' +
    '      <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}"\n' +
    '            spinner-key="spinner-work-detail-bids"></span>\n' +
    '      <a ng-show="workDetail.bidsLoader.hasMore() && !workDetail.bidsLoader.isLoading" ng-click="workDetail.loadBids()">Load more ...</a>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
