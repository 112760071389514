(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name Filters.filter:experienceDate
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('Filters')
    .filter('experienceDate', experienceDate);

  function experienceDate($filter) {
    return function (dateObj) {
      var
        dateParts,
        output = 'Present',
        tmpDate,
        day;

      if (!dateObj.isEmpty) {
        dateParts = dateObj.date.split('-');
        if (dateObj.isEmptyMonth) {
          output = dateParts[0]; // year only
        } else {
          day = parseInt(dateParts[2], 10);
          day = (day < 1 ? 1 : day);
          tmpDate = new Date(parseInt(dateParts[0], 10), parseInt(dateParts[1], 10) - 1, day);
          output = $filter('date')(tmpDate.getTime(), 'MMM y'); // year and month
        }
      }
      return output;
    };
  }

})();
