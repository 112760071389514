(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name Filters.filter:updatePicture
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('Filters')
    .filter('updatePicture', updatePicture);

  function updatePicture(Defaults, Auth) {
    return function (input, width, height) {
      var
        temp = '',
        params = {},
        paramsStr;
      if (input !== null && angular.isDefined(input) && angular.isDefined(input.id)) {
        temp = Defaults.baseUrl + '/UpdateFiles/file';
        params.id = input.id;
        params = angular.extend(params, Auth.getSecurityParamsForUrl());

        if (!isNaN(width) && width > 0) {
          params.w = width;
        }

        if (!isNaN(height) && height > 0) {
          params.h = height;
        }

        paramsStr = $.param(params);
        if (paramsStr !== '') {
          temp += '?' + paramsStr;
        }
      }
      return temp;
    };
  }

})();
