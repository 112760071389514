(function () {
  'use strict';

  /* @ngdoc object
   * @name Experience
   * @requires $stateProvider
   *
   * @description
   *
   */
  angular
    .module('Experience', [
      'ui.router'
    ]);

  angular
    .module('Experience')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('experienceValidation', {
        url: '/experience/validate/{token}',
        templateUrl: 'experience/experience-validation.tpl.html',
        controller: 'ExperienceValidationCtrl as experienceValidation',
        resolve: {
          experienceDetail: ['$stateParams', 'ExperienceManager', function ($stateParams, ExperienceManager) {
            return ExperienceManager.validationDetail($stateParams.token).then(function (result) {
              return result;
            }, function (result) {
              return {
                errorMessageUser: result.data.errorMessageUser,
                errorCode: result.data.errorCode
              };
            });
          }]
        }
      });
  }

})();
