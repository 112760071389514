(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name panels.work.controller:WorkDetailCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Work')
    .controller('WorkDetailCtrl', WorkDetailCtrl);

  function WorkDetailCtrl($scope, WorkPanelManager, WorkManager, RecordsSet, $filter, WorkDetailPanelManager,
                          usSpinnerService, $timeout, $state, InitHelper, Restangular) {
    var vm = this;
    vm.jobPosition = $scope.detail;
    vm.myProfile = $scope.userProfile;
    vm.messagesParameters = {
      idJobPosition: $scope.detail.id
    };
    vm.bidsLoader = RecordsSet.getLoader('JobApplications', 'index', {
      idJobPosition: $scope.detail.id
    });
    vm.updatesLoader = RecordsSet.getLoader('Updates', 'index', {
      entityFilter: {
        idJobPosition: $scope.detail.id
      },
      limit: 5
    });

    vm.conversationsLoader = RecordsSet.getLoader('Messages', 'conversations', {
      idJobPosition: $scope.detail.id
    });

    vm.closePanel = function () {
      WorkPanelManager.panels.close('detail');
    };

    vm.placeBid = function () {
      WorkManager.showApplyForJobDialog(vm.jobPosition);
    };

    vm.applicationDate = function (application) {
      var d = new Date(application.dateApplication);
      return $filter('date')(d, 'dd/MM/yyyy');
    };

    vm.jobPositionFileUrl = function (file) {
      return '/WorkFiles/download?id=' + file.id;
    };

    vm.getTitleForSharing = function () {
      return vm.jobPosition.jobTitle.name + ', ' + vm.jobPosition.reference;
    };

    vm.openConversation = function (contactProfile) {
      WorkDetailPanelManager.panels.open('messagesThread', {
        contactProfile: contactProfile,
        messagesParameters: vm.messagesParameters
      });
    };

    vm.openConversationById = function (id) {
      var $index = _.findIndex(vm.conversationsLoader.records, {contactProfile: {id: id}});
      if ($index !== -1) {
        vm.openConversation(vm.conversationsLoader.records[$index].contactProfile);
      } else {
        Restangular.one('Users').one('profile').get({id: id}).then(function (result) {
          vm.openConversation(result.plain());
        });
      }
    };

    vm.openConversationByIndex = function ($index) {
      $state.go('map.work.detail.messages', {
        id: InitHelper.encryptId($scope.detail.id),
        idUser: InitHelper.encryptId(vm.conversationsLoader.records[$index].contactProfile.id)
      });
    };

    vm.isUserWorkOwner = function () {
      return (vm.jobPosition.customer.user.id === $scope.userProfile.idUser);
    };

    function load(recordSet, spinnerId) {
      usSpinnerService.spin(spinnerId);
      recordSet.load()
        .then(function () {
          usSpinnerService.stop(spinnerId);
        }, function () {
          usSpinnerService.stop(spinnerId);
        });
    }

    vm.loadUpdates = function () {
      load(vm.updatesLoader, 'spinner-work-detail-updates');
    };

    vm.loadBids = function () {
      load(vm.bidsLoader, 'spinner-work-detail-bids');
    };

    vm.loadConversations = function () {
      if (vm.isUserWorkOwner()) {
        load(vm.conversationsLoader, 'spinner-work-detail-conversations');
      }
    };

    vm.openUpdateDetail = function (update) {
      WorkDetailPanelManager.panels.open('updateReplies', {
        parentUpdate: update,
        panelsManager: WorkDetailPanelManager.panels
      }).then(function (result) {
        if (result === 'deleted') {
          var $index = _.findIndex(vm.updatesLoader.records, {id: update.id});
          if ($index !== -1) {
            vm.updatesLoader.records.splice($index, 1);
          }
        }
      });
    };

    vm.openUpdateByIndex = function ($index) {
      $state.go('map.work.detail.updates', {
        id: InitHelper.encryptId($scope.detail.id),
        idUpdate: InitHelper.encryptId(vm.updatesLoader.records[$index].id)
      });
    };

    vm.openUpdateById = function (id) {
      var $index = _.findIndex(vm.updatesLoader.records, {id: id});
      if ($index !== -1) {
        vm.openUpdateDetail(vm.updatesLoader.records[$index]);
      } else {
        Restangular.one('Updates').one('index').get({id: id}).then(function (result) {
          vm.openUpdateDetail(result.plain());
        });
      }
    };

    vm.openMyBid = function ($index) {
      $state.go('map.workMyBids.detail', {
        id: InitHelper.encryptId(vm.bidsLoader.records[$index].id)
      });
    };

    $timeout(function () {
      vm.loadBids();
      vm.loadUpdates();
      vm.loadConversations();
    }, 100);

    $scope.$on('event:work-open-detail-updates', function ($event, data) {
      vm.openUpdateById(data.id);
    });

    $scope.$on('event:work-open-detail-messages', function ($event, data) {
      vm.openConversationById(data.id);
    });
  }

})();
