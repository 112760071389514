angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('home/signin.tpl.html',
    '<div id="home-page" class="image-background">\n' +
    '  <div id="signin-form" class="signin-form small-14 medium-10 large-7">\n' +
    '    <div class="row">\n' +
    '      <div class="small-15 columns">\n' +
    '        <form name="signinForm" role="signinForm" novalidate>\n' +
    '          <div class="row">\n' +
    '            <div class="small-4 columns">\n' +
    '              <h3>SIGN IN</h3>\n' +
    '            </div>\n' +
    '            <div class="small-11 columns text-right">\n' +
    '              <a href="/info/" target="{{vm.linksTarget}}"><img src="images/mineler.png" class="home-page-logo" /></a>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <ul ng-hide="vm.isLoading" class="small-block-grid-3 signin-social">\n' +
    '                <li><a ng-click="vm.socialSignIn(\'facebook\')"><i class="fa fa-facebook"></i></a></li>\n' +
    '                <li><a ng-click="vm.socialSignIn(\'linkedin\')"><i class="fa fa-linkedin"></i></a></li>\n' +
    '                <li><a ng-click="vm.socialSignIn(\'google\')"><i class="fa fa-google-plus"></i></a></li>\n' +
    '              </ul>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns text-right">\n' +
    '              First time here? <a href="/info/" target="{{vm.linksTarget}}"\n' +
    '                                  analytics-on\n' +
    '                                  analytics-category="Sign In">Learn more.</a>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <input type="email" required="required" ng-model="vm.email" name="email" placeholder="Email Address"/>\n' +
    '              <small class="signin-error" ng-show="signinForm.email.$dirty && signinForm.email.$error.required">Missing email\n' +
    '              </small>\n' +
    '              <small class="signin-error" ng-show="signinForm.email.$dirty && signinForm.email.$error.email">This is not a\n' +
    '                valid email\n' +
    '              </small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div class="small-15 columns">\n' +
    '              <input type="password" ng-required="true" ng-model="vm.password" name="password"\n' +
    '                     placeholder="Password"/>\n' +
    '              <small class="signin-error" ng-show="signinForm.password.$dirty && signinForm.password.$error.required">Missing\n' +
    '                password\n' +
    '              </small>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div class="small-10 columns">\n' +
    '              <small class="signin-error" ng-show="vm.errorMessage">{{vm.errorMessage}}</small>\n' +
    '            </div>\n' +
    '            <div class="small-5 columns text-right sign-buttons-container">\n' +
    '              <span us-spinner="{radius:3, width:3, length: 8, color: \'#ffffff\'}" spinner-key="spinner-signin"></span>\n' +
    '              <button ng-hide="vm.isLoading" class="button positive" ng-click="vm.signIn()" ng-disabled=\'signinForm.$invalid\'>\n' +
    '                SIGN IN\n' +
    '              </button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </form>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row">\n' +
    '      <div class="small-15 columns">\n' +
    '        <a target="{{vm.linksTarget}}" ui-sref="lostPassword"\n' +
    '           analytics-on\n' +
    '           analytics-event="Open Lost Password Form"\n' +
    '           analytics-category="Sign In">Forgot your password?</a>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row">\n' +
    '      <div class="small-15 columns">\n' +
    '        Don\'t have an account? Use one of the social services above or <a ng-click="vm.goToSignup()"\n' +
    '                                                                          analytics-on\n' +
    '                                                                          analytics-event="Open Sign Up Form"\n' +
    '                                                                          analytics-category="Sign In">click here</a>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
