angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/my-work/my-work-work-item.tpl.html',
    '<div class="small-4 columns map-panel-profile-photo">\n' +
    '  <img ng-src="{{work.customer.user | profilePicture:45}}" class="circle45">\n' +
    '</div>\n' +
    '<div class="small-9 columns">\n' +
    '  <div class="small-15 columns">\n' +
    '          <span class="panel-text-heading">\n' +
    '            {{work.jobTitle.name}}, {{work.reference}}\n' +
    '          </span><br/>\n' +
    '          <span class="panel-text-heading">\n' +
    '            {{work.project.name}}, {{work.customer.companyName}}\n' +
    '          </span><br/>\n' +
    '    {{work.description | truncate: 50: \'...\': true}}\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="small-2 columns">\n' +
    '          <span class="round label" ng-class="{success: work.bidAccepted, info: !work.bidAccepted}">\n' +
    '            {{work.bidsCount}}\n' +
    '          </span>\n' +
    '</div>\n' +
    '');
}]);
