angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('social-share/social-share-directive.tpl.html',
    '<div class="social-share-container">\n' +
    '  <span>\n' +
    '    <a class="social-share-button-share" data-options="align:left" data-dropdown="dropdown-share{{socialShare.id}}">\n' +
    '      <i class="fa fa-share-alt"></i> Share\n' +
    '    </a>\n' +
    '  </span>\n' +
    '\n' +
    '  <ul id="dropdown-share{{socialShare.id}}" class="f-dropdown drop-left" data-dropdown-content>\n' +
    '    <li><a class="social-share-button" ng-click="socialShare.share(\'facebook\')"><i class="fa fa-facebook-square"></i> Facebook</a></li>\n' +
    '    <li><a class="social-share-button" ng-click="socialShare.share(\'linkedin\')"><i class="fa fa-linkedin"></i> LinkedIn</a></li>\n' +
    '    <li><a class="social-share-button" ng-click="socialShare.share(\'twitter\')"><i class="fa fa-twitter"></i> Twitter</a></li>\n' +
    '    <li><a class="social-share-button" ng-click="socialShare.share(\'google\')"><i class="fa fa-google-plus"></i> Google+</a></li>\n' +
    '  </ul>\n' +
    '\n' +
    '  <script type="text/javascript">\n' +
    '    $(document).foundation({\n' +
    '      dropdown: {\n' +
    '        active_class: \'open\'\n' +
    '      }\n' +
    '    });\n' +
    '  </script>\n' +
    '</div>\n' +
    '');
}]);
