(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Network.service:NetworkManager
   *
   * @description
   *
   */
  angular
    .module('Network')
    .service('NetworkManager', NetworkManager);

  function NetworkManager(Restangular, $modal, $rootScope) {
    var self = this;

    self.quickConnect = function (userProfile, closeCallback, dismissCallback) {
      closeCallback = closeCallback || angular.noop;
      dismissCallback = dismissCallback || angular.noop;
      var modalInstance = $modal.open({
        templateUrl: 'network/quick-connect.tpl.html',
        controller: 'NetworkQuickConnectCtrl as networkQuickConnect',
        resolve: {
          userProfile: function () {
            return userProfile;
          }
        },
        windowClass: 'network-dialog'
      });

      modalInstance.result.then(function (result) {
        closeCallback(result);
      }, function () {
        dismissCallback();
      });
    };

    self.connect = function (idUser, email, firstName, lastName, message, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;
      Restangular.one('Network').customPOST({
        idUser: idUser,
        email: email,
        firstName: firstName,
        lastName: lastName,
        message: message
      }, 'index').then(function (result) {
        successCallback(result);
      }, function (result) {
        var reason = result.data.errorMessage;
        errorCallback(reason, result.data.errorCode);
      });
    };

    self.disconnect = function (idUser, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;
      Restangular.one('Network').one('index').remove({id: idUser}).then(function () {
        $rootScope.$broadcast('event:networkContact-removed', {id: idUser});
        successCallback();
      }, function (result) {
        var reason = result.data.errorMessage;
        errorCallback(reason);
      });
    };

    self.acceptRequest = function (token, successCallback, errorCallback) {
      self.processRequest(token, 'accept', successCallback, errorCallback);
    };

    self.ignoreRequest = function (token, successCallback, errorCallback) {
      self.processRequest(token, 'ignore', successCallback, errorCallback);
    };

    self.resendRequest = function (idUser, message, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;
      Restangular.one('Network').customPOST({
        requestMessage: message
      }, 'resend', {
        id: idUser
      }).then(function (result) {
        successCallback(result);
      }, function (result) {
        var reason = result.data.errorMessage;
        errorCallback(reason);
      });
    };

    self.processRequest = function (token, action, successCallback, errorCallback) {
      successCallback = successCallback || angular.noop;
      errorCallback = errorCallback || angular.noop;
      Restangular.one('Network').customPOST({}, action, {
        token: token
      }).then(function (result) {
        successCallback(result);
      }, function (result) {
        var reason = result.data.errorMessage;
        errorCallback(reason);
      });
    };

    self.requestDetail = function (token) {
      return Restangular.one('Network').one('requestDetail').get({token: token});
    };

    self.getInvitations = function () {
      return Restangular.one('NetworkRequests').getList('index', {limit: -1});
    };
  }

})();
