(function () {
  'use strict';

  /* @ngdoc object
   * @name Feedback
   *
   * @description
   *
   */
  angular
    .module('Feedback', [
      'angularFileUpload'
    ]);

  angular
    .module('Feedback')
    .config(config);

  function config() {
  }

})();
