angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/toolbars/search/search.tpl.html',
    '<div ng-controller="SearchCtrl as search">\n' +
    '  <div id="map-search" class="map-toolbar">\n' +
    '    <div class="row collapse">\n' +
    '      <div class="small-13 columns">\n' +
    '        <angucomplete-alt\n' +
    '          id="map-search-input"\n' +
    '          placeholder="SEARCH"\n' +
    '          pause="100"\n' +
    '          minlength="2"\n' +
    '          remote-url="{{search.searchUrl}}"\n' +
    '          title-field="query"\n' +
    '          remote-url-response-formatter="search.onSearchResponse"\n' +
    '          text-searching="SEARCHING ..."\n' +
    '          input-changed="search.onSearchChanged"\n' +
    '          selected-object="search.onSearchSelect"\n' +
    '          match-class="text-orange"\n' +
    '          focus-in="search.onFocusIn()"\n' +
    '          text-no-results=" "\n' +
    '          override-suggestions="true"\n' +
    '          initial-value="{{search.initialQuery}}"\n' +
    '          template-url="/map/toolbars/search/search-angucomplete.tpl.html"\n' +
    '          />\n' +
    '      </div>\n' +
    '      <div class="small-1 columns">\n' +
    '        <a class="postfix" ng-click="search.search();">\n' +
    '          <i class="fa fa-search"></i>\n' +
    '        </a>\n' +
    '      </div>\n' +
    '      <div class="small-1 columns">\n' +
    '        <a class="postfix" ng-click="search.toggleFilter();">\n' +
    '          <i class="fa fa-chevron-down"></i>\n' +
    '        </a>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div id="map-search-filter" ng-show="search.isFilterVisible"\n' +
    '       ng-include="\'map/toolbars/search/search-filters.tpl.html\'"></div>\n' +
    '  <div id="map-search-results" ng-show="search.isResultVisible">\n' +
    '    <div class="row" ng-show="search.isSearching">\n' +
    '      <div class="small-15 columns map-search-results map-search-results-title">\n' +
    '        LOADING, PLEASE WAIT ...\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row" ng-hide="search.isSearching || search.isFilterEmpty()">\n' +
    '      <div class="small-15 columns map-search-results map-search-filter-preview">\n' +
    '        <a ng-click="search.toggleFilter()">\n' +
    '          FILTER BY\n' +
    '          <span class="map-search-filter-tag map-search-filter-tag-country"\n' +
    '                ng-show="search.filters.idCountry.length">\n' +
    '            {{search.filters.idCountry | searchFilterPreview}}\n' +
    '          </span>\n' +
    '          <span class="map-search-filter-tag map-search-filter-tag-commodity"\n' +
    '                ng-show="search.filters.idCommodity.length">\n' +
    '            {{search.filters.idCommodity | searchFilterPreview}}\n' +
    '          </span>\n' +
    '          <span class="map-search-filter-tag map-search-filter-tag-project-type"\n' +
    '                ng-show="search.filters.idProjectType.length">\n' +
    '            {{search.filters.idProjectType | searchFilterPreview}}\n' +
    '          </span>\n' +
    '        </a>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row" ng-hide="search.isSearching">\n' +
    '      <div class="small-15 columns map-search-results">\n' +
    '        <div ng-include="\'map/toolbars/search/search-results-network.tpl.html\'"></div>\n' +
    '        <div ng-include="\'map/toolbars/search/search-results-suggestions.tpl.html\'"></div>\n' +
    '        <div ng-include="\'map/toolbars/search/search-results-companies.tpl.html\'"></div>\n' +
    '        <div ng-include="\'map/toolbars/search/search-results-projects.tpl.html\'"></div>\n' +
    '        <div ng-show="search.isResultEmpty()">\n' +
    '          NO RESULTS\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
