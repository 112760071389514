angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('widgets/helper.html',
    '<!DOCTYPE html>\n' +
    '<html>\n' +
    '<head lang="en">\n' +
    '  <meta charset="UTF-8">\n' +
    '  <title>Mineler Helper</title>\n' +
    '  <script type="text/javascript" src="/js/widgets-helper.js"></script>\n' +
    '</head>\n' +
    '<body>\n' +
    '<script>\n' +
    '  (function () {\n' +
    '    "use strict";\n' +
    '    window.MinelerHelper.init();\n' +
    '  })();\n' +
    '</script>\n' +
    '</body>\n' +
    '</html>\n' +
    '');
}]);
