(function () {
  'use strict';

  /* @ngdoc object
   * @name Components
   * @requires $stateProvider
   *
   * @description
   *
   */
  angular
    .module('Components', [
    ]);

  angular
    .module('Components')
    .config(config);

  function config() {
  }

})();
