(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name Panels.Profile.controller:ProfileEditContactCtrl
   *
   * @description
   *
   */
  angular
    .module('Map.Panels.Profile')
    .controller('ProfileEditContactCtrl', ProfileEditContactCtrl);

  function ProfileEditContactCtrl(PanelsProfileManager, ISO3166, $scope, Auth, usSpinnerService,
                                  Restangular, $analytics) {
    var vm = this;
    vm.isSaving = false;
    vm.countries = [];
    angular.forEach(ISO3166.codeToCountry, function (value, key) {
      vm.countries.push({code: key, name: value});
    });

    function loadStates(countryCode) {
      Restangular.one('Countries').getList('index', {
        relations: 'states',
        'filter[abbrevation]': countryCode,
        'relation[states][limit]': -1
      }).then(function (result) {
        var states = result[0].states;
        vm.states = states.records;
      });
      vm.lastSelectedCountryCode = countryCode;
    }

    vm.profile = angular.copy($scope.userProfile.profile);
    vm.states = null;
    vm.lastSelectedCountryCode = vm.profile.country.abbrevation;
    loadStates(vm.lastSelectedCountryCode);

    vm.closeEditPanel = function () {
      PanelsProfileManager.panels.close('profileEditContact');
    };

    vm.saveProfile = function () {
      vm.isSaving = true;
      usSpinnerService.spin('spinner-profile-edit');
      vm.editProfileErrorMessage = null;
      vm.profile.country.idCountry = 0;
      Auth.saveSession(vm.profile, function () {
        vm.isSaving = false;
        usSpinnerService.stop('spinner-profile-edit');
        vm.closeEditPanel();
        $analytics.eventTrack('Update Contact Details', {category: 'Profile'});
      }, function (error) {
        vm.isSaving = false;
        vm.editProfileErrorMessage = error;
        usSpinnerService.stop('spinner-profile-edit');
      });
    };

    $scope.$watch(function () {
      return vm.profile.country.abbrevation;
    }, angular.bind(vm, function (countryCode) {
      if (vm.lastSelectedCountryCode !== countryCode) {
        vm.states = null;
        loadStates(countryCode);
      }
    }));
  }

})();
