angular.module('Mineler').run(['$templateCache', function($templateCache) {
  $templateCache.put('map/panels/my-work/my-work-my-bid-item.tpl.html',
    '<div class="small-5 columns map-panel-profile-photo">\n' +
    '  <img ng-src="{{application.jobPosition.customer.user | profilePicture:45}}" class="circle45">\n' +
    '</div>\n' +
    '\n' +
    '<div class="small-10 columns">\n' +
    '  <div class="small-8 columns">\n' +
    '          <span class="panel-text-heading">\n' +
    '            {{application.jobTitle.name}}\n' +
    '          </span>\n' +
    '  </div>\n' +
    '  <div class="small-7 columns text-right">\n' +
    '          <span class="panel-text-heading">\n' +
    '            {{application.bid | currency:\'$\':0}}\n' +
    '          </span>\n' +
    '  </div>\n' +
    '  <div class="small-15 columns">\n' +
    '          <span class="info-text">\n' +
    '            <i class="fa fa-clock-o"></i> {{myBids.applicationDate(application)}}\n' +
    '          </span><br/>\n' +
    '          <span class="panel-text-heading">\n' +
    '            <span ng-if="application.jobPosition.project.name">{{application.jobPosition.project.name}},</span> {{application.jobPosition.customer.companyName}}\n' +
    '          </span>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
