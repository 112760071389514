(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home-module.controller:SignInCtrl
   *
   * @description
   *
   */
  angular
    .module('home')
    .controller('SignInCtrl', SignInCtrl);

  function SignInCtrl(Auth, $state, usSpinnerService, SocialAccounts, $stateParams, $analytics, WidgetsHelper) {
    var vm = this;
    vm.errorMessage = null;
    vm.isLoading = false;

    if ($stateParams.inPopup === true) {
      vm.linksTarget = '_blank';
    } else {
      vm.linksTarget = '_self';
    }

    function successCallback () {
      if ($stateParams.inPopup === true && angular.isDefined($stateParams.apiKey)) {
        WidgetsHelper.authorise($stateParams.apiKey);
      } else if (angular.isDefined($stateParams.nextState) && angular.isDefined($stateParams.nextState.name)) {
        $state.go($stateParams.nextState.name, $stateParams.nextState.params || {});
      } else {
        $state.go('map');
      }
    }

    vm.signIn = function () {
      usSpinnerService.spin('spinner-signin');
      vm.isLoading = true;
      Auth.passwordLogin(vm.email, vm.password, function () {
        $analytics.eventTrack('Email and Password', {category: 'Sign In'});
        successCallback();
      }, function (reason) {
        vm.errorMessage = reason;
        vm.isLoading = false;
        usSpinnerService.stop('spinner-signin');
      });
    };

    vm.socialSignIn = function (provider) {
      vm.isLoading = true;
      vm.errorMessage = null;
      usSpinnerService.spin('spinner-signin');
      SocialAccounts.signIn(provider, false).then(function () {
        successCallback();
      }, function (reason) {
        vm.isLoading = false;
        usSpinnerService.stop('spinner-signin');
        vm.errorMessage = reason;
      });
    };

    vm.goToSignup = function () {
      $state.go('signup', {
        apiKey: $stateParams.apiKey,
        inPopup: $stateParams.inPopup
      });
    };
  }

})();
